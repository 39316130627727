import * as React from "react";
import {
  MultiSelect,
  Grid,
  TextInput,
  Button,
  Modal,
  Title,
  useMantineTheme,
  Text,
  Stack,
  Flex,
  Tooltip,
  ActionIcon,
} from "@mantine/core";

import DataDisplay from "./DataDisplay";
import { useReconsetBasic, useReconsetColumnsDefinition } from "../../hooks";
import { getDatasetData } from "../../api";
import { UserContext } from "../../misc/UserContext";
import AddToMatch from "./AddToMatch";
import { getSystemStatus } from "../ReconsetUtils";
import { styles } from "../../styles";
import Loading from "../../ui/Loading";
import { MultiSelectCreatable } from "../../ui/MultiSelectCreatable";
import {
  IconChevronLeft,
  IconChevronRight,
  IconPlus,
} from "@tabler/icons-react";

export const escapeRegExp = (
  str: string // or better use 'escape-string-regexp' package
) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

// TODO: Display minimal columns
// TODO: Detail data display modal
// TODO: Entry level update

function getIsMatch(filter: string[]) {
  const isMatch = (data: any) => {
    let found = filter.every((search) => {
      const re = new RegExp(escapeRegExp(search), "i");
      let item = Object.keys(data).find((column) => {
        return re.test(data[column]);
      });
      if (item) {
        return true;
      }
      return false;
    });
    return found;
  };
  return isMatch;
}

export default function DatasetData({
  entityId,
  id,
  runId,
  runSeq,
  dataset,
  type,
  status,
  recon,
  setSelectedEntries,
}: {
  entityId: string | null | undefined;
  id: string | null | undefined;
  runId: string | null | undefined;
  runSeq: string | null | undefined;
  dataset: string | null | undefined;
  type: string | null | undefined;
  status: string | null | undefined;
  recon: string | null | undefined;
  setSelectedEntries: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const { state: applicationState } = React.useContext(UserContext);

  const { data: columnsDefinition } = useReconsetColumnsDefinition(
    entityId,
    id
  );

  const theme = useMantineTheme();

  const reconColumnsDefinition = columnsDefinition?.recons.find(
    (reconColumns) => reconColumns.id === recon
  );

  const { data: reconset } = useReconsetBasic(entityId, id);

  const reconDefinition = reconset?.definition.recons.find(
    (reconColumns) => reconColumns.id === recon
  );

  const [filterList, setFilterList] = React.useState<
    { value: string; label: string }[]
  >([]);
  const [filter, setFilter] = React.useState<string[]>([]);

  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState<{ __id: string }[]>([]);
  const [filteredData, setFilteredData] = React.useState<{ __id: string }[]>(
    []
  );

  const [selection, setSelection] = React.useState<string[]>([]);
  const [minId, setMinId] = React.useState("");
  const [maxId, setMaxId] = React.useState("");

  const [display, setDisplay] = React.useState<string>("none");

  const toggleRow = (__id: string) => {
    let newSelectedEntries: string[] = [];

    if (selection.includes(__id)) {
      newSelectedEntries = selection.filter((entry) => entry !== __id);
    } else {
      newSelectedEntries = [...selection, __id];
    }

    setSelection((current) =>
      current.includes(__id)
        ? current.filter((item) => item !== __id)
        : [...current, __id]
    );
    setSelectedEntries(newSelectedEntries);
  };

  const toggleAll = () => {
    let newSelectedEntries: string[] = [];
    if (selection.length === data.length) {
      newSelectedEntries = [];
    } else {
      newSelectedEntries = data.map((entry) => entry.__id);
    }

    setSelection((current) =>
      current.length === data.length ? [] : data.map((item) => item.__id)
    );
    setSelectedEntries(newSelectedEntries);
  };

  React.useEffect(() => {
    const getData = async () => {
      let values = await getDatasetData(
        entityId,
        id,
        dataset,
        "20",
        type,
        runId,
        runSeq,
        "next",
        status,
        recon,
        "",
        "",
        "",
        applicationState.reconset.queryColumnsValue
      );
      setData(values);
      const newFilteredData = values.filter(getIsMatch(filter));
      setFilteredData(newFilteredData);
      if (values && values.length > 0) {
        setMinId(values[0].__id);
        setMaxId(values[values.length - 1].__id);
      }
    };
    getData();
  }, [entityId, id, dataset, type, runId, status, recon]);

  React.useEffect(() => {
    const newFilteredData = data.filter(getIsMatch(filter));
    setFilteredData(newFilteredData);
  }, [filter]);

  async function refresh() {
    setMinId("");
    setMaxId("");
    setSelection([]);
    let values = await getDatasetData(
      entityId,
      id,
      dataset,
      "20",
      type,
      runId,
      runSeq,
      "next",
      status,
      recon,
      "",
      "",
      "",
      applicationState.reconset.queryColumnsValue
    );
    setData(values);
    const newFilteredData = values.filter(getIsMatch(filter));
    setFilteredData(newFilteredData);
    if (values && values.length > 0) {
      setMinId(values[0].__id);
      setMaxId(values[values.length - 1].__id);
    }
  }

  if (reconset) {
    return (
      <Stack mt="xl">
        <Flex align={"end"} gap={theme.spacing.xs}>
          <Text
            fw={"bold"}
            mb="0"
            c="dark.6"
            style={{
              borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
              paddingLeft: theme.spacing.xs,
            }}
          >
            {dataset === "primary" ? "Primary Dataset" : "Secondary Dataset"}
          </Text>

          <Tooltip label="Add to match">
            <ActionIcon
              variant="light"
              size="lg"
              onClick={async () => {
                setDisplay("add");
              }}
              disabled={selection.length === 0}
            >
              <IconPlus style={{ width: "70%", height: "70%" }} stroke={2} />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Previous">
            <ActionIcon
              variant="light"
              size="lg"
              onClick={async () => {
                const values = await getDatasetData(
                  entityId,
                  id,
                  dataset,
                  "20",
                  type,
                  runId,
                  runSeq,
                  "previous",
                  status,
                  recon,
                  minId,
                  maxId,
                  search,
                  applicationState.reconset.queryColumnsValue
                );
                setData(values);
                const newFilteredData = values.filter(getIsMatch(filter));
                setFilteredData(newFilteredData);
                if (values && values.length > 0) {
                  setMinId(values[0].__id);
                  setMaxId(values[values.length - 1].__id);
                } else {
                  setMinId("-1");
                }
              }}
              disabled={minId === "-1"}
            >
              <IconChevronLeft
                style={{ width: "70%", height: "70%" }}
                stroke={2}
              />
            </ActionIcon>
          </Tooltip>

          <Tooltip label="Next">
            <ActionIcon
              variant="light"
              size="lg"
              onClick={async () => {
                const values = await getDatasetData(
                  entityId,
                  id,
                  dataset,
                  "20",
                  type,
                  runId,
                  runSeq,
                  "next",
                  status,
                  recon,
                  minId,
                  maxId,
                  search,
                  applicationState.reconset.queryColumnsValue
                );
                setData(values);
                const newFilteredData = values.filter(getIsMatch(filter));
                setFilteredData(newFilteredData);
                if (values && values.length > 0) {
                  setMinId(values[0].__id);
                  setMaxId(values[values.length - 1].__id);
                } else {
                  setMaxId("-1");
                }
              }}
              disabled={maxId === "-1"}
            >
              <IconChevronRight
                style={{ width: "70%", height: "70%" }}
                stroke={2}
              />
            </ActionIcon>
          </Tooltip>
        </Flex>

        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={5}>
            <MultiSelectCreatable
              data={filterList}
              placeholder="Filter"
              onChange={(value) => {
                setFilter(value);
              }}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            <TextInput
              value={search}
              placeholder="Query (ex: COLUMN1 = 'ABC' AND COLUMN2 IN ('1','2'))"
              onChange={(event) => setSearch(event.currentTarget.value)}
            />
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              variant="default"
              fullWidth
              onClick={async () => {
                const values = await getDatasetData(
                  entityId,
                  id,
                  dataset,
                  "20",
                  type,
                  runId,
                  runSeq,
                  "next",
                  status,
                  recon,
                  "",
                  "",
                  search,
                  applicationState.reconset.queryColumnsValue
                );
                setData(values);
                const newFilteredData = values.filter(getIsMatch(filter));
                setFilteredData(newFilteredData);
                if (values && values.length > 0) {
                  setMinId(values[0].__id);
                  setMaxId(values[values.length - 1].__id);
                }
              }}
            >
              Fetch
            </Button>
          </Grid.Col>
        </Grid>
        <DataDisplay
          data={filteredData} // Pass as array
          table="details"
          definition={
            reconColumnsDefinition && reconColumnsDefinition?.primaryDataSet
              ? reconColumnsDefinition?.primaryDataSet
              : []
          }
          links={[
            {
              name: "__matchids",
              split: true,
              splitBy: ",",
              link: `/app/reconsets/view/match?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&recon=${recon}&matchId=`,
            },
          ]}
          enableSelection
          selection={selection}
          toggleAll={toggleAll}
          toggleRow={toggleRow}
        />

        <>
          <Modal
            centered
            size={"xl"}
            opened={display === "add"}
            onClose={() => setDisplay("none")}
            title={<Title order={4}>Draft Match</Title>}
          >
            <AddToMatch
              id={id as string}
              entityId={entityId as string}
              runId={runId as string}
              recon={recon as string}
              dataset={dataset as string}
              selection={selection}
              closeDialog={() => setDisplay("none")}
              systemStatusLOV={getSystemStatus("ALL")}
              userStatusLOV={(reconset.definition.userStatus || []).map(
                (status) => {
                  return { label: status, value: status };
                }
              )}
            />
          </Modal>
        </>
      </Stack>
    );
  } else {
    return (
      <div style={styles.viewMain}>
        <div style={styles.viewBody} id="body">
          <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
            <Grid.Col span={4}>
              <Loading />
            </Grid.Col>
          </Grid>
        </div>
      </div>
    );
  }
}
