import * as React from "react";
import { Skeleton } from "@mantine/core";

function Loading() {
  return (
    <div data-testid="loading">
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
    </div>
  );
}

export default Loading;
