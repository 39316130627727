import { useSearchParams } from "react-router-dom";

import { useTask } from "../hooks";
import { styles } from "../styles";
import {
  Badge,
  Divider,
  Flex,
  Grid,
  Stack,
  Text,
  Title,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import Loading from "../ui/Loading";
import { getColor } from "../Reconset/ReconsetUtils";
import AddComment from "./AddComment";
import TaskActvities from "./TaskActivities";
import TaskCollaborators from "./TaskCollaborators";
import TaskActions from "./TaskActions";
import dayjs from "dayjs";

function Task() {
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");

  const { data: task } = useTask(entityId, id);

  const theme = useMantineTheme();

  if (task) {
    return (
      <div style={{ marginTop: theme.spacing.lg }}>
        <div id="header">
          <Grid
            align="flex-start"
            gutter={"xs"}
            mt="xs"
            // pl="md"
            // pr="md"
            style={{
              display: "flex",
              alignItems: "flex-end",
              // borderLeft: "5px solid #12B886",
            }}
            columns={12}
          >
            <Grid.Col span={3}>
              <Flex align={"end"} gap={theme.spacing.xs}>
                <Stack gap={0}>
                  <Title order={3} c="dark">
                    {task.name}
                  </Title>
                  <Text size="sm" c="dark" truncate="end">
                    {task.description}
                  </Text>
                </Stack>
                <TaskActions task={task} />
              </Flex>
            </Grid.Col>
          </Grid>

          <Flex mt="xs" gap="xs" mb="xs" align={"center"}>
            <Text size="sm" inline c="dimmed">
              {dayjs(task.makerDate).format("YYYY-MM-DD HH:MM")}
            </Text>
            <Divider orientation="vertical" />
            <Text size="sm" inline c="dimmed">
              {task.reconsetName}
            </Text>

            <Divider orientation="vertical" />
            <Text size="sm" inline c="dimmed">
              {`Run Id - ${task?.details?.runId}`}
            </Text>
            <Divider orientation="vertical" />

            <Text size="sm" inline c="dimmed">
              {task.type}
            </Text>

            <Divider orientation="vertical" />

            {task.owner && (
              <Text size="sm" inline c="dimmed">
                {`${task.ownerName} - [ ${task.owner} ]`}
              </Text>
            )}

            <Divider orientation="vertical" />
            <Flex align={"flex-start"}>
              <Badge
                radius="xs"
                color={getColor(task.status)}
                variant="light"
                style={{ marginRight: theme.spacing.xs }}
              >
                {task.status}
              </Badge>
              <Badge
                radius="xs"
                color={getColor(task.priority)}
                variant="light"
                style={{ marginRight: theme.spacing.xs }}
              >
                {task.priority}
              </Badge>
            </Flex>
          </Flex>
        </div>
        <Divider size="lg" color="dark.2" />

        <div id="body" style={{ ...styles.viewBody, flexDirection: "row" }}>
          <Grid
            align="flex-start"
            gutter={"xs"}
            mb="xs"
            style={{ width: "100%" }}
          >
            <Grid.Col span={6}>
              <AddComment id={task.id} entityId={task.entityId} />
              <TaskActvities task={task} />
            </Grid.Col>

            <Grid.Col span={4}>
              <TaskCollaborators
                id={task.id}
                entityId={task.entityId}
                collaborators={task.collborators}
              />
            </Grid.Col>
          </Grid>
        </div>
      </div>
    );
  } else {
    return (
      <div style={styles.viewMain}>
        <div style={styles.viewBody} id="body">
          <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
            <Grid.Col span={4}>
              <Loading />
            </Grid.Col>
          </Grid>
        </div>
      </div>
    );
  }
}

export default Task;
