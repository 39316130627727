import * as React from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import { Text, ActionIcon, Grid } from "@mantine/core";
import {
  IconChevronRight,
  IconChevronDown,
  IconPlus,
} from "@tabler/icons-react";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import Where from "./Where";
import Having from "./Having";
import Order from "./Order";
import Columns from "./Columns";
import { useDataset } from "../../../hooks";
import { getColumnsLOV } from "../../ReconsetUtils";
import { CMultiSelect } from "@supportinitiative/react-hook-form-mantine";

function Query({
  name,
  control,
  type,
  recon,
  label,
}: {
  name:
    | `definition.recons.${number}.definition.actions.${number}.definition.left`
    | `definition.recons.${number}.definition.actions.${number}.definition.right`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchFor.left`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchIn.left`
    | "left"
    | "right";
  control: Control<any>;
  type: "PRIMARY" | "SECONDARY";
  recon: any;
  label?: string;
}) {
  const { state: reconsetCVMState } = React.useContext(ReconsetCVMContext);

  let [isOpen, setIsOpen] = React.useState(false);

  const columnsArray = useFieldArray({
    control,
    name: `${name}.additionalColumns`,
  });

  const primaryReconDataset = recon.definition.primaryDataset;

  const secondaryReconDataset = recon.definition.secondaryDataset;

  const primaryDatasetId = primaryReconDataset.id;

  const secondaryDatasetId = secondaryReconDataset?.id;

  const { data: primaryDataset } = useDataset(
    reconsetCVMState.entityId,
    primaryDatasetId
  );
  const { data: secondaryDataset } = useDataset(
    reconsetCVMState.entityId,
    secondaryDatasetId
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // marginLeft: "20px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          cursor: "pointer",
          padding: "5px 0px",
        }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? <IconChevronDown /> : <IconChevronRight />}

        <Text fw={500} style={{ fontSize: "14px" }}>
          {label ? label : "Query"}
        </Text>
      </div>

      {isOpen && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            width: "95%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Grid>
              <Grid.Col span={12}>
                <CMultiSelect
                  name={`${name}.columns`}
                  control={control}
                  searchable
                  required
                  data={
                    type
                      ? type === "PRIMARY"
                        ? getColumnsLOV(
                            primaryDataset,
                            primaryReconDataset.columns || [],
                            primaryReconDataset.additionalColumns || []
                          )
                        : getColumnsLOV(
                            secondaryDataset,
                            secondaryReconDataset.columns || [],
                            secondaryReconDataset.additionalColumns || []
                          )
                      : []
                  }
                  label={"Columns"}
                  disabled={reconsetCVMState.actionOptions.disableAll}
                />
              </Grid.Col>
            </Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <Text fw={500} style={{ fontSize: "14px" }}>
                Additional Columns
              </Text>
              <ActionIcon
                size="sm"
                variant="white"
                color="gray"
                onClick={() => {
                  columnsArray.append({
                    name: "",
                    type: "TEXT",
                    label: "",
                    genFunction: "",
                  });
                }}
                disabled={reconsetCVMState.actionOptions.disableAll}
              >
                <IconPlus />
              </ActionIcon>
            </div>

            {columnsArray.fields.map((field, index) => {
              return (
                <Columns
                  name={`${name}.additionalColumns.${index}`}
                  control={control}
                  key={field.id}
                  index={index}
                  columnsArray={columnsArray}
                />
              );
            })}
          </div>
          <Where name={`${name}.where`} control={control} />
          <Having name={`${name}.having`} control={control} />
          <Order name={`${name}.order`} control={control} />
        </div>
      )}
    </div>
  );
}

export default Query;
