import { RelationshipType, DatasetType } from "@supportinitiative/common";

import { ActionOptionsType } from "../types";

export function getActionOptions(
  currentAction: string,
  prevValues: RelationshipType | undefined,
  currentValues: RelationshipType | undefined,
  applicationState: any
): ActionOptionsType {
  const actions: Record<string, ActionOptionsType> = {
    new: {
      allowedActions: ["save", "cancel"],
      disableAll: false,
    },
    view: {
      allowedActions: [
        "edit",
        prevValues?.authStatus === "UNAUTH" ? "auth" : "",
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.modificationNo === 1 &&
        prevValues?.makerId === applicationState.user.userId
          ? "delete"
          : "",
        "cancel",
      ],
      disableAll: true,
    },
    edit: {
      allowedActions: ["save", "cancel"],
      disableAll: false,
      disabledFields: ["name"],
      // regexDisabledFields: ["definition.columns.\\d+.name"],
    },
  };

  return actions[currentAction];
}

export const getDisabledStatus = (
  name: string,
  actionOptions: ActionOptionsType,
  prevValues: RelationshipType | undefined,
  currentValues: RelationshipType | undefined,
  applicationState: any
) => {
  let disabled = false;

  disabled = actionOptions.disableAll || false;

  if (!disabled) {
    disabled = (actionOptions.disabledFields || []).indexOf(name) >= 0;
  }

  if (!disabled) {
    disabled = (actionOptions.regexDisabledFields || []).some(
      (regexDisabledField: string) => {
        let regex = new RegExp(regexDisabledField, "i");
        return regex.test(name);
      }
    );
  }

  return disabled;
};

export const getDatasetLOV = (datasets: DatasetType[]) => {
  let datasetLOV = (datasets || []).map((dataset) => {
    return {
      label: dataset.displayName || dataset.name,
      value: dataset.id as string,
    };
  });
  return datasetLOV;
};

export const getDataset = (datasets: DatasetType[], id: string) => {
  let datasetLOV = (datasets || []).find((dataset) => {
    return dataset.id === id;
  });
  return datasetLOV;
};

export const getColumnsLOV = (dataset: DatasetType | undefined) => {
  if (!dataset) {
    return [];
  }
  let columnsLOV = (dataset.definition.columns || [])
    .filter((col) => col.name)
    .map((col) => {
      return {
        label: `${col.label} [ ${col.name} ]` || col.name,
        value: col.name,
      };
    });
  columnsLOV.push({ label: "_id", value: "_id" });
  return columnsLOV;
};

export const operators = [
  {
    value: "=",
    label: "=",
  },
  {
    value: "<=",
    label: "<=",
  },
  {
    value: ">=",
    label: ">=",
  },
  {
    value: "<>",
    label: "<>",
  },
];
