import * as React from "react";
import { Title, Modal, Grid, Button } from "@mantine/core";

function Success({ message, onOk }: { message: string; onOk: () => void }) {
  let [open, setOpen] = React.useState(true);
  return (
    <Modal
      centered
      opened={open}
      onClose={() => {
        setOpen(false);
        onOk();
      }}
      title={
        <Title order={4} style={{ paddingLeft: "5px" }}>
          {message}
        </Title>
      }
    >
      <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
        <Grid.Col span={2}>
          <Button
            color="teal"
            fullWidth
            aria-label="Ok"
            onClick={() => {
              setOpen(false);
              onOk();
            }}
          >
            Ok
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default Success;
