import { Button, Grid, Text } from "@mantine/core";
import dayjs from "dayjs";

import { downloadTaskFile } from "../api";
import { TaskCommentType } from "@supportinitiative/common";

function TaskComment({ comment }: { comment: TaskCommentType | undefined }) {
  if (comment) {
    return (
      <div
        style={{
          borderLeft: "4px solid #16A596",
          paddingLeft: "10px",
          marginBottom: "10px",
        }}
        key={comment.id}
      >
        <Grid align="flex-start" gutter={"xs"} mt="lg">
          <Grid.Col
            style={{ display: "flex", justifyContent: "flex-start" }}
            span={8}
          >
            <Text size="sm" inline>
              {comment.comment}
            </Text>
          </Grid.Col>
        </Grid>

        <Grid align="flex-start" gutter={"xs"}>
          <Grid.Col
            style={{ display: "flex", justifyContent: "flex-start" }}
            span={8}
          >
            <Text size="sm" inline color="dimmed">
              {`${comment.userName} - [ ${comment.userId} ] - ${dayjs(
                comment.commentDate
              ).format("YYYY-MM-DD HH:MM")}`}
            </Text>
          </Grid.Col>
        </Grid>
        <>
          {comment.attachments &&
            comment.attachments.files &&
            comment.attachments.files.length > 0 &&
            comment.attachments.files.map((file: any) => {
              return (
                <Grid align="flex-start" gutter={"xs"} key={file.name}>
                  <Grid.Col
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    span={2}
                  >
                    <Button
                      variant="default"
                      onClick={async () => {
                        await downloadTaskFile(
                          comment.taskId,
                          comment.entityId,
                          file.name,
                          file.type
                        );
                      }}
                    >
                      {file.name}
                    </Button>
                  </Grid.Col>
                </Grid>
              );
            })}
        </>
      </div>
    );
  } else {
    return <></>;
  }
}

export default TaskComment;
