import * as React from "react";
import { BuilderContext, useDrawer } from "react-flow-builder";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import {
  Text,
  Grid,
  Button,
  Modal,
  ActionIcon,
  Title,
  Divider,
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import {
  CTextInput,
  CSelect,
  CMultiSelect,
  CCheckbox,
  CRadioGroup,
  CTextarea,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import {
  getLinkagesLOV,
  operators,
  getSystemStatus,
  getConfirmationStatus,
  getColumnsLOV,
} from "../../ReconsetUtils";
import Query from "../QueryMatch/Query";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FieldMatchActionType,
  FieldMatchActionSchema,
} from "@supportinitiative/common";
import { useDataset } from "src/hooks";

function FieldMatch() {
  const { selectedNode: node } = React.useContext(BuilderContext);
  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FieldMatchActionType>({
    defaultValues: node?.data || {
      name: "",
      statusOverride: "IGNORE",
    },
    criteriaMode: "all",
    resolver: yupResolver(FieldMatchActionSchema),
  });

  const type = useWatch({
    control,
    name: "type",
  });

  const anchorConditions = useFieldArray({
    control,
    name: "anchorConditions",
  });

  const recon = reconsetFormState.recon;

  const primaryReconDataset = recon.definition.primaryDataset;

  const secondaryReconDataset = recon.definition.secondaryDataset;

  const primaryDatasetId = primaryReconDataset.id;

  const secondaryDatasetId = secondaryReconDataset?.id;

  const { data: primaryDataset } = useDataset(
    reconsetFormState.entityId,
    primaryDatasetId
  );
  const { data: secondaryDataset } = useDataset(
    reconsetFormState.entityId,
    secondaryDatasetId
  );

  return (
    <Modal
      centered
      size={"xl"}
      opened={true}
      onClose={cancel}
      title={<Title order={4}>Multi Match</Title>}
    >
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name={`name`}
            control={control}
            showErrorText={false}
            label="Name"
            required
            disabled={node?.disabled || false}
          />
        </Grid.Col>
      </Grid>

      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CSelect
            name={`type`}
            control={control}
            data={[
              {
                label: "Primary against Secondary",
                value: "PRIMARY-AGAINST-SECONDARY",
              },
              {
                label: "Seconday against Primary",
                value: "SECONDARY-AGAINST-PRIMARY",
              },
            ]}
            showErrorText={false}
            label="Type"
            searchable
            clearable
            required
            disabled={node?.disabled || false}
          />
        </Grid.Col>
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
        }}
      >
        <Divider my="xs" label="Query" labelPosition="center" />
        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <Query
              name={`left`}
              control={control}
              label="Query - Left"
              type={
                type === "PRIMARY-AGAINST-SECONDARY" ? "PRIMARY" : "SECONDARY"
              }
              recon={recon}
            />
          </Grid.Col>
        </Grid>

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <Query
              name={`right`}
              control={control}
              label="Query - Right"
              type={
                type === "PRIMARY-AGAINST-SECONDARY" ? "SECONDARY" : "PRIMARY"
              }
              recon={recon}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Columns" labelPosition="center" />

        <Grid>
          <Grid.Col span={12}>
            <CMultiSelect
              name={`columnsToMatch`}
              control={control}
              searchable
              data={
                type
                  ? type === "PRIMARY-AGAINST-SECONDARY"
                    ? getColumnsLOV(
                        primaryDataset,
                        primaryReconDataset.columns || [],
                        primaryReconDataset.additionalColumns || []
                      )
                    : getColumnsLOV(
                        secondaryDataset,
                        secondaryReconDataset.columns || [],
                        secondaryReconDataset.additionalColumns || []
                      )
                  : []
              }
              label={"Columns to Match"}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>

        <Grid>
          <Grid.Col span={12}>
            <CMultiSelect
              name={`columnsToIgnore`}
              control={control}
              searchable
              data={
                type
                  ? type === "PRIMARY-AGAINST-SECONDARY"
                    ? getColumnsLOV(
                        primaryDataset,
                        primaryReconDataset.columns || [],
                        primaryReconDataset.additionalColumns || []
                      )
                    : getColumnsLOV(
                        secondaryDataset,
                        secondaryReconDataset.columns || [],
                        secondaryReconDataset.additionalColumns || []
                      )
                  : []
              }
              label={"Columns to Ignore"}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Anchor" labelPosition="center" />

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text fw={500} style={{ fontSize: "14px" }}>
            Anchor Conditions
          </Text>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              anchorConditions.append({
                left: "",
                operator: "=",
                right: "",
              });
            }}
            disabled={node?.disabled || false}
          >
            <IconPlus />
          </ActionIcon>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            marginLeft: "20px",
            width: "90%",
          }}
        >
          {anchorConditions.fields.map((field, index) => {
            return (
              <Grid align="stretch" gutter={"xs"} mb="xs" key={field.id}>
                <Grid.Col span={4}>
                  <CTextInput
                    name={`anchorConditions.${index}.left`}
                    control={control}
                    label={index === 0 ? "Left" : ""}
                    disabled={node?.disabled || false}
                  />
                </Grid.Col>
                <Grid.Col span={3}>
                  <CSelect
                    name={`anchorConditions.${index}.operator`}
                    control={control}
                    searchable
                    data={[
                      {
                        value: "=",
                        label: "=",
                      },
                    ]}
                    label={index === 0 ? "Operator" : ""}
                    disabled={node?.disabled || false}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <CTextInput
                    name={`anchorConditions.${index}.right`}
                    control={control}
                    label={index === 0 ? "Right" : ""}
                    disabled={node?.disabled || false}
                  />
                </Grid.Col>
                <Grid.Col
                  span={1}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <ActionIcon
                    size="sm"
                    variant="white"
                    color="gray"
                    onClick={() => {
                      anchorConditions.remove(index);
                    }}
                    disabled={node?.disabled || false}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            );
          })}
        </div>

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <CTextarea
              autosize
              minRows={4}
              name={`anchorCondition`}
              control={control}
              label={"Anchor Condition"}
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Status" labelPosition="center" />

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={4}>
            <CSelect
              name={`systemStatus`}
              control={control}
              label="Status"
              data={getSystemStatus("ALL")}
              showErrorText={false}
              searchable
              required
              disabled={node?.disabled || false}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CSelect
              name={`confirmationStatus`}
              control={control}
              label="Confirmation Status"
              data={getConfirmationStatus()}
              showErrorText={false}
              searchable
              required
              disabled={node?.disabled || false}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CMultiSelect
              name={`userStatus`}
              control={control}
              label="User Status"
              searchable
              data={reconsetFormState.userStatus || []}
              showErrorText={false}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Status Override" labelPosition="center" />

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <CRadioGroup
              name={`statusOverride`}
              control={control}
              data={[
                { label: "Ignore", value: "IGNORE" },
                /* { label: "Override", value: "OVERRIDE" }, */
                { label: "Append", value: "APPEND" },
              ]}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
      </div>

      <Grid align="stretch" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            color="teal"
            variant="filled"
            fullWidth
            type="submit"
            onClick={
              node?.disabled
                ? () => {
                    console.log("only cancel");
                    cancel();
                  }
                : async (event) => {
                    event.preventDefault();
                    const values = getValues();
                    // console.log("values", values);
                    clearErrors();
                    try {
                      await FieldMatchActionSchema.validate(values, {
                        abortEarly: false,
                      });

                      save?.(values);
                      //cancel();
                    } catch (error) {
                      console.log("errors", error);
                      console.log("error inner", JSON.stringify(error));
                      (error as any).inner.forEach((err: any) => {
                        setError(err.path, {
                          type: "custom",
                          message: err.message,
                        });
                      });
                    }
                  }
            }
          >
            Ok
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default FieldMatch;
