import * as React from "react";
import { useForm, useFieldArray, useWatch, Control } from "react-hook-form";
import {
  Button,
  Grid,
  Title,
  ActionIcon,
  Group,
  ScrollArea,
  Text,
} from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import { FunctionSchema, FunctionType } from "@supportinitiative/common";

import {
  CSelect,
  CTextInput,
  CTextarea,
} from "@supportinitiative/react-hook-form-mantine";

import { styles } from "../styles";
import { postFunction } from "../api";
import { AuditType, IDispatchAction } from "../types";
import {
  getActionOptions,
  // FunctionSchema,
} from "./FunctionUtils";
import { UserContext } from "../misc/UserContext";
import { useFunction } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";
import { getId } from "../utilities";

type actionTypes =
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR";

export const FunctionCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  actionOptions: {},
  setValue: () => {},
  submitting: false,
  error: "",
  successMessage: "",
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    default:
      return state;
  }
}

export default function FunctionCVM() {
  // let [dataset, setFunction] = React.useState<FunctionType>();

  let [audit, setAudit] = React.useState<AuditType | undefined>();

  // let [currentAction, setCurrentAction] = React.useState("new");
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const { data: dataset } = useFunction(entityId, id);
  // let [newFunction, setNewFunction] = React.useState<FunctionType | undefined>();
  const [functionFormState, functionFormDispatch] = React.useReducer(reducer, {
    ...initialState,
    actionOptions: getActionOptions("new", dataset, dataset, applicationState),
  });
  // console.log("dataset in component", dataset);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FunctionType>({
    defaultValues: {
      name: "",
      displayName: "",
      description: "",
      definition: {
        type: "SIMPLE",
        usage: "QUERY",
        returnType: "TEXT",
        language: "POSTGRES",
        customOrNative: "CUSTOM",
        templates: [],
      },
    },
    criteriaMode: "all",
    resolver: yupResolver(FunctionSchema),
  });

  const templatesFieldArray = useFieldArray({
    control,
    name: "definition.templates",
    keyName: "key",
  });

  const onOk = () => {
    navigate(`/app/functions?entityId=${entityId}`);
  };

  React.useEffect(() => {
    if (dataset) {
      functionFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      functionFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions("view", dataset, dataset, applicationState),
      });

      setAudit({
        status: dataset.status,
        authStatus: dataset.authStatus,
        usableStatus: dataset.usableStatus,
        makerId: dataset.makerId,
        makerDate: dataset.makerDate,
        modificationNo: dataset.modificationNo,
        checkerId: dataset.checkerId,
        checkerDate: dataset.checkerDate,
      });

      reset(dataset);
    }
  }, [dataset]);

  const onSubmit = async (data: any) => {
    try {
      functionFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      functionFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: functionFormState.currentAction,
        },
      };
      await postFunction(payload);
      // setError("Form", "Unable to Submit");
      functionFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["dataset", entityId, id]);
      queryClient.invalidateQueries(["datasets", entityId]);
      functionFormDispatch({
        type: "SET-SUCCESS",
        value: "Function Saved Successfully",
      });
    } catch (error: any) {
      // console.log("post catch", error);
      // console.log("post catch", error?.response?.data?.message);
      functionFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      functionFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save Function",
      });
    }
  };

  return (
    <FunctionCVMContext.Provider
      value={{ state: functionFormState, dispatch: functionFormDispatch }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={styles.main} noValidate>
        <div style={styles.header} id="header">
          <Title order={2}>Function</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={2}>
              <CTextInput
                name="name"
                control={control}
                label="Name"
                aria-label="Name"
                required
                showErrorText={false}
                disabled={functionFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CTextInput
                name="displayName"
                control={control}
                label="Display Name"
                aria-label="Display Name"
                showErrorText={false}
                disabled={functionFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CTextInput
                name="description"
                control={control}
                label="Description"
                aria-label="Description"
                required
                showErrorText={false}
                disabled={functionFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Title
                  mb="xs"
                  mt="xs"
                  order={5}
                  style={{
                    padding: "0px 5px",
                    backgroundColor: "#16A596",
                    color: "#fff",
                  }}
                >
                  Definition
                </Title>
              </div>
              <Grid align="stretch" gutter={"xs"} mb="xs">
                <Grid.Col span={2}>
                  <CTextInput
                    name="definition.alias"
                    control={control}
                    label="Alias"
                    aria-label="Alias"
                    showErrorText={false}
                    disabled={functionFormState.actionOptions.disableAll}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CSelect
                    name="definition.type"
                    control={control}
                    label="Type"
                    aria-label="Type"
                    data={[
                      { label: "Single", value: "SIMPLE" },
                      { label: "Aggregate", value: "AGGREGATE" },
                    ]}
                    required
                    showErrorText={false}
                    disabled={functionFormState.actionOptions.disableAll}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CSelect
                    name="definition.returnType"
                    control={control}
                    label="Return Type"
                    aria-label="Return Type"
                    data={[
                      { label: "Text", value: "TEXT" },
                      { label: "Date", value: "DATE" },
                      { label: "Numeric", value: "NUMERIC" },
                      { label: "Integer", value: "INTEGER" },
                      { label: "Big Integer", value: "BIGINT" },
                      { label: "JSON", value: "JSON" },
                      { label: "Boolean", value: "BOOLEAN" },
                      { label: "Void/None", value: "VOID" },
                    ]}
                    required
                    showErrorText={false}
                    disabled={functionFormState.actionOptions.disableAll}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CSelect
                    name="definition.usage"
                    control={control}
                    label="Usage"
                    aria-label="Usage"
                    data={[{ label: "Query", value: "QUERY" }]}
                    required
                    showErrorText={false}
                    disabled={functionFormState.actionOptions.disableAll}
                  />
                </Grid.Col>
              </Grid>

              <Grid align="stretch" gutter={"xs"} mb="xs">
                <Grid.Col span={2}>
                  <CSelect
                    name="definition.customOrNative"
                    control={control}
                    label="Custom or Native"
                    aria-label="Return Type"
                    data={[
                      { label: "Custom", value: "CUSTOM" },
                      { label: "Native", value: "NATIVE" },
                      { label: "Custom Derived", value: "CUSTOM-DERIVED" },
                    ]}
                    required
                    showErrorText={false}
                    disabled={functionFormState.actionOptions.disableAll}
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CSelect
                    name="definition.language"
                    control={control}
                    label="Language"
                    aria-label="Language"
                    data={[
                      { label: "Postgres", value: "POSTGRES" },
                      { label: "JS", value: "JS" },
                    ]}
                    required
                    showErrorText={false}
                    disabled={functionFormState.actionOptions.disableAll}
                  />
                </Grid.Col>
              </Grid>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Group mb="xs" mt="lg" style={{ alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Title
                  mb="xs"
                  mt="xs"
                  order={5}
                  style={{
                    padding: "0px 5px",
                    backgroundColor: "#16A596",
                    color: "#fff",
                  }}
                >
                  Templates
                </Title>
              </div>
              <ActionIcon
                size="sm"
                variant="white"
                color="gray"
                onClick={() => {
                  templatesFieldArray.append({
                    id: getId(),
                    definition: "",
                  });
                }}
                style={{
                  display:
                    functionFormState.currentAction.toUpperCase() === "VIEW"
                      ? "none"
                      : "",
                }}
                disabled={functionFormState.actionOptions.disableAll}
              >
                <IconPlus />
              </ActionIcon>
            </Group>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              {templatesFieldArray.fields.map((field, index) => {
                return (
                  <Grid align="stretch" gutter={"xs"} mb="xs" key={field.key}>
                    <Grid.Col span={8}>
                      <CTextarea
                        name={`definition.templates.${index}.definition`}
                        control={control}
                        showErrorText={false}
                        label={index === 0 ? "Definition" : ""}
                        required
                        autosize
                        minRows={10}
                        disabled={functionFormState.actionOptions.disableAll}
                      />
                    </Grid.Col>
                    <Grid.Col
                      span={1}
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <ActionIcon
                        size="sm"
                        variant="white"
                        color="gray"
                        onClick={() => {
                          templatesFieldArray.remove(index);
                        }}
                        disabled={functionFormState.actionOptions.disableAll}
                        style={{
                          display: functionFormState.actionOptions.disableAll
                            ? "none"
                            : "",
                        }}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                );
              })}
            </div>
          </div>
        </div>

        {(Object.keys(errors).length > 0 || functionFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={functionFormState.error} />
            </div>
          </div>
        )}

        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(functionFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action === "save") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          fullWidth
                          type="submit"
                          color="teal"
                          aria-label="Save"
                          loading={functionFormState.submitting}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "cancel") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/functions?entityId=${entityId}`}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "back") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/functions?entityId=${entityId}`}
                          aria-label="Back"
                        >
                          Back
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "edit") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Edit"
                          fullWidth
                          onClick={() => {
                            functionFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });
                            functionFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                dataset,
                                dataset,
                                applicationState
                              ),
                            });

                            setAudit({
                              status: dataset.status,
                              authStatus: "UNAUTH",
                              usableStatus: dataset.usableStatus,
                              makerId: applicationState.user.userId,
                              makerDate: new Date(),
                              modificationNo:
                                dataset.authStatus === "UNAUTH"
                                  ? dataset.modificationNo
                                  : (dataset.modificationNo || 0) + 1,
                              checkerId: "",
                              checkerDate: undefined,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "auth") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          aria-label="Auth"
                          onClick={async () => {
                            functionFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "AUTH",
                            });

                            try {
                              let payload = {
                                ...dataset,
                                entityId,
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postFunction(payload);
                              // setError("Form", "Unable to Submit");
                              functionFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              functionFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Function authorized successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              functionFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to authorize function",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "delete") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Delete"
                          fullWidth
                          onClick={async () => {
                            functionFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "DELETE",
                            });

                            try {
                              let payload = {
                                ...dataset,
                                entityId,
                                metaData: {
                                  action: "delete",
                                },
                              };
                              await postFunction(payload);
                              // setError("Form", "Unable to Submit");
                              functionFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              functionFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Function Deleted Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              functionFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Delete Function",
                              });
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {functionFormState.successMessage && (
          <Success message={functionFormState.successMessage} onOk={onOk} />
        )}
      </form>
    </FunctionCVMContext.Provider>
  );
}
