import * as React from "react";
import { Grid, Button, Textarea, Text, Group } from "@mantine/core";
import { IconUpload, IconPhoto, IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  MS_EXCEL_MIME_TYPE,
  PDF_MIME_TYPE,
  MS_WORD_MIME_TYPE,
} from "@mantine/dropzone";
import { showNotification } from "@mantine/notifications";

import { postComment, uploadReconsetCommentFile } from "../../api";
import { showError } from "../../utilities";

export default function AddComment({
  id,
  entityId,
  recon,
  runId,
  matchId,
  type,
}: {
  id: string;
  entityId: string;
  recon: string;
  runId: string;
  matchId: string;
  type: "TEMP" | "MATCH";
}) {
  const [comment, setComment] = React.useState("");
  const [files, setFiles] = React.useState<File[]>([]);
  const queryClient = useQueryClient();

  return (
    <>
      <Grid align="flex-start" gutter={"xs"} mt="xs" mb="xs">
        <Grid.Col span={6}>
          <Textarea
            placeholder="Comment"
            required
            value={comment}
            onChange={(event) => setComment(event.currentTarget.value)}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={5}>
          <Dropzone
            onDrop={(droppedFiles) => {
              setFiles([...files, ...droppedFiles]);
            }}
            onReject={(files) => console.log("rejected files", files)}
            maxSize={50 * 1024 ** 2}
            accept={[
              ...IMAGE_MIME_TYPE,
              ...MS_EXCEL_MIME_TYPE,
              ...PDF_MIME_TYPE,
              ...MS_WORD_MIME_TYPE,
              "text/csv",
            ]}
          >
            <Group
              justify="center"
              gap="xl"
              align="flex-start"
              style={{ pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload size={25} stroke={1.5} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={25} stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size={25} stroke={1.5} />
              </Dropzone.Idle>

              <div>
                <Text inline>Drag files here or click to select files</Text>
                <Text size="sm" c="dimmed" inline mt={7}>
                  Each file should not exceed 5mb
                </Text>
              </div>
            </Group>
          </Dropzone>
        </Grid.Col>
        <Grid.Col span={1}>
          <Button
            fullWidth
            variant="default"
            onClick={async () => {
              try {
                const uploadedFiles: {
                  uploadedTo: string;
                  link: string;
                  fileName: string;
                }[] = [];

                for (const file of files) {
                  const formData = new FormData();
                  formData.append("file", file);
                  formData.append("type", file.type);
                  formData.append("name", file.name);
                  // formData.append("file", file);

                  const uploadRespnse = await uploadReconsetCommentFile(
                    id,
                    entityId,
                    recon,
                    runId,
                    matchId,
                    formData
                  );
                  if (uploadRespnse) {
                    uploadedFiles.push(uploadRespnse.data.values);
                  }
                }

                await postComment(id, entityId, runId, recon, matchId, type, {
                  comment,
                  attachments: {
                    files: uploadedFiles,
                  },
                });

                showNotification({
                  id: "success",
                  title: "Post Comment",
                  message: "Comment posted successfully",
                  color: "teal",
                  icon: <IconCheck />,
                });
                setComment("");
                setFiles([]);
                queryClient.invalidateQueries([
                  "reconsetMatchComments",
                  entityId,
                  id,
                  runId,
                  recon,
                  matchId,
                ]);
              } catch (error: any) {
                showError(
                  error,
                  "Error in posting comment",
                  "Unable to post comment",
                  <IconX />
                );
              }
            }}
            disabled={!comment}
          >
            Post
          </Button>
        </Grid.Col>
      </Grid>

      {files && files.length > 0 && (
        <>
          {files.map((file, index) => {
            return (
              <Grid
                align="flex-start"
                gutter={"xs"}
                mt="lg"
                ml="xs"
                mb="xs"
                key={index}
              >
                <Text size="sm" inline>
                  {file.name}
                </Text>
                <Text size="sm" c="dimmed" inline ml={7}>
                  {`[ ${file.type} ]`}
                </Text>
              </Grid>
            );
          })}
        </>
      )}
    </>
  );
}
