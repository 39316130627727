import * as React from "react";

import FlowBuilder, {
  NodeContext,
  INode,
  IRegisterNode,
} from "react-flow-builder";

import { Popover, Card, Text, Group, ActionIcon, Button } from "@mantine/core";
import { getId } from "../../utilities";
import { IconPlus, IconTrash } from "@tabler/icons-react";

export const DrawerComponent: React.FC = (props: any) => {
  return <>{props.children}</>;
};

export const StartNodeDisplay: React.FC = () => {
  const node = React.useContext(NodeContext);
  return <div className="start-node">{node.name}</div>;
};

export const EndNodeDisplay: React.FC = () => {
  const node = React.useContext(NodeContext);
  return <div className="end-node">{node.name}</div>;
};

export const DataLoadDisplay: React.FC = () => {
  const node = React.useContext(NodeContext);
  return (
    <Card
      shadow="sm"
      p="xs"
      radius="md"
      withBorder
      style={{ minWidth: "200px" }}
    >
      <Group justify="apart">
        <Text fw={500}>{node.name}</Text>
      </Group>

      <Text size="sm" color="dimmed">
        {node.data ? node.data.name : node.name}
      </Text>
    </Card>
  );
};

export const NodeDisplay: React.FC = () => {
  const node = React.useContext(NodeContext);
  return (
    <Card
      shadow="sm"
      p="xs"
      radius="md"
      withBorder
      style={{ minWidth: "200px" }}
    >
      <Group justify="apart">
        <Text fw={500}>{node.name}</Text>
      </Group>

      <Text size="sm" c="dimmed">
        {node.data
          ? `${node.data.name} (${node.id})`
          : `${node.name} (${node.id})`}
      </Text>
    </Card>
  );
};

export const ReconDefDisplay: React.FC = () => {
  const node = React.useContext(NodeContext);
  return (
    <Card
      shadow="sm"
      p="xs"
      radius="md"
      withBorder
      style={{ minWidth: "200px" }}
    >
      <Group justify="apart">
        <Text fw={500}>Reconciliation</Text>
      </Group>

      <Text size="sm" color="dimmed">
        {`${node.name} (${node.id})`}
      </Text>
    </Card>
  );
};

export const defaultNodes = [
  {
    id: getId(),
    type: "start",
    name: "Start",
    path: ["0"],
  },
  {
    id: getId(),
    type: "end",
    name: "End",
    path: ["2"],
  },
];

export function AddMenu(props: any) {
  // console.log("AddMenu props", props);
  const node = React.useContext(NodeContext);

  // console.log("AddMenu node", node);

  const { visible, onVisibleChange, children, content, ...restProps } = props;
  // console.log("AddMenu visible", content);
  return (
    <Popover
      opened={visible}
      position="right-end"
      onChange={onVisibleChange}
      {...restProps}
    >
      <Popover.Target>
        <ActionIcon
          variant="default"
          aria-label="Settings"
          type="button"
          onClick={onVisibleChange}
        >
          <IconPlus style={{ width: "70%", height: "70%" }} />
        </ActionIcon>
      </Popover.Target>

      <Popover.Dropdown>
        {content.props.children.map((node: any, index: number) => {
          return (
            <div
              key={index}
              style={{
                minWidth: "200px",
                display: "flex",
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              {node}
            </div>
          );
        })}
      </Popover.Dropdown>
    </Popover>
  );
}

export const PopConfirm: React.FC = (props: any) => {
  const {
    title,
    visible,
    onConfirm,
    children,
    getPopupContainer,
    ...restProps
  } = props;

  return (
    <Popover width={200} opened={visible} position="right" {...restProps}>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown>
        <Text mb="xs">{title}</Text>
        <Button onClick={onConfirm}>Remove</Button>
      </Popover.Dropdown>
    </Popover>
  );
};
