import { getLocalStorage } from "./api";
import {
  IconFileText,
  IconGauge,
  IconFileUpload,
  IconSquareCheck,
  IconLink,
  IconUser,
  IconFunction,
  IconKey,
  IconListCheck,
  IconSubtask,
} from "@tabler/icons-react";

import { customAlphabet } from "nanoid";
import { AxiosError } from "axios";
import { showNotification } from "@mantine/notifications";

// Capital letters not used as postgres functions are not case sensitive. This is used to generate function names
const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 10);

export const getId = (length = 5) => {
  return nanoid(length);
};

export const escapeRegExp = (
  str: string // or better use 'escape-string-regexp' package
) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export function getRoleOptions(entityId: string) {
  let roles = getLocalStorage("roles");

  let entityRole = roles.find((role: any) => role.entityId === entityId);

  // console.log("entityRole", entityRole);
  let options = [
    {
      label: "Dashboard",
      icon: IconGauge,
      link: `/app/dashboard?entityId=${entityId}`,
    },
  ];
  options.push({
    label: "Tasks",
    icon: IconSubtask,
    link: `/app/tasks?entityId=${entityId}`,
  });

  if (entityRole.roleType === "ADMIN") {
    options.push({
      label: "Datasets",
      icon: IconFileText,
      link: `/app/datasets?entityId=${entityId}`,
    });
    /*     options.push({
      label: "Relationships",
      icon: IconLink,
      link: `/app/relationships?entityId=${entityId}`,
    }); */
    options.push({
      label: "Reconsets",
      icon: IconSquareCheck,
      link: `/app/reconsets?entityId=${entityId}`,
    });
    options.push({
      label: "Loaders",
      icon: IconFileUpload,
      link: `/app/loaders?entityId=${entityId}`,
    });
    options.push({
      label: "Functions",
      icon: IconFunction,
      link: `/app/functions?entityId=${entityId}`,
    });
    options.push({
      label: "Users",
      icon: IconUser,
      link: `/app/users?entityId=${entityId}`,
    });
    options.push({
      label: "Roles",
      icon: IconKey,
      link: `/app/roles?entityId=${entityId}`,
    });
  }

  return options;
}

const sortOrder = [
  "Dashboard",
  "Tasks",
  "Datasets",
  "Relationships",
  "Reconsets",
  "Loaders",
  "Functions",
  "Users",
  "Roles",
];

export function getRoleAccessOptions(
  entityId: string,
  userRoles: {
    entityId: string;
    accessType: string;
    accessOption: string;
    roleType: string;
  }[]
) {
  const entityRoles = (userRoles || []).filter(
    (role) => role.entityId === entityId
  );

  // console.log("entityRole", entityRole);
  const options = [
    {
      label: "Dashboard",
      icon: IconGauge,
      link: `/app/dashboard?entityId=${entityId}`,
    },
  ];

  options.push({
    label: "Tasks",
    icon: IconSubtask,
    link: `/app/tasks?entityId=${entityId}`,
  });

  for (const entityRole of entityRoles) {
    if (
      entityRole.accessType === "DATASET" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Datasets") === -1) {
        options.push({
          label: "Datasets",
          icon: IconFileText,
          link: `/app/datasets?entityId=${entityId}`,
        });
      }
    } else if (
      entityRole.accessType === "RELATIONSHIP" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (
        options.findIndex((option) => option.label === "Relationships") === -1
      ) {
        /*         options.push({
          label: "Relationships",
          icon: IconLink,
          link: `/app/relationships?entityId=${entityId}`,
        }); */
      }
    } else if (
      entityRole.accessType === "RECONSET" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Reconsets") === -1) {
        options.push({
          label: "Reconsets",
          icon: IconListCheck,
          link: `/app/reconsets?entityId=${entityId}`,
        });
      }
    } else if (
      entityRole.accessType === "LOADER" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Loaders") === -1) {
        options.push({
          label: "Loaders",
          icon: IconFileUpload,
          link: `/app/loaders?entityId=${entityId}`,
        });
      }
    } else if (
      entityRole.accessType === "USER" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Users") === -1) {
        options.push({
          label: "Users",
          icon: IconUser,
          link: `/app/users?entityId=${entityId}`,
        });
      }
    } else if (
      entityRole.accessType === "ROLE" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Roles") === -1) {
        options.push({
          label: "Roles",
          icon: IconKey,
          link: `/app/roles?entityId=${entityId}`,
        });
      }
    } else if (
      entityRole.accessType === "FUNCTION" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Functions") === -1) {
        options.push({
          label: "Functions",
          icon: IconFunction,
          link: `/app/functions?entityId=${entityId}`,
        });
      }
    } else if (
      entityRole.accessType === "TASK" &&
      entityRole.accessOption === "VIEW"
    ) {
      if (options.findIndex((option) => option.label === "Tasks") === -1) {
        options.push({
          label: "Tasks",
          icon: IconSquareCheck,
          link: `/app/tasks?entityId=${entityId}`,
        });
      }
    }
  }
  options.sort(function (a, b) {
    return sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label);
  });
  return options;
}

export function showError(
  error: AxiosError<{ message: string }>,
  title: string,
  message: string,
  icon: any
) {
  showNotification({
    id: "error",
    title: title,
    message: error?.response?.data?.message
      ? error?.response?.data?.message
      : message
        ? "Unknown error"
        : "Error",
    color: "red",
    icon: icon,
  });
}

export function hasEntityAdminTypeRole(
  entityId: string,
  userRoles: {
    entityId: string;
    accessType: string;
    accessOption: string;
    roleType: string;
  }[]
) {
  const entityRoles = (userRoles || []).filter(
    (role) => role.entityId === entityId && role.roleType === "ADMIN"
  );

  return entityRoles.length > 0;
}

export function hasEntityRoleAttached(
  entityId: string,
  userRoles: {
    entityId: string;
    accessType: string;
    accessOption: string;
    roleType: string;
    role: string;
  }[],
  roles: string[]
) {
  const entityRoles = (userRoles || []).filter(
    (userRole) =>
      userRole.entityId === entityId && roles.indexOf(userRole.role) !== -1
  );

  return entityRoles.length > 0;
}

export function getIsMatch(filter: string[]) {
  const isMatch = (data: any) => {
    let found = filter.every((search) => {
      const re = new RegExp(escapeRegExp(search), "i");
      let item = Object.keys(data).find((column) => {
        return re.test(data[column]);
      });
      if (item) {
        return true;
      }
      return false;
    });
    return found;
  };
  return isMatch;
}

export function isAuthorized() {
  return getLocalStorage("token") ? true : false;
}

export function getErrorMessage(error: any, defaultMessage: string = "") {
  let response = "";
  if (error.code && error.code?.substring(0, 2) === "AP") {
    response = `${defaultMessage ? `${defaultMessage} - ` : ""}${error.detail}`;
  } else if (error.response) {
    response = `${defaultMessage ? `${defaultMessage} - ` : ""}${error.response?.data?.message}`;
  } else if (error.message) {
    response = `${defaultMessage ? `${defaultMessage} - ` : ""}${error.message}`;
  } else {
    response = `${defaultMessage ? `${defaultMessage} - ` : ""}${error.output?.payload?.message}`;
  }

  return response;
}
