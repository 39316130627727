import * as React from "react";
import {
  TextInput,
  Button,
  Grid,
  Title,
  Card,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";

import { DatasetType } from "@supportinitiative/common";
import { escapeRegExp } from "../utilities";
import { useDatasets } from "../hooks";
import Loading from "../ui/Loading";
import { styles } from "../styles";

function getIsMatch(filter: string) {
  const isMatch = (dataset: DatasetType) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return (
      re.test(dataset.name) ||
      re.test(dataset.displayName) ||
      re.test(dataset.description)
    );
  };
  return isMatch;
}

export default function Datasets() {
  let [searchParams] = useSearchParams();
  let entityId = searchParams.get("entityId");

  let [filter, setFilter] = React.useState("");
  const { data: datasets, isLoading } = useDatasets(entityId);
  let [filteredDatasets, setFilteredDatasets] = React.useState<DatasetType[]>(
    []
  );
  const theme = useMantineTheme();

  React.useEffect(() => {
    if (datasets) {
      let filteredValues = datasets.filter(getIsMatch(filter));
      setFilteredDatasets(filteredValues);
    }
  }, [datasets, filter]);

  return (
    <div style={{ marginTop: theme.spacing.lg }}>
      <Title order={3}>Datasets</Title>
      <Grid align="center" gutter={"xs"} mb="xs">
        <Grid.Col span={3}>
          <TextInput
            placeholder="Search"
            onChange={(event) => {
              setFilter(event.target.value);
              let filteredValues = datasets.filter(
                getIsMatch(event.target.value)
              );
              setFilteredDatasets(filteredValues);
            }}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Button
            color="teal"
            aria-label="New"
            fullWidth
            component={Link}
            to={`/app/datasets/new?entityId=${entityId}`}
          >
            New
          </Button>
        </Grid.Col>
      </Grid>

      {isLoading ? (
        <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
          <Grid.Col span={3}>
            <Loading />
          </Grid.Col>
        </Grid>
      ) : (
        <>
          {filteredDatasets.length > 0 ? (
            <Grid align="center" gutter={"xs"} mb="xs">
              {filteredDatasets.map((dataset) => {
                return (
                  <Grid.Col span={3} key={dataset.id as unknown as string}>
                    <Card
                      component={Link}
                      to={`/app/datasets/edit?id=${dataset.id}&entityId=${entityId}`}
                      shadow="sm"
                      p="xs"
                      data-testid="dataset"
                    >
                      <Text fw={500}>{dataset.name}</Text>
                      <Text
                        size="sm"
                        style={{
                          color: theme.colors.gray[7],
                          lineHeight: 1.5,
                        }}
                      >
                        {dataset.description}
                      </Text>
                    </Card>
                  </Grid.Col>
                );
              })}
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: theme.spacing.lg,
              }}
            >
              <Text fw={500} style={styles.nodata} data-testid="nodata">
                No Datasets
              </Text>
            </div>
          )}
        </>
      )}
    </div>
  );
}
