import { FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { Alert, useMantineTheme } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

// TODO: Field errors

export default function FormStateError({
  errors,
  sourceField,
}: {
  errors: FieldErrors;
  sourceField?: string;
}) {
  if (errors) {
    return (
      <div>
        {Object.keys(errors).map((field) => {
          return (
            <FieldErrorMessages
              error={errors[field]}
              field={Array.isArray(errors) ? `Row ${Number(field) + 1}` : field}
              key={field}
              sourceField={sourceField}
            />
          );
        })}
      </div>
    );
  }

  return <></>;
}

function FieldErrorMessages({
  field,
  error,
  sourceField,
}: {
  field: string;
  error: FieldError | FieldErrors | undefined;
  sourceField?: string;
}) {
  const theme = useMantineTheme();

  if (error) {
    if (error.message) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing.xs,
          }}
          data-testid="error"
        >
          <Alert
            variant="light"
            miw={400}
            icon={<IconAlertCircle size={16} />}
            title={`Error - [ ${sourceField ? `${sourceField} / ${field}` : field} ]`}
            color="pink"
          >
            {`${error.message ? error.message : error.type}`}
          </Alert>
        </div>
      );
    } else {
      return (
        <FormStateError
          errors={error as FieldErrors}
          sourceField={sourceField ? `${sourceField} / ${field}` : field}
        />
      );
    }
  } else {
    return <></>;
  }
}
