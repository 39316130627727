import * as React from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import { Text, Grid, Title, Modal, ActionIcon, Button } from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import {
  CSelect,
  CTextInput,
  CCheckbox,
  CMultiSelect,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../ReconsetCVM";
import {
  getLinkagesLOV,
  getDataset,
  getDatasetColumnsLOV,
  columnTypes,
  getDatasetsLOV,
  getColumnsLOV,
  getColumnsToMergeLOV,
} from "../ReconsetUtils";
import {
  useDatasets,
  useRelationshipByPrimaryAndSecondaryDataset,
} from "../../hooks";

function OtherReconDatasetCVM({
  name,
  control,
}: {
  name: `definition.recons.${number}.definition.otherDatasets.${number}.definition`;
  control: Control<any>;
}) {
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);
  const { data: datasets } = useDatasets(reconsetFormState.entityId);

  let [isOpen, setIsOpen] = React.useState(false);

  const additionalColumnsArray = useFieldArray({
    control,
    name: `${name}.additionalColumns`,
    keyName: "key",
  });

  const reconDataset = useWatch({
    control,
    name: `${name}.id`,
  });

  const reconDatasetName = useWatch({
    control,
    name: `${name}.shortName`,
  });

  const columns = useWatch({
    control,
    name: `${name}.columns`,
  });

  const additionalColumns = useWatch({
    control,
    name: `${name}.additionalColumns`,
  });

  React.useEffect(() => {
    if (reconDataset) {
      let dataset = getDataset(datasets, reconDataset);
      if (dataset) {
        let columns = (dataset.definition.columns || []).map((col) => {
          return col.name;
        });
        reconsetFormState.setValue(`${name}.columns`, columns);
      }
    }
  }, [reconDataset]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // marginBottom: "20px",
        // paddingLeft: "10px",
        // borderLeft: "4px solid",
        width: "100%",
      }}
    >
      <Button
        fullWidth
        variant="default"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {reconDatasetName ? reconDatasetName : "Dataset"}
      </Button>

      <Modal
        centered
        size={"xl"}
        opened={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        title={<Title order={4}>{"Dataset"}</Title>}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CSelect
                name={`${name}.id`}
                label="Name"
                control={control}
                data={getDatasetsLOV(datasets)}
                required
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CTextInput
                name={`${name}.shortName`}
                label="Short Name"
                control={control}
                required
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CMultiSelect
                name={`${name}.columns`}
                control={control}
                searchable
                required
                label="Columns"
                data={getDatasetColumnsLOV(getDataset(datasets, reconDataset))}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text fw={500} style={{ fontSize: "14px" }}>
              Additional Columns
            </Text>
            <ActionIcon
              size="sm"
              variant="white"
              color="gray"
              onClick={() => {
                additionalColumnsArray.append({
                  name: "",
                  type: "TEXT",
                  label: "",
                  genFunction: "",
                  notNull: "NO",
                });
              }}
              disabled={reconsetFormState.actionOptions.disableAll}
            >
              <IconPlus />
            </ActionIcon>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid align="stretch" gutter={"xs"} mt="xs">
              <Grid.Col span={12}>
                {additionalColumnsArray.fields.map((field, index) => {
                  return (
                    <Grid align="stretch" gutter={"xs"} mb="xs" key={field.key}>
                      <Grid.Col span={2}>
                        <CTextInput
                          name={`${name}.additionalColumns.${index}.name`}
                          control={control}
                          showErrorText={false}
                          label={index === 0 ? "Name" : ""}
                          required
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <CSelect
                          name={`${name}.additionalColumns.${index}.type`}
                          control={control}
                          label={index === 0 ? "Type" : ""}
                          data={columnTypes}
                          showErrorText={false}
                          required
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <CTextInput
                          name={`${name}.additionalColumns.${index}.label`}
                          control={control}
                          showErrorText={false}
                          label={index === 0 ? "Label" : ""}
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <CTextInput
                          name={`${name}.additionalColumns.${index}.genFunction`}
                          control={control}
                          showErrorText={false}
                          label={index === 0 ? "Gen Function" : ""}
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col
                        span={1}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <CTextInput
                          name={`${name}.additionalColumns.${index}.id`}
                          control={control}
                          label={index === 0 ? "Id" : ""}
                          disabled={true}
                        />
                      </Grid.Col>
                      <Grid.Col
                        span={2}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <CCheckbox
                          name={`${name}.additionalColumns.${index}.notNull`}
                          control={control}
                          label="Not Null"
                          trueValue="YES"
                          falseValue="NO"
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col
                        span={1}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <ActionIcon
                          size="sm"
                          variant="white"
                          color="gray"
                          onClick={() => {
                            additionalColumnsArray.remove(index);
                          }}
                          disabled={reconsetFormState.actionOptions.disableAll}
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Grid.Col>
                    </Grid>
                  );
                })}
              </Grid.Col>
            </Grid>
          </div>

          {/*           <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CMultiSelect
                name={`${name}.resetMatchOnChangeOf`}
                control={control}
                searchable
                label="Reset Match on Change of"
                data={getColumnsToMergeLOV(
                  getDataset(datasets, reconDataset),
                  columns || [],
                  additionalColumns
                )}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid> */}

          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CMultiSelect
                name={`${name}.displayColumns`}
                control={control}
                searchable
                label="Display Columns"
                data={getColumnsLOV(
                  getDataset(datasets, reconDataset),
                  columns || [],
                  additionalColumns
                )}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CTextInput
                name={`${name}.queryColumnsCondition`}
                control={control}
                label="Query Columns - Conditions"
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CTextInput
                name={`${name}.accessControl`}
                control={control}
                label="Access Control"
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <Grid align="stretch" gutter={"xs"} mt="xs">
            <Grid.Col span={2}>
              <Button color="teal" fullWidth onClick={() => setIsOpen(!isOpen)}>
                Ok
              </Button>
            </Grid.Col>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default OtherReconDatasetCVM;
