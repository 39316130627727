import React, { useContext } from "react";
import { BuilderContext, useDrawer } from "react-flow-builder";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  Grid,
  Title,
  Text,
  Tabs,
  SegmentedControl,
  Modal,
} from "@mantine/core";
import {
  CTextInput,
  CMultiSelect,
  CSelect,
} from "@supportinitiative/react-hook-form-mantine";

/* const ReconDef: React.FC = (props) => {
  const { selectedNode: node } = useContext(BuilderContext);
  console.log("ReconDef", node?.data);

  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<any>({
    defaultValues: node?.data || { name: " " },
    criteriaMode: "all",
    // resolver: yupResolver(ReconsetSchema),
    // resolver: yupResolver(ReconsetSchema),
  });

  const onSubmitForm = async (data: any) => {
    try {
      console.log("onSubmit", data);
      save?.(data);
      cancel();
    } catch (error: any) {
      console.log("onSubmit error", error);
    }
  };

  return (
    <Modal
      size="xl"
      opened={true}
      onClose={cancel}
      title={<Title order={3}>Recon Definition</Title>}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          // handleSubmit(onSubmitForm)();
          event.stopPropagation();
          console.log("inside child");
        }}
        noValidate
      >
        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={12}>
            <CTextInput
              name="name"
              control={control}
              label="Name"
              required
              showErrorText={false}
            />
          </Grid.Col>
        </Grid>

        <Grid align="stretch" gutter={"xs"} mt="xs">
          <Grid.Col span={2}>
            <Button
              color="teal"
              variant="filled"
              fullWidth
              onClick={(event) => {
                event.preventDefault();
                const values = getValues();
                // console.log("values", values);
                save?.(values);
                cancel();
              }}
            >
              Ok
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};
 */

const ReconDef: React.FC = (props) => {
  const { selectedNode: node } = useContext(BuilderContext);

  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

  return <></>;
};
export default ReconDef;
