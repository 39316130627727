import * as React from "react";
import { Grid, Button, Select, TextInput, Alert } from "@mantine/core";

import Loading from "../../ui/Loading";
import { IReconsetState } from "../../types";
import { ReconsetBasicType } from "@supportinitiative/common";
import { IconCheck, IconInfoCircle } from "@tabler/icons-react";
import { apiAxios, getAuthHeader } from "src/api";
import { getErrorMessage } from "src/utilities";
import { showNotification } from "@mantine/notifications";

async function postNewRun(reconset: any) {
  return await apiAxios.post(
    "/api/reconset/run",
    { __inputs: reconset.__inputs },
    {
      params: {
        __entityId: reconset.entityId,
        __id: reconset.id,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    }
  );
}

function getDefaultInputValues(
  inputs: ReconsetBasicType["definition"]["inputs"]
) {
  // initialize array with empty strings
  if (!inputs) {
    return [];
  }
  let defaultValues = new Array(inputs.length).fill("");

  return defaultValues;
}

function formInputs(
  inputs: ReconsetBasicType["definition"]["inputs"],
  inputValues: string[]
) {
  let form: Record<string, string> = {};
  let inputIndex = 0;
  for (const input of inputs) {
    form[input.name] = inputValues[inputIndex];
    inputIndex++;
  }
  return form;
}

function validateInputs(
  inputs: ReconsetBasicType["definition"]["inputs"],
  inputValues: string[]
) {
  console.log(inputValues);
  let valid = true,
    errors = [];
  if (!inputValues[0]) {
    valid = false;
    errors.push("Description is required");
  }
  let inputIndex = 1;
  for (const input of inputs) {
    if (input.notNull === "YES" && !inputValues[inputIndex]) {
      valid = false;
      errors.push(`${input.label || input.name} is required`);
    }
    inputIndex++;
  }

  return { valid: valid, errors: errors };
}

export default function NewRun({
  entityId,
  id,
  reconsetState,
  setRunId,
  inputs,
  cancel,
}: {
  entityId: string | null | undefined;
  id: string | null | undefined;
  reconsetState: IReconsetState;
  setRunId: (runId: string) => void;
  inputs: ReconsetBasicType["definition"]["inputs"];
  cancel: () => void;
}) {
  const [description, setDescription] = React.useState("");
  const [inputValues, setInputValues] = React.useState<string[]>(
    getDefaultInputValues(inputs)
  );
  const [error, setError] = React.useState("");
  React.useEffect(() => {
    setInputValues(getDefaultInputValues(inputs));
  }, [inputs]);

  return (
    <>
      <Grid
        align="flex-start"
        gutter={"xs"}
        mb="xs"
        style={{ display: "flex", alignItems: "flex-end" }}
      >
        <Grid.Col span={12}>
          <TextInput
            label="Description"
            placeholder="Description"
            value={description}
            required
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
        </Grid.Col>
      </Grid>

      {inputs.length > 0 && (
        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          {inputs.map((input, index) => {
            return (
              <Grid.Col span={12} key={input.name}>
                <TextInput
                  label={input.label || input.name}
                  placeholder={input.label || input.name}
                  value={inputValues[index]}
                  required={input.notNull === "YES"}
                  onChange={(e) => {
                    let value = e.currentTarget.value;
                    let newInputValues = [...inputValues];
                    // replace the value at index
                    newInputValues[index] = value;
                    setInputValues(newInputValues);
                  }}
                />
              </Grid.Col>
            );
          })}
        </Grid>
      )}

      {error ? (
        <Alert
          variant="light"
          color="pink"
          title="Error"
          icon={<IconInfoCircle />}
          mb="lg"
        >
          {error}
        </Alert>
      ) : (
        <> </>
      )}

      <Grid align="stretch" gutter={"xs"}>
        <Grid.Col span={2}>
          <Button
            color="teal"
            variant="filled"
            fullWidth
            onClick={async () => {
              setError("");
              let validation = validateInputs(inputs, [
                description,
                ...inputValues,
              ]);
              if (validation.valid) {
                // call the start run api

                try {
                  const response = await postNewRun({
                    entityId,
                    id,
                    __inputs: {
                      description,
                      ...formInputs(inputs, inputValues),
                    },
                  });
                  console.log("Setting run =>", response.data.values.__runId);
                  setRunId(response.data.values.__runId);
                  showNotification({
                    id: "success",
                    title: "Start run",
                    message: "Run started successfully",
                    color: "teal",
                    icon: <IconCheck />,
                  });
                  cancel();
                } catch (error) {
                  setError(getErrorMessage(error));
                }
              } else {
                setError(validation.errors.join(", "));
              }
            }}
          >
            Start
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            variant="default"
            fullWidth
            aria-label="Cancel"
            onClick={cancel}
          >
            Cancel
          </Button>
        </Grid.Col>
      </Grid>
    </>
  );
}
