import * as React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  Group,
  MultiSelect,
  ScrollArea,
  Text,
  Title,
  Badge,
  Container,
  useMantineTheme,
  Stack,
  Flex,
  Divider,
} from "@mantine/core";
import { Link } from "react-router-dom";

import { TaskType } from "@supportinitiative/common";

import {
  useCurrentUserPendingCollabTasks,
  useCurrentUserPendingTasks,
  useCurrentUserPinned,
  useCurrentUserRolePendingTasks,
} from "../hooks";
import { UserContext } from "../misc/UserContext";

import { getIsMatch } from "../utilities";
import { getColor } from "../Reconset/ReconsetUtils";
import Loading from "../ui/Loading";
import ReconsetPinned from "./ReconsetPinned";
import Walkthrough from "../Walkthrough/Walkthrough";
import { postUserWalkthrough, setLocalStorage, getLocalStorage } from "../api";

function Tasks({ tasks }: { tasks: TaskType[] }) {
  if (tasks.length > 0) {
    return (
      <>
        {(tasks || []).map((task) => {
          return (
            <div key={task.id}>
              <Card
                p="xs"
                shadow="sm"
                mb="xs"
                component={Link}
                to={`/app/tasks/view?id=${task.id}&entityId=${task.entityId}`}
              >
                <Group justify="flex-start" align="center" gap={"2px"}>
                  <Text fw={500}>{task.name}</Text>
                  <Badge
                    ml="xs"
                    radius="xs"
                    color={getColor(task.status)}
                    variant="light"
                  >
                    {task.status}
                  </Badge>
                  <Badge
                    radius="xs"
                    color={getColor(task.priority)}
                    variant="light"
                  >
                    {task.priority}
                  </Badge>
                </Group>

                <Group justify="space-between" mb="xs">
                  <Text c="dimmed">{task.description}</Text>
                </Group>
                <Group justify="flex-start">
                  <Text
                    c="dimmed"
                    size="xs"
                    style={{ fontStyle: "italic" }}
                  >{`${
                    task.owner ? `${task.ownerName} - [ ${task.owner} ] / ` : ""
                  }${task.reconsetName}`}</Text>
                </Group>
              </Card>
            </div>
          );
        })}
      </>
    );
  } else {
    return <Divider label="No Tasks" color="gray" labelPosition="left" />;
  }
}

// TODO: Seperate Collab tasks
export default function Dashboard() {
  let [searchParams] = useSearchParams();
  let entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);

  const theme = useMantineTheme();

  // Walkthrough

  const [openWalkthrough, setOpenWalkthrough] = React.useState(
    getLocalStorage("walkthrough") !== "YES" &&
      (getLocalStorage("user")
        ? getLocalStorage("user").walkthrough !== "YES"
        : true)
  );

  const closeWalkthrough = async () => {
    setOpenWalkthrough(false);
    setLocalStorage("walkthrough", "YES");
    await postUserWalkthrough();
  };

  const { data: userPendingTasks, isLoading: isUserTasksLoading } =
    useCurrentUserPendingTasks(entityId);

  // Pinned
  const { data: userPinnedDetails, isLoading: isUserPinnedLoading } =
    useCurrentUserPinned(entityId);

  return (
    <div style={{ marginTop: theme.spacing.lg }}>
      <Walkthrough
        openWalkthrough={openWalkthrough}
        closeWalkthrough={closeWalkthrough}
      />

      <Grid align="stretch" gutter={"lg"} mb="lg">
        <Grid.Col span={4}>
          <Stack>
            <Stack
              justify="flex-start"
              gap="0"
              style={{
                backgroundColor: "#D1E9F6",
                boxShadow:
                  "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px",
              }}
            >
              <Flex>
                <Title
                  mb="xs"
                  order={5}
                  c="white"
                  py={0}
                  px={theme.spacing.xxs}
                  style={{
                    backgroundColor: theme.colors.emerald[0],
                  }}
                >
                  Tasks
                </Title>
              </Flex>

              <ScrollArea
                style={{
                  height: 250,
                  padding: theme.spacing.xs,
                  paddingTop: 0,
                }}
                type="auto"
                scrollbarSize={16}
              >
                {isUserTasksLoading ? (
                  <Grid.Col span={12}>
                    <Loading />
                  </Grid.Col>
                ) : (
                  <Tasks
                    tasks={
                      userPendingTasks && userPendingTasks.length > 0
                        ? userPendingTasks
                        : []
                    }
                  />
                )}
              </ScrollArea>
            </Stack>

            <Stack
              gap="0"
              justify="flex-start"
              style={{
                backgroundColor: "#F8F6E3",
                boxShadow:
                  "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px",
              }}
            >
              <Flex>
                <Title
                  mb="xs"
                  order={5}
                  c="white"
                  py={0}
                  px={theme.spacing.xxs}
                  style={{
                    backgroundColor: theme.colors.emerald[0],
                  }}
                >
                  Notifications
                </Title>
              </Flex>

              <ScrollArea
                style={{
                  height: 250,
                  padding: theme.spacing.xs,
                  paddingTop: 0,
                }}
                type="auto"
                scrollbarSize={16}
              >
                <Divider
                  label="No Notifications"
                  color="gray"
                  labelPosition="left"
                />
              </ScrollArea>
            </Stack>
          </Stack>
        </Grid.Col>

        <Grid.Col span={8}>
          <Flex>
            <Title
              mb="xs"
              order={5}
              c="white"
              py={0}
              px={theme.spacing.xxs}
              style={{
                backgroundColor: theme.colors.emerald[0],
              }}
            >
              Reconsets
            </Title>
          </Flex>
          <Stack>
            {isUserPinnedLoading ? (
              <Loading />
            ) : (
              <>
                {(userPinnedDetails || []).length > 0 ? (
                  (userPinnedDetails || []).map(
                    (details: any, index: number) => {
                      return (
                        <ReconsetPinned
                          key={details.pin.pinId}
                          details={details}
                          index={index}
                        />
                      );
                    }
                  )
                ) : (
                  <Divider label="No Pinned Reconsets" labelPosition="left" />
                )}
              </>
            )}
          </Stack>
        </Grid.Col>
      </Grid>
    </div>
  );
}
