import * as React from "react";
import { useFieldArray, useWatch, Control } from "react-hook-form";
import { Grid, ActionIcon, Text, Flex, useMantineTheme } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { CSelect } from "@supportinitiative/react-hook-form-mantine";

import { getLinkagesLOV } from "../ReconsetUtils";
import { ReconsetCVMContext } from "../ReconsetCVM";
import ReconDatasetCVM from "../Dataset/ReconDatasetCVM";
import Validations from "../Validation/Validations";
import { useRelationshipByPrimaryAndSecondaryDataset } from "../../hooks";
import { getId } from "../../utilities";
import OtherReconDatasetCVM from "../Dataset/OtherReconDatasetCVM";
import { IconTrash } from "@tabler/icons-react";

function DatasetsCVM({
  name,
  control,
}: {
  name: `definition.recons.${number}.definition`;
  control: Control<any>;
}) {
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);
  const theme = useMantineTheme();
  const masterDataset = useWatch({
    control,
    name: "definition.masterDataset.id",
  });

  const type = useWatch({
    control,
    name: `${name}.type`,
  });

  const primaryDataset = useWatch({
    control,
    name: `${name}.primaryDataset.id`,
  });

  const secondaryDataset = useWatch({
    control,
    name: `${name}.secondaryDataset.id`,
  });

  const noOfDatasets = useWatch({
    control,
    name: `${name}.noOfDatasets`,
  });

  /*   const actions = useWatch({
    control,
    name: `${name}.actions`,
  }); */

  const otherDatasetsArray = useFieldArray({
    control,
    name: `${name}.otherDatasets`,
    keyName: "key",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={2}>
          <CSelect
            name={`${name}.noOfDatasets`}
            control={control}
            label={"No of Datasets"}
            data={[
              { label: "One", value: "ONE" },
              { label: "Two", value: "TWO" },
            ]}
            showErrorText={false}
            required
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>

        <Grid.Col span={2} style={{ display: "flex", alignItems: "flex-end" }}>
          <ReconDatasetCVM
            name={`${name}.primaryDataset`}
            control={control}
            label="Primary Dataset"
          />
        </Grid.Col>
        {noOfDatasets === "TWO" && (
          <Grid.Col
            span={2}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <ReconDatasetCVM
              name={`${name}.secondaryDataset`}
              control={control}
              label="Secondary Dataset"
            />
          </Grid.Col>
        )}
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: theme.spacing.xs,
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            alignItems: "center",
          }}
        >
          <Text
            fw={500}
            style={{
              fontSize: "14px",
            }}
          >
            Other Datasets
          </Text>

          {!reconsetFormState.actionOptions.disableAll && (
            <ActionIcon
              size="sm"
              variant="white"
              color="gray"
              ml={"xs"}
              onClick={() => {
                otherDatasetsArray.append({
                  id: getId(5),
                  definition: {
                    // type: "PRIMARY-AGAINST-PRIMARY",
                  },
                });
              }}
              disabled={reconsetFormState.actionOptions.disableAll}
            >
              <IconPlus />
            </ActionIcon>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <Grid align="stretch" gutter={"xs"} mb="xs">
            {otherDatasetsArray.fields.map((field, index) => {
              return (
                <React.Fragment key={field.key}>
                  <Grid.Col span={2} key={field.key}>
                    <Flex align="end">
                      <OtherReconDatasetCVM
                        name={`${name}.otherDatasets.${index}.definition`}
                        control={control}
                      />
                      {!reconsetFormState.actionOptions.disableAll && (
                        <ActionIcon
                          size="sm"
                          variant="white"
                          color="gray"
                          ml="xs"
                          onClick={() => {
                            otherDatasetsArray.remove(index);
                          }}
                          disabled={reconsetFormState.actionOptions.disableAll}
                        >
                          <IconTrash />
                        </ActionIcon>
                      )}
                    </Flex>
                  </Grid.Col>
                </React.Fragment>
              );
            })}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default DatasetsCVM;
