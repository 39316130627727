import * as React from "react";
import { showNotification } from "@mantine/notifications";
import {
  IconX,
  IconCheck,
  IconCirclePlus,
  IconFolderOff,
  IconFolderPlus,
  IconCircleMinus,
  IconFolderShare,
  IconBan,
} from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import {
  ActionIcon,
  Button,
  Grid,
  Modal,
  Select,
  Title,
  Tooltip,
} from "@mantine/core";
import { TaskDetailType } from "@supportinitiative/common";

import { UserContext } from "../misc/UserContext";
import { postTaskAction } from "../api";
import { showError } from "../utilities";
import { useUsersBasic } from "../hooks";

function TaskActvities({ task }: { task: TaskDetailType }) {
  const { state: applicationState } = React.useContext(UserContext);
  const [display, setDisplay] = React.useState<string>("none");
  const queryClient = useQueryClient();

  const [owner, setOwner] = React.useState("");

  const { data: users } = useUsersBasic(task.entityId);

  return (
    <>
      {task?.allowedActions.map((taskAction) => {
        return (
          <React.Fragment key={taskAction}>
            {taskAction === "ACQUIRE" ? (
              <Tooltip label="Acquire">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("acquire")}
                >
                  <IconCirclePlus
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            ) : taskAction === "RELEASE" ? (
              <Tooltip label="Release">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("release")}
                >
                  <IconCircleMinus
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            ) : taskAction === "REOPEN" ? (
              <Tooltip label="Reopen">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("reopen")}
                >
                  <IconFolderPlus
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            ) : taskAction === "CLOSE" ? (
              <Tooltip label="Close">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("close")}
                >
                  <IconFolderOff
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            ) : taskAction === "CHANGE" ? (
              <Tooltip label="Change/Assign">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("change")}
                >
                  <IconFolderShare
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            ) : taskAction === "APPROVE" ? (
              <Tooltip label="Approve">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("approve")}
                >
                  <IconFolderShare
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            ) : taskAction === "REJECT" ? (
              <Tooltip label="Reject">
                <ActionIcon
                  variant="light"
                  size="lg"
                  onClick={() => setDisplay("reject")}
                >
                  <IconBan style={{ width: "70%", height: "70%" }} stroke={2} />
                </ActionIcon>
              </Tooltip>
            ) : (
              <></>
            )}
          </React.Fragment>
        );
      })}

      <>
        <Modal
          centered
          size={"lg"}
          opened={display === "acquire"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Acquire task?</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    await postTaskAction(task.id, task.entityId, {
                      action: "ACQUIRE",
                    });

                    showNotification({
                      id: "success",
                      title: "Acquire",
                      message: "Task acquired successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in acquired task",
                      "Unable to acquire task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          centered
          size={"lg"}
          opened={display === "release"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Release task?</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    await postTaskAction(task.id, task.entityId, {
                      action: "RELEASE",
                    });

                    showNotification({
                      id: "success",
                      title: "Release",
                      message: "Task released successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in releasing task",
                      "Unable to release task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          centered
          size={"lg"}
          opened={display === "close"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Close task?</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    await postTaskAction(task.id, task.entityId, {
                      action: "CLOSE",
                    });

                    showNotification({
                      id: "success",
                      title: "Close",
                      message: "Task closed successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in closing task",
                      "Unable to close task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          centered
          size={"lg"}
          opened={display === "reopen"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Reopen task?</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    await postTaskAction(task.id, task.entityId, {
                      action: "REOPEN",
                    });

                    showNotification({
                      id: "success",
                      title: "Close",
                      message: "Task reopened successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in reopening task",
                      "Unable to reopen task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          centered
          size={"lg"}
          opened={display === "change"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Change/Assign owner</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={12}>
              <Select
                value={owner}
                clearable
                searchable
                onChange={(val) => {
                  if (val) {
                    setOwner(val);
                  } else {
                    setOwner("");
                  }
                }}
                data={(users || []).map((user: any) => {
                  return { label: user.userName, value: user.userId };
                })}
                name="owner"
                placeholder="Owner"
              />
            </Grid.Col>
          </Grid>
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    const toAssignUser = users.find(
                      (userDetail: any) => userDetail.userId === owner
                    );

                    await postTaskAction(task.id, task.entityId, {
                      action: "CHANGE",
                      owner,
                      ownerName: toAssignUser.userName,
                    });

                    showNotification({
                      id: "success",
                      title: "Close",
                      message: "Task assigned successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    setOwner("");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in assigning task",
                      "Unable to assign task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          centered
          size={"lg"}
          opened={display === "approve"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Approve</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    await postTaskAction(task.id, task.entityId, {
                      action: "APPROVE",
                      owner,
                    });

                    showNotification({
                      id: "success",
                      title: "Close",
                      message: "Task approved successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    setOwner("");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in approve task",
                      "Unable to approving task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>

        <Modal
          centered
          size={"lg"}
          opened={display === "reject"}
          onClose={() => setDisplay("none")}
          title={<Title order={4}>Reject</Title>}
        >
          <Grid align="stretch" gutter={"xs"} mt="xs" justify="flex-end">
            <Grid.Col span={2}>
              <Button
                color="teal"
                fullWidth
                aria-label="Ok"
                onClick={async () => {
                  try {
                    const toAssignUser = users.find(
                      (userDetail: any) => userDetail.userId === owner
                    );

                    await postTaskAction(task.id, task.entityId, {
                      action: "REJECT",
                      owner,
                    });

                    showNotification({
                      id: "success",
                      title: "Close",
                      message: "Task rejected successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setDisplay("none");
                    setOwner("");
                    queryClient.invalidateQueries([
                      "task",
                      task.entityId,
                      task.id,
                    ]);
                  } catch (error: any) {
                    console.log(error);
                    showError(
                      error,
                      "Error in rejecting task",
                      "Unable to reject task",
                      <IconX />
                    );
                  }
                }}
              >
                Ok
              </Button>
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                variant="default"
                fullWidth
                aria-label="Cancel"
                onClick={() => setDisplay("none")}
              >
                Cancel
              </Button>
            </Grid.Col>
          </Grid>
        </Modal>
      </>
    </>
  );
}

export default TaskActvities;
