import { UserType } from "@supportinitiative/common";
import { ActionOptionsType } from "../types";

export function getActionOptions(
  currentAction: string,
  prevValues: UserType | undefined,
  currentValues: UserType | undefined,
  applicationState: any
): ActionOptionsType {
  switch (currentAction.toUpperCase()) {
    case "NEW": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    case "VIEW": {
      let actionOptions: { allowedActions: string[]; disableAll: boolean } = {
        allowedActions: [],
        disableAll: true,
      };

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.makerId !== applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("auth");
      } else {
        actionOptions.allowedActions.push("edit");
      }

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.modificationNo === 1 &&
        prevValues?.makerId === applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("delete");
      }
      actionOptions.allowedActions.push("cancel");
      return actionOptions;
    }
    case "EDIT": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    default:
      return { allowedActions: [] };
  }
}
