import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import { deleteFromMatch } from "../../api";
import { CSelect } from "@supportinitiative/react-hook-form-mantine";
import { Button, Grid } from "@mantine/core";
import { showError } from "../../utilities";

export const DeleteFromMatchSchema = object({
  systemStatus: string().required(),
  userStatus: string().nullable(),
});

export default function DeleteFromMatch({
  id,
  entityId,
  matchId,
  recon,
  runId,
  match,
  dataset,
  selection,
  systemStatusLOV,
  userStatusLOV,
  closeDialog,
}: {
  id: string;
  entityId: string;
  matchId: string;
  recon: string;
  runId: string;
  match: any;
  dataset: string;
  selection: string[];
  systemStatusLOV: { label: string; value: string }[];
  userStatusLOV: { label: string; value: string }[];
  closeDialog: () => void;
}) {
  const { control, handleSubmit } = useForm<{
    systemStatus: string;
    userStatus: string;
  }>({
    defaultValues: {
      systemStatus: match.systemStatus,
      userStatus: match.userStatus,
    },
    criteriaMode: "all",
    resolver: yupResolver(DeleteFromMatchSchema),
  });

  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    try {
      const matchData = {
        ...data,
        matchId: matchId,
        _ids: selection,
        dataset: dataset,
      };

      await deleteFromMatch(id, entityId, recon, runId, matchId, matchData);
      queryClient.invalidateQueries([
        "reconsetMatchDetails",
        entityId,
        id,
        runId,
        matchId,
      ]);
      closeDialog();
      showNotification({
        id: "success",
        title: "Match detals update",
        message: "Match details updated successfully",
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error: any) {
      showError(
        error,
        "Error in match update",
        "Unable to update match details",
        <IconX />
      );
    }
  };

  return (
    <form noValidate>
      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <CSelect
            name={"systemStatus"}
            control={control}
            label={"New System Status"}
            data={systemStatusLOV}
            showErrorText={false}
            required
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CSelect
            name={"userStatus"}
            control={control}
            label={"New User Status"}
            data={userStatusLOV}
            showErrorText={false}
            clearable
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            fullWidth
            variant="default"
            color="teal"
            onClick={handleSubmit(onSubmit)}
          >
            Delete
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button variant="default" onClick={closeDialog} fullWidth>
            Cancel
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
