import { Timeline, Text, Badge, Divider, Alert } from "@mantine/core";
import {
  IconAlertCircle,
  IconAnchor,
  IconDatabase,
  IconEdit,
  IconFileUpload,
  IconFilter,
  IconFingerprint,
  IconFlag,
  IconNotification,
  IconRun,
} from "@tabler/icons-react";
import dayjs from "dayjs";

function getTypeIcon(type: string) {
  if (type.toLowerCase() === "start") {
    return <IconRun style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "dataload") {
    return <IconFileUpload style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "derive-data") {
    return <IconDatabase style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "end") {
    return <IconFlag style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "anchor-match") {
    return <IconAnchor style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "filter") {
    return <IconFilter style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "notification") {
    return <IconNotification style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "status-update") {
    return <IconEdit style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "finding") {
    return <IconFingerprint style={{ width: "70%", height: "70%" }} />;
  }
}

function getColor(type: string) {
  if (type.toLowerCase() === "error") {
    return "pink";
  } else if (type.toLowerCase() === "warning") {
    return "yellow";
  } else {
    return "gray";
  }
}

export default function Findings({
  findings,
}: {
  findings: {
    id: string;
    runId: string;
    runSeq: string;
    recon: string;
    reconName: string;
    action: string;
    actionName: string;
    type: string;
    subject: string;
    body: string;
    details: any;
  }[];
}) {
  if (findings && findings.length > 0) {
    return (
      <>
        {findings.map((finding, index) => {
          return (
            <Alert
              key={finding.id}
              variant="light"
              icon={<IconAlertCircle size={16} />}
              title={finding.subject}
              color={getColor(finding.type)}
            >
              {finding.body}
            </Alert>
          );
        })}
      </>
    );
  } else {
    return <Divider label="No Findings" labelPosition="left" />;
  }
}
