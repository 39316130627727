import * as React from "react";
import { ActionIcon, Grid } from "@mantine/core";
import { Control } from "react-hook-form";
import { IconTrash } from "@tabler/icons-react";

import {
  CTextInput,
  CSelect,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import { columnTypes } from "../../ReconsetUtils";

function Columns({
  name,
  index,
  columnsArray,
  control,
}: {
  name:
    | `definition.recons.${number}.definition.actions.${number}.definition.left.additionalColumns.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.right.additionalColumns.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchFor.left.additionalColumns.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchIn.left.additionalColumns.${number}`
    | `left.additionalColumns.${number}`
    | `right.additionalColumns.${number}`;
  index: number;
  columnsArray: any;

  control: Control<any>;
}) {
  const { state: reconsetCVMState } = React.useContext(ReconsetCVMContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "5px",
      }}
    >
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={3}>
          <CTextInput
            name={`${name}.name`}
            control={control}
            showErrorText={false}
            label={index === 0 ? "Name" : ""}
            required
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <CSelect
            name={`${name}.type`}
            control={control}
            label={index === 0 ? "Type" : ""}
            data={columnTypes}
            showErrorText={false}
            required
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <CTextInput
            name={`${name}.label`}
            control={control}
            showErrorText={false}
            label={index === 0 ? "Label" : ""}
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <CTextInput
            name={`${name}.genFunction`}
            control={control}
            showErrorText={false}
            required
            label={index === 0 ? "Gen Function" : ""}
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>

        <Grid.Col span={1} style={{ display: "flex", alignItems: "flex-end" }}>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              columnsArray.remove(index);
            }}
            disabled={reconsetCVMState.actionOptions.disableAll}
          >
            <IconTrash />
          </ActionIcon>
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default Columns;
