import { DatasetType } from "@supportinitiative/common";

import { ActionOptionsType } from "../types";

export function getActionOptions(
  currentAction: string,
  prevValues: DatasetType | undefined,
  currentValues: DatasetType | undefined,
  applicationState: any
): ActionOptionsType {
  switch (currentAction.toUpperCase()) {
    case "NEW": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    case "VIEW": {
      let actionOptions: { allowedActions: string[]; disableAll: boolean } = {
        allowedActions: [],
        disableAll: true,
      };

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.makerId !== applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("auth");
      } else {
        actionOptions.allowedActions.push("edit");
      }

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.modificationNo === 1 &&
        prevValues?.makerId === applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("delete");
      }
      actionOptions.allowedActions.push("cancel");
      return actionOptions;
    }
    case "EDIT": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    default:
      return { allowedActions: [] };
  }
}

export const getColumnsLOV = (
  columns: {
    name: string;
    type: string;
    label?: string;
  }[]
) => {
  let columnsLOV = (columns || [])
    .filter(
      (col, index, self) =>
        index ===
        self.findIndex((t) => t.name.toLowerCase() === col.name.toLowerCase())
    )
    .filter((col) => col.name)
    .map((col) => {
      return {
        label: `${col.label} [ ${col.name} ]` || col.name,
        value: col.name,
      };
    });
  return columnsLOV;
};

export const getDisabledStatus = (
  name: string,
  actionOptions: ActionOptionsType,
  prevValues: DatasetType | undefined,
  currentValues: DatasetType | undefined,
  applicationState: any
) => {
  let disabled = false;

  disabled = actionOptions.disableAll || false;

  if (!disabled) {
    disabled = (actionOptions.disabledFields || []).indexOf(name) >= 0;
  }

  if (!disabled) {
    disabled = (actionOptions.regexDisabledFields || []).some(
      (regexDisabledField: string) => {
        let regex = new RegExp(regexDisabledField, "i");
        return regex.test(name);
      }
    );
  }

  return disabled;
};

export const columnTypes = [
  {
    value: "TEXT",
    label: "Text",
  },
  {
    value: "INTEGER",
    label: "Integer",
  },
  {
    value: "BIGINT",
    label: "Bigint",
  },
  {
    value: "AMOUNT",
    label: "Amount",
  },
  {
    value: "NUMERIC",
    label: "Numeric",
  },
  {
    value: "DATE",
    label: "Date",
  },
  {
    value: "TIMESTAMP",
    label: "Timestamp",
  },
  {
    value: "TIMESTAMP WITHOUT TIME ZONE",
    label: "Timestamp w/o Timezone",
  },
];
