import * as React from "react";
import { useForm, useFieldArray, useWatch, Control } from "react-hook-form";
import { Button, Grid, Title, ActionIcon, Group, Text } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import {
  CSelect,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";

import {
  RelationshipType,
  RelationshipSchema,
  DatasetType,
} from "@supportinitiative/common";

import { styles } from "../styles";
import { postRelationship } from "../api";
import { AuditType, IDispatchAction } from "../types";
import {
  getColumnsLOV,
  getActionOptions,
  operators,
  getDatasetLOV,
  getDataset,
} from "./RelationshipUtils";
import { UserContext } from "../misc/UserContext";
import { useDatasets, useRelationship } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";
import { getId } from "../utilities";

type actionTypes =
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR";

export const RelationshipCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  actionOptions: {},
  setValue: () => {},
  submitting: false,
  error: "",
  successMessage: "",
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    default:
      return state;
  }
}

function Links({
  name,
  control,
  datasets,
}: {
  name: `definition.links.${number}.conditions`;
  control: Control<RelationshipType>;
  datasets: DatasetType[];
}) {
  const { state: relationshipFormState } = React.useContext(
    RelationshipCVMContext
  );

  const primaryDataset = useWatch({ control, name: "primaryDataset" });
  const secondaryDataset = useWatch({ control, name: "secondaryDataset" });

  const conditionsArray = useFieldArray({
    control,
    name,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "20px",
        width: "100%",
      }}
    >
      <Group mb="xs" style={{ alignItems: "center" }}>
        <Text fw={500} style={{ fontSize: "14px" }}>
          Conditions
        </Text>
        <ActionIcon
          onClick={() => {
            conditionsArray.append({
              left: "",
              operator: "=",
              right: "",
            });
          }}
          disabled={relationshipFormState.actionOptions.disableAll}
        >
          <IconPlus />
        </ActionIcon>
      </Group>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        {conditionsArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} mb="xs" key={field.id}>
              <Grid.Col span={2}>
                <CSelect
                  name={`${name}.${index}.left`}
                  control={control}
                  searchable
                  data={getColumnsLOV(getDataset(datasets, primaryDataset))}
                  disabled={relationshipFormState.actionOptions.disableAll}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <CSelect
                  name={`${name}.${index}.operator`}
                  control={control}
                  searchable
                  data={operators}
                  disabled={relationshipFormState.actionOptions.disableAll}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <CSelect
                  name={`${name}.${index}.right`}
                  control={control}
                  searchable
                  data={getColumnsLOV(getDataset(datasets, secondaryDataset))}
                  disabled={relationshipFormState.actionOptions.disableAll}
                />
              </Grid.Col>
              <Grid.Col
                span={1}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <ActionIcon
                  onClick={() => {
                    conditionsArray.remove(index);
                  }}
                  disabled={relationshipFormState.actionOptions.disableAll}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

export default function RelationshipCVM() {
  // let [relationship, setRelationship] = React.useState<RelationshipType>();

  let [audit, setAudit] = React.useState<AuditType | undefined>();

  // let [currentAction, setCurrentAction] = React.useState("new");
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const { data: datasets } = useDatasets(entityId);

  const { data: relationship } = useRelationship(entityId, id);
  // let [newRelationship, setNewRelationship] = React.useState<RelationshipType | undefined>();
  const [relationshipFormState, relationshipFormDispatch] = React.useReducer(
    reducer,
    {
      ...initialState,
      actionOptions: getActionOptions(
        "new",
        relationship,
        relationship,
        applicationState
      ),
    }
  );
  // console.log("relationship in component", relationship);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<RelationshipType>({
    defaultValues: {
      primaryDataset: "",
      secondaryDataset: "",
      description: "",
      definition: {
        links: [],
      },
    },
    criteriaMode: "all",
    resolver: yupResolver(RelationshipSchema),
  });

  const linksFieldArray = useFieldArray({
    control,
    name: "definition.links",
  });

  const onOk = () => {
    navigate(`/app/relationships?entityId=${entityId}`);
  };

  React.useEffect(() => {
    if (relationship) {
      relationshipFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      relationshipFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions(
          "view",
          relationship,
          relationship,
          applicationState
        ),
      });

      setAudit({
        status: relationship.status,
        authStatus: relationship.authStatus,
        usableStatus: relationship.usableStatus,
        makerId: relationship.makerId,
        makerDate: relationship.makerDate,
        modificationNo: relationship.modificationNo,
        checkerId: relationship.checkerId,
        checkerDate: relationship.checkerDate,
      });

      reset(relationship);
    }
  }, [relationship]);

  const onSubmit = async (data: any) => {
    try {
      relationshipFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      relationshipFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: relationshipFormState.currentAction,
        },
      };
      await postRelationship(payload);
      // setError("Form", "Unable to Submit");
      relationshipFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["relationship", entityId, id]);
      queryClient.invalidateQueries(["relationships", entityId]);
      relationshipFormDispatch({
        type: "SET-SUCCESS",
        value: "Relationship Saved Successfully",
      });
    } catch (error: any) {
      // console.log("post catch", error);
      // console.log("post catch", error?.response?.data?.message);
      relationshipFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      relationshipFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save Relationship",
      });
    }
  };

  return (
    <RelationshipCVMContext.Provider
      value={{
        state: relationshipFormState,
        dispatch: relationshipFormDispatch,
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={styles.main} noValidate>
        <div style={styles.header} id="header">
          <Title order={2}>Relationship</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={2}>
              <CSelect
                name="primaryDataset"
                control={control}
                label={"Primary Dataset"}
                data={getDatasetLOV(datasets)}
                showErrorText={false}
                searchable
                required
                disabled={relationshipFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CSelect
                name="secondaryDataset"
                control={control}
                label={"Secondary Dataset"}
                data={getDatasetLOV(datasets)}
                showErrorText={false}
                searchable
                required
                disabled={relationshipFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CTextInput
                name="description"
                control={control}
                label="Description"
                required
                showErrorText={false}
                disabled={relationshipFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Group mb="xs" style={{ alignItems: "center" }}>
              <Title order={4}>Links</Title>
              <ActionIcon
                onClick={() => {
                  linksFieldArray.append({
                    id: getId(),
                    name: "",
                    type: "JOIN",
                    conditions: [],
                  });
                }}
                style={{
                  display:
                    relationshipFormState.currentAction.toUpperCase() === "VIEW"
                      ? "none"
                      : "",
                }}
                disabled={relationshipFormState.actionOptions.disableAll}
              >
                <IconPlus />
              </ActionIcon>
            </Group>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            {linksFieldArray.fields.map((field, index) => {
              return (
                <div key={field.id}>
                  <Grid align="stretch" gutter={"xs"} mb="xs">
                    <Grid.Col span={4}>
                      <CTextInput
                        name={`definition.links.${index}.name`}
                        control={control}
                        showErrorText={false}
                        label={"Name"}
                        required
                        disabled={
                          relationshipFormState.actionOptions.disableAll
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <CSelect
                        name={`definition.links.${index}.type`}
                        control={control}
                        label={"Type"}
                        data={[
                          { value: "JOIN", label: "Join" },
                          { value: "IN", label: "In" },
                          { value: "EXISTS", label: "Exists" },
                        ]}
                        showErrorText={false}
                        required
                        disabled={
                          relationshipFormState.actionOptions.disableAll
                        }
                      />
                    </Grid.Col>
                    <Grid.Col
                      span={1}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <ActionIcon
                        onClick={() => {
                          linksFieldArray.remove(index);
                        }}
                        disabled={
                          relationshipFormState.actionOptions.disableAll
                        }
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "20px",
                    }}
                  >
                    <Links
                      name={`definition.links.${index}.conditions`}
                      control={control}
                      datasets={datasets}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {(Object.keys(errors).length > 0 || relationshipFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={relationshipFormState.error} />
            </div>
          </div>
        )}

        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(relationshipFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action === "save") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          fullWidth
                          type="submit"
                          color="teal"
                          variant="outline"
                          aria-label="Save"
                          loading={relationshipFormState.submitting}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "cancel") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/relationships?entityId=${entityId}`}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "back") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/relationships?entityId=${entityId}`}
                          aria-label="Back"
                        >
                          Back
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "edit") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          color="red"
                          variant="outline"
                          aria-label="Edit"
                          fullWidth
                          onClick={() => {
                            relationshipFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });
                            relationshipFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                relationship,
                                relationship,
                                applicationState
                              ),
                            });

                            setAudit({
                              status: relationship.status,
                              authStatus: "UNAUTH",
                              usableStatus: relationship.usableStatus,
                              makerId: applicationState.user.userId,
                              makerDate: new Date(),
                              modificationNo:
                                relationship.authStatus === "UNAUTH"
                                  ? relationship.modificationNo
                                  : (relationship.modificationNo || 0) + 1,
                              checkerId: "",
                              checkerDate: undefined,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "auth") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          color="yellow"
                          variant="outline"
                          aria-label="Auth"
                          fullWidth
                          onClick={async () => {
                            relationshipFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "AUTH",
                            });

                            try {
                              let payload = {
                                ...relationship,
                                entityId,
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postRelationship(payload);
                              // setError("Form", "Unable to Submit");
                              relationshipFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              relationshipFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Relationship Authorized Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              relationshipFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Authorize Relationship",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "delete") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Delete"
                          fullWidth
                          onClick={async () => {
                            relationshipFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "DELETE",
                            });

                            try {
                              let payload = {
                                ...relationship,
                                entityId,
                                metaData: {
                                  action: "delete",
                                },
                              };
                              await postRelationship(payload);
                              // setError("Form", "Unable to Submit");
                              relationshipFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              relationshipFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Relationship Deleted Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              relationshipFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Delete Relationship",
                              });
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {relationshipFormState.successMessage && (
          <Success message={relationshipFormState.successMessage} onOk={onOk} />
        )}
      </form>
    </RelationshipCVMContext.Provider>
  );
}
