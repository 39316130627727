import { Grid, Text, Title, Card, Button } from "@mantine/core";
import { Link } from "react-router-dom";

import landingPageStyles from "./LandingPage.module.css";

function Mobile() {
  return (
    <div className={landingPageStyles.mobile}>
      <section id="main" className={landingPageStyles.container}>
        <nav className={landingPageStyles.nav}>
          <div
            className={landingPageStyles.logo}
            style={{ padding: "5px 15px" }}
          >
            <img
              src="./logo2.png"
              alt="logo"
              style={{ height: "33px", width: "165px" }}
            />
          </div>
        </nav>
        <main className={landingPageStyles.hero}>
          <div className={landingPageStyles.main}>
            <Grid align="center" justify="center" gutter={"lg"} pt="0">
              <Grid.Col span={12} pb="0" pt="0">
                <Title order={2} c="#3A3D3F">
                  Reduce{" "}
                  <span style={{ color: "rgb(233,119,119)" }}>
                    costs, complaints
                  </span>
                </Title>
              </Grid.Col>
              <Grid.Col span={12} pb="0" pt="0">
                <Title order={2} c="#3A3D3F">
                  Improve{" "}
                  <span style={{ color: "#0ca678" }}>
                    productivity, efficiencies
                  </span>
                </Title>
              </Grid.Col>
              <Grid.Col span={12} pb="0" pt="0">
                <Title order={2} c="#3A3D3F">
                  Mitigate{" "}
                  <span style={{ color: "rgb(233,119,119)" }}>risks</span>{" "}
                  arising out of data inconsistencies
                </Title>
              </Grid.Col>

              <Grid.Col span={12} mt="lg" mb="lg">
                <Text style={{ fontStyle: "italic" }}>
                  Thoroughly reconcile, analyze, validate and audit the data
                  within and across systems
                </Text>
              </Grid.Col>
            </Grid>
            <Card
              p="lg"
              shadow="sm"
              style={{ backgroundColor: "rgba(233,119,119, 0.9)" }}
            >
              <Grid align="center" justify="center" gutter={"lg"}>
                <Grid.Col span={12}>
                  <Text ta="center" fw="bold" c="#fff">
                    Rekonist works with large data sets and tables. Application
                    optimized for large screens. Please login through a desktop
                    or a laptop
                  </Text>
                </Grid.Col>
              </Grid>
            </Card>
          </div>
        </main>
      </section>
    </div>
  );
}

export default Mobile;
