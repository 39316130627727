import {
  ReconsetType,
  DatasetType,
  FunctionType,
  RelationshipType,
  ReconsetBasicType,
} from "@supportinitiative/common";

import { object, string, number, date, array, setLocale } from "yup";

import { ActionOptionsType } from "../types";
import { apiAxios, getAuthHeader, getLocalStorage } from "../api";

export function getActionOptions(
  currentAction: string,
  prevValues: ReconsetType | undefined,
  currentValues: ReconsetType | undefined,
  applicationState: any
): ActionOptionsType {
  switch (currentAction.toUpperCase()) {
    case "NEW": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    case "VIEW": {
      let actionOptions: { allowedActions: string[]; disableAll: boolean } = {
        allowedActions: [],
        disableAll: true,
      };

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.makerId !== applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("auth");
      } else {
        actionOptions.allowedActions.push("edit");
      }

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.modificationNo === 1 &&
        prevValues?.makerId === applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("delete");
      }
      actionOptions.allowedActions.push("cancel");
      return actionOptions;
    }
    case "EDIT": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    default:
      return { allowedActions: [] };
  }
}

export const getDisabledStatus = (
  name: string,
  actionOptions: ActionOptionsType,
  prevValues: ReconsetType | undefined,
  currentValues: ReconsetType | undefined,
  applicationState: any
) => {
  let disabled = false;

  disabled = actionOptions.disableAll || false;

  if (!disabled) {
    disabled = (actionOptions.disabledFields || []).indexOf(name) >= 0;
  }

  if (!disabled) {
    disabled = (actionOptions.regexDisabledFields || []).some(
      (regexDisabledField: string) => {
        let regex = new RegExp(regexDisabledField, "i");
        return regex.test(name);
      }
    );
  }

  return disabled;
};

export const getDatasetsLOV = (datasets: DatasetType[]) => {
  let datasetLOV = (datasets || []).map((dataset) => {
    return {
      label: dataset.displayName
        ? `${dataset.displayName} [ ${dataset.name} ]`
        : dataset.name,
      value: dataset.id as string,
    };
  });
  return datasetLOV;
};

export const getFunctionsLOV = (functions: FunctionType[]) => {
  let datasetLOV = (functions || []).map((func) => {
    return {
      label: `${func.name}${
        func.definition.alias ? `[ ${func.definition.alias} ]` : ""
      }`,
      value: func.id as string,
    };
  });
  return datasetLOV;
};

export const getLinkagesLOV = (relationship: RelationshipType | undefined) => {
  if (!relationship) {
    return [];
  }

  let linksLOV = (relationship.definition.links || []).map((link) => {
    return {
      label: link.name,
      value: link.id as string,
    };
  });
  return linksLOV;
};

export const getDataset = (datasets: DatasetType[], id: string | undefined) => {
  if (!id) {
    return;
  }
  let datasetLOV = (datasets || []).find((dataset) => {
    return dataset.id === id;
  });
  return datasetLOV;
};

export const getDatasetColumnsLOV = (dataset: DatasetType | undefined) => {
  if (!dataset) {
    return [];
  }
  let columnsLOV = (dataset.definition.columns || []).map((col) => {
    return {
      label: `${col.label} [ ${col.name} ]` || col.name,
      value: col.name,
    };
  });

  return columnsLOV;
};

export const getColumnsLOV = (
  dataset: DatasetType | undefined,
  columns: string[],
  additionalColumns: {
    name: string;
    type: string;
    label?: string;
    notNull?: string;
    genFunction?: string;
  }[]
) => {
  if (!dataset) {
    return [];
  }
  let columnsLOV = (dataset.definition.columns || [])
    .filter((col) => columns.indexOf(col.name) > -1)
    .map((col) => {
      return {
        label: `${col.label} [ ${col.name} ]` || col.name,
        value: col.name,
      };
    });

  let additionalColumnsLOV = (additionalColumns || [])
    .filter((col) => col.name)
    .map((col) => {
      return {
        label: `${col.label} [ ${col.name} ]` || col.name,
        value: col.name,
      };
    });

  // remove duplicate additional columns by value. This is to avoid duplicate columns in the dropdown which was causing error while typing the column that starts same name
  additionalColumnsLOV = additionalColumnsLOV.filter(
    (col, index, self) => index === self.findIndex((t) => t.value === col.value)
  );
  // remove additional columns which are already in columnsLOV. This is to avoid duplicate columns in the dropdown which was causing error while typing the column that starts same name
  additionalColumnsLOV = additionalColumnsLOV.filter(
    (col) =>
      columnsLOV.findIndex(
        (t) => t.value.toLowerCase() === col.value.toLowerCase()
      ) === -1
  );

  columnsLOV.push(...additionalColumnsLOV);
  return columnsLOV;
};

export const operators = [
  {
    value: "=",
    label: "=",
  },
  {
    value: "<=",
    label: "<=",
  },
  {
    value: "<",
    label: "<",
  },
  {
    value: ">=",
    label: ">=",
  },
  {
    value: ">",
    label: ">",
  },
  {
    value: "<>",
    label: "<>",
  },
  {
    value: "= (-1)",
    label: "= (-1)",
  },
  {
    value: "CONTAINS",
    label: "Contains",
  },
  {
    value: "DOES_NOT_CONTAIN",
    label: "Does not contain",
  },
  {
    value: "IS_CONTAINED_IN",
    label: "Is contained in",
  },
  {
    value: "NOT_CONTAINED_IN",
    label: "Not contained in",
  },
  {
    value: "STARTS_WITH",
    label: "Starts with",
  },
  {
    value: "DOES_NOT_START_WITH",
    label: "Does not start with",
  },
  {
    value: "IS_AT_THE_START_OF",
    label: "Is at the start of",
  },
  {
    value: "IS_NOT_AT_THE_START_OF",
    label: "Is not at the start of",
  },
  {
    value: "ENDS_WITH",
    label: "Ends with",
  },
  {
    value: "DOES_NOT_END_WITH",
    label: "Does not end with",
  },
  {
    value: "IS_AT_THE_END_OF",
    label: "Is at the end of",
  },
  {
    value: "IS_NOT_AT_THE_END_OF",
    label: "Is not at the end of",
  },
  {
    value: "IS NULL",
    label: "Is Null",
  },
  {
    value: "IS NOT NULL",
    label: "Is Not Null",
  },
];

export const columnTypes = [
  {
    value: "TEXT",
    label: "Text",
  },
  {
    value: "INTEGER",
    label: "Integer",
  },
  {
    value: "BIGINT",
    label: "Bigint",
  },
  {
    value: "AMOUNT",
    label: "Amount",
  },
  {
    value: "NUMERIC",
    label: "Numeric",
  },
  {
    value: "DATE",
    label: "Date",
  },
  {
    value: "TIMESTAMP",
    label: "Timestamp",
  },
  {
    value: "TIMESTAMP WITHOUT TIME ZONE",
    label: "Timestamp w/o Timezone",
  },
];

export async function getRelationship(
  primaryDataset: string,
  secondaryDataset: string
) {
  let response = await apiAxios.get("/api/relationship/primarysecondary", {
    params: {
      primaryDataset: primaryDataset,
      secondaryDataset: secondaryDataset,
      entityId: getLocalStorage("currentEntity"),
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response;
}

export function getSystemStatus(type = "SINGLE_DATASET_ENTRY") {
  if (type === "VARIANCE") {
    return [
      { label: "Within Variance", value: "WITHIN-VARIANCE" },
      { label: "Outside Variance", value: "OUTSIDE-VARIANCE" },
      { label: "Exception", value: "EXCEPTION" },
      { label: "No Status", value: "NOSTATUS" },
    ];
  } else if (type === "VALUE") {
    return [
      { label: "Valid", value: "VALID" },
      { label: "Invalid", value: "INVALID" },
      { label: "Exception", value: "EXCEPTION" },
      { label: "Skipped", value: "SKIPPED" },
      { label: "No Status", value: "NOSTATUS" },
    ];
  } else if (type === "ALL") {
    return [
      { label: "Valid", value: "VALID" },
      { label: "Invalid", value: "INVALID" },
      { label: "Exception", value: "EXCEPTION" },
      { label: "Skipped", value: "SKIPPED" },
      { label: "No Status", value: "NOSTATUS" },
      { label: "Within Variance", value: "WITHIN-VARIANCE" },
      { label: "Outside Variance", value: "OUTSIDE-VARIANCE" },
      { label: "Matched", value: "MATCHED" },
      { label: "Unmatched", value: "UNMATCHED" },
      { label: "Mismatched", value: "MISMATCHED" },
      { label: "Partial", value: "PARTIAL" },
      { label: "Matched within Margin", value: "MATCHED-WITHIN-MARGIN" },
      { label: "Matched with Exception", value: "MATCHED-WITH-EXCEPTION" },
      { label: "Ignored", value: "IGNORED" },
    ];
  }
  return [
    { label: "Matched", value: "MATCHED" },
    { label: "Unmatched", value: "UNMATCHED" },
    { label: "Partial", value: "PARTIAL" },
    { label: "Matched within Margin", value: "MATCHED-WITHIN-MARGIN" },
    { label: "Matched with Exception", value: "MATCHED-WITH-EXCEPTION" },
    { label: "Ignored", value: "IGNORED" },
    { label: "No Status", value: "NOSTATUS" },
  ];
}

export function getConfirmationStatus() {
  return [
    { label: "Confirmed", value: "CONFIRMED" },
    { label: "Unconfirmed", value: "UNCONFIRMED" },
  ];
}

export function getQueryColumnsDefinition(
  reconset: ReconsetBasicType,
  dataset: DatasetType
) {
  const queryColumns = [];

  if (reconset.definition.masterDataset.type === "NONE") {
    return [];
  }
  const datasetColumns = (dataset.definition.columns || [])
    .filter(
      (col) =>
        (reconset.definition.masterDataset.type !== "NONE"
          ? reconset.definition.masterDataset.queryColumns || []
          : []
        ).indexOf(col.name) > -1
    )
    .map((col) => {
      return {
        name: col.name,
        type: col.type,
        label: col.label,
      };
    });

  const reconsetAdditionalColumns = (
    reconset.definition.masterDataset.additionalColumns || []
  )
    .filter(
      (col) =>
        (reconset.definition.masterDataset.type !== "NONE"
          ? reconset.definition.masterDataset.queryColumns || []
          : []
        ).indexOf(col.name) > -1
    )
    .map((col) => {
      return {
        name: col.name,
        type: col.type,
        label: col.label,
      };
    });
  queryColumns.push({ name: "_runid", type: "TEXT", label: "Run Id" });
  queryColumns.push(...datasetColumns);
  queryColumns.push(...reconsetAdditionalColumns);
  return queryColumns;
}

export function getColor(value: string | null | undefined) {
  if (
    value === "UNMATCHED" ||
    value === "UNCONFIRMED" ||
    value === "EXCEPTION" ||
    value === "OUTSIDE-VARIANCE" ||
    value === "OUTSIDE VARIANCE" ||
    value === "OUTSIDE_VARIANCE" ||
    value === "FAILED" ||
    value === "ERROR" ||
    value === "OPEN" ||
    value === "HIGH" ||
    value === "INVALID"
  ) {
    return "red";
  } else if (value === "WAITING") {
    return "orange";
  } else if (value === "WAITING-COMPLETE") {
    return "yellow";
  } else if (value === "NO-DATA") {
    return "gray";
  }
  return "teal";
}

export function getNoOfDatasets(
  reconset: ReconsetBasicType | undefined,
  reconId: string | null | undefined
) {
  if (reconset && reconId) {
    const recon = reconset.definition.recons.find((rec) => rec.id === reconId);
    return recon?.definition?.noOfDatasets ?? "ONE";
  }
  return "ONE";
}

export function getReconType(
  reconset: ReconsetBasicType | undefined,
  reconId: string | null | undefined
) {
  if (reconset && reconId) {
    const recon = reconset.definition.recons.find((rec) => rec.id === reconId);
    return recon?.type;
  }
  return;
}

export function getFirstReconId(
  reconset: ReconsetBasicType | null | undefined
) {
  if (reconset) {
    const recons = reconset.definition.recons.filter(
      (recon) => !(recon.type === "PRE" || recon.type === "POST")
    );
    if (recons && recons.length > 0) {
      return recons[0].id;
    }
  }
  return "1";
}

// Recon V2

export const getColumnsToMergeLOV = (
  dataset: DatasetType | undefined,
  columns: string[],
  additionalColumns: {
    name: string;
    type: string;
    label?: string;
    notNull?: string;
    genFunction?: string;
  }[]
) => {
  if (!dataset) {
    return [];
  }
  let columnsLOV = (dataset.definition.columns || [])
    .filter((col) => columns.indexOf(col.name) > -1)
    .filter((col) => dataset.definition.primaryKey.indexOf(col.name) === -1)
    .map((col) => {
      return {
        label: `${col.label} [ ${col.name} ]` || col.name,
        value: col.name,
      };
    });

  let additionalColumnsLOV = (additionalColumns || [])
    .filter((col) => col.name)
    .map((col) => {
      return {
        label: `${col.label} [ ${col.name} ]` || col.name,
        value: col.name,
      };
    });

  // remove duplicate additional columns by value. This is to avoid duplicate columns in the dropdown which was causing error while typing the column that starts same name
  additionalColumnsLOV = additionalColumnsLOV.filter(
    (col, index, self) => index === self.findIndex((t) => t.value === col.value)
  );
  // remove additional columns which are already in columnsLOV. This is to avoid duplicate columns in the dropdown which was causing error while typing the column that starts same name
  additionalColumnsLOV = additionalColumnsLOV.filter(
    (col) =>
      columnsLOV.findIndex(
        (t) => t.value.toLowerCase() === col.value.toLowerCase()
      ) === -1
  );

  columnsLOV.push(...additionalColumnsLOV);
  return columnsLOV;
};

export const inlineQueryOperators = [
  {
    value: "IN",
    label: "In",
  },
];

const ReconSchema = object({
  id: string().nullable(),
  name: string().required(),
  definition: object({
    noOfDatasets: string().oneOf(["ONE", "TWO"]).required(),
  }),
});

export const ReconsetSchema = object({
  id: string().nullable(),
  entityId: string().nullable(),
  status: string().nullable(),
  authStatus: string().nullable(),
  usableStatus: string().nullable(),
  makerId: string().nullable(),
  makerDate: date().nullable(),
  modificationNo: number().nullable(),
  checkerId: string().nullable(),
  checkerDate: date().nullable(),
  name: string().required().min(5),
  displayName: string().required(),
  description: string().required(),
  definition: object({
    masterDataset: object({
      type: string().required().oneOf(["DATASET", "MANAGED", "NONE"]),
      id: string().when("type", {
        is: "DATASET",
        then: string().required(),
        otherwise: string().nullable(),
      }),
      columns: array().when("type", {
        is: "DATASET",
        then: array().of(string()).min(1),
        otherwise: array().of(string()).max(0),
      }),
      additionalColumns: array().when("type", {
        is: "MANAGED",
        then: array()
          .of(
            object({
              name: string().required(),
              type: string().required(),
              label: string().nullable(),
              genFunction: string().nullable(),
              notNull: string(),
              id: string().nullable(),
            })
          )
          .min(1),
        otherwise: array().of(
          object({
            name: string().required(),
            type: string().required(),
            label: string().nullable(),
            genFunction: string().nullable(),
            notNull: string(),
            id: string().nullable(),
          })
        ),
      }),
      queryColumns: array().of(string()),
      partitionBy: array().of(string()),
      accessControl: string().nullable(),
    }),
    userStatus: array().of(string()),
    loaderId: string().nullable(),
    recons: array().of(ReconSchema).min(1),
  }),
});
