import * as React from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import {
  Text,
  Grid,
  Title,
  Button,
  ActionIcon,
  Modal,
  Stack,
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import {
  CSelect,
  CTextInput,
  CCheckbox,
  CMultiSelect,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../ReconsetCVM";
import {
  getDataset,
  getDatasetColumnsLOV,
  columnTypes,
  getColumnsLOV,
  getDatasetsLOV,
} from "../ReconsetUtils";
import { useDatasets } from "../../hooks";

function Miscellaneous({
  name,
  masterDataset,
  control,
}: {
  name: `definition.masterDataset`;
  masterDataset: string;
  control: Control<any>;
}) {
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);
  const { data: datasets } = useDatasets(reconsetFormState.entityId);

  const masterDatasetColumns = useWatch({
    control,
    name: `${name}.columns`,
  });

  const masterDatasetAdditionalColumns = useWatch({
    control,
    name: `${name}.additionalColumns`,
  });

  return (
    <>
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <CMultiSelect
            name={`${name}.queryColumns`}
            control={control}
            searchable
            label="Query Columns"
            data={getColumnsLOV(
              getDataset(datasets, masterDataset),
              masterDatasetColumns || [],
              masterDatasetAdditionalColumns || []
            )}
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CMultiSelect
            name={`${name}.partitionBy`}
            control={control}
            searchable
            label="Parition By"
            data={getColumnsLOV(
              getDataset(datasets, masterDataset),
              masterDatasetColumns || [],
              masterDatasetAdditionalColumns || []
            )}
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>

      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name={`${name}.accessControl`}
            control={control}
            label="Access Control"
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

function MasterDatasetCVM({
  name,
  control,
}: {
  name: `definition.masterDataset`;
  control: Control<any>;
}) {
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);
  let [isOpen, setIsOpen] = React.useState(false);
  const { data: datasets } = useDatasets(reconsetFormState.entityId);

  const additionalColumnsArray = useFieldArray({
    control,
    name: `${name}.additionalColumns`,
    keyName: "key",
  });

  const masterDataset = useWatch({
    control,
    name: `${name}.id`,
  });

  const type = useWatch({
    control,
    name: `${name}.type`,
  });

  const additionalColumns = useWatch({
    control,
    name: `${name}.additionalColumns`,
  });

  const columns = useWatch({
    control,
    name: `${name}.columns`,
  });
  /*   const masterDatasetColumns = useWatch({
    control,
    name: `${name}.columns`,
  });

  const masterDatasetAdditionalColumns = useWatch({
    control,
    name: `${name}.additionalColumns`,
  });
 */
  /*   React.useEffect(() => {
    if (masterDataset) {
      let dataset = getDataset(datasets, masterDataset);
      if (
        dataset &&
        ((datasetColumns && datasetColumns.length() === 0) || !datasetColumns)
      ) {
        let columns = (dataset.definition.columns || []).map((col) => {
          return col.name;
        });
        reconsetFormState.setValue(`${name}.columns`, columns);
        // reconsetFormState.setValue(`${name}.displaySetColumns`, columns);
      }
    }
  }, [masterDataset]); */

  React.useEffect(() => {
    if (masterDataset) {
      let dataset = getDataset(datasets, masterDataset);
      if (dataset) {
        if (!columns || columns.length === 0) {
          // Only set columns when existing columns are empty
          let datasetColumns = (dataset.definition.columns || []).map((col) => {
            return col.name;
          });
          reconsetFormState.setValue(`${name}.columns`, datasetColumns);
        }
      }
    }
  }, [masterDataset]);

  return (
    <Stack>
      <Button
        fullWidth
        variant="default"
        // color="teal"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        Master Dataset
      </Button>

      <Modal
        centered
        size={"xl"}
        opened={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        title={<Title order={3}>Master Dataset</Title>}
      >
        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <CSelect
              name={`${name}.type`}
              label="Type"
              control={control}
              data={[
                { label: "Dataset", value: "DATASET" },
                // { label: "Self", value: "SELF" }, // No need for now. This is anyway managed as to how data load happens
                { label: "None", value: "NONE" },
              ]}
              showErrorText={false}
              searchable
              clearable
              required
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
        {type !== "NONE" && (
          <>
            {type === "DATASET" ? (
              <>
                <Grid align="stretch" gutter={"xs"} mb="xs">
                  <Grid.Col span={12}>
                    <CSelect
                      name={`${name}.id`}
                      label="Name"
                      control={control}
                      data={getDatasetsLOV(datasets)}
                      showErrorText={false}
                      searchable
                      clearable
                      required
                      disabled={reconsetFormState.actionOptions.disableAll}
                    />
                  </Grid.Col>
                </Grid>

                <Grid align="stretch" gutter={"xs"} mb="xs">
                  <Grid.Col span={12}>
                    <CMultiSelect
                      name={`${name}.columns`}
                      control={control}
                      searchable
                      required
                      label="Columns"
                      data={getDatasetColumnsLOV(
                        getDataset(datasets, masterDataset)
                      )}
                      disabled={reconsetFormState.actionOptions.disableAll}
                    />
                  </Grid.Col>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text fw={500} style={{ fontSize: "14px" }}>
                    Additional Columns
                  </Text>
                  <ActionIcon
                    size="sm"
                    variant="white"
                    color="gray"
                    onClick={() => {
                      additionalColumnsArray.append({
                        name: "",
                        type: "TEXT",
                        label: "",
                        genFunction: "",
                        notNull: "NO",
                      });
                    }}
                    disabled={reconsetFormState.actionOptions.disableAll}
                    style={{
                      display: reconsetFormState.actionOptions.disableAll
                        ? "none"
                        : "",
                    }}
                  >
                    <IconPlus />
                  </ActionIcon>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                >
                  <Grid align="stretch" gutter={"xs"} mt="xs">
                    <Grid.Col span={12}>
                      {additionalColumnsArray.fields.map((field, index) => {
                        return (
                          <Grid
                            align="stretch"
                            gutter={"xs"}
                            mb="xs"
                            key={field.key}
                          >
                            <Grid.Col span={2}>
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.name`}
                                control={control}
                                showErrorText={false}
                                label={index === 0 ? "Name" : ""}
                                required
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <CSelect
                                name={`${name}.additionalColumns.${index}.type`}
                                control={control}
                                label={index === 0 ? "Type" : ""}
                                data={columnTypes}
                                showErrorText={false}
                                required
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.label`}
                                control={control}
                                showErrorText={false}
                                required
                                label={index === 0 ? "Label" : ""}
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.genFunction`}
                                control={control}
                                showErrorText={false}
                                required
                                label={index === 0 ? "Gen Function" : ""}
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col
                              span={1}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.id`}
                                control={control}
                                label={index === 0 ? "Id" : ""}
                                disabled={true}
                              />
                            </Grid.Col>
                            <Grid.Col
                              span={2}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <CCheckbox
                                name={`${name}.additionalColumns.${index}.notNull`}
                                control={control}
                                label="Not Null"
                                trueValue="YES"
                                falseValue="NO"
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col
                              span={1}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <ActionIcon
                                size="sm"
                                variant="white"
                                color="gray"
                                onClick={() => {
                                  additionalColumnsArray.remove(index);
                                }}
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                                style={{
                                  display: reconsetFormState.actionOptions
                                    .disableAll
                                    ? "none"
                                    : "",
                                }}
                              >
                                <IconTrash />
                              </ActionIcon>
                            </Grid.Col>
                          </Grid>
                        );
                      })}
                    </Grid.Col>
                  </Grid>
                </div>

                <Miscellaneous
                  name={name}
                  masterDataset={masterDataset}
                  control={control}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Text fw={500} style={{ fontSize: "14px" }}>
                    Columns
                  </Text>
                  <ActionIcon
                    size="sm"
                    variant="white"
                    color="gray"
                    onClick={() => {
                      additionalColumnsArray.append({
                        name: "",
                        type: "TEXT",
                        label: "",
                        genFunction: "",
                        notNull: "NO",
                      });
                    }}
                    disabled={reconsetFormState.actionOptions.disableAll}
                    style={{
                      display: reconsetFormState.actionOptions.disableAll
                        ? "none"
                        : "",
                    }}
                  >
                    <IconPlus />
                  </ActionIcon>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                    marginLeft: "20px",
                    width: "100%",
                  }}
                >
                  <Grid align="stretch" gutter={"xs"} mt="xs">
                    <Grid.Col span={12}>
                      {additionalColumnsArray.fields.map((field, index) => {
                        return (
                          <Grid
                            align="stretch"
                            gutter={"xs"}
                            mb="xs"
                            key={field.key}
                          >
                            <Grid.Col span={2}>
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.name`}
                                control={control}
                                showErrorText={false}
                                label={index === 0 ? "Name" : ""}
                                required
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <CSelect
                                name={`${name}.additionalColumns.${index}.type`}
                                control={control}
                                label={index === 0 ? "Type" : ""}
                                data={columnTypes}
                                showErrorText={false}
                                required
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.label`}
                                control={control}
                                showErrorText={false}
                                required
                                label={index === 0 ? "Label" : ""}
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col span={2}>
                              <CTextInput
                                name={`${name}.additionalColumns.${index}.genFunction`}
                                control={control}
                                showErrorText={false}
                                label={index === 0 ? "Gen Function" : ""}
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col
                              span={2}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <CCheckbox
                                name={`${name}.additionalColumns.${index}.notNull`}
                                control={control}
                                label="Not Null"
                                trueValue="YES"
                                falseValue="NO"
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                              />
                            </Grid.Col>
                            <Grid.Col
                              span={1}
                              style={{
                                display: "flex",
                                alignItems: "flex-end",
                              }}
                            >
                              <ActionIcon
                                size="sm"
                                variant="white"
                                color="gray"
                                onClick={() => {
                                  additionalColumnsArray.remove(index);
                                }}
                                disabled={
                                  reconsetFormState.actionOptions.disableAll
                                }
                                style={{
                                  display: reconsetFormState.actionOptions
                                    .disableAll
                                    ? "none"
                                    : "",
                                }}
                              >
                                <IconTrash />
                              </ActionIcon>
                            </Grid.Col>
                          </Grid>
                        );
                      })}
                    </Grid.Col>
                  </Grid>
                </div>
                <Grid>
                  <Grid.Col>
                    <CMultiSelect
                      name={`${name}.queryColumns`}
                      control={control}
                      searchable
                      label="Query Columns"
                      data={getColumnsLOV(
                        getDataset(datasets, masterDataset),
                        [],
                        additionalColumns || []
                      )}
                      disabled={reconsetFormState.actionOptions.disableAll}
                    />
                  </Grid.Col>
                </Grid>
              </>
            )}
          </>
        )}
        <Grid align="stretch" gutter={"xs"} mt="xs">
          <Grid.Col span={2}>
            <Button
              color="teal"
              variant="filled"
              fullWidth
              onClick={() => setIsOpen(!isOpen)}
            >
              Ok
            </Button>
          </Grid.Col>
        </Grid>
      </Modal>
    </Stack>
  );
}

export default MasterDatasetCVM;
