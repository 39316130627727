import { createTheme, lighten } from "@mantine/core";

export const theme = createTheme({
  headings: {
    fontFamily: "'Open Sans Condensed', Roboto, sans-serif",
  },
  spacing: {
    xxs: "5px",
    xxl: "64px",
    xxxl: "96px",
  },
  colors: {
    emerald: [
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
      "#16A596",
    ],
  },
});

export const styles: {
  main: React.CSSProperties;
  header: React.CSSProperties;
  body: React.CSSProperties;
  viewMain: React.CSSProperties;
  viewHeader: React.CSSProperties;
  viewBody: React.CSSProperties;
  errors: React.CSSProperties;
  actions: React.CSSProperties;
  audit: React.CSSProperties;
  link: React.CSSProperties;
  badgeLink: React.CSSProperties;
  nodata: React.CSSProperties;
  control: React.CSSProperties;
  linksGroupLink: React.CSSProperties;
  chevron: React.CSSProperties;
  text: React.CSSProperties;
} = {
  main: {
    // padding: `theme.spacing?.xs || "10px" ${theme.spacing?.md || "16px"}`,
    boxShadow:
      "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing?.xs || "10px"} ${theme.spacing?.md || "16px"}`,
    borderLeft: `${theme.spacing?.lg || "20px"} solid #16A596`,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing?.xs || "10px"} ${theme.spacing?.md || "16px"}`,
    borderLeft: `${theme.spacing?.xs || "10px"} solid #FFE2E2`,
  },
  viewMain: {
    // padding: `theme.spacing?.xs || "10px" ${theme.spacing?.md || "16px"}`,
    display: "flex",
    flexDirection: "column",
  },
  viewHeader: {
    display: "flex",
    flexDirection: "column",
  },
  viewBody: {
    display: "flex",
    flexDirection: "column",
    paddingTop: `${theme.spacing?.md || "16px"}`,
  },
  errors: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing?.xs || "10px"} ${theme.spacing?.md || "16px"}`,
    borderLeft: `${theme.spacing?.xs || "10px"}  solid #D61C4E`,
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing?.xs || "10px"} ${theme.spacing?.md || "16px"}`,
    borderLeft: `${theme.spacing?.xs || "10px"}  solid #F8B400`,
  },
  audit: {
    display: "flex",
    flexDirection: "column",
    padding: `${theme.spacing?.xs || "10px"} ${theme.spacing?.md || "16px"}`,
    borderLeft: `${theme.spacing?.xs || "10px"}  solid #e2f6f6`,
  },
  link: {
    width: 50,
    height: 50,
    borderRadius: theme.radius?.md || "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.black,
    opacity: 0.85,
    // marginBottom: theme.spacing.xs,
  },
  badgeLink: {
    textDecoration: "none",
    cursor: "pointer",
  },
  nodata: {
    paddingLeft: theme.spacing?.xs || "10px",
    borderLeft: `5px solid ${theme.colors?.dark ? theme.colors?.dark[4] : "#424242"}`,
  },
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `theme.spacing?.xs || "10px" ${theme.spacing?.md || "16px"}`,
    color: "#1f1f1f",
    fontSize: "14px",
  },
  linksGroupLink: {
    textDecoration: "none",
    color: "#1f1f1f",
  },
  text: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `theme.spacing?.xs || "10px" ${theme.spacing?.md || "16px"}`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: "14px",
    color: "#495057",
    borderLeft: `1px solid #dee2e6`,
  },
  chevron: {
    transition: "transform 200ms ease",
  },
};
