import {
  Button,
  Group,
  Stepper,
  Modal,
  Title,
  Text,
  Grid,
} from "@mantine/core";
import * as React from "react";

function Walkthrough({
  openWalkthrough,
  closeWalkthrough,
}: {
  openWalkthrough: boolean;
  closeWalkthrough: () => void;
}) {
  const [opened, setOpened] = React.useState(false);

  const [active, setActive] = React.useState(0);
  const nextStep = () =>
    setActive((current) => (current < 6 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <Modal
      opened={openWalkthrough}
      onClose={() => closeWalkthrough()}
      size="auto"
      centered
      title={<Title order={2}>Welcome</Title>}
    >
      <Stepper size="sm" active={active} onStepClick={setActive} color="teal">
        <Stepper.Step label="Datasets">
          <Title mt="lg" order={3}>
            Configure Data Sets
          </Title>
          <Text>Design data tables that match the data to be reconciled</Text>
        </Stepper.Step>
        <Stepper.Step label="Reconsets">
          <Title mt="lg" order={3}>
            Define Recon Sets
          </Title>
          <Text>
            Describe reconciliation rules and actions to be run on the data sets
          </Text>
        </Stepper.Step>
        <Stepper.Step label="Loaders">
          <Title mt="lg" order={3}>
            Setup Data Loaders
          </Title>
          <Text>
            Configure data loaders to describe the data load configuration
          </Text>
        </Stepper.Step>

        <Stepper.Step label="Upload">
          <Title mt="lg" order={3}>
            Upload Data
          </Title>
          <Text>Run data loaders to ingest data into datasets</Text>
        </Stepper.Step>

        <Stepper.Step label="Reconciliation">
          <Title mt="lg" order={3}>
            Run Reconciliation
          </Title>
          <Text>Execute reconciliation rules and actions on the data set</Text>
        </Stepper.Step>

        <Stepper.Step label="Post Process">
          <Title mt="lg" order={3}>
            Post Process
          </Title>
          <Text>
            Raise alerts, assign tasks, create status reports and more
          </Text>
        </Stepper.Step>
        <Stepper.Completed>
          <Title mt="lg" order={3}>
            Rekonist
          </Title>
          <Text>
            Thoroughly reconcile, analyze, validate and audit the data within
            and across systems
          </Text>
        </Stepper.Completed>
      </Stepper>

      <Grid align="stretch" gutter={"xs"} mt="lg" columns={24}>
        <Grid.Col span={3}>
          <Button
            fullWidth
            variant="default"
            onClick={prevStep}
            disabled={active === 0}
          >
            Back
          </Button>
        </Grid.Col>
        <Grid.Col span={3}>
          <Button
            fullWidth
            variant="default"
            onClick={nextStep}
            disabled={active === 6}
          >
            Next
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default Walkthrough;
