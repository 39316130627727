import * as React from "react";
import { useForm, useFieldArray, Control, useWatch } from "react-hook-form";
import { Button, Grid, Title, ActionIcon, Group } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import { RoleSchema, RoleType } from "@supportinitiative/common";

import {
  CMultiSelect,
  CSelect,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";

import { styles } from "../styles";
import { postRole } from "../api";
import { AuditType, IDispatchAction } from "../types";
import { getActionOptions } from "./RoleUtils";
import { UserContext } from "../misc/UserContext";
import { useRole } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";

type actionTypes =
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR";

export const RoleCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  entityId: "",
  actionOptions: {},
  setValue: () => {},
  submitting: false,
  error: "",
  successMessage: "",
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    default:
      return state;
  }
}

function getAccessTypeOptions(type: string) {
  return [
    { label: "View", value: "VIEW" },
    { label: "Create", value: "CREATE" },
  ];
}

function AccessControl({
  name,
  index,
  accessFieldArray,
  control,
  roleFormState,
}: {
  name: `access.${number}`;
  index: number;
  accessFieldArray: any;
  control: Control<any>;
  roleFormState: any;
}) {
  const type = useWatch({
    control,
    name: `${name}.type`,
  });

  return (
    <Grid align="stretch" gutter={"xs"} mb="xs">
      <Grid.Col span={2}>
        <CSelect
          name={`${name}.type`}
          control={control}
          data={[
            { label: "Dataset", value: "DATASET" },
            { label: "Relationship", value: "RELATIONSHIP" },
            { label: "Reconset", value: "RECONSET" },
            { label: "Loader", value: "LOADER" },
            { label: "Function", value: "FUNCTION" },
            { label: "User", value: "USER" },
            { label: "Role", value: "ROLE" },
          ]}
          showErrorText={false}
          required
          disabled={roleFormState.actionOptions.disableAll}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CMultiSelect
          name={`${name}.options`}
          control={control}
          data={getAccessTypeOptions(type)}
          showErrorText={false}
          required
          disabled={roleFormState.actionOptions.disableAll}
        />
      </Grid.Col>

      <Grid.Col span={1} style={{ display: "flex", alignItems: "flex-end" }}>
        <ActionIcon
          variant="subtle"
          color="gray"
          onClick={() => {
            accessFieldArray.remove(index);
          }}
          style={{
            display:
              roleFormState.currentAction.toUpperCase() === "VIEW"
                ? "none"
                : "",
          }}
          disabled={roleFormState.actionOptions.disableAll}
        >
          <IconTrash />
        </ActionIcon>
      </Grid.Col>
    </Grid>
  );
}

export default function RoleCVM() {
  // let [role, setRole] = React.useState<RoleType>();

  let [audit, setAudit] = React.useState<AuditType | undefined>();

  // let [currentAction, setCurrentAction] = React.useState("new");
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const { data: role } = useRole(entityId, id);
  // let [newRole, setNewRole] = React.useState<RoleType | undefined>();
  const [roleFormState, roleFormDispatch] = React.useReducer(reducer, {
    ...initialState,
    entityId,
    actionOptions: getActionOptions("new", role, role, applicationState),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RoleType>({
    defaultValues: {
      name: "",
      description: "",
      type: "OPERATOR",
    },
    criteriaMode: "all",
    resolver: yupResolver(RoleSchema),
  });

  const accessFieldArray = useFieldArray({
    control,
    name: "access",
    keyName: "key",
  });

  const onOk = () => {
    navigate(`/app/roles?entityId=${entityId}`);
  };

  React.useEffect(() => {
    if (role) {
      roleFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      roleFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions("view", role, role, applicationState),
      });

      setAudit({
        status: role.status,
        authStatus: role.authStatus,
        usableStatus: role.usableStatus,
        makerId: role.makerId,
        makerDate: role.makerDate,
        modificationNo: role.modificationNo,
        checkerId: role.checkerId,
        checkerDate: role.checkerDate,
      });

      reset(role);
    }
  }, [role]);

  const onSubmit = async (data: any) => {
    try {
      roleFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      roleFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: roleFormState.currentAction,
        },
      };
      await postRole(payload);
      // setError("Form", "Unable to Submit");
      roleFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["role", entityId, id]);
      queryClient.invalidateQueries(["roles", entityId]);
      roleFormDispatch({
        type: "SET-SUCCESS",
        value: "Role Saved Successfully",
      });
    } catch (error: any) {
      // console.log("post catch", error);
      // console.log("post catch", error?.response?.data?.message);
      roleFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      roleFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save Role",
      });
    }
  };

  return (
    <RoleCVMContext.Provider
      value={{ state: roleFormState, dispatch: roleFormDispatch }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={styles.main} noValidate>
        <div style={styles.header} id="header">
          <Title order={2}>Role</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={2}>
              <CTextInput
                name="name"
                control={control}
                label="Name"
                aria-label="Name"
                required
                showErrorText={false}
                disabled={roleFormState.actionOptions.disableAll}
              />
            </Grid.Col>

            <Grid.Col span={4}>
              <CTextInput
                name="description"
                control={control}
                label="Description"
                aria-label="Description"
                required
                showErrorText={false}
                disabled={roleFormState.actionOptions.disableAll}
              />
            </Grid.Col>

            <Grid.Col span={2}>
              <CSelect
                name="type"
                control={control}
                label="Type"
                aria-label="Type"
                data={[
                  { label: "Admin", value: "ADMIN" },
                  { label: "Operator", value: "OPERATOR" },
                ]}
                required
                showErrorText={false}
                disabled={roleFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Group mb="xs" mt="xs" style={{ alignItems: "center" }}>
              <Title order={4}>Access</Title>
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={() => {
                  accessFieldArray.append({
                    type: "",
                    options: [],
                  });
                }}
                style={{
                  display:
                    roleFormState.currentAction.toUpperCase() === "VIEW"
                      ? "none"
                      : "",
                }}
                disabled={roleFormState.actionOptions.disableAll}
              >
                <IconPlus />
              </ActionIcon>
            </Group>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              {accessFieldArray.fields.map((field, index) => {
                return (
                  <AccessControl
                    name={`access.${index}`}
                    index={index}
                    control={control}
                    accessFieldArray={accessFieldArray}
                    roleFormState={roleFormState}
                    key={field.key}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {(Object.keys(errors).length > 0 || roleFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={roleFormState.error} />
            </div>
          </div>
        )}

        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(roleFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action === "save") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          fullWidth
                          type="submit"
                          color="teal"
                          aria-label="Save"
                          loading={roleFormState.submitting}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "cancel") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/roles?entityId=${entityId}`}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "back") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/roles?entityId=${entityId}`}
                          aria-label="Back"
                        >
                          Back
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "edit") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Edit"
                          fullWidth
                          onClick={() => {
                            roleFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });
                            roleFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                role,
                                role,
                                applicationState
                              ),
                            });

                            setAudit({
                              status: role.status,
                              authStatus: "UNAUTH",
                              usableStatus: role.usableStatus,
                              makerId: applicationState.user.userId,
                              makerDate: new Date(),
                              modificationNo:
                                role.authStatus === "UNAUTH"
                                  ? role.modificationNo
                                  : (role.modificationNo || 0) + 1,
                              checkerId: "",
                              checkerDate: undefined,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "auth") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Auth"
                          fullWidth
                          onClick={async () => {
                            roleFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "AUTH",
                            });

                            try {
                              let payload = {
                                ...role,
                                entityId,
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postRole(payload);
                              // setError("Form", "Unable to Submit");
                              roleFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              roleFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Role Authorized Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              roleFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Authorize Role",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "delete") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Delete"
                          fullWidth
                          onClick={async () => {
                            roleFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "DELETE",
                            });

                            try {
                              let payload = {
                                ...role,
                                entityId,
                                metaData: {
                                  action: "delete",
                                },
                              };
                              await postRole(payload);
                              // setError("Form", "Unable to Submit");
                              roleFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              roleFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Role Deleted Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              roleFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Delete Role",
                              });
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {roleFormState.successMessage && (
          <Success message={roleFormState.successMessage} onOk={onOk} />
        )}
      </form>
    </RoleCVMContext.Provider>
  );
}
