import * as React from "react";
import {
  Grid,
  Title,
  Card,
  Text,
  useMantineTheme,
  MultiSelect,
  Badge,
  Divider,
  Flex,
  Table,
  Stack,
} from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";

import { TaskType } from "@supportinitiative/common";

import { useTasks } from "../hooks";
import Loading from "../ui/Loading";
import { getIsMatch } from "../utilities";
import { getColor } from "../Reconset/ReconsetUtils";
import { MultiSelectCreatable } from "../ui/MultiSelectCreatable";
import dayjs from "dayjs";

export default function Tasks() {
  let [searchParams] = useSearchParams();
  let entityId = searchParams.get("entityId");

  const { data: tasks, isLoading } = useTasks(entityId);
  let [filteredTasks, setFilteredTasks] = React.useState<TaskType[]>([]);
  const theme = useMantineTheme();

  const [taskFilter, setTaskFilter] = React.useState<string[]>([]);
  const [taskFilterList, setTaskFilterList] = React.useState<
    { value: string; label: string }[]
  >([]);

  React.useEffect(() => {
    const newFilteredData = (tasks || []).filter(getIsMatch(taskFilter));
    setFilteredTasks(newFilteredData);
  }, [taskFilter, tasks]);

  return (
    <div style={{ marginTop: theme.spacing.lg }}>
      <Title order={3}>Tasks</Title>
      <Grid align="center" gutter={"xs"}>
        <Grid.Col span={4}>
          <MultiSelectCreatable
            data={taskFilterList}
            placeholder="Filter"
            onChange={(value) => {
              setTaskFilter(value);
            }}
          />
        </Grid.Col>
      </Grid>

      <Table stickyHeader mt="lg" stickyHeaderOffset={60}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Date</Table.Th>
            <Table.Th>Name</Table.Th>
            <Table.Th>Reconset</Table.Th>
            <Table.Th>Owner</Table.Th>
            <Table.Th>Details</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <>
            {isLoading ? (
              <Stack mt="xs">
                <Loading />
              </Stack>
            ) : (
              <>
                {filteredTasks.length > 0 ? (
                  <>
                    {filteredTasks.map((task) => {
                      return (
                        <Table.Tr key={task.id}>
                          <Table.Td
                            style={{
                              maxWidth: "100px",
                            }}
                          >
                            <Text truncate="end" size="sm">
                              {dayjs(task.makerDate).format(
                                "ddd, YYYY-MM-DD HH:MM"
                              )}
                            </Text>
                          </Table.Td>
                          <Table.Td
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            <Text truncate="end" size="sm">
                              <Link
                                to={`/app/tasks/view?id=${task.id}&entityId=${entityId}`}
                                style={{ color: theme.colors.blue[5] }}
                              >
                                {task.id} - {task.name}
                              </Link>
                            </Text>
                          </Table.Td>

                          <Table.Td
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            <Text truncate="end" size="sm">
                              {task.reconsetName}
                            </Text>
                          </Table.Td>
                          <Table.Td>
                            <Text truncate="end" size="sm">
                              {task.ownerName}
                            </Text>
                          </Table.Td>
                          <Table.Td
                            style={{
                              maxWidth: "150px",
                            }}
                          >
                            <Flex>
                              <Badge
                                size="sm"
                                radius="xs"
                                color={getColor(task.status)}
                                variant="light"
                                style={{ marginRight: theme.spacing.xs }}
                              >
                                {task.status}
                              </Badge>
                              <Badge
                                size="sm"
                                radius="xs"
                                color={getColor(task.priority)}
                                variant="light"
                                style={{ marginRight: theme.spacing.xs }}
                              >
                                {task.priority}
                              </Badge>

                              <Text size="sm"></Text>
                            </Flex>
                          </Table.Td>
                        </Table.Tr>
                      );
                    })}
                  </>
                ) : (
                  <Divider mt="lg" label="No Tasks" labelPosition="left" />
                )}
              </>
            )}
          </>
        </Table.Tbody>
      </Table>
    </div>
  );
}
