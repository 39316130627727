import * as React from "react";
import { useWatch, Control, UseFieldArrayReturn } from "react-hook-form";
import {
  Grid,
  ActionIcon,
  Title,
  Stack,
  useMantineTheme,
  Flex,
} from "@mantine/core";
import {
  IconEdit,
  IconFingerprint,
  IconHeartCheck,
  IconNotification,
  IconTimelineEventX,
  IconTrash,
} from "@tabler/icons-react";

import {
  CSelect,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";
import { ReconsetType } from "@supportinitiative/common";

import { ReconsetCVMContext } from "../ReconsetCVM";

import FlowBuilder, { INode, IRegisterNode } from "react-flow-builder";
import {
  DrawerComponent,
  StartNodeDisplay,
  EndNodeDisplay,
  NodeDisplay,
  ReconDefDisplay,
  PopConfirm,
  AddMenu,
} from "../Workflow/Components";
import { getId } from "../../utilities";
import ReconDef from "./ReconDef";
import {
  IconAnchor,
  IconChartLine,
  IconChecklist,
  IconChecks,
  IconDatabase,
  IconFileUpload,
  IconFilter,
  IconSubtask,
} from "@tabler/icons-react";
import DatasetsCVM from "./DatasetsCVM";
import DataLoadRecon from "../Action/DataLoad/DataLoadRecon";
import AnchorQueryMatch from "../Action/QueryMatch/AnchorMatch";
import MultiMatch from "../Action/MultiMatch/MultiMatch";
import Filter from "../Action/Filter/Filter";
import ValueValidation from "../Action/QueryMatch/ValueValidation";
import CreateTask from "../Action/CreateTask/CreateTask";
import SummaryStats from "../Action/SummaryStats/SummaryStats";
import DeriveData from "../Action/DeriveData/DeriveData";
import Notification from "../Action/Notification/Notification";
import StatusUpdate from "../Action/StatusUpdate/StatusUpdate";
import Finding from "../Action/Finding/Finding";
import Remediation from "../Action/Remediation/Remediation";
import FieldMatch from "../Action/FieldMatch/FieldMatch";

function formOrchestratorNodes(
  nodes: any,
  recons: any,
  reconIndex: number,
  disabled: boolean
) {
  if (nodes && nodes.length > 0) {
    const newNodes = nodes.map((node: any) => {
      const newNode: {
        id: string;
        type: string;
        name: string;
        path: string[];
        data?: any;
        disabled: boolean;
      } = {
        id: node.id,
        type: node.type,
        name: node.name,
        path: node.path,
        data: node.data,
        disabled: disabled,
      };

      if (node.type.toUpperCase() === "RECON") {
        const recon = recons.find((recon: any) => recon.id === node.id);
        if (recon) {
          newNode.name = recon.name;
        }
      }
      return newNode;
    });
    return newNodes;
  }
  // console.log("defaultNodes", defaultNodes);
  return [
    {
      id: getId(),
      type: "START",
      name: "Start",
      path: ["0"],
    },
    {
      id: getId(),
      type: "END",
      name: "End",
      path: ["2"],
    },
  ];
}

function ReconCVM({
  name,
  index,
  reconsArray,
  control,
  setActiveTab,
}: {
  name: `definition.recons.${number}`;
  index: number;
  // reconsArray: UseFieldArrayReturn<ReconsetType, "definition.recons", "key">;
  reconsArray: any;
  control: Control<any>;
  setActiveTab: (index: string) => void;
}) {
  const theme = useMantineTheme();
  const type = useWatch({
    control,
    name: `${name}.type`,
  });

  const { state: reconsetFormState, dispatch: reconsetFormDispatch } =
    React.useContext(ReconsetCVMContext);

  const orchestratorNodes = useWatch({
    control,
    name: `${name}.workflow`,
  });

  const recons = useWatch({
    control,
    name: "definition.recons",
  });

  const userStatus = useWatch({
    control,
    name: "definition.userStatus",
  });

  const recon = useWatch({
    control,
    name: `definition.recons.${index}`,
  });

  const [nodes, setNodes] = React.useState<INode[]>(
    formOrchestratorNodes(
      orchestratorNodes,
      recons,
      index,
      reconsetFormState.actionOptions.disableAll
    )
  );

  React.useEffect(() => {
    setNodes(
      formOrchestratorNodes(
        orchestratorNodes,
        recons,
        index,
        reconsetFormState.actionOptions.disableAll
      )
    );
  }, [reconsetFormState.actionOptions.disableAll]);

  React.useEffect(() => {
    if (recon) {
      reconsetFormDispatch({ type: "SET-RECON", value: recon });
    }
  }, [recon]);

  const handleChange = (nodes: INode[]) => {
    setNodes(nodes);

    const newRecons = (nodes || [])
      .filter((node) => node.type.toUpperCase() === "RECON")
      .filter(
        (node) =>
          (recons.map((recon: any) => recon.id) || []).indexOf(node.id) === -1
      )
      .map((node) => {
        return {
          id: node.id,
          name: node.data ? node.data.name : node.name,
        };
      });

    if (newRecons && newRecons.length > 0) {
      reconsArray.append(newRecons);
    }
    reconsetFormState.setValue(`${name}.workflow`, nodes);
  };

  const registerNodes: IRegisterNode[] = [
    {
      type: "START",
      name: "Start",
      displayComponent: StartNodeDisplay,
      isStart: true,
    },
    {
      type: "END",
      name: "End",
      displayComponent: EndNodeDisplay,
      isEnd: true,
    },
    {
      type: "ANCHOR-MATCH",
      name: "Anchor and Match",
      displayComponent: NodeDisplay,
      configComponent: AnchorQueryMatch,
      addIcon: <IconAnchor />,
      removeConfirmTitle: "Delete Match?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "DATALOAD",
      name: "Dataload",
      displayComponent: NodeDisplay,
      configComponent: DataLoadRecon,
      addIcon: <IconFileUpload />,
      removeConfirmTitle: "Delete Dataload?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    /*     {
      type: "entrymatch",
      name: "Entry Match",
      displayComponent: NodeDisplay,
      configComponent: EntryMatch,
      addIcon: <IconRelationManyToMany />,
      removeConfirmTitle: "Delete Match?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    }, */
    {
      type: "DERIVE-DATA",
      name: "Derive Data",
      displayComponent: NodeDisplay,
      configComponent: DeriveData,
      addIcon: <IconDatabase />,
      removeConfirmTitle: "Delete data derivation?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "FIELD-MATCH",
      name: "Field Match",
      displayComponent: NodeDisplay,
      configComponent: FieldMatch,
      addIcon: <IconTimelineEventX />,
      removeConfirmTitle: "Delete Field Match?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "FILTER",
      name: "Filter",
      displayComponent: NodeDisplay,
      configComponent: Filter,
      addIcon: <IconFilter />,
      removeConfirmTitle: "Delete Filter?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "FINDING",
      name: "Finding",
      displayComponent: NodeDisplay,
      configComponent: Finding,
      addIcon: <IconFingerprint />,
      removeConfirmTitle: "Delete Finding?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "MULTI-MATCH",
      name: "Multi Match",
      displayComponent: NodeDisplay,
      configComponent: MultiMatch,
      addIcon: <IconTimelineEventX />,
      removeConfirmTitle: "Delete Multi Match?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "NOTIFICATION",
      name: "Notification",
      displayComponent: NodeDisplay,
      configComponent: Notification,
      addIcon: <IconNotification />,
      removeConfirmTitle: "Delete Notification?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "RECON",
      name: "Reconciliation",
      displayComponent: ReconDefDisplay,
      configComponent: ReconDef,
      addIcon: <IconChecklist />,
      removeConfirmTitle: "Delete Reconset?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "REMEDIATION",
      name: "Remediation",
      displayComponent: NodeDisplay,
      configComponent: Remediation,
      addIcon: <IconHeartCheck />,
      removeConfirmTitle: "Delete Remediation?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "STATUS-UPDATE",
      name: "Status Update",
      displayComponent: NodeDisplay,
      configComponent: StatusUpdate,
      addIcon: <IconEdit />,
      removeConfirmTitle: "Delete Status Update?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "SUMMARY-STATS",
      name: "Summary Stats",
      displayComponent: NodeDisplay,
      configComponent: SummaryStats,
      addIcon: <IconChartLine />,
      removeConfirmTitle: "Delete Summary Stats?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "TASK",
      name: "Create Task",
      displayComponent: NodeDisplay,
      configComponent: CreateTask,
      addIcon: <IconSubtask />,
      removeConfirmTitle: "Delete Create Task?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "VALUE-VALIDATION",
      name: "Value Validation",
      displayComponent: NodeDisplay,
      configComponent: ValueValidation,
      addIcon: <IconChecks />,
      removeConfirmTitle: "Delete Value Validation?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
  ];

  return (
    <Stack
      style={{
        display: "flex",
        flexDirection: "column",
        // marginBottom: "20px",
        // paddingLeft: "10px",
        marginTop: theme.spacing.xs,
        padding: theme.spacing.xs,
        width: "100%",
      }}
    >
      <Flex>
        <Title
          mb="xs"
          order={5}
          c="white"
          py={0}
          px={theme.spacing.xxs}
          style={{
            backgroundColor: theme.colors.emerald[0],
          }}
        >
          Name
        </Title>
      </Flex>
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={4}>
          <CTextInput
            name={`${name}.name`}
            control={control}
            required
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
        {!reconsetFormState.actionOptions.disableAll && (
          <Grid.Col
            span={1}
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <ActionIcon
              size="sm"
              variant="white"
              color="gray"
              onClick={() => {
                reconsArray.remove(index);
                setActiveTab(index === 0 ? "0" : (index - 1).toString());
              }}
              disabled={
                reconsetFormState.actionOptions.disableAll ||
                type === "PRE" ||
                type === "POST"
              }
            >
              <IconTrash />
            </ActionIcon>
          </Grid.Col>
        )}
      </Grid>
      <Flex>
        <Title
          mb="xs"
          mt="xs"
          order={5}
          c="white"
          py={0}
          px={theme.spacing.xxs}
          style={{
            backgroundColor: theme.colors.emerald[0],
          }}
        >
          Datasets
        </Title>
      </Flex>
      <DatasetsCVM
        control={control}
        name={`definition.recons.${index}.definition`}
      />
      <div
        style={{
          display: "flex",
        }}
      >
        <Title
          mb="xs"
          mt="xs"
          order={5}
          c="white"
          py={0}
          px={theme.spacing.xxs}
          style={{
            backgroundColor: theme.colors.emerald[0],
          }}
        >
          Workflow
        </Title>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <FlowBuilder
          className="flow"
          nodes={nodes}
          onChange={handleChange}
          registerNodes={registerNodes}
          DrawerComponent={DrawerComponent}
          PopoverComponent={(props) => {
            return (
              <AddMenu
                {...props}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            );
          }}
          PopconfirmComponent={PopConfirm}
          backgroundColor="#FFF"
          zoomTool={{ hidden: true, initialValue: 90 }}
          createUuid={(type) => {
            return getId();
          }}
        />
      </div>
    </Stack>
  );
}

export default ReconCVM;
