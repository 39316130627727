import * as React from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import {
  Button,
  Grid,
  Title,
  SegmentedControl,
  useMantineTheme,
} from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";

import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { CTextInput } from "@supportinitiative/react-hook-form-mantine";
import { ReconsetType } from "@supportinitiative/common";

import { apiAxios, getAuthHeader, getLocalStorage } from "../api";
import { AuditType } from "../types";
import { getActionOptions, ReconsetSchema } from "./ReconsetUtils";
import { UserContext } from "../misc/UserContext";
import { IDispatchAction } from "../types";
import ReconCVM from "./Recon/ReconCVM";
import { useReconset } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";
import PreferencesCVM from "./PreferencesCVM";
import OrchestratorCVM from "./OrchestratorCVM";
import { styles } from "src/styles";

async function postReconset(reconset: any) {
  return await apiAxios.post("/api/reconset", reconset, {
    params: {
      __entityId: reconset.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

type actionTypes =
  | "SET-ENTITY-ID"
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR"
  | "SET-RECON"
  | "SET-USER-STATUS";

export const ReconsetCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  entityId: "",
  actionOptions: {},
  setValue: () => {},
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "SET-ENTITY-ID":
      return { ...state, entityId: action.value };
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    case "SET-RECON":
      return { ...state, recon: action.value };
    case "SET-USER-STATUS":
      return { ...state, userStatus: action.value };
    default:
      return state;
  }
}

// TODO: Deletion

export default function ReconsetCVM() {
  let [audit, setAudit] = React.useState<AuditType | undefined>();
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const theme = useMantineTheme();

  const { data: reconset } = useReconset(entityId, id);
  const [activeTab, setActiveTab] = React.useState<string>("0");
  const [userStatus, setUserStatus] = React.useState<
    { label: string; value: string }[]
  >([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ReconsetType>({
    defaultValues: {
      name: "",
      displayName: "",
      description: "",
      definition: {
        masterDataset: {
          type: "DATASET",
        },
        loaderId: "",
        userStatus: [],
        preferences: {},
        orchestrator: {},
        recons: [],
      },
    },
    criteriaMode: "all",
    // resolver: yupResolver(ReconsetSchema),
    resolver: yupResolver(ReconsetSchema),
  });

  const [reconsetFormState, reconsetFormDispatch] = React.useReducer(reducer, {
    ...initialState,
    entityId,
    actionOptions: getActionOptions(
      "new",
      reconset,
      reconset,
      applicationState
    ),
    setValue: setValue,
  });

  const reconsArray = useFieldArray({
    control,
    name: "definition.recons",
    keyName: "key",
  });

  const recons = useWatch({
    control,
    name: "definition.recons",
  });

  const onOk = () => {
    navigate(`/app/reconsets?entityId=${entityId}`);
  };

  React.useEffect(() => {
    if (reconset) {
      reconsetFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      reconsetFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions("view", reconset, reconset, applicationState),
      });
      setAudit({
        status: reconset.status,
        authStatus: reconset.authStatus,
        usableStatus: reconset.usableStatus,
        makerId: reconset.makerId,
        makerDate: reconset.makerDate,
        modificationNo: reconset.modificationNo,
        checkerId: reconset.checkerId,
        checkerDate: reconset.checkerDate,
      });

      let userStatus = (reconset.definition.userStatus || []).map((status) => {
        return { label: status, value: status };
      });
      setUserStatus(userStatus || []);
      reconsetFormDispatch({
        type: "SET-USER-STATUS",
        value: userStatus,
      });
      reset(reconset);
    }
  }, [reconset]);

  const onSubmit = async (data: any) => {
    try {
      reconsetFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      reconsetFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: reconsetFormState.currentAction,
        },
      };
      await postReconset(payload);
      reconsetFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["reconset", entityId, id]);
      queryClient.invalidateQueries(["reconsets", entityId]);
      reconsetFormDispatch({
        type: "SET-SUCCESS",
        value: "Reconset Saved Successfully",
      });
    } catch (error: any) {
      reconsetFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      reconsetFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save Reconset",
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ ...styles.main, marginTop: theme.spacing.lg }}
      noValidate
    >
      <ReconsetCVMContext.Provider
        value={{ state: reconsetFormState, dispatch: reconsetFormDispatch }}
      >
        <div style={styles.header} id="header">
          <Title order={2}>Reconset</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid
            align="flex-start"
            gutter={"xs"}
            mb="xs"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <Grid.Col span={2}>
              <CTextInput
                name="name"
                control={control}
                label="Name"
                required
                showErrorText={false}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CTextInput
                name="displayName"
                control={control}
                label="Display Name"
                required
                showErrorText={false}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CTextInput
                name="description"
                control={control}
                label="Description"
                required
                showErrorText={false}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <SegmentedControl
              value={activeTab}
              onChange={setActiveTab}
              data={[
                {
                  label: `Preferences`,
                  value: "0",
                },
                {
                  label: `Orchestrator`,
                  value: "1",
                },
                ...recons.map((recon, index) => {
                  return {
                    label: `${
                      recon.name
                        ? `${recon.name} (${recon.id})`
                        : `Recon ${index + 1}`
                    }`,
                    value: (index + 2).toString(),
                  };
                }),
              ]}
            />

            {activeTab === "0" ? (
              <PreferencesCVM
                name="definition"
                control={control}
                userStatus={userStatus}
                setUserStatus={setUserStatus}
              />
            ) : activeTab === "1" ? (
              <OrchestratorCVM
                name="definition.orchestrator"
                control={control}
                reconsArray={reconsArray}
              />
            ) : (
              <>
                {reconsArray.fields.map((field, index) => {
                  if ((index + 2).toString() === activeTab) {
                    return (
                      <ReconCVM
                        name={`definition.recons.${index}`}
                        control={control}
                        key={field.key}
                        index={index}
                        reconsArray={reconsArray}
                        setActiveTab={setActiveTab}
                      />
                    );
                  }
                })}
              </>
            )}
          </div>

          {/* 
          {reconsArray.fields.length > 0 && (
            <Tabs
              value={activeTab}
              color="teal"
              onTabChange={setActiveTab}
              styles={(theme) => ({
                root: {
                  marginTop: theme.spacing.lg,
                },
                tabsList: {
                  margnBottom: theme.spacing.lg,
                },
                panel: {
                  marginTop: theme.spacing.lg,
                },
              })}
            >
              <Tabs.List>
                {reconsArray.fields.map((field, index) => {
                  return (
                    <Tabs.Tab value={index.toString()} key={index}>
                      <Text fw={500} style={{ fontSize: "14px" }}>
                        {field.name ? field.name : `Recon ${index + 1}`}
                      </Text>
                    </Tabs.Tab>
                  );
                })}
              </Tabs.List>
              {reconsArray.fields.map((field, index) => {
                return (
                  <ReconCVM
                    name={`definition.recons.${index}`}
                    control={control}
                    key={field.key}
                    index={index}
                    reconsArray={reconsArray}
                    setActiveTab={setActiveTab}
                  />
                );
              })}
            </Tabs>
          )}

 */}
        </div>

        {(Object.keys(errors).length > 0 || reconsetFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={reconsetFormState.error} />
            </div>
          </div>
        )}
        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(reconsetFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action.toUpperCase() === "SAVE") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          color="teal"
                          variant="filled"
                          fullWidth
                          type="submit"
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action.toUpperCase() === "CANCEL") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/reconsets?entityId=${entityId}`}
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action.toUpperCase() === "EDIT") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          onClick={() => {
                            reconsetFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });

                            reconsetFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                reconset,
                                reconset,
                                applicationState
                              ),
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action.toUpperCase() === "AUTH") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          onClick={async () => {
                            reconsetFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "auth",
                            });
                            try {
                              let payload = {
                                ...reconset,
                                entityId: getLocalStorage("currentEntity"),
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postReconset(payload);
                              // setError("Form", "Unable to Submit");
                              reconsetFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "view",
                              });

                              reconsetFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Reconset Authorized Successfully",
                              });
                            } catch (error: any) {
                              reconsetFormDispatch({
                                type: "SET-SUBMITTING",
                                value: false,
                              });
                              reconsetFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Authorize Reconset",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action.toUpperCase() === "DELETE") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          onClick={() => {
                            reconsetFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "delete",
                            });
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {reconsetFormState.successMessage && (
          <Success message={reconsetFormState.successMessage} onOk={onOk} />
        )}
      </ReconsetCVMContext.Provider>
    </form>
  );
}
