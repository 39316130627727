import { Grid, Text } from "@mantine/core";
import { TaskCommentType, TaskDetailType } from "@supportinitiative/common";
import dayjs from "dayjs";

import TaskComment from "./TaskComment";

function getComment(
  id: string | null | undefined,
  comments: TaskCommentType[]
) {
  if (!id) return;

  const comment = comments.find((comment) => comment.id === id);
  return comment;
}

function TaskActvities({ task }: { task: TaskDetailType }) {
  return (
    <div
      style={{
        marginTop: "20px",
        marginBottom: "10px",
      }}
    >
      {task.activities.map((activity) => {
        return (
          <div key={activity.id}>
            {activity.name === "COMMENT" ? (
              <TaskComment
                comment={getComment(activity.relatedId, task.comments)}
              />
            ) : (
              <div
                style={{
                  borderLeft: "4px solid #16A596",
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <Grid align="flex-start" gutter={"xs"} mt="lg">
                  <Grid.Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    span={12}
                  >
                    <Text size="sm" inline>
                      {activity.name === "CREATE"
                        ? "Task Created"
                        : activity.description}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid align="flex-start" gutter={"xs"}>
                  <Grid.Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    span={12}
                  >
                    <Text size="sm" inline c="dimmed">
                      {`${activity.userName} - [ ${activity.userId} ] - ${dayjs(
                        activity.activityDate
                      ).format("YYYY-MM-DD HH:MM")}`}
                    </Text>
                  </Grid.Col>
                </Grid>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default TaskActvities;
