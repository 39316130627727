import * as React from "react";
import {
  Button,
  Grid,
  Title,
  Text,
  Divider,
  Badge,
  useMantineTheme,
  ActionIcon,
  lighten,
  Flex,
  Timeline,
  Modal,
  Stack,
  Pill,
  Tooltip,
} from "@mantine/core";
import { useSearchParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  IconX,
  IconCheck,
  IconTrash,
  IconPin,
  IconCirclePlus,
  IconEdit,
  IconSearch,
  IconPinned,
} from "@tabler/icons-react";
import { showNotification } from "@mantine/notifications";

import { styles } from "../../styles";
import {
  useReconsetBasic,
  useReconsetColumnsDefinition,
  useReconsetRun,
  useCurrentUserPinned,
} from "../../hooks";
import Loading from "../../ui/Loading";
import { getColor, getFirstReconId } from "../ReconsetUtils";
import QueryColumns from "./QueryColumns";
import {
  adhocRun,
  getQueryColumnsListOfValues,
  postPin,
  postUnpin,
} from "../../api";
import { UserContext } from "../../misc/UserContext";
import Stats from "./Stats";
import DataDisplay from "./DataDisplay";
import History from "./HistorySummaryStats";
import RunTasks from "./RunTasks";
import ReconsetRunTasks from "./ReconsetTasks";
import { showError } from "../../utilities";
import NewRun from "./NewRun";
import Actions from "./Actions";
import Findings from "./Findings";
import SummaryStats from "./SummaryStats";
import Remediations from "./Remediations";
import SOP from "./SOP";

// TODO: Custom Actions

function isPinned(
  entityId: string | null | undefined,
  id: string | null | undefined,
  userPinnedDetails: any
) {
  let pinned = (userPinnedDetails || []).some(
    (pinnedDetail: any) =>
      pinnedDetail.pin.entityId === entityId &&
      pinnedDetail.pin.type === "RECONSET" &&
      pinnedDetail.pin.pinId === id
  );
  return pinned;
}

export default function Reconset() {
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const { data: reconset } = useReconsetBasic(entityId, id);

  const { state: applicationState, dispatch: applicationStateDispatch } =
    React.useContext(UserContext);

  const setValue = (name: string, value: string | null | undefined) => {
    applicationStateDispatch({
      type: "SET-RECONSET-QUERY-COLUMNS-VALUE",
      value: { name: name, value: value },
    });
  };

  const [showNewRun, setShowNewRun] = React.useState(false);
  const [runId, setRunId] = React.useState("");
  const { data: run } = useReconsetRun(
    entityId,
    id,
    applicationState.reconset.queryColumnsValue
  );

  const { data: columnsDefinition } = useReconsetColumnsDefinition(
    entityId,
    id
  );

  // Pinned
  const { data: userPinnedDetails } = useCurrentUserPinned(entityId);

  React.useEffect(() => {
    const getRunId = async () => {
      let runIds = await getQueryColumnsListOfValues(
        entityId,
        id,
        "__runid",
        []
      );

      if (runIds && runIds.length > 0) {
        setValue("__runid", runIds[0].value);
        setRunId(runIds[0].value);
        applicationStateDispatch({
          type: "SET-RECONSET-ENTITY-ID",
          value: entityId,
        });

        applicationStateDispatch({
          type: "SET-RECONSET-ID",
          value: id,
        });
      } else {
        setValue("__runid", "");
        setRunId("");
        applicationStateDispatch({
          type: "SET-RECONSET-ENTITY-ID",
          value: entityId,
        });

        applicationStateDispatch({
          type: "SET-RECONSET-ID",
          value: id,
        });
      }
    };

    if (
      applicationState.reconset.entityId &&
      entityId !== applicationState.reconset.entityId
    ) {
      // If entity id changes, get the run id
      getRunId();
    } else if (
      applicationState.reconset.id &&
      id !== applicationState.reconset.id
    ) {
      // If reconset id changes, get the run id
      getRunId();
    } else if (
      !applicationState.reconset.entityId ||
      !applicationState.reconset.id
    ) {
      // If entity id or reconset id is not set, get the run id
      getRunId();
    }

    // This is to repickup when entity/reconset id changes
    /*     if (
      (applicationState.reconset.entityId &&
        entityId !== applicationState.reconset.entityId) ||
      (applicationState.reconset.id && id !== applicationState.reconset.id) ||
      !applicationState.reconset.entityId ||
      !applicationState.reconset.id
    ) {
      getRunId();
    } */

    getRunId();

    // getLOVData("_runid", []);
  }, [entityId, id]);

  if (reconset) {
    return (
      <div style={{ marginTop: theme.spacing.lg }}>
        <div id="header">
          <Grid
            align="flex-end"
            gutter={"xs"}
            mt="xs"
            mb="xs"
            // mb="xs"
            style={{
              display: "flex",
              // borderLeft: `${theme.spacing.xxs} solid ${theme.colors.emerald[7]}`,
            }}
            columns={12}
          >
            <Grid.Col span={3}>
              <Flex align={"end"} gap={theme.spacing.xs}>
                <Stack gap={0}>
                  <Title order={3} c="dark">
                    {reconset.name}
                  </Title>
                  <Text size="sm" c="dark">
                    {reconset.displayName}
                  </Text>
                </Stack>
                <Tooltip label="New">
                  <ActionIcon
                    variant="light"
                    size="lg"
                    ml="lg"
                    onClick={() => {
                      setShowNewRun(true);
                    }}
                  >
                    <IconCirclePlus
                      style={{ width: "70%", height: "70%" }}
                      stroke={2}
                    />
                  </ActionIcon>
                </Tooltip>

                {!isPinned(entityId, id, userPinnedDetails) && (
                  <Tooltip
                    label="Pin"
                    onClick={async () => {
                      try {
                        let payload = {
                          entityId: entityId,
                          type: "RECONSET",
                          pinId: id,
                        };
                        await postPin(payload);
                        // setError("Form", "Unable to Submit");
                        showNotification({
                          id: "success",
                          title: "Pin",
                          message: "Reconset pinned successfully",
                          color: "teal",
                          icon: <IconCheck />,
                        });
                      } catch (error: any) {
                        // console.log("post catch", error);
                        showError(
                          error,
                          "Error in pinning reconset",
                          "Unable to pin reconset",
                          <IconX />
                        );
                      }
                    }}
                  >
                    <ActionIcon variant="light" size="lg">
                      <IconPin
                        style={{ width: "70%", height: "70%" }}
                        stroke={2}
                      />
                    </ActionIcon>
                  </Tooltip>
                )}

                {isPinned(entityId, id, userPinnedDetails) && (
                  <Tooltip label="Unpin">
                    <ActionIcon
                      variant="light"
                      size="lg"
                      onClick={async () => {
                        try {
                          let payload = {
                            entityId: entityId,
                            type: "RECONSET",
                            pinId: id,
                          };
                          await postUnpin(payload);
                          // setError("Form", "Unable to Submit");
                          showNotification({
                            id: "success",
                            title: "Pin",
                            message: "Reconset unpinned successfully",
                            color: "teal",
                            icon: <IconCheck />,
                          });
                        } catch (error: any) {
                          // console.log("post catch", error);
                          showError(
                            error,
                            "Error in unpinning reconset",
                            "Unable to unpin reconset",
                            <IconX />
                          );
                        }
                      }}
                    >
                      <IconPinned
                        style={{ width: "70%", height: "70%" }}
                        stroke={2}
                      />
                    </ActionIcon>
                  </Tooltip>
                )}

                <Tooltip label="Edit">
                  <ActionIcon variant="light" size="lg">
                    <IconEdit
                      style={{ width: "70%", height: "70%" }}
                      stroke={2}
                      onClick={() => {
                        navigate(
                          `/app/reconsets/edit?id=${id}&entityId=${entityId}`
                        );
                      }}
                    />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            </Grid.Col>
          </Grid>
        </div>
        <Divider size="lg" color="dark.2" />

        <Flex mt="lg">
          <Title
            mt="xs"
            order={5}
            c="white"
            py={0}
            px={theme.spacing.xxs}
            style={{
              backgroundColor: theme.colors.emerald[0],
            }}
          >
            Query
          </Title>
        </Flex>

        <Grid align="flex-end" gutter={"xs"} mb="xs" columns={12}>
          <Grid.Col span={9}>
            <QueryColumns
              entityId={entityId}
              id={id}
              reconsetState={applicationState.reconset}
              queryColumns={reconset.definition.queryColumns}
              setValue={setValue}
              key={runId}
            />
          </Grid.Col>
        </Grid>

        {run?.details && (
          <Grid align="flex-end" gutter={"xs"} mb="xs" columns={12}>
            <Grid.Col span={12}>
              <Flex gap="xs" align={"center"}>
                <Text size="sm" inline c="dimmed">
                  {`Seq: ${run.details.runSeq}`}
                </Text>
                <Divider orientation="vertical" />
                <Text size="sm" inline c="dimmed">
                  {run.details.description}
                </Text>
                <Divider orientation="vertical" />
                <Text size="sm" inline c="dimmed">
                  {dayjs(run.details.startDate).format("YYYY-MM-DD HH:MM")}
                </Text>
                <Divider orientation="vertical" />
                <Badge
                  radius="xs"
                  color={getColor(run.details.status)}
                  variant="light"
                  style={{ marginRight: theme.spacing.xs }}
                >
                  {run.details.status}
                </Badge>
              </Flex>
            </Grid.Col>
          </Grid>
        )}

        <Divider />

        <Grid align="flex-start" gutter={"xs"} mb="xs" columns={12}>
          <Grid.Col span={3} mb="xs">
            <Stack mt="xs">
              <Flex>
                <Title
                  mt="xs"
                  order={5}
                  c="white"
                  py={0}
                  px={theme.spacing.xxs}
                  style={{
                    backgroundColor: theme.colors.emerald[0],
                  }}
                >
                  Actions
                </Title>
              </Flex>
              <Actions actions={run?.actions} />
            </Stack>
          </Grid.Col>

          <Grid.Col span={9} mb="xs">
            {run?.masterDataset && (
              <Grid align="flex-start" gutter={"xs"} columns={12}>
                <Grid.Col span={12} mb="xs">
                  <Stack mt="xs">
                    <Flex>
                      <Title
                        mt="xs"
                        order={5}
                        c="white"
                        py={0}
                        px={theme.spacing.xxs}
                        style={{
                          backgroundColor: theme.colors.emerald[0],
                        }}
                      >
                        Master Data
                      </Title>
                    </Flex>

                    <DataDisplay
                      data={run?.masterDataset || []}
                      links={[]}
                      table="master"
                      definition={
                        columnsDefinition && columnsDefinition.masterDataset
                          ? columnsDefinition.masterDataset
                          : []
                      }
                      height={100}
                    />
                  </Stack>
                </Grid.Col>
              </Grid>
            )}

            <Grid align="flex-start" gutter={"xs"} columns={12}>
              <Grid.Col span={12} mb="xs">
                <Stack mt="xs">
                  <Flex>
                    <Title
                      mt="xs"
                      order={5}
                      c="white"
                      py={0}
                      px={theme.spacing.xxs}
                      style={{
                        backgroundColor: theme.colors.emerald[0],
                      }}
                    >
                      Charts
                    </Title>
                  </Flex>
                  <History historyStats={run?.historySummaryStats} />
                </Stack>
              </Grid.Col>
            </Grid>

            <Grid align="flex-start" gutter={"xs"} columns={12}>
              <Grid.Col span={12} mb="xs">
                <Stack mt="xs">
                  <Flex>
                    <Title
                      mt="xs"
                      order={5}
                      c="white"
                      py={0}
                      px={theme.spacing.xxs}
                      style={{
                        backgroundColor: theme.colors.emerald[0],
                      }}
                    >
                      Stats
                    </Title>
                  </Flex>
                  <SummaryStats
                    summaryStats={
                      reconset.definition.preferences.run === "CONTINUOUS"
                        ? run?.prevSummaryStats
                        : run?.summaryStats
                    }
                  />
                </Stack>
              </Grid.Col>
            </Grid>

            <Grid align="flex-start" gutter={"xs"} columns={12}>
              <Grid.Col span={6} mb="xs">
                <Stack mt="xs">
                  <Flex>
                    <Title
                      mt="xs"
                      order={5}
                      c="white"
                      py={0}
                      px={theme.spacing.xxs}
                      style={{
                        backgroundColor: theme.colors.emerald[0],
                      }}
                    >
                      Findings
                    </Title>
                  </Flex>
                  <Findings findings={run?.findings} />
                </Stack>
              </Grid.Col>

              <Grid.Col span={6} mb="xs">
                <Stack mt="xs">
                  <Flex>
                    <Title
                      mt="xs"
                      order={5}
                      c="white"
                      py={0}
                      px={theme.spacing.xxs}
                      style={{
                        backgroundColor: theme.colors.emerald[0],
                      }}
                    >
                      Remediations
                    </Title>
                  </Flex>
                  <Remediations remediations={run?.remediations} />
                </Stack>
              </Grid.Col>
            </Grid>

            <Grid align="flex-start" gutter={"xs"} columns={12}>
              <Grid.Col span={12} mb="xs">
                <Stack mt="xs">
                  <Flex>
                    <Title
                      mt="xs"
                      order={5}
                      c="white"
                      py={0}
                      px={theme.spacing.xxs}
                      style={{
                        backgroundColor: theme.colors.emerald[0],
                      }}
                    >
                      System Stats
                    </Title>
                  </Flex>
                  <Stats
                    reconset={reconset}
                    runId={run?.details?.runId}
                    runSeq={run?.details?.runSeq}
                    stats={
                      reconset.definition.preferences.run === "CONTINUOUS"
                        ? run?.previousStats
                        : run?.stats
                    }
                  />
                </Stack>
              </Grid.Col>
            </Grid>
            <Divider />
            <Grid align="flex-start" gutter={"xs"} columns={12}>
              <Grid.Col span={12} mb="xs">
                <Stack mt="xs">
                  <Flex>
                    <Title
                      mt="xs"
                      order={5}
                      c="white"
                      py={0}
                      px={theme.spacing.xxs}
                      style={{
                        backgroundColor: theme.colors.emerald[0],
                      }}
                    >
                      SOP
                    </Title>
                  </Flex>
                  {/* <SOP sop={reconset.definition.sop} /> */}
                </Stack>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>

        <Modal
          centered
          size={"xl"}
          opened={showNewRun}
          onClose={() => setShowNewRun(false)}
          title={<Title order={4}>New Run</Title>}
        >
          <NewRun
            entityId={entityId}
            id={id}
            reconsetState={applicationState.reconset}
            setRunId={(runId: string) => {
              setValue("__runid", runId);
              setRunId(runId);
            }}
            inputs={reconset.definition.inputs}
            cancel={() => setShowNewRun(false)}
          />
        </Modal>
      </div>
    );
  } else {
    return (
      <div style={styles.viewMain}>
        <div style={styles.viewBody} id="body">
          <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
            <Grid.Col span={4}>
              <Loading />
            </Grid.Col>
          </Grid>
        </div>
      </div>
    );
  }
}
