import * as React from "react";
import { Control, useWatch } from "react-hook-form";
import { Stack, Title, useMantineTheme } from "@mantine/core";
import { IconPlus, IconFileUpload } from "@tabler/icons-react";

import FlowBuilder, { INode, IRegisterNode } from "react-flow-builder";

import { ReconsetCVMContext } from "./ReconsetCVM";

import { getId } from "../utilities";
import DataLoad from "./Action/DataLoad/DataLoad";
import ReconDef from "./Recon/ReconDef";
import {
  DrawerComponent,
  StartNodeDisplay,
  EndNodeDisplay,
  DataLoadDisplay,
  ReconDefDisplay,
  defaultNodes,
  AddMenu,
  PopConfirm,
} from "./Workflow/Components";

function formOrchestratorNodes(nodes: any, recons: any, disabled: boolean) {
  if (nodes && nodes.length > 0) {
    const newNodes = nodes.map((node: any) => {
      const newNode = {
        id: node.id,
        type: node.type,
        name: node.name,
        path: node.path,
        data: node.data,
        disabled: disabled,
      };

      if (node.type.toUpperCase() === "RECON") {
        const recon = recons.find((recon: any) => recon.id === node.id);
        if (recon) {
          newNode.name = recon.name;
        }
      }
      return newNode;
    });

    return newNodes;
  }

  return [
    {
      id: getId(),
      type: "START",
      name: "Start",
      path: ["0"],
    },
    {
      id: getId(),
      type: "END",
      name: "End",
      path: ["2"],
    },
  ];
}

function OrchestratorCVM({
  name,
  control,
  reconsArray,
}: {
  name: `definition.orchestrator`;
  control: Control<any>;
  reconsArray: any;
}) {
  const theme = useMantineTheme();
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);
  // console.log("OrchestratorCVM name", name);

  const orchestratorNodes = useWatch({
    control,
    name: "definition.orchestrator.workflow",
  });

  const recons = useWatch({
    control,
    name: "definition.recons",
  });

  const [nodes, setNodes] = React.useState<INode[]>(
    formOrchestratorNodes(
      orchestratorNodes,
      recons,
      reconsetFormState.actionOptions.disableAll
    )
  );

  React.useEffect(() => {
    setNodes(
      formOrchestratorNodes(
        orchestratorNodes,
        recons,
        reconsetFormState.actionOptions.disableAll
      )
    );
  }, [reconsetFormState.actionOptions.disableAll]);

  const handleChange = (nodes: INode[]) => {
    setNodes(nodes);

    const newRecons = (nodes || [])
      .filter((node) => node.type.toUpperCase() === "RECON")
      .filter(
        (node) =>
          (recons.map((recon: any) => recon.id) || []).indexOf(node.id) === -1
      )
      .map((node) => {
        return {
          id: node.id,
          name: node.data ? node.data.name : node.name,
        };
      });

    if (newRecons.length !== 0) {
      reconsArray.append(newRecons);
    }

    reconsetFormState.setValue(`${name}.workflow`, nodes);
  };

  const registerNodes: IRegisterNode[] = [
    {
      type: "START",
      name: "Start",
      displayComponent: StartNodeDisplay,
      isStart: true,
    },
    {
      type: "END",
      name: "End",
      displayComponent: EndNodeDisplay,
      isEnd: true,
    },
    {
      type: "RECON",
      name: "Recon",
      displayComponent: ReconDefDisplay,
      configComponent: ReconDef,
      addIcon: <IconPlus />,
      removeConfirmTitle: "Delete Reconset?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
    {
      type: "DATALOAD",
      name: "Dataload",
      displayComponent: DataLoadDisplay,
      configComponent: DataLoad,
      addIcon: <IconFileUpload />,
      removeConfirmTitle: "Delete Dataload?",
      customRemove: reconsetFormState.actionOptions.disableAll,
    },
  ];

  return (
    <Stack
      style={{
        marginTop: theme.spacing.xs,
        padding: theme.spacing.xs,
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <Title
          mb="xs"
          order={5}
          c="white"
          py={0}
          px={theme.spacing.xxs}
          style={{
            backgroundColor: theme.colors.emerald[0],
          }}
        >
          Workflow
        </Title>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <FlowBuilder
          className="flow"
          nodes={nodes}
          onChange={handleChange}
          registerNodes={registerNodes}
          DrawerComponent={DrawerComponent}
          PopoverComponent={({
            visible,
            onVisibleChange,
            children,
            content,
            ...restProps
          }) => {
            return (
              <AddMenu
                // {...props}
                visible={visible}
                onVisibleChange={onVisibleChange}
                children={children}
                content={content}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            );
          }}
          PopconfirmComponent={PopConfirm}
          backgroundColor="#FFF"
          zoomTool={{ hidden: true, initialValue: 90 }}
          createUuid={(type) => {
            return getId();
          }}
        />
      </div>
    </Stack>
  );
}

export default OrchestratorCVM;
