import * as React from "react";
import { Divider, Button, Grid } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import {
  CMultiSelect,
  CSelect,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";
import { useForm } from "react-hook-form";
import { useReconsetMatchesTemp } from "../../hooks";
import { addToMatchTemp } from "../../api";
import { showError } from "../../utilities";
import { getSystemStatus } from "../ReconsetUtils";

export default function AddToMatch({
  id,
  entityId,
  recon,
  runId,
  dataset,
  selection,
  systemStatusLOV,
  userStatusLOV,
  closeDialog,
}: {
  id: string;
  entityId: string;
  recon: string;
  runId: string;
  dataset: string;
  selection: string[];
  systemStatusLOV: { label: string; value: string }[];
  userStatusLOV: { label: string; value: string }[];
  closeDialog: () => void;
}) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{
    name: string;
    tempMatchId: string;
    matchId: string;
    systemStatus: string;
    userStatus: string[];
  }>({
    defaultValues: {
      name: "",
      matchId: "",
    },
    criteriaMode: "all",
    // resolver: yupResolver(ReconsetSchema),
  });
  const queryClient = useQueryClient();

  const { data: tempMatches } = useReconsetMatchesTemp(
    entityId,
    id,
    runId,
    recon
  );

  const onSubmit = async (data: any) => {
    try {
      const matchData = {
        __name: data.name,
        __matchId: data.matchId,
        __systemStatus: data.systemStatus,
        __userStatus: data.userStatus,
        __runId: runId,
        __recon: recon,
        __ids: selection,
        __dataset: dataset,
      };
      await addToMatchTemp(id, entityId, recon, runId, matchData);
      queryClient.invalidateQueries([
        "reconsetMatches",
        entityId,
        id,
        runId,
        recon,
      ]);
      closeDialog();
      showNotification({
        id: "success",
        title: "Match detals update",
        message: "Match details updated successfully",
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error: any) {
      showError(
        error,
        "Error in match",
        "Unable to add to match temp",
        <IconX />
      );
    }
  };

  return (
    <form noValidate>
      <Grid align="flex-start" gutter={"xs"} mb="lg">
        <Grid.Col span={12}>
          <CTextInput
            name="name"
            control={control}
            label="Temp Match Name"
            aria-label="Temp Match Name"
            required
            showErrorText={false}
          />
        </Grid.Col>
      </Grid>
      <Grid align="flex-start" gutter={"xs"} mb="lg">
        <Grid.Col span={6}>
          <CSelect
            name="systemStatus"
            control={control}
            label="System Status"
            aria-label="System Status"
            required
            clearable
            data={getSystemStatus("ALL")}
            showErrorText={false}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CMultiSelect
            name="userStatus"
            control={control}
            label="User Status"
            aria-label="User Status"
            data={userStatusLOV}
            showErrorText={false}
          />
        </Grid.Col>
      </Grid>

      <Divider
        my="xs"
        label="(OR) Existing Temp Match"
        labelPosition="center"
      />

      <Grid align="flex-start" gutter={"xs"} mb="lg">
        <Grid.Col span={12}>
          <CSelect
            name="matchId"
            control={control}
            label="Temp Match"
            aria-label="Temp Match"
            clearable
            data={(tempMatches || []).map((temp) => {
              return {
                label: `${temp.id} - ${temp.actionName}`,
                value: temp.id,
              };
            })}
            required
            showErrorText={false}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" justify="flex-start" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            fullWidth
            variant="filled"
            color="teal"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button variant="default" fullWidth onClick={closeDialog}>
            Cancel
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
