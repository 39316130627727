import * as React from "react";
import { Alert } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

function FormSubmitError({ error }: { error: string }) {
  if (error) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "5px",
        }}
        data-testid="error"
      >
        <Alert icon={<IconAlertCircle size={16} />} title={"Error"} color="red">
          {error}
        </Alert>
      </div>
    );
  }
  return <></>;
}

export default FormSubmitError;
