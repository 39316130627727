import * as React from "react";
import {
  TextInput,
  Button,
  Grid,
  Title,
  Card,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";
import { RelationshipType } from "@supportinitiative/common";

import { escapeRegExp } from "../utilities";
import { useRelationships } from "../hooks";
import Loading from "../ui/Loading";
import { styles } from "../styles";

type RelationshipDetailType = RelationshipType & {
  primaryDatasetName: string;
  secondaryDatasetName: string;
  primaryDatasetDisplayName: string;
  secondaryDatasetDisplayName: string;
};

function getIsMatch(filter: string) {
  const isMatch = (relationship: RelationshipDetailType) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return (
      re.test(relationship.primaryDatasetDisplayName) ||
      re.test(relationship.secondaryDatasetDisplayName) ||
      re.test(relationship.description)
    );
  };
  return isMatch;
}

export default function Relationships() {
  let [searchParams] = useSearchParams();
  let entityId = searchParams.get("entityId");

  let [filter, setFilter] = React.useState("");
  const { data: relationships, isLoading } = useRelationships(entityId);
  let [filteredRelationships, setFilteredRelationships] = React.useState<
    RelationshipDetailType[]
  >([]);
  const theme = useMantineTheme();

  React.useEffect(() => {
    if (relationships) {
      let filteredValues = relationships.filter(getIsMatch(filter));
      setFilteredRelationships(filteredValues);
    }
  }, [relationships, filter]);

  return (
    <div>
      <Title order={3}>Relationships</Title>
      <Grid align="center" gutter={"xs"}>
        <Grid.Col span={4}>
          <TextInput
            placeholder="Search"
            onChange={(event) => {
              setFilter(event.target.value);
              let filteredValues = relationships.filter(
                getIsMatch(event.target.value)
              );
              setFilteredRelationships(filteredValues);
            }}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Button
            color="teal"
            styles={() => ({
              root: {
                margin: "5px",
              },
            })}
            aria-label="New"
            fullWidth
            component={Link}
            to={`/app/relationships/new?entityId=${entityId}`}
          >
            New
          </Button>
        </Grid.Col>
      </Grid>

      {isLoading ? (
        <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
          <Grid.Col span={4}>
            <Loading />
          </Grid.Col>
        </Grid>
      ) : (
        <>
          {filteredRelationships.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              {filteredRelationships.map((relationship) => {
                return (
                  <Grid
                    align="center"
                    gutter={"xs"}
                    mb="xs"
                    key={relationship.id as unknown as string}
                  >
                    <Grid.Col span={4}>
                      <Card
                        component={Link}
                        to={`/app/relationships/edit?id=${relationship.id}&entityId=${entityId}`}
                        shadow="sm"
                        p="xs"
                        data-testid="relationship"
                      >
                        <Text
                          fw={500}
                        >{`${relationship.primaryDatasetDisplayName} / ${relationship.secondaryDatasetDisplayName}`}</Text>
                        <Text
                          size="sm"
                          style={{
                            color: theme.colors.gray[7],
                            lineHeight: 1.5,
                          }}
                        >
                          {relationship.description}
                        </Text>
                      </Card>
                    </Grid.Col>
                  </Grid>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <Text fw={500} style={styles.nodata} data-testid="nodata">
                No Relationships
              </Text>
            </div>
          )}
        </>
      )}
    </div>
  );
}
