import {
  Button,
  Flex,
  Grid,
  Modal,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";

import landingPageStyles from "./LandingPage.module.css";
import { getLocalStorage } from "../api";
import Logo from "./Logo";
import { isAuthorized } from "../utilities";

function Desktop({
  setLoginPage,
}: {
  setLoginPage: (loginPage: boolean) => void;
}) {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  return (
    <div className={landingPageStyles.desktop}>
      <section id="main" className={landingPageStyles.container}>
        <div className={landingPageStyles.backgroundimage}></div>
        <main className={landingPageStyles.hero}>
          <div className={landingPageStyles.main}>
            <Flex align={"center"} justify={"center"}>
              <Title
                order={1}
                style={{
                  fontSize: "3rem",
                  borderRight: "5px solid #0ca678",
                  backgroundColor: theme.colors.emerald[0],
                }}
                title="brand"
                c="white"
                px="lg"
                pt="xs"
                pb="xs"
              >
                Rekonist
              </Title>
              <Stack gap="0" p="xl">
                <Title order={2} c="#3A3D3F" ta="center">
                  Reduce{" "}
                  <span style={{ color: "rgb(233,119,119)" }}>
                    costs, complaints
                  </span>
                </Title>
                <Title order={2} c="#3A3D3F" ta="center">
                  Improve{" "}
                  <span style={{ color: "#0ca678" }}>
                    productivity, efficiencies
                  </span>
                </Title>
                <Title order={2} c="#3A3D3F" ta="center">
                  Mitigate{" "}
                  <span style={{ color: "rgb(233,119,119)" }}>risks</span>{" "}
                  arising out of data inconsistencies
                </Title>
                <Text ta="center" style={{ fontStyle: "italic" }} pb="lg">
                  Thoroughly reconcile, analyze, validate and audit the data
                  within and across systems
                </Text>
                <Flex align={"center"} justify={"center"}>
                  {isAuthorized() && (
                    <Button
                      color="teal"
                      style={{ width: "250px" }}
                      onClick={() => {
                        const entityId = getLocalStorage("currentEntity");
                        navigate(`/app/dashboard?entityId=${entityId}`);
                      }}
                    >
                      Dashboard
                    </Button>
                  )}
                  {!isAuthorized() && (
                    <Button
                      style={{ width: "250px" }}
                      color="teal"
                      onClick={() => setLoginPage(true)}
                    >
                      Login
                    </Button>
                  )}
                </Flex>
              </Stack>
            </Flex>
          </div>
        </main>
      </section>
    </div>
  );
}

export default Desktop;
