import { Grid, Text, Button, useMantineTheme } from "@mantine/core";
import dayjs from "dayjs";
import { downloadFile } from "../../api";

import { useReconsetMatchComments } from "../../hooks";
import AddComment from "./AddComment";

export default function Comments({
  id,
  entityId,
  recon,
  runId,
  matchId,
  type,
}: {
  id: string;
  entityId: string;
  recon: string;
  runId: string;
  matchId: string;
  type: "TEMP" | "MATCH";
}) {
  const theme = useMantineTheme();
  const { data: comments } = useReconsetMatchComments(
    entityId,
    id,
    runId,
    recon,
    matchId,
    type
  );

  return (
    <div>
      <AddComment
        id={id}
        entityId={entityId}
        recon={recon}
        runId={runId}
        matchId={matchId}
        type={type}
      />

      <div>
        {comments &&
          comments.length > 0 &&
          comments.map((comment) => {
            return (
              <div
                style={{
                  borderLeft: `${theme.spacing.xxs} solid ${theme.colors.dark[2]}`,
                  paddingLeft: "10px",
                  marginBottom: "10px",
                }}
                key={comment.id}
              >
                <Grid align="flex-start" gutter={"xs"} mt="lg">
                  <Grid.Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    span={6}
                  >
                    <Text size="sm" inline>
                      {comment.comment}
                    </Text>
                  </Grid.Col>
                </Grid>

                <Grid align="flex-start" gutter={"xs"}>
                  <Grid.Col
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    span={12}
                  >
                    <Text size="sm" inline color="dimmed">
                      {`${comment.userName} - [ ${comment.userId} ] - ${dayjs(
                        comment.commentDate
                      ).format("YYYY-MM-DD HH:MM")}`}
                    </Text>
                  </Grid.Col>
                </Grid>
                <>
                  {comment.attachments &&
                    comment.attachments.files &&
                    comment.attachments.files.length > 0 &&
                    comment.attachments.files.map((file: any) => {
                      return (
                        <Grid align="flex-start" gutter={"xs"} key={file.name}>
                          <Grid.Col
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                            span={2}
                          >
                            <Button
                              variant="default"
                              onClick={async () => {
                                await downloadFile(
                                  id,
                                  entityId,
                                  file.name,
                                  file.type
                                );
                              }}
                            >
                              {file.name}
                            </Button>
                          </Grid.Col>
                        </Grid>
                      );
                    })}
                </>
              </div>
            );
          })}
      </div>
    </div>
  );
}
