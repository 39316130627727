import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

import { Title, Paper, PasswordInput, Button } from "@mantine/core";
import {
  IconSquareCheck,
  IconChecklist,
  IconX,
  IconCheck,
} from "@tabler/icons-react";
import { showError } from "../utilities";
import { postPasswordReset } from "../api";

export default function PasswordReset() {
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();

  let resetKey = searchParams.get("resetKey");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper shadow="xs" pb="xl" pt="xl" p="md" style={{ minWidth: "500px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconChecklist
            size={40}
            strokeWidth={1.75}
            color={"teal"}
            style={{ marginRight: "10px" }}
          />
          <Title
            order={2}
            ta="center"
            style={{
              // marginLeft: "80px",
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            Rekonist
          </Title>
        </div>

        <Title
          mb="xs"
          mt="xl"
          order={4}
          ta="center"
          c="gray.7"
          style={{
            // marginLeft: "80px",
            marginTop: "20px",
          }}
        >
          Password Reset
        </Title>

        <PasswordInput
          placeholder="New Password"
          style={{ marginBottom: "10px" }}
          value={password}
          onChange={(event) => setPassword(event.currentTarget.value)}
        />
        <Button
          color="teal"
          fullWidth
          onClick={async () => {
            try {
              if (password && resetKey) {
                await postPasswordReset(password, resetKey);

                showNotification({
                  id: "success",
                  title: "Password reset",
                  message: "Pasword reset successfully",
                  color: "teal",
                  icon: <IconCheck />,
                });
                navigate("/");
              }
              // navigate("/app/dashboard");
            } catch (error: any) {
              showError(
                error,
                "Unable to reset password",
                "Unable to reset password",
                <IconX />
              );
            }
          }}
          disabled={!password || !resetKey}
        >
          Reset
        </Button>
      </Paper>
    </div>
  );
}
