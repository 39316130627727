import * as React from "react";
import { BuilderContext, useDrawer } from "react-flow-builder";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Text,
  Grid,
  Button,
  Modal,
  ActionIcon,
  Title,
  Divider,
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import {
  CTextInput,
  CSelect,
  CTextarea,
  CMultiSelect,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import Where from "../QueryMatch/Where";

import { yupResolver } from "@hookform/resolvers/yup";
import { useActiveRoles } from "../../../hooks";
import { getId } from "../../../utilities";
import { CreateTaskActionSchema } from "@supportinitiative/common";

function CreateTask() {
  const { selectedNode: node } = React.useContext(BuilderContext);
  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: node?.data || { name: "" },
    criteriaMode: "all",
    resolver: yupResolver(CreateTaskActionSchema),
  });

  const dataPopulation = useFieldArray({
    control,
    name: "dataPopulation",
  });

  const { data: activeRoles } = useActiveRoles(reconsetFormState.entityId);

  return (
    <Modal
      centered
      size={"xl"}
      opened={true}
      onClose={cancel}
      title={<Title order={4}>Create Task</Title>}
    >
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name={`name`}
            control={control}
            showErrorText={false}
            label="Name"
            required
            disabled={node?.disabled || false}
          />
        </Grid.Col>
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Data Population" labelPosition="center" />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
            width: "90%",
          }}
        >
          <Text fw={500} style={{ fontSize: "14px" }}>
            Columns
          </Text>
          <ActionIcon
            onClick={() => {
              dataPopulation.append({
                column: "",
                genFunction: "",
                id: getId(),
              });
            }}
            disabled={reconsetFormState.actionOptions.disableAll}
          >
            <IconPlus />
          </ActionIcon>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            marginLeft: "20px",
            width: "90%",
          }}
        >
          {dataPopulation.fields.map((field, index) => {
            return (
              <Grid align="stretch" gutter={"xs"} mb="xs" key={field.id}>
                <Grid.Col span={3}>
                  <CTextInput
                    name={`dataPopulation.${index}.column`}
                    control={control}
                    label={"Column"}
                    disabled={reconsetFormState.actionOptions.disableAll}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={6}>
                  <CTextarea
                    name={`dataPopulation.${index}.genFunction`}
                    control={control}
                    label={"Generate Function"}
                    minRows={4}
                    disabled={reconsetFormState.actionOptions.disableAll}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={2}>
                  <CTextInput
                    name={`dataPopulation.${index}.id`}
                    control={control}
                    label={"Id"}
                    disabled={true}
                  />
                </Grid.Col>
                <Grid.Col
                  span={1}
                  style={{ display: "flex", alignItems: "flex-end" }}
                >
                  <ActionIcon
                    onClick={() => {
                      dataPopulation.remove(index);
                    }}
                    disabled={reconsetFormState.actionOptions.disableAll}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Grid.Col>
              </Grid>
            );
          })}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Conditions" labelPosition="center" />
        <div
          style={{
            marginLeft: "20px",
            width: "90%",
          }}
        >
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <Where name={`left.where`} control={control} />
            </Grid.Col>
          </Grid>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Details" labelPosition="center" />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "20px",
            marginLeft: "20px",
            width: "90%",
          }}
        >
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CTextInput
                name={`header`}
                control={control}
                label="Header"
                required
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CTextInput
                name={`template`}
                control={control}
                label="Template"
                required
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={12}>
              <CTextarea
                name={`body`}
                minRows={4}
                control={control}
                label="Header"
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={6}>
              <CMultiSelect
                name={`assignTo`}
                control={control}
                label="Assign To"
                data={
                  activeRoles
                    ? activeRoles.map((role) => {
                        return {
                          label: `${role.name} - ${role.description}`,
                          value: role.name,
                        };
                      })
                    : []
                }
                showErrorText={false}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CSelect
                name={`priority`}
                control={control}
                label="Priority"
                required
                data={[
                  { label: "Low", value: "LOW" },
                  { label: "Medium", value: "MEDIUM" },
                  { label: "High", value: "HIGH" },
                ]}
                showErrorText={false}
                disabled={reconsetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
        </div>
      </div>

      <Grid align="stretch" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            color="teal"
            variant="filled"
            fullWidth
            type="submit"
            onClick={
              node?.disabled
                ? () => {
                    cancel();
                  }
                : async (event) => {
                    event.preventDefault();
                    const values = getValues();
                    // console.log("values", values);
                    clearErrors();
                    try {
                      await CreateTaskActionSchema.validate(values, {
                        abortEarly: false,
                      });

                      save?.(values);
                      //cancel();
                    } catch (error) {
                      console.log("errors", error);
                      console.log("error inner", JSON.stringify(error));
                      (error as any).inner.forEach((err: any) => {
                        setError(err.path, {
                          type: "custom",
                          message: err.message,
                        });
                      });
                    }
                  }
            }
          >
            Ok
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default CreateTask;
