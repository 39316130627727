import {
  ActionIcon,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import Loading from "../ui/Loading";
import SummaryStatsView from "../Reconset/View/SummaryStats";
import { useReconsetBasic } from "../hooks";
import { getColor } from "../Reconset/ReconsetUtils";
import SummaryStats from "../Reconset/View/SummaryStats";
import { IconEye } from "@tabler/icons-react";

function getColorStyles(color: string | null | undefined) {
  switch ((color || "").toUpperCase()) {
    case "TEAL":
      return { backgroundColor: "rgba(166, 227, 233, 0.5)" };
    case "PINK":
      return { backgroundColor: "rgba(240, 168, 208, .5)" };
    case "RED":
      return { backgroundColor: "rgba(255, 212, 212, 0.5)" };
    case "BLUE":
      return { backgroundColor: "rgba(34, 139, 230, 0.35)" };
    default:
      return { backgroundColor: "rgba(255, 255, 255, 0.5)" };
  }
}

function getColorByType(type: string | null | undefined) {
  switch ((type || "").toUpperCase()) {
    case "WARNING":
      return { backgroundColor: "rgba(250, 82, 82, 0.5)" };
    case "ERROR":
      return { backgroundColor: "rgba(255, 212, 212, 0.5)" };
    case "INFO":
      return { backgroundColor: "rgba(34, 139, 230, 0.2)" };
    default:
      return { backgroundColor: "rgba(255, 255, 255, 0.5)" };
  }
}

function ReconsetPinned({ details, index }: { details: any; index: number }) {
  const { data: reconset } = useReconsetBasic(
    details.pin.entityId,
    details.pin.pinId
  );

  const theme = useMantineTheme(),
    navigate = useNavigate();

  if (reconset) {
    return (
      <Stack gap="xs" mb="lg">
        <Divider
          size="sm"
          labelPosition="left"
          label={
            <Stack gap="0">
              <Link
                to={`/app/reconsets/view?id=${reconset.id}&entityId=${reconset.entityId}`}
                //style={{ textDecoration: "none" }}
              >
                <Text fw={"bold"} fs="italic" c="dark.4">
                  {reconset.name}
                </Text>
              </Link>
              <Text c="dark.4" size="xs">
                {reconset.description}
              </Text>
            </Stack>
          }
        />

        <Flex gap="xs" align={"center"} mb="xs">
          <Text size="sm" inline c="dimmed">
            {`Seq: ${details?.runDetails?.details?.seq}`}
          </Text>
          <Divider orientation="vertical" />

          <Text size="sm" inline c="dimmed">
            {dayjs(details?.runDetails?.details?.startDate).format(
              "YYYY-MM-DD HH:MM"
            )}
          </Text>
          <Divider orientation="vertical" />
          <Badge
            radius="xs"
            color={getColor(details?.runDetails?.details?.status)}
            variant="light"
            style={{ marginRight: "5px" }}
          >
            {details?.runDetails?.details?.status}
          </Badge>
        </Flex>

        {details?.runDetails?.summaryStats &&
        details?.runDetails?.summaryStats.length > 0 ? (
          <Grid align="stretch" gutter={"xs"}>
            {details?.runDetails?.summaryStats.map(
              (summaryStat: any, index: number) => {
                return (
                  <Grid.Col span={4} pb="0" pt="0" mb="lg" key={index}>
                    <Paper
                      p="xs"
                      style={{ ...getColorStyles(summaryStat.color) }}
                    >
                      <Group justify="apart">
                        <div>
                          <Title order={5} c="gray.9">
                            {summaryStat.header}
                          </Title>

                          <Text fw="bold" size="xl" color="gray.9">
                            {summaryStat.value}
                          </Text>
                          <Text
                            color="gray.7"
                            size="xs"
                            style={{ fontStyle: "italic" }}
                          >
                            {summaryStat.type} / {summaryStat.additionalContext}
                          </Text>
                        </div>
                      </Group>
                    </Paper>
                  </Grid.Col>
                );
              }
            )}
          </Grid>
        ) : (
          <Grid align="stretch" gutter={"xs"}>
            <Grid.Col span={4} pb="0" pt="0" mb="lg" key={index}>
              <Divider label="No Summary Stats" labelPosition="left" />
            </Grid.Col>
          </Grid>
        )}
      </Stack>
    );
  } else {
    return (
      <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
        <Grid.Col span={4}>
          <Loading />
        </Grid.Col>
      </Grid>
    );
  }
}

export default ReconsetPinned;
