import * as React from "react";
import { Control, useFieldArray, useWatch } from "react-hook-form";
import {
  Text,
  Grid,
  Title,
  Button,
  ActionIcon,
  Modal,
  Group,
  Divider,
  useMantineTheme,
  Stack,
  Flex,
} from "@mantine/core";

import {
  CCheckbox,
  CMultiSelect,
  CMultiSelectCreatable,
  CRadioGroup,
  CSelect,
  CTextarea,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "./ReconsetCVM";

import MasterDatasetCVM from "./Dataset/MasterDatasetCVM";
import { columnTypes } from "./ReconsetUtils";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { getId } from "../utilities";
import { useLoaders } from "src/hooks";

function PreferencesCVM({
  name,
  control,
  userStatus,
  setUserStatus,
}: {
  name: `definition`;
  control: Control<any>;
  userStatus: {
    label: string;
    value: string;
  }[];
  setUserStatus: any;
}) {
  const { state: reconsetFormState, dispatch: reconsetFormDispatch } =
    React.useContext(ReconsetCVMContext);

  const theme = useMantineTheme();

  const inputsArray = useFieldArray({
    control,
    name: `${name}.inputs`,
    keyName: "key",
  });

  const { data: loaders } = useLoaders(reconsetFormState.entityId);

  return (
    <Stack
      style={{
        marginTop: theme.spacing.xs,
        padding: theme.spacing.xs,
        width: "100%",
      }}
    >
      <Stack>
        <Flex>
          <Title
            mb="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            Master Dataset
          </Title>
        </Flex>
        <Grid gutter={"xs"} mb="xs">
          <Grid.Col span={2}>
            <MasterDatasetCVM
              control={control}
              name="definition.masterDataset"
            />
          </Grid.Col>
        </Grid>
      </Stack>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Flex>
          <Title
            mb="xs"
            mt="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            User Status
          </Title>
        </Flex>

        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="xs"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={4}>
            <CMultiSelectCreatable
              name={`definition.userStatus`}
              placeholder="User Status"
              control={control}
              data={userStatus}
              disabled={reconsetFormState.actionOptions.disableAll}
              onCreate={(values) => {
                // const newUserStatus = [...userStatus, ...values];
                // union userStatus and values
                const newStatus = [...values, ...userStatus].map(
                  (value: any) => value.value
                );
                const uniqueStatus = [...new Set(newStatus)];

                setUserStatus(
                  uniqueStatus.map((value: any) => {
                    return { label: value, value: value };
                  })
                );
                reconsetFormDispatch({
                  type: "SET-USER-STATUS",
                  value: uniqueStatus.map((value: any) => {
                    return { label: value, value: value };
                  }),
                });
              }}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Title
            mb="xs"
            mt="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            Loader
          </Title>
        </div>

        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={4}>
            <CSelect
              name={`definition.loaderId`}
              placeholder="Loader"
              control={control}
              data={
                loaders && loaders.length > 0
                  ? loaders.map((loader: any) => {
                      return { label: loader.name, value: loader.id };
                    })
                  : []
              }
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Group mb="xs" align="center" style={{ alignItems: "center" }}>
          <Flex>
            <Title
              m={0}
              order={5}
              style={{
                padding: "0px 5px",
                backgroundColor: "#16A596",
                color: "#fff",
              }}
            >
              Inputs
            </Title>
          </Flex>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              inputsArray.append({
                name: "",
                type: "TEXT",
                label: "",
                notNull: "NO",
                id: getId(),
              });
            }}
            style={{
              display:
                reconsetFormState.currentAction.toUpperCase() === "VIEW"
                  ? "none"
                  : "",
            }}
            disabled={reconsetFormState.actionOptions.disableAll}
          >
            <IconPlus />
          </ActionIcon>
        </Group>

        {inputsArray.fields.length === 0 ? (
          <Grid align="stretch" gutter={"xs"} mb="xs">
            <Grid.Col span={8}>
              <Divider label="No Inputs" labelPosition="left" />
            </Grid.Col>
          </Grid>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid align="stretch" gutter={"xs"}>
              <Grid.Col span={12}>
                {inputsArray.fields.map((field, index) => {
                  return (
                    <Grid align="stretch" gutter={"xs"} mb="xs" key={field.key}>
                      <Grid.Col span={2}>
                        <CTextInput
                          name={`${name}.inputs.${index}.name`}
                          control={control}
                          showErrorText={false}
                          label={index === 0 ? "Name" : ""}
                          required
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <CSelect
                          name={`${name}.inputs.${index}.type`}
                          control={control}
                          label={index === 0 ? "Type" : ""}
                          data={columnTypes}
                          showErrorText={false}
                          required
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col span={2}>
                        <CTextInput
                          name={`${name}.inputs.${index}.label`}
                          control={control}
                          showErrorText={false}
                          required
                          label={index === 0 ? "Label" : ""}
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>

                      <Grid.Col
                        span={2}
                        style={{ display: "flex", alignItems: "flex-end" }}
                      >
                        <CCheckbox
                          name={`${name}.inputs.${index}.notNull`}
                          control={control}
                          label="Not Null"
                          trueValue="YES"
                          falseValue="NO"
                          disabled={reconsetFormState.actionOptions.disableAll}
                        />
                      </Grid.Col>
                      <Grid.Col
                        span={1}
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <ActionIcon
                          size="sm"
                          variant="white"
                          color="gray"
                          onClick={() => {
                            inputsArray.remove(index);
                          }}
                          disabled={reconsetFormState.actionOptions.disableAll}
                          style={{
                            display: reconsetFormState.actionOptions.disableAll
                              ? "none"
                              : "",
                          }}
                        >
                          <IconTrash />
                        </ActionIcon>
                      </Grid.Col>
                    </Grid>
                  );
                })}
              </Grid.Col>
            </Grid>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Title
            mb="xs"
            mt="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            Run
          </Title>
        </div>

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={2}>
            <CRadioGroup
              name={`${name}.preferences.run`}
              control={control}
              data={[
                { label: "Allow multiple active run", value: "MULTIPLE" },
                { label: "Allow continuous run", value: "CONTINUOUS" },
                { label: "Allow only one run per master", value: "ONCE" },
              ]}
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>

          <Grid.Col span={2}>
            <CCheckbox
              name={`${name}.preferences.allowMultipleMatch`}
              control={control}
              label="Allow Multiple Match"
              trueValue="YES"
              falseValue="NO"
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <Title
            mb="xs"
            mt="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            SOP
          </Title>
        </div>

        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={8}>
            <CTextarea
              name={`definition.sop`}
              control={control}
              autosize
              minRows={10}
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
      </div>
    </Stack>
  );
}

export default PreferencesCVM;
