import { Divider, Grid } from "@mantine/core";
import { LineChart } from "@mantine/charts";

export function replaceItem(items: any[], itemToReplace: any, index: number) {
  const prevItems = items.slice(0, index);
  const nextItems = items.slice(index + 1);
  const newItems = [...prevItems, itemToReplace, ...nextItems];
  return newItems;
}

function getCharts(
  historyStats: {
    runId: string;
    runSeq: string;
    type: string;
    header: string;
    value: string;
    valueType?: string;
    additionalContext?: string;
    increaseOrDecrease?: string;
    color?: string;
    chartGroup?: string;
  }[]
) {
  const charts: string[] = [];

  historyStats.forEach((historyStat) => {
    if (
      historyStat.chartGroup &&
      historyStat.chartGroup !== "" &&
      historyStat.valueType === "INTEGER"
    ) {
      if (charts.indexOf(historyStat.chartGroup) === -1) {
        charts.push(historyStat.chartGroup);
      }
    }
  });

  return charts;
}

function getChartData(
  chart: string,
  series: { name: string; color: string }[] = [],
  historyStats: {
    runId: string;
    runSeq: string;
    type: string;
    header: string;
    value: string;
    valueType?: string;
    additionalContext?: string;
    increaseOrDecrease?: string;
    color?: string;
    chartGroup?: string;
  }[]
) {
  const chartGroupData = historyStats.filter((historyStat) => {
    return historyStat.chartGroup === chart;
  });

  let chartData: any[] = [];
  // group chart data by runSeq
  chartGroupData.forEach((groupData) => {
    const dataIndex = chartData.findIndex((d) => d.runSeq === groupData.runSeq);
    if (dataIndex !== -1) {
      const data = chartData.find((d) => d.runSeq === groupData.runSeq);
      data[groupData.header] = Number(groupData.value);
      chartData = replaceItem(chartData, data, dataIndex);
    } else {
      const newData: Record<string, any> = {
        runSeq: groupData.runSeq,
      };
      series.forEach((s) => {
        newData[s.name] = 0;
      });

      newData[groupData.header] = Number(groupData.value);
      chartData.push(newData);
    }
  });
  return chartData;
}

function getColor(color: string | null | undefined, length: number) {
  if (color) {
    if (color.toUpperCase() === "RED") {
      return `red.${Math.min(length + 3, 9)}`;
    } else if (color.toUpperCase() === "PINK") {
      return `red.${Math.min(length + 3, 9)}`;
    } else if (color.toUpperCase() === "TEAL") {
      return `teal.${Math.min(length + 3, 9)}`;
    } else if (color.toUpperCase() === "BLUE") {
      return `blue.${Math.min(length + 3, 9)}`;
    }
  }

  return "teal.6";
}

function getChartSeries(
  chart: string,
  historyStats: {
    runId: string;
    runSeq: string;
    type: string;
    header: string;
    value: string;
    valueType?: string;
    additionalContext?: string;
    increaseOrDecrease?: string;
    color?: string;
    chartGroup?: string;
  }[]
) {
  const chartGroupData = historyStats.filter((historyStat) => {
    return historyStat.chartGroup === chart;
  });

  const series: { name: string; color: string }[] = [];

  chartGroupData.forEach((groupData) => {
    const seriesIndex = series.findIndex((s) => s.name === groupData.header);
    if (seriesIndex === -1) {
      series.push({
        name: groupData.header,
        color: getColor(groupData.color, series.length),
      });
    }
  });

  return series;
}

function History({
  historyStats,
}: {
  historyStats: {
    runId: string;
    runSeq: string;
    type: string;
    header: string;
    value: string;
    valueType?: string;
    additionalContext?: string;
    increaseOrDecrease?: string;
    color?: string;
    chartGroup?: string;
  }[];
}) {
  if (historyStats && historyStats.length > 0) {
    const charts = getCharts(historyStats);
    if (charts && charts.length > 0) {
      return (
        <Grid align="flex-start" gutter={"xs"} columns={12}>
          {charts.map((chart) => {
            getChartData(
              chart,
              getChartSeries(chart, historyStats),
              historyStats
            );
            return (
              <Grid.Col span={6} key={chart}>
                <LineChart
                  h={300}
                  data={getChartData(
                    chart,
                    getChartSeries(chart, historyStats),
                    historyStats
                  )}
                  dataKey="runSeq"
                  series={getChartSeries(chart, historyStats)}
                  curveType="linear"
                  withXAxis={false}
                  withDots={false}
                />
              </Grid.Col>
            );
          })}
        </Grid>
      );
    } else {
      return <Divider label="No Charts" labelPosition="left" />;
    }
  } else {
    return <Divider label="No Charts" labelPosition="left" />;
  }
}

export default History;
