import * as React from "react";
import { Grid, Button, Modal, Title, Divider } from "@mantine/core";
import { BuilderContext, useDrawer } from "react-flow-builder";
import { useForm, useFieldArray, useWatch } from "react-hook-form";

import {
  CMultiSelect,
  CRadioGroup,
  CSelect,
  CTextarea,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import { getSystemStatus, getConfirmationStatus } from "../../ReconsetUtils";
import Where from "../QueryMatch/Where";
import { object, string, number, date, array, setLocale } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  StatusUpdateActionType,
  StatusUpdateActionSchema,
} from "@supportinitiative/common";

function StatusUpdate() {
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);

  const { selectedNode: node } = React.useContext(BuilderContext);
  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<StatusUpdateActionType>({
    defaultValues: node?.data || {
      name: "",
      dataset: "PRIMARY",
      leftCondition: "",
      systemStatus: "",
      confirmationStatus: "",
      userStatus: [],
      statusOverride: "IGNORE",
    },
    criteriaMode: "all",
    resolver: yupResolver(StatusUpdateActionSchema),
  });

  return (
    <Modal
      centered
      size={"xl"}
      opened={true}
      onClose={cancel}
      title={<Title order={3}>Status Update</Title>}
    >
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name={`name`}
            control={control}
            showErrorText={false}
            label="Name"
            required
            disabled={node?.disabled || false}
          />
        </Grid.Col>
      </Grid>

      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CSelect
            name={`dataset`}
            control={control}
            data={[
              {
                label: "Primary",
                value: "PRIMARY",
              },
              {
                label: "Secondary",
                value: "SECONDARY",
              },
            ]}
            showErrorText={false}
            label="Dataset"
            searchable
            clearable
            required
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Query" labelPosition="center" />
        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <Where name={`left.where`} control={control} />
          </Grid.Col>
        </Grid>

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <CTextarea
              autosize
              minRows={2}
              name={`leftCondition`}
              control={control}
              label={"Condition"}
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Status" labelPosition="center" />

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={4}>
            <CSelect
              name={`systemStatus`}
              control={control}
              label="Status"
              searchable
              data={getSystemStatus("ALL")}
              showErrorText={false}
              required
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CSelect
              name={`confirmationStatus`}
              control={control}
              label="Confirmation Status"
              searchable
              data={getConfirmationStatus()}
              showErrorText={false}
              required
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CMultiSelect
              name={`userStatus`}
              control={control}
              label="User Status"
              searchable
              data={reconsetFormState.userStatus || []}
              showErrorText={false}
              disabled={reconsetFormState.actionOptions.disableAll}
            />
          </Grid.Col>
        </Grid>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Status Override" labelPosition="center" />

        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <CRadioGroup
              name={`statusOverride`}
              control={control}
              data={[
                { label: "Ignore", value: "IGNORE" },
                { label: "Append", value: "APPEND" },
                { label: "Override", value: "OVERRIDE" },
              ]}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
      </div>

      <Grid align="stretch" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            color="teal"
            variant="filled"
            fullWidth
            type="submit"
            onClick={
              node?.disabled
                ? () => {
                    cancel();
                  }
                : async (event) => {
                    event.preventDefault();
                    const values = getValues();
                    clearErrors();
                    try {
                      await StatusUpdateActionSchema.validate(values, {
                        abortEarly: false,
                      });

                      save?.(values);
                      //cancel();
                    } catch (error) {
                      console.log("Save error =>", error);
                      console.log("Save error =>", JSON.stringify(error));
                      (error as any).inner.forEach((err: any) => {
                        setError(err.path, {
                          type: "custom",
                          message: err.message,
                        });
                      });
                    }
                  }
            }
          >
            Ok
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default StatusUpdate;
