import {
  getDatasets,
  getDataset,
  getReconsets,
  getReconset,
  getFunctions,
  getRelationships,
  getRelationship,
  getRelationshipByPrimaryAndSecondaryDataset,
  getColumnsDefinition,
  getReconsetBasic,
  getReconsetMatchDetails,
  getReconsetMatches,
  getReconsetMatchComments,
  getLoaders,
  getLoader,
  getLoaderBasic,
  getLoaderStatus,
  getFunction,
  getRoles,
  getRole,
  getUsers,
  getUser,
  getActiveRoles,
  getQueryColumnsListOfValues,
  getRunDetails,
  getTask,
  getTasks,
  getReconsetTasks,
  getReconsetRunTasks,
  getUsersBasic,
  getCurrentUserPendingTasks,
  getCurrentUserRolePendingTasks,
  getCurrentUserPinned,
  getCurrentUserPendingCollabTasks,
  getReconsetMatchesTemp,
  getReconsetMatchTempDetails,
} from "./api";
import { useQuery } from "@tanstack/react-query";

export function useDatasets(entityId: string | null | undefined) {
  return useQuery(["datasets", entityId], () => getDatasets(entityId));
}

export function useDataset(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["dataset", entityId, id], () => getDataset(entityId, id));
}

export function useReconsets(entityId: string | null | undefined) {
  return useQuery(["reconsets", entityId], () => getReconsets(entityId));
}

export function useReconset(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery({
    queryKey: ["reconset", entityId, id],
    queryFn: () => getReconset(entityId, id),
    enabled: !!(entityId && id),
    retry: 0,
  });
}

export function useReconsetBasic(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(
    ["reconsetBasic", entityId, id],
    () => getReconsetBasic(entityId, id),
    {
      refetchInterval: 5000,
    }
  );
}

export function useReconsetQueryColumnsListOfValues(
  entityId: string | null | undefined,
  id: string | null | undefined,
  __name: string,
  params: any
) {
  return useQuery(
    ["reconsetQueryColumnsListOfValues", entityId, id, __name, params],
    () => getQueryColumnsListOfValues(entityId, id, __name, params)
  );
}

export function useReconsetRun(
  entityId: string | null | undefined,
  id: string | null | undefined,
  params: any
) {
  return useQuery(
    ["reconsetRun", entityId, id, params],
    () => getRunDetails(entityId, id, params),
    {
      refetchInterval: 10000,
    }
  );
}

export function useReconsetColumnsDefinition(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["reconsetColumnsDefinition", entityId, id], () =>
    getColumnsDefinition(entityId, id)
  );
}

export function useReconsetMatchDetails(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  matchId: string | null | undefined
) {
  return useQuery(
    ["reconsetMatchDetails", entityId, id, runId, recon, matchId],
    () => getReconsetMatchDetails(entityId, id, runId, recon, matchId)
  );
}

export function useReconsetMatchTempDetails(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  matchId: string | null | undefined
) {
  return useQuery(
    ["reconsetMatchTempDetails", entityId, id, runId, recon, matchId],
    () => getReconsetMatchTempDetails(entityId, id, runId, recon, matchId)
  );
}

export function useReconsetMatchComments(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  matchId: string | null | undefined,
  type: "TEMP" | "MATCH"
) {
  return useQuery(
    ["reconsetMatchComments", entityId, id, runId, recon, matchId, type],
    () => getReconsetMatchComments(entityId, id, runId, recon, matchId, type)
  );
}

export function useReconsetMatches(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined
) {
  return useQuery(["reconsetMatches", entityId, id, runId, recon], () =>
    getReconsetMatches(entityId, id, runId, recon)
  );
}

export function useReconsetMatchesTemp(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined
) {
  return useQuery(["reconsetMatchesTemp", entityId, id, runId, recon], () =>
    getReconsetMatchesTemp(entityId, id, runId, recon)
  );
}

export function useFunctions(entityId: string | null | undefined) {
  return useQuery(["functions", entityId], () => getFunctions(entityId));
}

export function useRelationships(entityId: string | null | undefined) {
  return useQuery(["relationships", entityId], () =>
    getRelationships(entityId)
  );
}

export function useRelationship(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["relationship", entityId, id], () =>
    getRelationship(entityId, id)
  );
}

export function useRelationshipByPrimaryAndSecondaryDataset(
  entityId: string | null | undefined,
  primaryDataset: string | null | undefined,
  secondaryDataset: string | null | undefined
) {
  return useQuery(
    [
      "relationshipByPrimaryAndSecondaryDataset",
      entityId,
      primaryDataset,
      secondaryDataset,
    ],
    () =>
      getRelationshipByPrimaryAndSecondaryDataset(
        entityId,
        primaryDataset,
        secondaryDataset
      )
  );
}

export function useLoaders(entityId: string | null | undefined) {
  return useQuery(["loaders", entityId], () => getLoaders(entityId));
}

export function useLoader(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["loader", entityId, id], () => getLoader(entityId, id));
}

export function useLoaderBasic(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["loaderBasic", entityId, id], () =>
    getLoaderBasic(entityId, id)
  );
}

export function useLoaderStatus(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["loaderStatus", entityId, id], () =>
    getLoaderStatus(entityId, id)
  );
}

export function useFunction(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["function", entityId, id], () => getFunction(entityId, id));
}

export function useRoles(entityId: string | null | undefined) {
  return useQuery(["roles", entityId], () => getRoles(entityId));
}

export function useActiveRoles(entityId: string | null | undefined) {
  return useQuery(["activeRoles", entityId], () => getActiveRoles(entityId));
}

export function useRole(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["role", entityId, id], () => getRole(entityId, id));
}

export function useCurrentUserRolePendingTasks(
  entityId: string | null | undefined
) {
  return useQuery(["currentUserRolePendingTasks", entityId], () =>
    getCurrentUserRolePendingTasks(entityId)
  );
}

export function useCurrentUserPendingCollabTasks(
  entityId: string | null | undefined
) {
  return useQuery(["currentUserPendingCollabTasks", entityId], () =>
    getCurrentUserPendingCollabTasks(entityId)
  );
}

export function useCurrentUserPinned(entityId: string | null | undefined) {
  return useQuery(
    ["currentUserPinned", entityId],
    () => getCurrentUserPinned(entityId),
    {
      refetchInterval: 5000,
    }
  );
}

export function useUsers(entityId: string | null | undefined) {
  return useQuery(["users", entityId], () => getUsers(entityId));
}

export function useUsersBasic(entityId: string | null | undefined) {
  return useQuery(["usersBasic", entityId], () => getUsersBasic(entityId));
}

export function useUser(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["user", entityId, id], () => getUser(entityId, id));
}

export function useCurrentUserPendingTasks(
  entityId: string | null | undefined
) {
  return useQuery(["currentUserPendingTasks", entityId], () =>
    getCurrentUserPendingTasks(entityId)
  );
}

export function useTask(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  return useQuery(["task", entityId, id], () => getTask(entityId, id));
}

export function useTasks(entityId: string | null | undefined) {
  return useQuery(["tasks", entityId], () => getTasks(entityId));
}

export function useReconsetTasks(
  entityId: string | null | undefined,
  reconsetId: string | null | undefined
) {
  return useQuery(["reconsetTasks", entityId, reconsetId], () =>
    getReconsetTasks(entityId, reconsetId)
  );
}

export function useReconsetRunTasks(
  entityId: string | null | undefined,
  reconsetId: string | null | undefined,
  runId: string | null | undefined
) {
  return useQuery(["reconsetRunTasks", entityId, reconsetId, runId], () =>
    getReconsetRunTasks(entityId, reconsetId, runId)
  );
}
