import React from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./App.css";
import { UserContext } from "./misc/UserContext";
import LandingPage from "./LandingPage/LandingPage";
import Dashboard from "./Dashboard/Dashboard";
import { IDispatchAction, IReconsetState, IUserState } from "./types";
import AppContainer from "./ui/AppContainer/AppContainer";
import Datasets from "./Dataset/Datasets";
import DatasetCVM from "./Dataset/DatasetCVM";
import { getLocalStorage } from "./api";
import Relationships from "./Relationship/Relationships";
import RelationshipCVM from "./Relationship/RelationshipCVM";
import Reconsets from "./Reconset/Reconsets";
import ReconsetCVM from "./Reconset/ReconsetCVM";
import Reconset from "./Reconset/View/Reconset";
import Status from "./Reconset/View/Status";
import Match from "./Reconset/View/Match";
import Loaders from "./Loader/Loaders";
import LoaderCVM from "./Loader/LoaderCVM";
import Loader from "./Loader/Loader";
import FunctionCVM from "./Function/FunctionCVM";
import Functions from "./Function/Functions";
import UserCVM from "./User/UserCVM";
import Users from "./User/Users";
import Roles from "./Role/Roles";
import RoleCVM from "./Role/RoleCVM";
import PasswordReset from "./User/PasswordReset";
import Task from "./Task/Task";
import Tasks from "./Task/Tasks";
import MatchTemp from "./Reconset/View/Temp/MatchTemp";

type actionTypes =
  | "SET-SUCCESS"
  | "SET-ERROR"
  | "SET-RECONSET-ENTITY-ID"
  | "SET-RECONSET-ID"
  | "SET-RECONSET-RUN"
  | "SET-RECONSET-QUERY-COLUMNS-VALUES"
  | "SET-RECONSET-QUERY-COLUMNS-LOVS"
  | "SET-RECONSET-QUERY-COLUMNS"
  | "SET-RECONSET-QUERY-COLUMNS-VALUE"
  | "SET-RECONSET-QUERY-COLUMNS-LOV"
  | "SET-RECONSET-RUN-ID";

const initialState = {
  successMessage: "",
  errorMessage: "",
  user: getLocalStorage("user"),
  currentEntity: getLocalStorage("currentEntity"),
  reconset: {
    entityId: "",
    id: "",
    queryColumns: [],
    queryColumnsValue: [],
    queryColumnsLOV: [],
  },
};

function reducer(state: IUserState, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, errorMessage: action.value };
    case "SET-RECONSET-ENTITY-ID":
      return {
        ...state,
        reconset: { ...state.reconset, entityId: action.value },
      };
    case "SET-RECONSET-ID":
      return { ...state, reconset: { ...state.reconset, id: action.value } };
    case "SET-RECONSET-QUERY-COLUMNS-VALUES":
      return {
        ...state,
        reconset: { ...state.reconset, queryColumnsValue: action.value },
      };
    case "SET-RECONSET-QUERY-COLUMNS-VALUE": {
      let valueIndex = state.reconset.queryColumnsValue.findIndex(
        (column) => column.name === action.value.name
      );

      if (valueIndex >= 0) {
        const newQueryColumnsColumnValues =
          state.reconset.queryColumnsValue.map((column) => {
            if (column.name === action.value.name) {
              return {
                name: column.name,
                value: action.value.value,
              };
            }
            return column;
          });

        return {
          ...state,
          reconset: {
            ...state.reconset,
            queryColumnsValue: newQueryColumnsColumnValues,
          },
        };
      } else {
        const newQueryColumnsColumnValues = [
          ...state.reconset.queryColumnsValue,
        ];
        newQueryColumnsColumnValues.push(action.value);
        return {
          ...state,
          reconset: {
            ...state.reconset,
            queryColumnsValue: newQueryColumnsColumnValues,
          },
        };
      }
    }
    case "SET-RECONSET-QUERY-COLUMNS-LOV": {
      let valueIndex = state.reconset.queryColumnsLOV.findIndex(
        (column) => column.name === action.value.name
      );

      if (valueIndex >= 0) {
        const newQueryColumnsColumnValues = state.reconset.queryColumnsLOV.map(
          (column) => {
            if (column.name === action.value.name) {
              return {
                name: column.name,
                value: action.value.value,
              };
            }
            return column;
          }
        );

        return {
          ...state,
          reconset: {
            ...state.reconset,
            queryColumnsLOV: newQueryColumnsColumnValues,
          },
        };
      } else {
        const newQueryColumnsColumnValues = [...state.reconset.queryColumnsLOV];
        newQueryColumnsColumnValues.push(action.value);
        return {
          ...state,
          reconset: {
            ...state.reconset,
            queryColumnsLOV: newQueryColumnsColumnValues,
          },
        };
      }
    }
    default:
      return state;
  }
}

const queryClient = new QueryClient();

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state: state, dispatch: dispatch }}>
      <DndProvider backend={HTML5Backend}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/app/dashboard"
              element={
                <AppContainer component={Dashboard} header="Dashboard" />
              }
            />

            <Route path="/passwordreset" element={<PasswordReset />} />

            <Route path="/app/datasets">
              <Route
                path=""
                element={
                  <AppContainer component={Datasets} header="Datasets" />
                }
              />
              <Route
                path="/app/datasets/new"
                element={
                  <AppContainer component={DatasetCVM} header="New Dataset" />
                }
              />
              <Route
                path="/app/datasets/edit"
                element={
                  <AppContainer component={DatasetCVM} header="Edit Dataset" />
                }
              />
            </Route>

            <Route path="/app/relationships">
              <Route
                path=""
                element={
                  <AppContainer
                    component={Relationships}
                    header="Relationships"
                  />
                }
              />
              <Route
                path="/app/relationships/new"
                element={
                  <AppContainer
                    component={RelationshipCVM}
                    header="New Relationship"
                  />
                }
              />
              <Route
                path="/app/relationships/edit"
                element={
                  <AppContainer
                    component={RelationshipCVM}
                    header="Edit Relationship"
                  />
                }
              />
            </Route>

            <Route path="/app/reconsets">
              <Route
                path=""
                element={
                  <AppContainer component={Reconsets} header="Reconsets" />
                }
              />
              <Route
                path="/app/reconsets/new"
                element={
                  <AppContainer component={ReconsetCVM} header="New Reconset" />
                }
              />

              <Route path="/app/reconsets/view">
                <Route
                  path="/app/reconsets/view"
                  element={
                    <AppContainer component={Reconset} header="Reconset" />
                  }
                />

                <Route
                  path="/app/reconsets/view/status"
                  element={
                    <AppContainer component={Status} header="Reconset" />
                  }
                />

                <Route
                  path="/app/reconsets/view/match"
                  element={<AppContainer component={Match} header="Reconset" />}
                />
                <Route
                  path="/app/reconsets/view/temp"
                  element={
                    <AppContainer component={MatchTemp} header="Reconset" />
                  }
                />
              </Route>
              <Route
                path="/app/reconsets/edit"
                element={
                  <AppContainer
                    component={ReconsetCVM}
                    header="Edit Reconset"
                  />
                }
              />
            </Route>

            <Route path="/app/loaders">
              <Route
                path=""
                element={<AppContainer component={Loaders} header="Loaders" />}
              />
              <Route
                path="/app/loaders/new"
                element={
                  <AppContainer component={LoaderCVM} header="New Loader" />
                }
              />
              <Route
                path="/app/loaders/edit"
                element={
                  <AppContainer component={LoaderCVM} header="Edit Loader" />
                }
              />

              <Route
                path="/app/loaders/view"
                element={<AppContainer component={Loader} header="Loader" />}
              />
            </Route>

            <Route path="/app/functions">
              <Route
                path=""
                element={
                  <AppContainer component={Functions} header="Functions" />
                }
              />
              <Route
                path="/app/functions/new"
                element={
                  <AppContainer component={FunctionCVM} header="New Function" />
                }
              />
              <Route
                path="/app/functions/edit"
                element={
                  <AppContainer
                    component={FunctionCVM}
                    header="Edit Function"
                  />
                }
              />
            </Route>

            <Route path="/app/users">
              <Route
                path=""
                element={<AppContainer component={Users} header="Users" />}
              />
              <Route
                path="/app/users/new"
                element={<AppContainer component={UserCVM} header="New User" />}
              />
              <Route
                path="/app/users/edit"
                element={
                  <AppContainer component={UserCVM} header="Edit User" />
                }
              />
            </Route>

            <Route path="/app/roles">
              <Route
                path=""
                element={<AppContainer component={Roles} header="Roles" />}
              />
              <Route
                path="/app/roles/new"
                element={<AppContainer component={RoleCVM} header="New Role" />}
              />
              <Route
                path="/app/roles/edit"
                element={
                  <AppContainer component={RoleCVM} header="Edit Role" />
                }
              />
            </Route>

            <Route path="/app/tasks">
              <Route
                path=""
                element={<AppContainer component={Tasks} header="Tasks" />}
              />
              <Route
                path="/app/tasks/view"
                element={<AppContainer component={Task} header="Tasks" />}
              />
            </Route>
          </Routes>
        </QueryClientProvider>
      </DndProvider>
    </UserContext.Provider>
  );
}

export default App;
