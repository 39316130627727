import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array } from "yup";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { updateMatch, updateMatchTemp } from "src/api";
import {
  CMultiSelect,
  CSelect,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";
import { Button, Grid, Select, TextInput } from "@mantine/core";
import { getConfirmationStatus } from "../../ReconsetUtils";
import { showError } from "../../../utilities";

export const UpdateMatchTempSchema = object({
  actionName: string().required(),
  systemStatus: string().required(),
  userStatus: array().of(string()),
  comments: string().nullable(),
});

export default function UpdateMatchTemp({
  id,
  entityId,
  matchId,
  recon,
  runId,
  match,
  systemStatusLOV,
  userStatusLOV,
  closeDialog,
}: {
  id: string;
  entityId: string;
  matchId: string;
  recon: string;
  runId: string;
  match: any;
  systemStatusLOV: { label: string; value: string }[];
  userStatusLOV: { label: string; value: string }[];
  closeDialog: () => void;
}) {
  const { control, handleSubmit } = useForm<{
    name: string;
    actionName: string;
    systemStatus: string;
    userStatus: string;
    comment: string;
    details: any;
  }>({
    defaultValues: {
      name: match.name,
      actionName: match.actionName,
      systemStatus: match.systemStatus,
      userStatus: (match.userStatus || "").split(","),
      comment: match.comment,
      details: match.details,
    },
    criteriaMode: "all",
    resolver: yupResolver(UpdateMatchTempSchema),
  });

  const [editable, setEditable] = React.useState(false);
  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    try {
      await updateMatchTemp(id, entityId, recon, matchId, runId, data);
      queryClient.invalidateQueries([
        "reconsetMatchTempDetails",
        entityId,
        id,
        runId,
        recon,
        matchId,
      ]);
      closeDialog();
      showNotification({
        id: "success",
        title: "Match detals update",
        message: "Match details updated successfully",
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error: any) {
      showError(
        error,
        "Error in match update",
        "Unable to update match details",
        <IconX />
      );
    }
  };

  return (
    <form noValidate>
      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name="name"
            control={control}
            label="Name"
            required
            showErrorText={false}
            disabled={!editable}
          />
        </Grid.Col>
      </Grid>
      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <TextInput value={match.recon} label="Recon ID" disabled={true} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput value={match.reconName} label="Recon" disabled={true} />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <TextInput value={match.action} label="Action ID" disabled={true} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            value={match.actionName}
            label="Action Name"
            disabled={true}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <TextInput value={match.makerId} label="Maker Id" disabled={true} />
        </Grid.Col>
        <Grid.Col span={6}>
          <TextInput
            value={dayjs(match.makerDate).format("YYYY-MM-DD HH:MM")}
            label="Maker Date"
            disabled={true}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name="comment"
            control={control}
            label="Comment"
            showErrorText={false}
            disabled={!editable}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <CSelect
            name={"systemStatus"}
            control={control}
            label={"System Status"}
            data={systemStatusLOV}
            showErrorText={false}
            required
            disabled={!editable}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CMultiSelect
            name={"userStatus"}
            control={control}
            label={"User Status"}
            data={userStatusLOV}
            showErrorText={false}
            disabled={!editable}
            clearable
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mt="xs">
        {!editable && (
          <Grid.Col span={2}>
            <Button
              fullWidth
              variant="filled"
              color="teal"
              onClick={() => {
                setEditable(true);
              }}
            >
              Edit
            </Button>
          </Grid.Col>
        )}
        {editable && (
          <Grid.Col span={2}>
            <Button
              fullWidth
              variant="filled"
              color="teal"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Grid.Col>
        )}
        <Grid.Col span={2}>
          <Button variant="default" onClick={closeDialog} fullWidth>
            Cancel
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
