import * as React from "react";
import {
  Title,
  Text,
  Grid,
  Paper,
  ActionIcon,
  Button,
  useMantineTheme,
  Divider,
  Flex,
  Stack,
  Tooltip,
} from "@mantine/core";
import { useSearchParams, useNavigate } from "react-router-dom";
import { IconHome } from "@tabler/icons-react";

import { useReconsetBasic } from "../../hooks";
import { styles } from "../../styles";
import Loading from "../../ui/Loading";
import { getNoOfDatasets, getReconType } from "../ReconsetUtils";
import DatasetData from "./DatasetData";
import { getReconsetMatchesTemp } from "src/api";
import DataDisplay from "./DataDisplay";

// Status entries
// Link to match id
// Link to rule
// Entries by match id
// Link to rule
// Entries by action rule

export default function Status() {
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId"),
    type = searchParams.get("type"),
    status = searchParams.get("status"),
    recon = searchParams.get("recon"),
    runId = searchParams.get("runId"),
    runSeq = searchParams.get("runSeq");

  const theme = useMantineTheme();

  const { data: reconset } = useReconsetBasic(entityId, id);
  const navigate = useNavigate();
  const noOfDatasets = getNoOfDatasets(reconset, recon);

  const [matchesTemp, setMatchesTemp] = React.useState<
    {
      id: string;
      name: string;
      reconName: string;
      actionName: string;
      systemStatus: string;
      userStatus: string[];
    }[]
  >([]);
  const [selectedEntriesPrimary, setSelectedEntriesPrimary] = React.useState<
    string[]
  >([]);
  const [selectedEntriesSecondary, setSelectedEntriesSecondary] =
    React.useState<string[]>([]);

  React.useEffect(() => {
    const getTempMatches = async () => {
      const temp = await getReconsetMatchesTemp(
        entityId,
        id,
        runId,
        recon,
        // slice first 20 entries and join
        selectedEntriesPrimary.slice(0, 20).join(","),
        selectedEntriesSecondary.slice(0, 20).join(",")
      );
      if (temp) {
        setMatchesTemp(temp);
      }
    };

    if (status === "__NONE") {
      getTempMatches();
    }
  }, [selectedEntriesPrimary, selectedEntriesSecondary]);

  if (reconset) {
    return (
      <div style={{ marginTop: theme.spacing.lg }}>
        <div id="header">
          <Grid
            align="flex-end"
            gutter={"xs"}
            mt="xs"
            mb="xs"
            // mb="xs"
            style={{
              display: "flex",
              // borderLeft: `${theme.spacing.xxs} solid ${theme.colors.emerald[7]}`,
            }}
            columns={12}
          >
            <Grid.Col span={3}>
              <Flex align={"end"} gap={theme.spacing.xs}>
                <Stack gap={0}>
                  <Title order={3} c="dark">
                    {reconset.name}
                  </Title>
                  <Text size="sm" c="dark">
                    {reconset.displayName}
                  </Text>
                </Stack>
                <Tooltip label="Home">
                  <ActionIcon
                    variant="light"
                    size="lg"
                    ml="lg"
                    mr="xs"
                    onClick={() => {
                      navigate(
                        `/app/reconsets/view?id=${id}&entityId=${entityId}`
                      );
                    }}
                  >
                    <IconHome
                      style={{ width: "70%", height: "70%" }}
                      stroke={2}
                    />
                  </ActionIcon>
                </Tooltip>
              </Flex>
            </Grid.Col>
          </Grid>
        </div>
        <Divider size="lg" color="dark.2" />

        <Flex mt="lg" mb="xxl">
          <Title
            mt="xs"
            order={4}
            // c="white"
            py={0}
            px={theme.spacing.xs}
            style={{
              borderLeft: `${theme.spacing.xxs} solid ${theme.colors.emerald[2]}`,
            }}
          >
            {type?.toLowerCase() === "system"
              ? "System Status"
              : type?.toLowerCase() === "user"
                ? "User Status"
                : type?.toLowerCase() === "confirmation"
                  ? "Confirmation Status"
                  : ""}
            {" - "} {status}
          </Title>
        </Flex>
        <Stack mb="xxl">
          <DatasetData
            type={type}
            entityId={entityId}
            dataset="primary"
            id={id}
            status={status}
            recon={recon}
            runId={runId}
            runSeq={runSeq}
            setSelectedEntries={setSelectedEntriesPrimary}
          />
        </Stack>
        {noOfDatasets === "TWO" && (
          <Stack mb="xxl">
            <DatasetData
              type={type}
              entityId={entityId}
              dataset="secondary"
              id={id}
              status={status}
              recon={recon}
              runId={runId}
              runSeq={runSeq}
              setSelectedEntries={setSelectedEntriesSecondary}
            />
          </Stack>
        )}

        {status === "__NONE" ? (
          <Stack mb="xxl">
            <Text
              fw={"bold"}
              c="dark.6"
              style={{
                borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
                paddingLeft: theme.spacing.xs,
              }}
            >
              Temp Matches
            </Text>
            <DataDisplay
              data={matchesTemp} // Pass as array
              table="details"
              definition={[]}
              links={[
                {
                  name: "id",
                  link: `/app/reconsets/view/temp?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&recon=${recon}&matchId=`,
                },
              ]}
              enableSelection={false}
              // selection={selection}
              // toggleAll={toggleAll}
              // toggleRow={toggleRow}
            />
          </Stack>
        ) : (
          <Stack mb="xxl"></Stack>
        )}
      </div>
    );
  } else {
    return (
      <div style={styles.viewMain}>
        <div style={styles.viewBody} id="body">
          <div style={{ width: "600px" }}>
            <Loading />
          </div>
        </div>
      </div>
    );
  }
}
