import * as React from "react";
import {
  AppShell,
  Tooltip,
  UnstyledButton,
  Center,
  Stack,
  Select,
  Title,
  Menu,
  ActionIcon,
  Text,
  Group,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import { IconChecklist, IconLogout, IconUserCircle } from "@tabler/icons-react";
import { useLocalStorage } from "@mantine/hooks";
import { useNavigate, createSearchParams, useMatch } from "react-router-dom";

import appContainerStyles from "./AppContainer.module.css";

import { getLocalStorage, signOut } from "../../api";
import { getRoleAccessOptions } from "../../utilities";
import { UserContext } from "../../misc/UserContext";
import { styles } from "../../styles";

interface IAppContainerProps {
  component: React.ComponentType;
  header?: string;
}

function getEntityLOV(userRoles: any) {
  const entityLOV: { label: string; value: string }[] = [];

  for (const role of userRoles) {
    if (
      entityLOV.findIndex((entity) => entity.value === role.entityId) === -1
    ) {
      entityLOV.push({
        value: role.entityId,
        label: role.entityName,
      });
    }
  }

  return entityLOV;
}

interface NavbarLinkProps {
  icon: any;
  label: string;
  link: string;
  active?: boolean;
}

function NavbarLink({ icon: Icon, label, link }: NavbarLinkProps) {
  let navigate = useNavigate();
  const active = useMatch({ path: `/app/${label.toLowerCase()}` });
  const viewActive = useMatch({
    path: `/app/${label.toLowerCase()}/view`,
  });
  const editActive = useMatch({
    path: `/app/${label.toLowerCase()}/edit`,
  });
  const newActive = useMatch({
    path: `/app/${label.toLowerCase()}/new`,
  });

  return (
    <Tooltip label={label} position="right">
      <UnstyledButton
        className={appContainerStyles.root}
        onClick={() => {
          navigate(link);
        }}
        style={
          active || viewActive || editActive || newActive
            ? { ...styles.link, color: "#439CEA" }
            : styles.link
        }
      >
        <Icon
          stroke={active || viewActive || editActive || newActive ? 1.75 : 1.5}
        />
      </UnstyledButton>
    </Tooltip>
  );
}

export default function AppContainer({
  component: Component,
  header,
  ...rest
}: IAppContainerProps) {
  const [currentEntity, setCurrentEntity] = useLocalStorage({
    key: "currentEntity",
    defaultValue: getLocalStorage("currentEntity"),
  });

  const [user] = useLocalStorage({
    key: "user",
    defaultValue: { userId: "", userName: "" },
  });

  const [userRoles] = useLocalStorage({
    key: "roles",
    defaultValue: getLocalStorage("roles"),
  });
  let navigate = useNavigate();

  const links = getRoleAccessOptions(currentEntity, userRoles).map((link) => (
    <NavbarLink
      {...link}
      key={link.label}
      // active={index === active}
      // onClick={() => setActive(index)}
    />
  ));

  const theme = useMantineTheme();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 80, breakpoint: "sm", collapsed: { mobile: false } }}
      padding="md"
      style={{ backgroundColor: theme.colors.gray[0] }}
    >
      <AppShell.Navbar
        p="md"
        h={"100vh"}
        w={{ base: 80 }}
        style={{ zIndex: 101, top: 0 }}
      >
        <Center>
          <IconChecklist size={40} strokeWidth={1.75} color={"teal"} />
        </Center>

        <Stack justify="center" align="center" gap={0} pt={"xl"}>
          {links}
        </Stack>
      </AppShell.Navbar>

      <AppShell.Header
        h={67}
        py={theme.spacing.md}
        pl={theme.spacing.xxxl}
        w="100vw"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Title
            order={2}
            py={0}
            px={theme.spacing.xxs}
            c="white"
            style={{
              // marginLeft: "80px",
              backgroundColor: theme.colors.emerald[0],
            }}
          >
            Rekonist
          </Title>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexGrow: 2,
              paddingRight: theme.spacing.xl,
            }}
          >
            <Select
              mr={theme.spacing.md}
              value={currentEntity}
              onChange={(val) => {
                if (val) {
                  setCurrentEntity(val);
                  navigate({
                    pathname: "/app/dashboard",
                    search: createSearchParams({
                      entityId: val,
                    }).toString(),
                  });
                }
                //   navigate("/app/dashboard");
              }}
              data={getEntityLOV(userRoles)}
            />

            <Menu position="bottom-end">
              <Menu.Target>
                <ActionIcon
                  variant="transparent"
                  c="gray.6"
                  aria-label="Profile"
                  style={{ outline: "none" }}
                >
                  <IconUserCircle />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown style={{ minWidth: "250px" }} c="gray.9">
                <Menu.Item onClick={() => signOut()}>
                  <Group justify="flex-start">
                    <IconLogout color="#585D60" />
                    <Text>Logout</Text>
                  </Group>
                </Menu.Item>
                <Divider />
                <Menu.Item c="gray.9">
                  <Text fw={500}>{user.userName}</Text>
                  <Text c="dimmed">{user.userId}</Text>
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
      </AppShell.Header>

      <AppShell.Main>
        <Component {...rest} />
      </AppShell.Main>
    </AppShell>
  );
}
