import * as React from "react";

import {
  Combobox,
  ComboboxItem,
  Pill,
  ComboboxProps,
  useCombobox,
  CheckIcon,
  Group,
  PillsInput,
} from "@mantine/core";

export function MultiSelectCreatable(
  props: {
    data: ComboboxItem[];
    placeholder?: string;
    onChange?: (value: string[]) => void;
    showErrorText?: boolean;
  } & ComboboxProps
) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const { data, placeholder, onChange } = props;

  const [search, setSearch] = React.useState("");
  const [listOfValues, setListOfValues] = React.useState(data || []);
  const [value, setValue] = React.useState<string[]>([]);

  const exactOptionMatch = listOfValues.some((item) => item.value === search);

  const handleValueSelect = (val: string) => {
    setSearch("");

    if (val === "$create") {
      setListOfValues((current) => [
        ...current,
        { label: search, value: search },
      ]);
      let newValue = [...value, search];
      setValue((current) => [...current, search]);
      onChange?.(newValue && newValue.length > 0 ? [...newValue] : []);
    } else {
      setValue((current) =>
        current.includes(val)
          ? current.filter((v) => v !== val)
          : [...current, val]
      );
      let newValue = value.includes(val)
        ? value.filter((v) => v !== val)
        : [...value, val];
      onChange?.(newValue && newValue.length > 0 ? [...newValue] : []);
    }
  };

  const handleValueRemove = (val: string) => {
    let newValue = value.includes(val)
      ? value.filter((v) => v !== val)
      : [...value, val];
    setValue((current) => current.filter((v) => v !== val));
    onChange?.(newValue && newValue.length > 0 ? [...newValue] : []);
  };

  const values = value.map((item) => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ));

  const options = listOfValues
    .filter((item) =>
      item.value.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={value.includes(item.value)}
      >
        <Group gap="sm">
          {value.includes(item.value) ? <CheckIcon size={12} /> : null}
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          placeholder={placeholder}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={placeholder}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    if (value.length > 0) {
                      handleValueRemove(value[value.length - 1]);
                    }
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options}

          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
          )}

          {exactOptionMatch &&
            search.trim().length > 0 &&
            options.length === 0 && (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
