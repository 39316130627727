import {
  ScrollArea,
  Table,
  Checkbox,
  Badge,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import { Link } from "react-router-dom";

const standardColumnDefinition = [
  {
    name: "id",
    label: "ID",
    type: "TEXT",
  },
  {
    name: "reconid",
    label: "Recon ID",
    type: "TEXT",
  },
  {
    name: "status",
    label: "Status",
    type: "TEXT",
  },
  {
    name: "startdate",
    label: "Start Date",
    type: "TEXT",
  },
  {
    name: "logdate",
    label: "Log Date",
    type: "TEXT",
  },
  {
    name: "enddate",
    label: "End Date",
    type: "TEXT",
  },
  {
    name: "error",
    label: "Error",
    type: "TEXT",
  },
  {
    name: "details",
    label: "Details",
    type: "TEXT",
  },
  {
    name: "action",
    label: "Action",
    type: "TEXT",
  },
  {
    name: "runId",
    label: "Run ID",
    type: "TEXT",
  },
  {
    name: "reconId",
    label: "Recon ID",
    type: "TEXT",
  },
  {
    name: "reconName",
    label: "Recon Name",
    type: "TEXT",
  },
  {
    name: "actionId",
    label: "Action ID",
    type: "TEXT",
  },
  {
    name: "actionName",
    label: "Action Name",
    type: "TEXT",
  },
  {
    name: "matchDate",
    label: "Match Date",
    type: "DATE",
  },
  {
    name: "makerId",
    label: "Maker ID",
    type: "TEXT",
  },
  {
    name: "makerDate",
    label: "Maker Date",
    type: "DATE",
  },
  {
    name: "checkerId",
    label: "Checker ID",
    type: "TEXT",
  },
  {
    name: "checkerDate",
    label: "Checker Date",
    type: "DATE",
  },
  {
    name: "systemStatus",
    label: "System Status",
    type: "TEXT",
  },
  {
    name: "userStatus",
    label: "User Status",
    type: "TEXT",
  },
  {
    name: "confirmationStatus",
    label: "Confirmation Status",
    type: "TEXT",
  },
  {
    name: "comments",
    label: "Comments",
    type: "TEXT",
  },
  {
    name: "tempId",
    label: "Temp Match ID",
    type: "TEXT",
  },
  {
    name: "recon",
    label: "Recon ID",
    type: "TEXT",
  },
];

function getDataHeaders(
  table: string,
  data: any[],
  definition:
    | {
        name: string;
        type: string;
        label?: string;
      }[]
    | [],
  ignoreColumns: string[]
) {
  const headers: string[] = [];

  if (data && data.length > 0) {
    const firstRow = data[0];
    Object.keys(firstRow)
      .filter((key) => ignoreColumns.indexOf(key) === -1)
      .forEach((key) => {
        const column = definition.find((def) => {
          return def.name === key;
        });
        if (column) {
          headers.push(
            column.label
              ? `${column.label ? column.label : column.name} [ ${column.name} ]`
              : column.name
          );
        } else {
          const column = standardColumnDefinition.find((def) => {
            return def.name === key;
          });
          if (column) {
            headers.push(`${column.label ? column.label : column.name}`);
          } else {
            headers.push(key);
          }
        }
      });
  }
  return headers;
}

// TODO: Client side pagination
// TODO: Details modal
// TODO: Timestamp display

export default function DataDisplay({
  table,
  data,
  definition,
  links,
  enableSelection = false,
  selection = [],
  toggleRow,
  toggleAll,
  ignoreColumns = [],
  displayInModal = false,
  height = 250,
}: {
  table: string;
  data: any[];
  definition:
    | {
        name: string;
        type: string;
        label?: string;
      }[]
    | [];
  links: {
    name: string;
    split?: boolean;
    splitBy?: string;
    link: string;
  }[];
  enableSelection?: boolean;
  selection?: string[];
  toggleRow?: (id: string) => void;
  toggleAll?: () => void;
  ignoreColumns?: string[];
  displayInModal?: boolean;
  height?: number;
}) {
  const theme = useMantineTheme();
  const headers = getDataHeaders(table, data, definition, ignoreColumns);

  if (data && data.length > 0) {
    return (
      <ScrollArea
        style={{ height: height }}
        offsetScrollbars
        scrollbarSize={16}
      >
        <Table
          verticalSpacing="sm"
          fs="xs"
          horizontalSpacing={"xs"}
          style={{ marginBottom: "20px" }}
        >
          <Table.Thead>
            <Table.Tr>
              {enableSelection ? (
                <Table.Th key={"__checkbox"}>
                  <Checkbox
                    // onChange={toggleAll}
                    onChange={toggleAll}
                    checked={selection.length === data.length}
                    indeterminate={
                      selection.length > 0 && selection.length !== data.length
                    }
                  />
                </Table.Th>
              ) : (
                <></>
              )}
              {headers.map((header) => {
                return (
                  <Table.Th
                    key={header}
                    style={{
                      minWidth: `${
                        displayInModal
                          ? Math.max(header.length * 7, 150)
                          : header.length * 7
                      }px`,
                    }}
                  >
                    {header}
                  </Table.Th>
                );
              })}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {(data || []).map((rowData, index) => {
              return (
                <Table.Tr key={index}>
                  {enableSelection ? (
                    <Table.Td key={"__checkbox"}>
                      <Checkbox
                        checked={selection.includes(rowData.__id)}
                        onChange={() =>
                          toggleRow ? toggleRow(rowData.__id) : ""
                        }
                      />
                    </Table.Td>
                  ) : (
                    <></>
                  )}
                  {Object.keys(rowData)
                    .filter((key) => ignoreColumns.indexOf(key) === -1)
                    .map((key) => {
                      const columnLink = links.find(
                        (link) => link.name === key
                      );
                      if (columnLink) {
                        if (columnLink.split) {
                          const links = rowData[key]
                            ? rowData[key].split(columnLink.splitBy || ",")
                            : [];
                          return (
                            <Table.Td key={key}>
                              {links.map((link: any) => {
                                return (
                                  <Link
                                    style={{ marginRight: theme.spacing.xxs }}
                                    key={link}
                                    to={`${columnLink.link}${link}`}
                                    // className={classes.badgeLink}
                                  >
                                    {link}
                                  </Link>
                                );
                              })}
                            </Table.Td>
                          );
                        } else {
                          return (
                            <Table.Td key={key}>
                              <Link
                                to={`${columnLink.link}${rowData[key]}`}
                                // className={classes.badgeLink}
                              >
                                {rowData[key]}
                              </Link>
                            </Table.Td>
                          );
                        }
                      }
                      return <Table.Td key={key}>{rowData[key]}</Table.Td>;
                    })}
                </Table.Tr>
              );
            })}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    );
  } else {
    return (
      <Divider
        label="No data found for the query/search"
        labelPosition="left"
      />
    );
  }
}
