import axios, { AxiosResponse } from "axios";
import FileDownload from "js-file-download";

import {
  LoaderType,
  ReconsetBasicType,
  ReconsetType,
  TaskDetailType,
  TaskType,
} from "@supportinitiative/common";

import history from "./history";

axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASE_URL;

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log("error", error);
    if (error.response.status === 401 || error.response.status === 403) {
      signOut();
      console.error("error", error.response);
    }
    return Promise.reject(error);
  }
);

export const apiAxios = axios;

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}

export function setLocalStorage(key: string, value: any) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error("setLocalStorage Error", err);
  }
}

export function getLocalStorage(key: string) {
  try {
    const value = localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return "";
    }
  } catch (error) {
    console.error("getLocalStorage Error", key, error);
  }
}

export function getAuthHeader() {
  return `Bearer ${getLocalStorage("token")}`;
}

export async function signOut() {
  removeLocalStorage("token");
  removeLocalStorage("roles");
  // removeLocalStorage("authToken");
  removeLocalStorage("user");
  removeLocalStorage("currentEntity");
  history.push("/");
}

export async function auth(payload: { userId: string; password: string }) {
  return axios.post("/api/auth", {
    ...payload,
  });
}

export async function getReconsets(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/reconsets", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getReconset(
  entityId: string | null | undefined,
  id: string | null | undefined
): Promise<ReconsetType | undefined> {
  let response = await apiAxios.get("/api/reconset", {
    params: {
      id,
      entityId: getLocalStorage("currentEntity"),
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data.values;
}

export async function getReconsetBasic(
  entityId: string | null | undefined,
  id: string | null | undefined
): Promise<ReconsetBasicType | undefined> {
  if (id && entityId) {
    let response = await apiAxios.get("/api/reconset/basic", {
      params: {
        __id: id,
        __entityId: getLocalStorage("currentEntity"),
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
}

export async function getQueryColumnsListOfValues(
  entityId: string | null | undefined,
  id: string | null | undefined,
  __name: string,
  params: any
): Promise<{ label: string; value: string }[] | undefined> {
  if (id && entityId && __name) {
    let queryParams: Record<string, string> = {};

    params.forEach((param: any) => {
      queryParams[param.name as string] = param.value;
    });

    let response = await axios.get("/api/reconset/querycolumns/listofvalues", {
      params: {
        __entityId: entityId,
        __id: id,
        __name,
        ...queryParams,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getRunDetails(
  entityId: string | null | undefined,
  id: string | null | undefined,
  params: any
): Promise<any> {
  // Call only when params is not undefined and length is > 0 and atleast 1st value is present
  // Else it will be no use to call the api. return empty

  const { queryColumnValues } = params;

  if (id && entityId && params && params.length > 0 && params[0].value) {
    let queryParams: Record<string, string> = {};

    params.forEach((param: any) => {
      queryParams[param.name as string] = param.value;
    });

    let response = await axios.get("/api/reconset/run", {
      params: {
        __entityId: entityId,
        __id: id,
        ...queryParams,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getColumnsDefinition(
  entityId: string | null | undefined,
  id: string | null | undefined
): Promise<
  | {
      masterDataset: {
        name: string;
        type: string;
        label?: string;
      }[];
      recons: {
        id: string;
        primaryDataSet: {
          name: string;
          type: string;
          label?: string;
        }[];
        secondaryDataset?: {
          name: string;
          type: string;
          label?: string;
        }[];
      }[];
    }
  | undefined
> {
  if (id && entityId) {
    let response = await axios.get("/api/reconset/columns/definition", {
      params: {
        __entityId: entityId,
        __id: id,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return;
}

export async function getDatasetData(
  entityId: string | null | undefined,
  id: string | null | undefined,
  dataset: string | null | undefined,
  limit: string | null | undefined,
  type: string | null | undefined,
  runId: string | null | undefined,
  runSeq: string | null | undefined,
  query: string | null | undefined,
  status: string | null | undefined,
  recon: string | null | undefined,
  minId: string | null | undefined,
  maxId: string | null | undefined,
  search: string | null | undefined,
  params: any
): Promise<{ __id: string }[] | []> {
  if (id && entityId) {
    let queryParams: Record<string, string> = {};

    params.forEach((param: any) => {
      queryParams[param.name as string] = param.value;
    });

    let response = await axios.get("/api/reconset/data/dataset", {
      params: {
        __entityId: entityId,
        __reconsetId: id,
        __dataset: dataset,
        __limit: limit,
        __type: type,
        __runId: runId,
        __runSeq: runSeq,
        __query: query,
        __status: status,
        __recon: recon,
        __minid: minId,
        __maxid: maxId,
        __search: search ? encodeURIComponent(search) : "",
        ...queryParams,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getReconsetMatchDetails(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  matchId: string | null | undefined
): Promise<
  { details: any; primaryDataset: any; secondaryDataset?: any } | undefined
> {
  if (id && entityId && runId && matchId && recon) {
    let response = await axios.get("/api/reconset/data/match", {
      params: {
        __entityId: entityId,
        __reconsetId: id,
        __runId: runId,
        __recon: recon,
        __matchId: matchId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return;
}

export async function getReconsetMatchTempDetails(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  matchId: string | null | undefined
): Promise<
  { details: any; primaryDataset: any; secondaryDataset?: any } | undefined
> {
  if (id && entityId && runId && matchId && recon) {
    let response = await axios.get("/api/reconset/data/match/temp", {
      params: {
        __entityId: entityId,
        __reconsetId: id,
        __runId: runId,
        __recon: recon,
        __matchId: matchId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return;
}

export async function getReconsetMatchComments(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  matchId: string | null | undefined,
  type: "TEMP" | "MATCH"
): Promise<
  | {
      id: string;
      comment: string;
      userId: string;
      userName: string;
      attachments: any;
      commentDate: Date;
    }[]
  | []
> {
  if (id && entityId && runId && matchId && recon) {
    if (type === "TEMP") {
      let response = await axios.get("/api/reconset/data/match/temp", {
        params: {
          __entityId: entityId,
          __reconsetId: id,
          __runId: runId,
          __recon: recon,
          __matchId: matchId,
          __type: type,
          __detail: "COMMENTS",
        },
        headers: {
          Authorization: getAuthHeader(),
        },
      });
      return response.data.values;
    } else {
      let response = await axios.get("/api/reconset/data/match", {
        params: {
          __entityId: entityId,
          __reconsetId: id,
          __runId: runId,
          __recon: recon,
          __matchId: matchId,
          __type: type,
          __detail: "COMMENTS",
        },
        headers: {
          Authorization: getAuthHeader(),
        },
      });
      return response.data.values;
    }
  }
  return [];
}

export async function getReconsetMatches(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined
): Promise<
  | {
      id: string;
      name: string;
      reconName: string;
      actionName: string;
      systemStatus: string;
      userStatus: string[];
    }[]
  | undefined
> {
  if (id && entityId && runId && recon) {
    let response = await axios.get("/api/reconset/data/matches", {
      params: {
        __entityId: entityId,
        __reconsetId: id,
        __runId: runId,
        __recon: recon,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return;
}

export async function getReconsetMatchesTemp(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  recon: string | null | undefined,
  primaryIds?: string,
  secondaryIds?: string
): Promise<
  | {
      id: string;
      name: string;
      reconName: string;
      actionName: string;
      systemStatus: string;
      userStatus: string[];
    }[]
  | undefined
> {
  if (id && entityId && runId && recon) {
    let response = await axios.get("/api/reconset/data/matches/temp", {
      params: {
        __entityId: entityId,
        __reconsetId: id,
        __runId: runId,
        __recon: recon,
        __primaryIds: primaryIds,
        __secondaryIds: secondaryIds,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return;
}

export async function updateMatch(
  id: string,
  entityId: string,
  reconId: string,
  matchId: string,
  runId: string,
  match: any,
  action: string
) {
  return await axios.post("/api/reconset/match/action", match, {
    params: {
      __entityId: entityId,
      __reconsetId: id,
      __recon: reconId,
      __matchId: matchId,
      __matchAction: action,
      __runId: runId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function addToMatch(
  id: string,
  entityId: string,
  reconId: string,
  runId: string,
  match: any
) {
  return await axios.post("/api/reconset/match/add", match, {
    params: {
      _entityid: entityId,
      _reconsetid: id,
      _reconid: reconId,
      _runid: runId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function addToMatchTemp(
  id: string,
  entityId: string,
  recon: string,
  runId: string,
  match: any
) {
  return await axios.post("/api/reconset/match/temp/add", match, {
    params: {
      __entityId: entityId,
      __reconsetId: id,
      __recon: recon,
      __runId: runId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function deleteFromMatch(
  id: string,
  entityId: string,
  recon: string,
  runId: string,
  matchId: string,
  match: any
) {
  return await axios.post("/api/reconset/match/delete", match, {
    params: {
      __entityid: entityId,
      __reconsetid: id,
      __recon: recon,
      __runid: runId,
      __matchid: matchId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function uploadReconsetCommentFile(
  id: string,
  entityId: string,
  reconId: string,
  runId: string,
  matchId: string,
  formData: FormData
) {
  return await axios.post("/api/reconset/file/upload", formData, {
    params: {
      _entityid: entityId,
      _reconsetid: id,
      _reconid: reconId,
      _runid: runId,
      _matchid: matchId,
    },
    headers: {
      Authorization: getAuthHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function downloadFile(
  id: string,
  entityId: string,
  fileName: string,
  fileType: string
) {
  const response = await axios
    .get("/api/reconset/file/download", {
      params: {
        entityId: entityId,
        id: id,
        fileName: fileName,
        fileType: fileType,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      FileDownload(response.data, fileName);
      return {
        status: response.status,
      };
    })
    .catch((error) => {
      return {
        status: 500,
      };
    });

  return response;
}

export async function postComment(
  id: string,
  entityId: string,
  runId: string,
  recon: string,
  matchId: string,
  type: "TEMP" | "MATCH",
  comment: {
    comment: string;
    attachments: {
      files: {
        uploadedTo: string;
        link: string;
        fileName: string;
      }[];
    };
  }
) {
  return axios.post("/api/reconset/comment", comment, {
    params: {
      __entityId: entityId,
      __reconsetId: id,
      __runId: runId,
      __recon: recon,
      __matchId: matchId,
      __type: type,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function getComments(
  entityId: string | null | undefined,
  id: string | null | undefined,
  runId: string | null | undefined,
  matchId: string | null | undefined
): Promise<
  | {
      comment: string;
      attachments: {
        files: {
          uploadedTo: string;
          link: string;
          fileName: string;
        }[];
      };
    }[]
  | []
> {
  if (id && entityId && runId && matchId) {
    const response = await axios.get("/api/reconset/comments", {
      params: {
        entityId: entityId,
        id: id,
        runId: runId,
        matchId: matchId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
  return [];
}

/*******************Relationships*******************/

export async function getRelationships(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/relationships", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getRelationship(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  if (id && entityId) {
    let response = await apiAxios.get("/api/relationship", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
}

export async function getRelationshipByPrimaryAndSecondaryDataset(
  entityId: string | null | undefined,
  primaryDataset: string | null | undefined,
  secondaryDataset: string | null | undefined
) {
  if (primaryDataset && secondaryDataset && entityId) {
    let response = await apiAxios.get("/api/relationship/primarysecondary", {
      params: {
        primaryDataset,
        secondaryDataset,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
}

export async function postRelationship(
  relatioship: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/relationship", relatioship, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

/*******************Datasets*******************/

export async function getDatasets(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/datasets", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function postDataset(
  dataset: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/dataset", dataset, {
    params: {
      __entityId: dataset.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function getDataset(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  if (id && entityId) {
    let response = await apiAxios.get("/api/dataset", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
  return;
}

/*******************Loaders*******************/

export async function getLoaders(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/loaders", {
      params: {
        __entityId: entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function postLoader(
  loader: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/loader", loader, {
    params: {
      __entityId: loader.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function getLoader(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  if (id && entityId) {
    let response = await apiAxios.get("/api/loader", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
}

export async function runLoader(
  id: string | null | undefined,
  entityId: string | null | undefined,
  loader: any
) {
  if (id && entityId) {
    return axios.post("/api/loader/run", loader, {
      params: {
        _entityid: entityId,
        _loaderid: id,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
  }
}

/*******************Functions*******************/

export async function getFunctions(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/functions", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function postFunction(
  funcDefinition: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/function", funcDefinition, {
    params: {
      __entityId: funcDefinition.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function getFunction(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  if (id && entityId) {
    let response = await apiAxios.get("/api/function", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
}

/*******************Loaders*******************/

export async function uploadLoaderFile(
  id: string,
  entityId: string,
  formData: FormData
) {
  return await axios.post("/api/loader/file/upload", formData, {
    params: {
      _entityid: entityId,
      _loaderid: id,
    },
    headers: {
      Authorization: getAuthHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getLoaderBasic(
  entityId: string | null | undefined,
  id: string | null | undefined
): Promise<LoaderType | undefined> {
  if (id && entityId) {
    let response = await apiAxios.get("/api/loader/basic", {
      params: {
        id,
        entityId: getLocalStorage("currentEntity"),
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
}

export async function getLoaderStatus(
  entityId: string | null | undefined,
  id: string | null | undefined
): Promise<{ id: string }[] | undefined> {
  if (id && entityId) {
    let response = await apiAxios.get("/api/loader/details", {
      params: {
        id,
        entityId: getLocalStorage("currentEntity"),
        _detail: "STATUS",
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
}

/*******************Roles*******************/

export async function getRoles(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/roles", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getActiveRoles(
  entityId: string | null | undefined
): Promise<{ name: string; description: string }[]> {
  if (entityId) {
    let response = await axios.get("/api/roles/active", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function postRole(
  role: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/role", role, {
    params: {
      __entityId: role.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function getRole(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  if (id && entityId) {
    let response = await apiAxios.get("/api/role", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
}

export async function getCurrentUserRolePendingTasks(
  entityId: string | null | undefined
): Promise<TaskType[] | []> {
  if (entityId) {
    let response = await apiAxios.get("/api/role/tasks/current/pending", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
  return [];
}

/*******************Users*******************/

export async function getUsers(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/users", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getUsersBasic(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/users/basic", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function postUser(
  user: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/user", user, {
    params: {
      __entityId: user.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function getUser(
  entityId: string | null | undefined,
  id: string | null | undefined
) {
  if (id && entityId) {
    let response = await apiAxios.get("/api/user", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
}

export async function getCurrentUserPendingTasks(
  entityId: string | null | undefined
): Promise<TaskType[] | []> {
  if (entityId) {
    let response = await apiAxios.get("/api/user/tasks/current/pending", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
  return [];
}

export async function getCurrentUserPendingCollabTasks(
  entityId: string | null | undefined
): Promise<TaskType[] | []> {
  if (entityId) {
    let response = await apiAxios.get(
      "/api/user/tasks/current/pending/collab",
      {
        params: {
          entityId,
        },
        headers: {
          Authorization: getAuthHeader(),
        },
      }
    );

    return response.data.values;
  }
  return [];
}

export async function postPasswordReset(
  password: string,
  resetKey: string
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post(
    "/api/password/reset",
    {
      password,
      resetKey,
    },
    {
      headers: {
        Authorization: getAuthHeader(),
      },
    }
  );
  return response.data;
}

export async function adhocRun(
  reconset: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post(
    "/api/reconset/run",
    {},
    {
      params: {
        _entityid: reconset._entityid,
        _reconsetid: reconset._reconsetid,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    }
  );
  return response.data;
}

export async function postUserWalkthrough(): Promise<
  { message: string; type: string } | undefined
> {
  let response = await axios.post(
    "/api/user/walkthrough",
    {},
    {
      headers: {
        Authorization: getAuthHeader(),
      },
    }
  );
  return response.data;
}
/*******************Tasks*******************/

export async function getTasks(entityId: string | null | undefined) {
  if (entityId) {
    let response = await axios.get("/api/tasks", {
      params: {
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function postTask(
  funcDefinition: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/task", funcDefinition, {
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function getTask(
  entityId: string | null | undefined,
  id: string | null | undefined
): Promise<TaskDetailType | undefined> {
  if (id && entityId) {
    let response = await apiAxios.get("/api/task", {
      params: {
        id,
        entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
}

export async function getReconsetTasks(
  entityId: string | null | undefined,
  reconsetId: string | null | undefined
): Promise<TaskType[]> {
  if (entityId && reconsetId) {
    let response = await axios.get("/api/reconset/tasks", {
      params: {
        entityId,
        reconsetId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function getReconsetRunTasks(
  entityId: string | null | undefined,
  reconsetId: string | null | undefined,
  runId: string | null | undefined
): Promise<TaskType[]> {
  if (entityId && reconsetId && runId) {
    let response = await axios.get("/api/reconset/run/tasks", {
      params: {
        entityId,
        reconsetId,
        runId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });
    return response.data.values;
  }
  return [];
}

export async function uploadTaskCommentFile(
  id: string,
  entityId: string,
  formData: FormData
) {
  return await axios.post("/api/task/file/upload", formData, {
    params: {
      entityId: entityId,
      id: id,
    },
    headers: {
      Authorization: getAuthHeader(),
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function postTaskComment(
  id: string,
  entityId: string,
  comment: {
    comment: string;
    attachments: {
      files: {
        uploadedTo: string;
        link: string;
        fileName: string;
      }[];
    };
  }
) {
  return axios.post("/api/task/comment", comment, {
    params: {
      entityId: entityId,
      id: id,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function downloadTaskFile(
  id: string,
  entityId: string,
  fileName: string,
  fileType: string
) {
  const response = await axios
    .get("/api/task/file/download", {
      params: {
        entityId: entityId,
        id: id,
        fileName: fileName,
        fileType: fileType,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
      responseType: "arraybuffer",
    })
    .then((response) => {
      FileDownload(response.data, fileName);
      return {
        status: response.status,
      };
    })
    .catch((error) => {
      return {
        status: 500,
      };
    });

  return response;
}

export async function postTaskCollaborator(
  id: string,
  entityId: string,
  collaborator: {
    userId: string;
    userName: string;
  }
) {
  return axios.post("/api/task/collaborator", collaborator, {
    params: {
      entityId: entityId,
      id: id,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function postTaskAction(
  id: string,
  entityId: string,
  action: any
) {
  return axios.post("/api/task/action", action, {
    params: {
      __entityId: entityId,
      __id: id,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function getCurrentUserPinned(
  entityId: string | null | undefined
) {
  if (entityId) {
    let response = await apiAxios.get("/api/user/pinned", {
      params: {
        __entityId: entityId,
      },
      headers: {
        Authorization: getAuthHeader(),
      },
    });

    return response.data.values;
  }
}

export async function postPin(
  pin: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/user/pin", pin, {
    params: {
      __entityId: pin.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function postUnpin(
  pin: any
): Promise<{ message: string; type: string } | undefined> {
  let response = await axios.post("/api/user/unpin", pin, {
    params: {
      __entityId: pin.entityId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
  return response.data;
}

export async function deleteFromMatchTemp(
  id: string,
  entityId: string,
  recon: string,
  runId: string,
  matchId: string,
  type: string,
  match: { primaryIds?: string[]; secondaryIds?: string[] }
) {
  return await axios.post("/api/reconset/match/temp/delete", match, {
    params: {
      __entityId: entityId,
      __reconsetId: id,
      __recon: recon,
      __runId: runId,
      __matchId: matchId,
      __type: type,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function updateMatchTemp(
  id: string,
  entityId: string,
  recon: string,
  matchId: string,
  runId: string,
  match: any
) {
  return await axios.post("/api/reconset/match/temp/update", match, {
    params: {
      __entityId: entityId,
      __reconsetId: id,
      __recon: recon,
      __runId: runId,
      __matchId: matchId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}

export async function finalizeMatchTemp(
  id: string,
  entityId: string,
  recon: string,
  runId: string,
  matchId: string,
  match: any
) {
  return await axios.post("/api/reconset/match/temp/finalize", match, {
    params: {
      __entityId: entityId,
      __reconsetId: id,
      __recon: recon,
      __runId: runId,
      __matchId: matchId,
    },
    headers: {
      Authorization: getAuthHeader(),
    },
  });
}
