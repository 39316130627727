import React, { useContext } from "react";
import { BuilderContext, useDrawer } from "react-flow-builder";
import { useForm, useFieldArray, useWatch, Control } from "react-hook-form";
import {
  Button,
  Grid,
  Title,
  Text,
  Tabs,
  SegmentedControl,
  Modal,
  ActionIcon,
  Flex,
  Stack,
  Divider,
} from "@mantine/core";
import {
  CTextInput,
  CMultiSelect,
  CSelect,
} from "@supportinitiative/react-hook-form-mantine";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { object, string, number, date, array, setLocale } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { inlineQueryOperators, operators } from "../../ReconsetUtils";
import { getId } from "../../../utilities";
import { useLoaders } from "src/hooks";
import { ReconsetCVMContext } from "src/Reconset/ReconsetCVM";

setLocale({
  mixed: {
    required: "Required",
    notType: "Invalid Value",
  },
});

const inputSchema = object({
  conditions: array().of(
    object({
      column: string().required(),
      value: string().required(),
    })
  ),
});

const derivationSchema = object({
  rules: array().of(
    object({
      rule: string().required(),
      ruleId: string().required(),
    })
  ),
});

const externalSchema = object({
  table: string().required(),
  mappings: array().of(
    object({
      column: string().required(),
      external: string().required(),
    })
  ),
  conditions: array().of(
    object({
      left: string().required(),
      operator: string().required(),
    })
  ),
});

const customSchema = object({
  config: string().required(),
  conditions: array().of(
    object({
      left: string().required(),
      operator: string().required(),
    })
  ),
});

const DataLoadActionSchema = object({
  name: string().required(),
  dataset: string().required().oneOf(["MASTER", "PRIMARY", "SECONDARY"]),
  type: string()
    .required()
    .oneOf([
      "DATASET",
      "FROM_INPUT",
      "DERIVED",
      "CUSTOM",
      "EXTERNAL",
      "EXTERNALLY_MANAGED",
    ]),
  inputs: array().when("type", {
    is: "FROM_INPUT",
    then: inputSchema,
  }),
  derivations: array().when("type", {
    is: "DERIVED",
    then: derivationSchema,
  }),
  external: object().when("type", {
    is: "EXTERNAL",
    then: externalSchema,
  }),
  custom: object().when("type", {
    is: "CUSTOM",
    then: customSchema,
  }),
});

function Inputs({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<any>;
  disabled: boolean;
}) {
  const conditionsArray = useFieldArray({
    control,
    name: `${name}.inputs.conditions`,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text fw={500} fz="sm">
            Conditions
          </Text>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              conditionsArray.append({
                left: "",
                operator: "=",
                right: "",
                leftId: getId(5),
                rightId: getId(5),
              });
            }}
            disabled={disabled || false}
            style={{
              display: disabled || false ? "none" : "",
            }}
          >
            <IconPlus />
          </ActionIcon>
        </div>

        {conditionsArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.inputs.conditions.${index}.column`}
                  control={control}
                  label={index === 0 ? "Column" : ""}
                  disabled={disabled}
                  showErrorText={false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.inputs.conditions.${index}.value`}
                  control={control}
                  label={index === 0 ? "Value" : ""}
                  disabled={disabled}
                  showErrorText={false}
                />
              </Grid.Col>

              <Grid.Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <ActionIcon
                  size="sm"
                  variant="white"
                  color="gray"
                  onClick={() => {
                    conditionsArray.remove(index);
                  }}
                  disabled={disabled}
                  style={{
                    display: disabled ? "none" : "",
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

/* function Derivations({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<any>;
  disabled: boolean;
}) {
  const rulesArray = useFieldArray({
    control,
    name: `${name}.derivations.rules`,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text fw={500} fz="sm">
            Rules
          </Text>
          <ActionIcon
            onClick={() => {
              rulesArray.append({
                rule: "",
                ruleId: getId(5),
              });
            }}
            disabled={disabled || false}
            style={{
              display: disabled || false ? "none" : "",
            }}
          >
            <IconPlus />
          </ActionIcon>
        </div>

        {rulesArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
              <Grid.Col span={8}>
                <CTextInput
                  name={`${name}.derivations.rules.${index}.rule`}
                  control={control}
                  label={index === 0 ? "Rule" : ""}
                  disabled={disabled}
                  showErrorText={false}
                  required
                />
              </Grid.Col>
              <Grid.Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <ActionIcon
                  onClick={() => {
                    rulesArray.remove(index);
                  }}
                  disabled={disabled}
                  style={{
                    display: disabled ? "none" : "",
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}
 */

function Derivation({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<any>;
  disabled: boolean;
}) {
  const mappingsArray = useFieldArray({
    control,
    name: `${name}.derivation.mappings`,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
        <Grid.Col span={12}>
          <CTextInput
            name={`${name}.derivation.rule`}
            control={control}
            label="Rule"
            required
            showErrorText={false}
            disabled={disabled || false}
          />
        </Grid.Col>
      </Grid>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text fw={500} fz="sm">
            Columns
          </Text>
          <ActionIcon
            onClick={() => {
              mappingsArray.append({
                column: "",
                external: "",
              });
            }}
            disabled={disabled || false}
            style={{
              display: disabled || false ? "none" : "",
            }}
          >
            <IconPlus />
          </ActionIcon>
        </div>

        {mappingsArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.custom.mappings.${index}.column`}
                  control={control}
                  disabled={disabled}
                  showErrorText={false}
                  label={index === 0 ? "Operator" : ""}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.custom.mappings.${index}.external`}
                  control={control}
                  disabled={disabled}
                  showErrorText={false}
                  label={index === 0 ? "External" : ""}
                  required
                />
              </Grid.Col>
              <Grid.Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <ActionIcon
                  onClick={() => {
                    mappingsArray.remove(index);
                  }}
                  disabled={disabled}
                  style={{
                    display: disabled ? "none" : "",
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

function External({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<any>;
  disabled: boolean;
}) {
  const mappingsArray = useFieldArray({
    control,
    name: `${name}.external.mappings`,
  });
  const conditionsArray = useFieldArray({
    control,
    name: `${name}.external.conditions`,
  });
  return (
    <Stack>
      <Divider my="xs" label="External" />
      <Grid align="stretch" gutter={"xs"} mb="lg">
        <Grid.Col span={12}>
          <CTextInput
            name={`${name}.external.table`}
            control={control}
            label="Table"
            required
            showErrorText={false}
            disabled={disabled || false}
          />
        </Grid.Col>
      </Grid>

      <Stack mb="lg" gap="xs">
        <Flex>
          <Text fw={500} fz="sm">
            Columns
          </Text>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              mappingsArray.append({
                column: "",
                external: "",
              });
            }}
            disabled={disabled || false}
            style={{
              display: disabled || false ? "none" : "",
            }}
          >
            <IconPlus />
          </ActionIcon>
        </Flex>

        {mappingsArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} mb="xs" key={index}>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.external.mappings.${index}.column`}
                  control={control}
                  disabled={disabled}
                  showErrorText={false}
                  label={index === 0 ? "Column" : ""}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.external.mappings.${index}.external`}
                  control={control}
                  disabled={disabled}
                  showErrorText={false}
                  label={index === 0 ? "External" : ""}
                  required
                />
              </Grid.Col>
              <Grid.Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <ActionIcon
                  size="sm"
                  variant="white"
                  color="gray"
                  onClick={() => {
                    mappingsArray.remove(index);
                  }}
                  disabled={disabled}
                  style={{
                    display: disabled ? "none" : "",
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </Stack>

      <Stack mb="lg" gap="xs">
        <Flex>
          <Text fw={500} fz="sm">
            Conditions
          </Text>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              conditionsArray.append({
                left: "",
                operator: "=",
                right: "",
              });
            }}
            disabled={disabled || false}
            style={{
              display: disabled || false ? "none" : "",
            }}
          >
            <IconPlus />
          </ActionIcon>
        </Flex>

        {conditionsArray.fields.map((field, index) => {
          return (
            <Grid
              align="stretch"
              gutter={"xs"}
              style={{ marginBottom: "5px" }}
              key={index}
            >
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.external.conditions.${index}.left`}
                  control={control}
                  label={index === 0 ? "Left" : ""}
                  disabled={disabled}
                  showErrorText={false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CSelect
                  name={`${name}.external.conditions.${index}.operator`}
                  control={control}
                  searchable
                  data={[...operators, ...inlineQueryOperators]}
                  label={index === 0 ? "Operator" : ""}
                  disabled={disabled}
                  showErrorText={false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.external.conditions.${index}.right`}
                  control={control}
                  label={index === 0 ? "Right" : ""}
                  disabled={disabled}
                  showErrorText={false}
                />
              </Grid.Col>

              <Grid.Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <ActionIcon
                  size="sm"
                  variant="white"
                  color="gray"
                  onClick={() => {
                    conditionsArray.remove(index);
                  }}
                  disabled={disabled}
                  style={{
                    display: disabled ? "none" : "",
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </Stack>
    </Stack>
  );
}

function Custom({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<any>;
  disabled: boolean;
}) {
  const conditionsArray = useFieldArray({
    control,
    name: `${name}.custom.conditions`,
  });
  return (
    <Stack>
      <Divider my="xs" label="Custom" />
      <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
        <Grid.Col span={12}>
          <CTextInput
            name={`${name}.custom.config`}
            control={control}
            label="Config"
            required
            showErrorText={false}
            disabled={disabled || false}
          />
        </Grid.Col>
      </Grid>

      <Stack mb="lg" gap="xs">
        <Flex>
          <Text fw={500} fz="sm">
            Conditions
          </Text>
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              conditionsArray.append({
                left: "",
                operator: "=",
                right: "",
              });
            }}
            disabled={disabled || false}
            style={{
              display: disabled || false ? "none" : "",
            }}
          >
            <IconPlus />
          </ActionIcon>
        </Flex>

        {conditionsArray.fields.map((field, index) => {
          return (
            <Grid
              align="stretch"
              gutter={"xs"}
              style={{ marginBottom: "5px" }}
              key={index}
            >
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.custom.conditions.${index}.left`}
                  control={control}
                  label={index === 0 ? "Left" : ""}
                  disabled={disabled}
                  showErrorText={false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={3}>
                <CSelect
                  name={`${name}.custom.conditions.${index}.operator`}
                  control={control}
                  searchable
                  data={operators}
                  label={index === 0 ? "Operator" : ""}
                  disabled={disabled}
                  showErrorText={false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <CTextInput
                  name={`${name}.custom.conditions.${index}.right`}
                  control={control}
                  label={index === 0 ? "Right" : ""}
                  disabled={disabled}
                  showErrorText={false}
                />
              </Grid.Col>

              <Grid.Col
                span={1}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <ActionIcon
                  size="sm"
                  variant="white"
                  color="gray"
                  onClick={() => {
                    conditionsArray.remove(index);
                  }}
                  disabled={disabled}
                  style={{
                    display: disabled ? "none" : "",
                  }}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </Stack>
    </Stack>
  );
}

const DataLoad: React.FC = (props) => {
  const { selectedNode: node } = useContext(BuilderContext);

  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    defaultValues: node?.data || {
      name: "",
      dataset: "MASTER",
      type: "DATASET",
    },
    criteriaMode: "all",
    resolver: yupResolver(DataLoadActionSchema),
  });

  const dataLoadType = useWatch({
    control,
    name: `type`,
  });

  const { data: loaders } = useLoaders(reconsetFormState.entityId);

  const onSubmitForm = async (data: any) => {
    try {
      console.log("onSubmit", data);
      save?.(data);
    } catch (error: any) {
      console.log("onSubmit error", error);
    }
  };

  return (
    <Modal
      centered
      size="xl"
      opened={true}
      onClose={cancel}
      title={<Title order={4}>Data Load</Title>}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          // handleSubmit(onSubmitForm)();
          event.stopPropagation();
          console.log("inside child");
          // cancel();
        }}
        noValidate
      >
        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={12}>
            <CTextInput
              name="name"
              control={control}
              label="Name"
              required
              showErrorText={false}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={6}>
            <CSelect
              name={`dataset`}
              control={control}
              label={"Dataset"}
              data={[
                {
                  value: "MASTER",
                  label: "Master",
                },
              ]}
              showErrorText={false}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <CSelect
              name={`type`}
              control={control}
              label={"Type"}
              data={[
                {
                  value: "DATASET",
                  label: "Dataset",
                },
                /*                 {
                  value: "FROM_INPUT",
                  label: "From Input",
                },
                {
                  value: "DERIVED",
                  label: "Derived",
                }, */
                {
                  value: "EXTERNAL",
                  label: "External",
                },
                {
                  value: "CUSTOM",
                  label: "Custom",
                },
                /* {
                  value: "EXTERNALLY_MANAGED",
                  label: "Externally Managed",
                }, */
              ]}
              showErrorText={false}
              required
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={12}>
            <CSelect
              name="loaderId"
              label="Loader"
              placeholder="Select Loader"
              control={control}
              data={
                loaders && loaders.length > 0
                  ? loaders.map((loader: any) => {
                      return { label: loader.name, value: loader.id };
                    })
                  : []
              }
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
        {dataLoadType === "FROM_INPUT" ? (
          <Inputs name={""} control={control} disabled={node?.disabled} />
        ) : dataLoadType === "EXTERNAL" ? (
          <External name={""} control={control} disabled={node?.disabled} />
        ) : dataLoadType === "CUSTOM" ? (
          <Custom name={""} control={control} disabled={node?.disabled} />
        ) : dataLoadType === "DERIVED" ? (
          <Derivation name={""} control={control} disabled={node?.disabled} />
        ) : (
          <></>
        )}
        <Grid align="stretch" gutter={"xs"} mt="xs">
          <Grid.Col span={2}>
            <Button
              color="teal"
              variant="filled"
              fullWidth
              type="submit"
              onClick={
                node?.disabled
                  ? () => {
                      console.log("only cancel");
                      cancel();
                    }
                  : async (event) => {
                      event.preventDefault();
                      const values = getValues();
                      // console.log("values", values);
                      clearErrors();
                      try {
                        await DataLoadActionSchema.validate(values, {
                          abortEarly: false,
                        });

                        save?.(values);
                        //cancel();
                      } catch (error) {
                        console.log("errors", error);
                        console.log("errors", JSON.stringify(error));
                        console.log("error inner", JSON.stringify(error));
                        (error as any).inner.forEach((err: any) => {
                          setError(err.path, {
                            type: "custom",
                            message: err.message,
                          });
                        });
                      }
                    }
              }
            >
              Ok
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default DataLoad;
