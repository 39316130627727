import * as React from "react";
import { useForm, useFieldArray, useWatch, Control } from "react-hook-form";
import {
  Button,
  Grid,
  Title,
  ActionIcon,
  Group,
  ScrollArea,
} from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  IconChevronLeft,
  IconChevronRight,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";

import { DatasetSchema, DatasetType } from "@supportinitiative/common";

import {
  CSelect,
  CTextInput,
  CCheckbox,
  CMultiSelect,
} from "@supportinitiative/react-hook-form-mantine";

import { styles } from "../styles";
import { postDataset } from "../api";
import { AuditType, IDispatchAction } from "../types";
import {
  getColumnsLOV,
  getActionOptions,
  columnTypes,
  // DatasetSchema,
} from "./DatasetUtils";
import { UserContext } from "../misc/UserContext";
import { useDataset } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";
import { getId } from "../utilities";

type actionTypes =
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR";

function SetColumns({ control }: { control: Control<DatasetType> }) {
  const columns = useWatch({
    control,
    name: "definition.columns",
  });
  const { state: datasetCVMState } = React.useContext(DatasetCVMContext);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <Title
          mb="xs"
          mt="xs"
          order={5}
          style={{
            padding: "0px 5px",
            backgroundColor: "#16A596",
            color: "#fff",
          }}
        >
          Set Columns
        </Title>
      </div>
      <Grid align="stretch" gutter={"xs"} mb="lg">
        <Grid.Col span={6}>
          <CMultiSelect
            name={"definition.setColumns"}
            control={control}
            searchable
            data={getColumnsLOV(columns)}
            disabled={datasetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

function PrimaryKey({ control }: { control: Control<DatasetType> }) {
  const columns = useWatch({
    control,
    name: "definition.columns",
  });
  const { state: datasetCVMState } = React.useContext(DatasetCVMContext);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <Title
          mb="xs"
          mt="xs"
          order={5}
          style={{
            padding: "0px 5px",
            backgroundColor: "#16A596",
            color: "#fff",
          }}
        >
          Primary Key
        </Title>
      </div>
      <Grid align="stretch" gutter={"xs"} mb="lg">
        <Grid.Col span={6}>
          <CMultiSelect
            name={"definition.primaryKey"}
            control={control}
            searchable
            data={getColumnsLOV(columns)}
            disabled={datasetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

function ColumnToTrackDataLoad({ control }: { control: Control<DatasetType> }) {
  const columns = useWatch({
    control,
    name: "definition.columns",
  });
  const { state: datasetCVMState } = React.useContext(DatasetCVMContext);

  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <Title
          mb="xs"
          mt="xs"
          order={5}
          style={{
            padding: "0px 5px",
            backgroundColor: "#16A596",
            color: "#fff",
          }}
        >
          Column to Track
        </Title>
      </div>
      <Grid align="stretch" gutter={"xs"} mb="lg">
        <Grid.Col span={6}>
          <CSelect
            name={`definition.columnToTrackDataLoad`}
            control={control}
            clearable
            searchable
            data={getColumnsLOV(columns)}
            disabled={datasetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>
    </>
  );
}

function Uniques({ control }: { control: Control<DatasetType> }) {
  const columns = useWatch({
    control,
    name: "definition.columns",
  });

  const uniquesArray = useFieldArray({
    control,
    name: "definition.uniques",
  });
  const { state: datasetCVMState } = React.useContext(DatasetCVMContext);

  return (
    <>
      <Group mb="xs" mt="lg" style={{ alignItems: "center" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Title
            mb="xs"
            mt="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            Uniques
          </Title>
        </div>
        <ActionIcon
          variant="subtle"
          color="gray"
          onClick={() => {
            uniquesArray.append({
              unique: [],
            });
          }}
          style={{
            display:
              datasetCVMState.currentAction.toUpperCase() === "VIEW"
                ? "none"
                : "",
          }}
          disabled={datasetCVMState.actionOptions.disableAll}
        >
          <IconPlus />
        </ActionIcon>
      </Group>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        {uniquesArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} mb="xs" key={field.id}>
              <Grid.Col span={6}>
                <CMultiSelect
                  name={`definition.uniques.${index}.unique`}
                  control={control}
                  searchable
                  data={getColumnsLOV(columns)}
                  disabled={datasetCVMState.actionOptions.disableAll}
                />
              </Grid.Col>

              <Grid.Col
                span={1}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  onClick={() => {
                    uniquesArray.remove(index);
                  }}
                  style={{
                    display:
                      datasetCVMState.currentAction.toUpperCase() === "VIEW"
                        ? "none"
                        : "",
                  }}
                  disabled={datasetCVMState.actionOptions.disableAll}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </>
  );
}

function Indexes({ control }: { control: Control<DatasetType> }) {
  const columns = useWatch({
    control,
    name: "definition.columns",
  });

  const indexesArray = useFieldArray({
    control,
    name: "definition.indexes",
  });
  const { state: datasetCVMState } = React.useContext(DatasetCVMContext);

  return (
    <>
      <Group mb="xs" mt="lg" style={{ alignItems: "center" }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <Title
            mb="xs"
            mt="xs"
            order={5}
            style={{
              padding: "0px 5px",
              backgroundColor: "#16A596",
              color: "#fff",
            }}
          >
            Indexes
          </Title>
        </div>
        <ActionIcon
          variant="subtle"
          color="gray"
          onClick={() => {
            indexesArray.append({
              index: [],
            });
          }}
          style={{
            display:
              datasetCVMState.currentAction.toUpperCase() === "VIEW"
                ? "none"
                : "",
          }}
          disabled={datasetCVMState.actionOptions.disableAll}
        >
          <IconPlus />
        </ActionIcon>
      </Group>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
        }}
      >
        {indexesArray.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} mb="xs" key={field.id}>
              <Grid.Col span={6}>
                <CMultiSelect
                  name={`definition.indexes.${index}.index`}
                  control={control}
                  searchable
                  data={getColumnsLOV(columns)}
                  disabled={datasetCVMState.actionOptions.disableAll}
                />
              </Grid.Col>

              <Grid.Col
                span={1}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  onClick={() => {
                    indexesArray.remove(index);
                  }}
                  style={{
                    display:
                      datasetCVMState.currentAction.toUpperCase() === "VIEW"
                        ? "none"
                        : "",
                  }}
                  disabled={datasetCVMState.actionOptions.disableAll}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </>
  );
}

export const DatasetCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  actionOptions: {},
  setValue: () => {},
  submitting: false,
  error: "",
  successMessage: "",
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    default:
      return state;
  }
}

export default function DatasetCVM() {
  // let [dataset, setDataset] = React.useState<DatasetType>();

  let [audit, setAudit] = React.useState<AuditType | undefined>();

  // let [currentAction, setCurrentAction] = React.useState("new");
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const { data: dataset } = useDataset(entityId, id);
  // let [newDataset, setNewDataset] = React.useState<DatasetType | undefined>();
  const [datasetFormState, datasetFormDispatch] = React.useReducer(reducer, {
    ...initialState,
    actionOptions: getActionOptions("new", dataset, dataset, applicationState),
  });
  // console.log("dataset in component", dataset);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<DatasetType>({
    defaultValues: {
      name: "",
      displayName: "",
      description: "",
      definition: {
        primaryKey: [],
        columns: [],
        indexes: [],
        uniques: [],
        columnToTrackDataLoad: "",
        allowMerge: "NO",
        maintainHistory: "NO",
      },
    },
    criteriaMode: "all",
    resolver: yupResolver(DatasetSchema),
  });

  const columnsFieldArray = useFieldArray({
    control,
    name: "definition.columns",
    keyName: "key",
  });
  /* 
  const columns = useWatch({
    control,
    name: "definition.columns",
  }); */

  // const [columns, setColumns] = React.useState<DatasetType["definition"]["columns"]>([]);

  const [startColumn, setStartColumn] = React.useState(0);
  const [endColumn, setEndColumn] = React.useState(20);
  const onOk = () => {
    navigate(`/app/datasets?entityId=${entityId}`);
  };

  React.useEffect(() => {
    if (dataset) {
      datasetFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      datasetFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions("view", dataset, dataset, applicationState),
      });

      // setColumns(dataset.definition.columns || []);

      setAudit({
        status: dataset.status,
        authStatus: dataset.authStatus,
        usableStatus: dataset.usableStatus,
        makerId: dataset.makerId,
        makerDate: dataset.makerDate,
        modificationNo: dataset.modificationNo,
        checkerId: dataset.checkerId,
        checkerDate: dataset.checkerDate,
      });

      reset(dataset);
    }
  }, [dataset]);

  const onSubmit = async (data: any) => {
    try {
      datasetFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      datasetFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: datasetFormState.currentAction,
        },
      };
      await postDataset(payload);
      // setError("Form", "Unable to Submit");
      datasetFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["dataset", entityId, id]);
      queryClient.invalidateQueries(["datasets", entityId]);
      datasetFormDispatch({
        type: "SET-SUCCESS",
        value: "Dataset Saved Successfully",
      });
    } catch (error: any) {
      // console.log("post catch", error);
      // console.log("post catch", error?.response?.data?.message);
      datasetFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      datasetFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save Dataset",
      });
    }
  };

  return (
    <DatasetCVMContext.Provider
      value={{ state: datasetFormState, dispatch: datasetFormDispatch }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={styles.main} noValidate>
        <div style={styles.header} id="header">
          <Title order={2}>Dataset</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={2}>
              <CTextInput
                name="name"
                control={control}
                label="Name"
                aria-label="Name"
                required
                showErrorText={false}
                disabled={datasetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CTextInput
                name="displayName"
                control={control}
                label="Display Name"
                aria-label="Display Name"
                showErrorText={false}
                disabled={datasetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CTextInput
                name="description"
                control={control}
                label="Description"
                aria-label="Description"
                required
                showErrorText={false}
                disabled={datasetFormState.actionOptions.disableAll}
              />
            </Grid.Col>

            <Grid.Col span={2}>
              <CSelect
                name="definition.type"
                control={control}
                label="Type"
                aria-label="Type"
                data={[
                  { label: "Pure Incremental", value: "PURE_INCREMENTAL" },
                  { label: "Set Incremental", value: "SET_INCREMENTAL" },
                  { label: "Set Full", value: "SET_FULL" },
                  { label: "Full", value: "FULL" },
                ]}
                required
                showErrorText={false}
                disabled={datasetFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Group mb="xs" mt="lg" gap={"xs"} style={{ alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Title
                  mb="xs"
                  mt="xs"
                  order={5}
                  style={{
                    padding: "0px 5px",
                    backgroundColor: "#16A596",
                    color: "#fff",
                  }}
                >
                  Columns
                </Title>
              </div>

              <ActionIcon
                variant="light"
                size="lg"
                onClick={() => {
                  if (startColumn >= 20) {
                    setStartColumn(startColumn - 20);
                    setEndColumn(endColumn - 20);
                  }
                }}
                disabled={startColumn < 20}
              >
                <IconChevronLeft
                  style={{ width: "70%", height: "70%" }}
                  stroke={2}
                />
              </ActionIcon>
              <ActionIcon
                variant="light"
                size="lg"
                onClick={() => {
                  if (endColumn < columnsFieldArray.fields.length) {
                    setStartColumn(startColumn + 20);
                    setEndColumn(endColumn + 20);
                  }
                }}
                disabled={endColumn >= columnsFieldArray.fields.length}
              >
                <IconChevronRight
                  style={{ width: "70%", height: "70%" }}
                  stroke={2}
                />
              </ActionIcon>
              <ActionIcon
                variant="light"
                size="lg"
                onClick={() => {
                  columnsFieldArray.append({
                    name: "",
                    type: "TEXT",
                    genFunction: "",
                    label: "",
                    notNull: "NO",
                    id: getId(),
                  });
                }}
                style={{
                  display:
                    datasetFormState.currentAction.toUpperCase() === "VIEW"
                      ? "none"
                      : "",
                }}
                disabled={datasetFormState.actionOptions.disableAll}
              >
                <IconPlus style={{ width: "70%", height: "70%" }} stroke={2} />
              </ActionIcon>
            </Group>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              <ScrollArea
                style={{
                  height:
                    datasetFormState.currentAction.toUpperCase() === "EDIT" ||
                    datasetFormState.currentAction.toUpperCase() === "NEW"
                      ? Math.min(
                          Math.max(columnsFieldArray.fields.length || 3, 3) *
                            100,
                          250
                        )
                      : Math.min(
                          (columnsFieldArray.fields.length || 0) * 100,
                          250
                        ),
                }}
                type="auto"
                offsetScrollbars
                scrollbarSize={16}
              >
                {columnsFieldArray.fields
                  .slice(startColumn, endColumn)
                  .map((field, index) => {
                    return (
                      <Grid
                        columns={15}
                        align="stretch"
                        gutter={"xs"}
                        mb="xs"
                        key={field.key}
                      >
                        <Grid.Col span={2}>
                          <CTextInput
                            name={`definition.columns.${startColumn + index}.name`}
                            control={control}
                            showErrorText={false}
                            label={index === 0 ? "Name" : ""}
                            required
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <CSelect
                            name={`definition.columns.${startColumn + index}.type`}
                            control={control}
                            label={index === 0 ? "Type" : ""}
                            data={columnTypes}
                            showErrorText={false}
                            required
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <CTextInput
                            name={`definition.columns.${startColumn + index}.label`}
                            control={control}
                            showErrorText={false}
                            label={index === 0 ? "Label" : ""}
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>
                        <Grid.Col span={2}>
                          <CTextInput
                            name={`definition.columns.${startColumn + index}.genFunction`}
                            control={control}
                            showErrorText={false}
                            label={index === 0 ? "Generate Function" : ""}
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <CTextInput
                            name={`definition.columns.${startColumn + index}.default`}
                            control={control}
                            showErrorText={false}
                            label={index === 0 ? "Default" : ""}
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <CTextInput
                            name={`definition.columns.${startColumn + index}.format`}
                            control={control}
                            showErrorText={false}
                            label={index === 0 ? "Format" : ""}
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <CTextInput
                            name={`definition.columns.${startColumn + index}.id`}
                            control={control}
                            label={index === 0 ? "Id" : ""}
                            disabled={true}
                          />
                        </Grid.Col>
                        <Grid.Col
                          span={2}
                          style={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <CCheckbox
                            name={`definition.columns.${startColumn + index}.notNull`}
                            control={control}
                            label="Not Null"
                            trueValue="YES"
                            falseValue="NO"
                            disabled={datasetFormState.actionOptions.disableAll}
                          />
                        </Grid.Col>

                        <Grid.Col
                          span={1}
                          style={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <ActionIcon
                            variant="subtle"
                            color="gray"
                            onClick={() => {
                              columnsFieldArray.remove(startColumn + index);
                            }}
                            style={{
                              display:
                                datasetFormState.currentAction.toUpperCase() ===
                                "VIEW"
                                  ? "none"
                                  : "",
                            }}
                            disabled={datasetFormState.actionOptions.disableAll}
                          >
                            <IconTrash />
                          </ActionIcon>
                        </Grid.Col>
                      </Grid>
                    );
                  })}
              </ScrollArea>
            </div>
          </div>

          <SetColumns control={control} />

          <PrimaryKey control={control} />

          <Uniques control={control} />

          <Indexes control={control} />

          <ColumnToTrackDataLoad control={control} />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <Title
                mb="xs"
                mt="xs"
                order={5}
                style={{
                  padding: "0px 5px",
                  backgroundColor: "#16A596",
                  color: "#fff",
                }}
              >
                Preferences
              </Title>
            </div>
            <Grid align="stretch" gutter={"xs"} mb="xs">
              <Grid.Col span={2}>
                <CCheckbox
                  name={"definition.allowMerge"}
                  control={control}
                  label="Allow merge"
                  trueValue="YES"
                  falseValue="NO"
                  disabled={datasetFormState.actionOptions.disableAll}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <CCheckbox
                  name={"definition.maintainHistory"}
                  control={control}
                  label="Maintain history"
                  trueValue="YES"
                  falseValue="NO"
                  disabled={datasetFormState.actionOptions.disableAll}
                />
              </Grid.Col>
            </Grid>
          </div>
        </div>

        {(Object.keys(errors).length > 0 || datasetFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={datasetFormState.error} />
            </div>
          </div>
        )}

        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(datasetFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action === "save") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          fullWidth
                          type="submit"
                          color="teal"
                          aria-label="Save"
                          loading={datasetFormState.submitting}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "cancel") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/datasets?entityId=${entityId}`}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "back") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/datasets?entityId=${entityId}`}
                          aria-label="Back"
                        >
                          Back
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "edit") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Edit"
                          fullWidth
                          onClick={() => {
                            datasetFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });
                            datasetFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                dataset,
                                dataset,
                                applicationState
                              ),
                            });

                            setAudit({
                              status: dataset.status,
                              authStatus: "UNAUTH",
                              usableStatus: dataset.usableStatus,
                              makerId: applicationState.user.userId,
                              makerDate: new Date(),
                              modificationNo:
                                dataset.authStatus === "UNAUTH"
                                  ? dataset.modificationNo
                                  : (dataset.modificationNo || 0) + 1,
                              checkerId: "",
                              checkerDate: undefined,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "auth") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Auth"
                          fullWidth
                          onClick={async () => {
                            datasetFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "AUTH",
                            });

                            try {
                              let payload = {
                                ...dataset,
                                entityId,
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postDataset(payload);
                              // setError("Form", "Unable to Submit");
                              datasetFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              datasetFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Dataset Authorized Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              datasetFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Authorize Dataset",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "delete") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Delete"
                          fullWidth
                          onClick={async () => {
                            datasetFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "DELETE",
                            });

                            try {
                              let payload = {
                                ...dataset,
                                entityId,
                                metaData: {
                                  action: "delete",
                                },
                              };
                              await postDataset(payload);
                              // setError("Form", "Unable to Submit");
                              datasetFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              datasetFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Dataset Deleted Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              datasetFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Delete Dataset",
                              });
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {datasetFormState.successMessage && (
          <Success message={datasetFormState.successMessage} onOk={onOk} />
        )}
      </form>
    </DatasetCVMContext.Provider>
  );
}
