import { Grid, TextInput } from "@mantine/core";
import dayjs from "dayjs";

import { styles } from "../../styles";
import { AuditType } from "../../types";

function Audit({ audit }: { audit: AuditType | undefined }) {
  return (
    <div style={styles.audit} id="audit">
      <Grid align="stretch" gutter={"xs"} columns={24}>
        <Grid.Col span={3}>
          <TextInput label="Status" disabled value={audit?.status ?? "-"} />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput label="Maker ID" disabled value={audit?.makerId ?? "-"} />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Maker Date"
            disabled
            value={
              audit?.makerDate
                ? dayjs(audit.makerDate).format("YYYY-MM-DD")
                : "-"
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Modification No"
            aria-label="Modification No"
            disabled
            value={audit?.modificationNo ?? "-"}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Checker ID"
            disabled
            value={audit?.checkerId ?? "-"}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Checker Date"
            disabled
            value={
              audit?.checkerDate
                ? dayjs(audit.checkerDate).format("YYYY-MM-DD")
                : "-"
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Auth Status"
            disabled
            value={audit?.authStatus ?? "-"}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <TextInput
            label="Usable Status"
            disabled
            value={audit?.usableStatus ?? "-"}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

export default Audit;
