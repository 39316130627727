import { Grid, Button, Select } from "@mantine/core";

import Loading from "../../ui/Loading";
import { IReconsetState } from "../../types";
import { ReconsetBasicType } from "@supportinitiative/common";
import { useReconsetQueryColumnsListOfValues } from "../../hooks";

function getValue(
  queryColumnsValue: {
    name: string;
    value: string | Date | null | undefined;
  }[],
  name: string
) {
  let columnValue = queryColumnsValue.find((column) => column.name === name);
  return columnValue?.value;
}

function QueryColumn({
  entityId,
  id,
  reconsetState,
  queryColumns,
  name,
  label,
  setValue,
}: {
  entityId: string | null | undefined;
  id: string | null | undefined;
  reconsetState: IReconsetState;
  queryColumns: ReconsetBasicType["definition"]["queryColumns"];
  name: string;
  label?: string;
  setValue: (name: string, value: string | null | undefined) => void;
}) {
  // Get current column index
  let columnIndex = queryColumns.findIndex(
    (queryColumn) => queryColumn.name === name
  );

  const { data: lovData } = useReconsetQueryColumnsListOfValues(
    entityId,
    id,
    name,
    // The params passed to 1st column should be [] and for 2nd - [1st column] and for 3rd [1st, 2nd] and so on
    (reconsetState.queryColumnsValue || []).slice(0, columnIndex)
  );

  return (
    <Select
      key={name}
      placeholder={label || name}
      searchable
      clearable
      data={lovData ? lovData : []}
      value={getValue(reconsetState.queryColumnsValue, name) as string}
      onChange={(value) => {
        // Set column value
        setValue(name, value);

        // Get current column index
        let columnIndex = queryColumns.findIndex(
          (queryColumn) => queryColumn.name === name
        );
        if (columnIndex !== queryColumns.length - 1) {
          // Identify columns after the column for which the value is set.
          // This is to reset their values and fetch LOV for those columnss
          const nextColumns = queryColumns.slice(columnIndex + 1);

          // Reset all next column values.
          // Basically if 1st column value is reset, Change the 2nd, 3rd, ... column values
          nextColumns.forEach((nextColumn) => {
            // Rest only when value is present. Else do not reset. Should be ok for 0 value too as all values are treated as string
            if (getValue(reconsetState.queryColumnsValue, nextColumn.name)) {
              setValue(nextColumn.name, "");
            }
          });
        }
      }}
    />
  );
}

export default function QueryColumns({
  entityId,
  id,
  reconsetState,
  queryColumns,
  setValue,
}: {
  entityId: string | null | undefined;
  id: string | null | undefined;
  reconsetState: IReconsetState;
  queryColumns: ReconsetBasicType["definition"]["queryColumns"];
  setValue: (name: string, value: string | null | undefined) => void;
}) {
  return (
    <Grid
      align="flex-start"
      gutter={"xs"}
      mt="xs"
      style={{ display: "flex", alignItems: "flex-end" }}
    >
      {queryColumns && queryColumns.length === 0 ? (
        <Grid.Col span={3}>
          <Loading />
        </Grid.Col>
      ) : (
        <>
          {(queryColumns || []).map((column) => {
            return (
              <Grid.Col span={3} key={column.name}>
                <QueryColumn
                  entityId={entityId}
                  id={id}
                  queryColumns={queryColumns}
                  reconsetState={reconsetState}
                  name={column.name}
                  label={column.label}
                  setValue={setValue}
                />
              </Grid.Col>
            );
          })}
        </>
      )}
    </Grid>
  );
}
