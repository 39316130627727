import { Timeline, Text, Badge, Divider, Alert, Flex } from "@mantine/core";
import {
  IconAlertCircle,
  IconAnchor,
  IconDatabase,
  IconEdit,
  IconFileUpload,
  IconFilter,
  IconFingerprint,
  IconFlag,
  IconNotification,
  IconRun,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";

function getTypeIcon(type: string) {
  if (type.toLowerCase() === "start") {
    return <IconRun style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "dataload") {
    return <IconFileUpload style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "derive-data") {
    return <IconDatabase style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "end") {
    return <IconFlag style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "anchor-match") {
    return <IconAnchor style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "filter") {
    return <IconFilter style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "notification") {
    return <IconNotification style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "status-update") {
    return <IconEdit style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "remediation") {
    return <IconFingerprint style={{ width: "70%", height: "70%" }} />;
  }
}

function getColor(type: string) {
  if (type.toLowerCase() === "error") {
    return "pink";
  } else if (type.toLowerCase() === "warning") {
    return "yellow";
  } else {
    return "gray";
  }
}

export default function Remediations({
  remediations,
}: {
  remediations: {
    id: string;
    runId: string;
    runSeq: string;
    recon: string;
    reconName: string;
    action: string;
    actionName: string;
    type: string;
    subject: string;
    body: string;
    details: any;
    status: string;
    taskId: string;
  }[];
}) {
  if (remediations && remediations.length > 0) {
    return (
      <>
        {remediations.map((remediation, index) => {
          return (
            <Alert
              key={remediation.id}
              variant="light"
              icon={<IconAlertCircle size={16} />}
              title={remediation.subject}
              color={getColor("")}
            >
              <Flex gap="xs" align={"center"}>
                <Link
                  to={`/app/tasks/view?id=${remediation.taskId}&entityId=wV9zzW8jq5`}
                  //style={styles.badgeLink}
                >
                  {remediation.taskId}
                </Link>
                <Divider orientation="vertical" />
                <Badge radius="xs" variant="light">
                  {remediation.status}
                </Badge>
              </Flex>
            </Alert>
          );
        })}
      </>
    );
  } else {
    return <Divider label="No Remediations" labelPosition="left" />;
  }
}
