import * as React from "react";
import {
  Grid,
  Badge,
  Table,
  Select,
  Divider,
  useMantineTheme,
} from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";

import {
  getConfirmationStatus,
  getSystemStatus,
  getColor,
} from "../ReconsetUtils";
import { styles } from "../../styles";
import { ReconsetBasicType } from "@supportinitiative/common";

// TODO: Insert empty stats when no data available
// TODO: Separate secondary dataset stat insert

function getStats(
  type: any,
  reconId: string,
  noOfDatasets: string | null | undefined,
  userStatus: string[],
  stats: any
) {
  let recon,
    reconStats: {
      label: string;
      value: string;
      stat: number;
      stat2?: number | string;
    }[] = [];
  if (stats && stats.length > 0) {
    recon = stats.find((stat: any) => {
      return stat.recon === reconId;
    });
  }

  if (recon?.stats) {
    if (type.toLowerCase() === "system") {
      let systemStatus = [
        ...getSystemStatus("ALL"),
        { label: "__NONE", value: "__NONE" },
      ];
      const primarySystemStats = recon.stats.primaryDataset.systemStatus;
      let secondarySystemStats: any;
      if (noOfDatasets === "TWO") {
        if (recon.stats.secondaryDataset) {
          secondarySystemStats = recon.stats.secondaryDataset.systemStatus;
        }
      }

      systemStatus.forEach((status) => {
        const primarySystemStat = primarySystemStats.find(
          (stat: any) =>
            stat.status.toUpperCase() === status.value.toUpperCase()
        );

        let stat: {
          label: string;
          value: string;
          stat: number;
          stat2?: number | string;
        } = {
          label: status.label,
          value: status.value,
          stat: primarySystemStat?.count || 0,
          stat2: 0,
        };

        if (noOfDatasets === "TWO") {
          if (secondarySystemStats) {
            const secondarySystemStat = secondarySystemStats.find(
              (stat: any) =>
                stat.status.toUpperCase() === status.value.toUpperCase()
            );

            stat.stat2 = secondarySystemStat?.count || 0;
          } else {
            stat.stat2 = "-";
          }
        }

        reconStats.push(stat);
      });
    } else if (type.toLowerCase() === "user") {
      const primaryUserStats = recon.stats.primaryDataset.userStatus;
      let secondaryUserStats: any;
      if (noOfDatasets === "TWO") {
        if (recon.stats.secondaryDataset) {
          secondaryUserStats = recon.stats.secondaryDataset.userStatus;
        }
      }

      [...userStatus, "__NONE"].forEach((status) => {
        const primaryUserStat = primaryUserStats.find(
          (stat: any) => stat.status.toUpperCase() === status.toUpperCase()
        );

        let stat: {
          label: string;
          value: string;
          stat: number;
          stat2?: number | string;
        } = {
          label: status,
          value: status.toUpperCase(),
          stat: primaryUserStat?.count || 0,
          stat2: 0,
        };
        if (noOfDatasets === "TWO") {
          if (secondaryUserStats) {
            const secondaryUserStat = secondaryUserStats.find(
              (stat: any) => stat.status.toUpperCase() === status.toUpperCase()
            );

            stat.stat2 = secondaryUserStat?.count || 0;
          } else {
            stat.stat2 = "-";
          }
        }
        reconStats.push(stat);
      });
    } else if (type.toLowerCase() === "confirmation") {
      let confirmationStatus = [
        ...getConfirmationStatus(),
        { label: "__NONE", value: "__NONE" },
      ];
      const primaryConfirmationStats =
        recon.stats.primaryDataset.confirmationStatus;

      let secondaryConfirmationStats: any;
      if (noOfDatasets === "TWO") {
        if (recon.stats.secondaryDataset) {
          secondaryConfirmationStats =
            recon.stats.secondaryDataset.confirmationStatus;
        }
      }

      confirmationStatus.forEach((status) => {
        const primaryConfirmationStat = primaryConfirmationStats.find(
          (stat: any) =>
            stat.status.toUpperCase() === status.value.toUpperCase()
        );

        let stat: {
          label: string;
          value: string;
          stat: number;
          stat2?: number | string;
        } = {
          label: status.label,
          value: status.value,
          stat: primaryConfirmationStat?.count || 0,
          stat2: 0,
        };

        if (noOfDatasets === "TWO") {
          if (secondaryConfirmationStats) {
            const secondaryConfirmationStat = secondaryConfirmationStats.find(
              (stat: any) =>
                stat.status.toUpperCase() === status.value.toUpperCase()
            );

            stat.stat2 = secondaryConfirmationStat?.count || 0;
          } else {
            stat.stat2 = "-";
          }
        }

        reconStats.push(stat);
      });
    }
  }

  return reconStats.filter((stat) => stat.stat > 0 || (stat?.stat2 ?? 0 > 0));
}

export default function Stats({
  reconset,
  runId,
  runSeq,
  stats,
}: {
  reconset: ReconsetBasicType;
  runId: string;
  runSeq: number;
  stats: any;
}) {
  const theme = useMantineTheme();

  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");

  const firstRecon = reconset.definition.recons[0];

  const [recon, setRecon] = React.useState<any>(firstRecon);
  const [recons, setRecons] = React.useState<any>([]);

  React.useEffect(() => {
    if (reconset) {
      const reconsLov = reconset.definition.recons.map((recon) => {
        return {
          label: recon.name,
          value: recon.id,
        };
      });

      setRecons(reconsLov);
      const firstRecon = reconset.definition.recons[0];
      setRecon(firstRecon);
    }
  }, [reconset]);

  const reconSystemStats = getStats(
    "SYSTEM",
    recon.id,
    recon.definition.noOfDatasets,
    reconset.definition.userStatus || [],
    stats
  );
  const reconUserStats = getStats(
    "USER",
    recon.id,
    recon.definition.noOfDatasets,
    reconset.definition.userStatus || [],
    stats
  );
  const reconConfirmationStats = getStats(
    "CONFIRMATION",
    recon.id,
    recon.definition.noOfDatasets,
    reconset.definition.userStatus || [],
    stats
  );

  return (
    <Grid align="flex-start" gutter={"lg"}>
      <Grid.Col span={12}>
        <Select
          data={recons}
          value={recon.id}
          onChange={(value) => {
            console.log("value", value);
            console.log(reconset.definition.recons);

            const recon = reconset.definition.recons.find(
              (recon: any) => recon.id === value
            );
            console.log(recon);

            if (recon) {
              setRecon(recon);
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <Table verticalSpacing="sm" fs={"xs"}>
          <Table.Thead
            style={{
              borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
            }}
          >
            <Table.Tr>
              <Table.Th>System Status</Table.Th>
              <Table.Th ta="end">Primary</Table.Th>
              {recon.definition.noOfDatasets === "TWO" ? (
                <Table.Th ta="end">Secondary</Table.Th>
              ) : (
                <></>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {reconSystemStats.length === 0 ? (
              <Table.Tr key={"NO-DATA"}>
                <Table.Td>
                  <Divider label="No System Stats" labelPosition="left" />
                </Table.Td>
                <Table.Td>-</Table.Td>
              </Table.Tr>
            ) : (
              <>
                {reconSystemStats.map((stat) => {
                  return (
                    <Table.Tr key={stat.value}>
                      <Table.Td>
                        <Link
                          to={`/app/reconsets/view/status?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&type=${"SYSTEM"}&status=${stat.value}&recon=${recon.id}`}
                          style={styles.badgeLink}
                        >
                          {stat.label}
                        </Link>
                      </Table.Td>
                      <Table.Td ta="end">{stat.stat}</Table.Td>
                      {recon.definition.noOfDatasets === "TWO" ? (
                        <Table.Td ta="end">{stat.stat2}</Table.Td>
                      ) : (
                        <></>
                      )}
                    </Table.Tr>
                  );
                })}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Grid.Col>

      <Grid.Col span={4}>
        <Table verticalSpacing="sm" fs={"xs"}>
          <Table.Thead
            style={{
              borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
            }}
          >
            <Table.Tr>
              <Table.Th>User Status</Table.Th>
              <Table.Th ta="end">Primary</Table.Th>
              {recon.definition.noOfDatasets === "TWO" ? (
                <Table.Th ta="end">Secondary</Table.Th>
              ) : (
                <></>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {reconUserStats.length === 0 ? (
              <Table.Tr key={"NO-DATA"}>
                <Table.Td>
                  <Divider label="No User Stats" labelPosition="left" />
                </Table.Td>
                <Table.Td>-</Table.Td>
              </Table.Tr>
            ) : (
              <>
                {reconUserStats.map((stat) => {
                  return (
                    <Table.Tr key={stat.value}>
                      <Table.Td>
                        <Link
                          to={`/app/reconsets/view/status?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&type=${"USER"}&status=${stat.value}&recon=${recon.id}`}
                          style={styles.badgeLink}
                        >
                          {stat.label}
                        </Link>
                      </Table.Td>
                      <Table.Td ta="end">{stat.stat}</Table.Td>
                      {recon.definition.noOfDatasets === "TWO" ? (
                        <Table.Td ta="end">{stat.stat2}</Table.Td>
                      ) : (
                        <></>
                      )}
                    </Table.Tr>
                  );
                })}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Grid.Col>

      <Grid.Col span={4}>
        <Table verticalSpacing="sm" fs={"xs"}>
          <Table.Thead
            style={{
              borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
            }}
          >
            <Table.Tr>
              <Table.Th>Confirmation Status</Table.Th>
              <Table.Th ta="end">Primary</Table.Th>
              {recon.definition.noOfDatasets === "TWO" ? (
                <Table.Th ta="end">Secondary</Table.Th>
              ) : (
                <></>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {reconConfirmationStats.length === 0 ? (
              <Table.Tr key={"NO-DATA"}>
                <Table.Td>
                  <Divider label="No Confirmation Stats" labelPosition="left" />
                </Table.Td>
                <Table.Td>-</Table.Td>
              </Table.Tr>
            ) : (
              <>
                {reconConfirmationStats.map((stat) => {
                  return (
                    <Table.Tr key={stat.value}>
                      <Table.Td>
                        <Link
                          to={`/app/reconsets/view/status?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&type=${"CONFIRMATION"}&status=${stat.value}&recon=${recon.id}`}
                          style={styles.badgeLink}
                        >
                          {stat.label}
                        </Link>
                      </Table.Td>
                      <Table.Td ta="end">{stat.stat}</Table.Td>
                      {recon.definition.noOfDatasets === "TWO" ? (
                        <Table.Td ta="end">{stat.stat2}</Table.Td>
                      ) : (
                        <></>
                      )}
                    </Table.Tr>
                  );
                })}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Grid.Col>
    </Grid>
  );
}
