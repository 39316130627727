import * as React from "react";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import {
  Badge,
  Button,
  Grid,
  Paper,
  Select,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useUsersBasic } from "../hooks";
import { postTaskCollaborator } from "../api";
import { showError } from "../utilities";
import { TaskCollaboratorType } from "@supportinitiative/common";

function TaskCollaborators({
  id,
  entityId,
  collaborators,
}: {
  id: string;
  entityId: string;
  collaborators: TaskCollaboratorType[];
}) {
  const { data: users } = useUsersBasic(entityId);
  const [user, setUser] = React.useState("");
  const queryClient = useQueryClient();
  const theme = useMantineTheme();
  return (
    <Paper
      shadow="xs"
      mt="xs"
      p="xs"
      style={{ width: "100%", textAlign: "center" }}
    >
      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <Text c="gray.7" fw="bold" ta="start">
            Collaborators
          </Text>
        </Grid.Col>

        <Grid.Col span={12}>
          <Grid align="flex-start" gutter={"xs"}>
            <Grid.Col span={10}>
              <Select
                value={user}
                clearable
                searchable
                onChange={(val) => {
                  if (val) {
                    setUser(val);
                  } else {
                    setUser("");
                  }
                }}
                data={(users || []).map((user: any) => {
                  return { label: user.userName, value: user.userId };
                })}
                name="Collaborator"
                placeholder="User"
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Button
                fullWidth
                color="teal"
                disabled={!user}
                onClick={async () => {
                  try {
                    const toAssignUser = users.find(
                      (userDetail: any) => userDetail.userId === user
                    );

                    await postTaskCollaborator(id, entityId, {
                      userId: user,
                      userName: toAssignUser.userName,
                    });

                    showNotification({
                      id: "success",
                      title: "Add collaborator",
                      message: "Collaborator added successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                    setUser("");
                    queryClient.invalidateQueries(["task", entityId, id]);
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in posting comment",
                      "Unable to post comment",
                      <IconX />
                    );
                  }
                }}
              >
                Add
              </Button>
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      {collaborators.map((collaborator) => {
        return (
          <div
            key={collaborator.userId}
            style={{
              marginBottom: theme.spacing.xs,
            }}
          >
            <Grid align="flex-start" gutter={"xs"}>
              <Grid.Col
                style={{ display: "flex", justifyContent: "flex-start" }}
                span={12}
              >
                <Text size="sm" inline c="dimmed">
                  {`${collaborator.userName} - [ ${collaborator.userId} ]`}
                </Text>
              </Grid.Col>
            </Grid>
          </div>
        );
      })}
    </Paper>
  );
}

export default TaskCollaborators;
