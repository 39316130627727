import * as React from "react";
import { Control, useFieldArray, useForm, useWatch } from "react-hook-form";
import {
  Text,
  Grid,
  Button,
  Modal,
  ActionIcon,
  Title,
  Divider,
} from "@mantine/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { object, string, number, date, array, setLocale } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { BuilderContext, useDrawer } from "react-flow-builder";

import {
  CTextInput,
  CSelect,
  CTextarea,
  CMultiSelect,
} from "@supportinitiative/react-hook-form-mantine";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import { getId } from "../../../utilities";
import Where from "../QueryMatch/Where";
import { getColumnsLOV } from "../../ReconsetUtils";
import { useDataset } from "../../../hooks";

import {
  DeriveDataActionSchema,
  DeriveDataActionType,
  DeriveDataColumnsActionType,
  DeriveDataCustomActionType,
  DeriveDataRowsActionType,
} from "@supportinitiative/common";

function Rows({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<DeriveDataRowsActionType>;
  disabled: boolean;
}) {
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);

  const dataset = useWatch({
    control,
    name: `dataset`,
  });

  const otherdataset = useWatch({
    control,
    name: `otherDataset`,
  });

  let columns: { label: string; value: string }[] = [];

  if (dataset === "PRIMARY") {
    const primaryReconDataset =
      reconsetFormState.recon.definition.primaryDataset;
    const { data: reconDataset } = useDataset(
      reconsetFormState.entityId,
      primaryReconDataset.id
    );
    columns = getColumnsLOV(
      reconDataset,
      primaryReconDataset.columns,
      primaryReconDataset.additionalColumns
    );
  } else if (dataset === "SECONDARY") {
    const secondaryReconDataset =
      reconsetFormState.recon.definition.secondaryDataset;
    const { data: reconDataset } = useDataset(
      reconsetFormState.entityId,
      secondaryReconDataset.id
    );
    columns = getColumnsLOV(
      reconDataset,
      secondaryReconDataset.columns,
      secondaryReconDataset.additionalColumns
    );
  } else if (dataset === "OTHER") {
    if (otherdataset) {
      const otherReconDataset =
        reconsetFormState.recon.definition.otherDatasets.filter(
          (dataset: any) => dataset.definition.shortName === otherdataset
        )[0];

      const { data: reconDataset } = useDataset(
        reconsetFormState.entityId,
        otherReconDataset.definition.id
      );
      columns = getColumnsLOV(
        reconDataset,
        otherReconDataset.definition.columns,
        otherReconDataset.definition.additionalColumns
      );
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Divider my="xs" label="Derivation" labelPosition="center" />

      <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
        <Grid.Col span={12}>
          <CMultiSelect
            name={`rows.columns`}
            control={control}
            label="Columns"
            required
            data={columns}
            searchable
            showErrorText={false}
            disabled={disabled || false}
          />
        </Grid.Col>

        <Grid.Col span={12}>
          <CTextarea
            name={`rows.dataPopulation.genFunction`}
            control={control}
            label="Gen Function"
            required
            showErrorText={false}
            disabled={disabled || false}
            minRows={4}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

function Custom({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<DeriveDataCustomActionType>;
  disabled: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Divider my="xs" label="Derivation" labelPosition="center" />

      <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
        <Grid.Col span={12}>
          <CTextarea
            name={`custom.dataPopulation.genFunction`}
            control={control}
            label="Gen Function"
            required
            showErrorText={false}
            minRows={4}
            disabled={disabled || false}
          />
        </Grid.Col>
      </Grid>
    </div>
  );
}

function Columns({
  name,
  control,
  disabled,
}: {
  name: "";
  control: Control<DeriveDataColumnsActionType>;
  disabled: boolean;
}) {
  const dataPopulation = useFieldArray({
    control,
    name: `columns.dataPopulation`,
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Filter" labelPosition="center" />
        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <Where name={`left.where`} control={control} />
          </Grid.Col>
        </Grid>
      </div>

      <Divider my="xs" label="Derivation" labelPosition="center" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text fw={500} style={{ fontSize: "14px" }}>
          Columns
        </Text>
        <ActionIcon
          size="sm"
          variant="white"
          color="gray"
          onClick={() => {
            dataPopulation.append({
              column: "",
              genFunction: "",
              id: getId(),
            });
          }}
          disabled={disabled || false}
        >
          <IconPlus />
        </ActionIcon>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          marginLeft: "20px",
          width: "90%",
        }}
      >
        {dataPopulation.fields.map((field, index) => {
          return (
            <Grid align="stretch" gutter={"xs"} mb="xs" key={field.id}>
              <Grid.Col span={3}>
                <CTextInput
                  name={`columns.dataPopulation.${index}.column`}
                  control={control}
                  label={"Column"}
                  disabled={disabled || false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={6}>
                <CTextarea
                  name={`columns.dataPopulation.${index}.genFunction`}
                  control={control}
                  label={"Generate Function"}
                  minRows={4}
                  disabled={disabled || false}
                  required
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <CTextInput
                  name={`columns.dataPopulation.${index}.id`}
                  control={control}
                  label={"Id"}
                  disabled={true}
                />
              </Grid.Col>
              <Grid.Col
                span={1}
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                <ActionIcon
                  size="sm"
                  variant="white"
                  color="gray"
                  onClick={() => {
                    dataPopulation.remove(index);
                  }}
                  disabled={disabled || false}
                >
                  <IconTrash />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          );
        })}
      </div>
    </div>
  );
}

function DeriveData() {
  const { selectedNode: node } = React.useContext(BuilderContext);
  const { closeDrawer: cancel, saveDrawer: save } = useDrawer();
  const { state: reconsetFormState } = React.useContext(ReconsetCVMContext);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<DeriveDataActionType>({
    defaultValues: node?.data || {
      name: "",
      dataset: "PRIMARY",
      type: "COLUMNS",
    },
    criteriaMode: "all",
    resolver: yupResolver(DeriveDataActionSchema),
  });

  const deriveDataType = useWatch({
    control,
    name: `type`,
  });

  const dataset = useWatch({
    control,
    name: `dataset`,
  });

  return (
    <Modal
      centered
      size={"xl"}
      opened={true}
      onClose={cancel}
      title={<Title order={4}>Data Derivation</Title>}
    >
      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name={`name`}
            control={control}
            showErrorText={false}
            label="Name"
            required
            disabled={node?.disabled || false}
          />
        </Grid.Col>
      </Grid>

      <Grid align="stretch" gutter={"xs"} mb="xs">
        <Grid.Col span={6}>
          <CSelect
            name={`type`}
            control={control}
            data={[
              {
                label: "Columns",
                value: "COLUMNS",
              },
              {
                label: "Rows",
                value: "ROWS",
              },
              {
                label: "Custom",
                value: "CUSTOM",
              },
            ]}
            showErrorText={false}
            label="Type"
            searchable
            clearable
            required
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <CSelect
            name={`dataset`}
            control={control}
            data={
              reconsetFormState?.recon?.definition?.secondaryDataset
                ? [
                    {
                      value: "PRIMARY",
                      label: "Primary",
                    },
                    {
                      value: "SECONDARY",
                      label: "Secondary",
                    },
                    {
                      value: "OTHER",
                      label: "Other",
                    },
                  ]
                : [
                    {
                      value: "PRIMARY",
                      label: "Primary",
                    },
                    {
                      value: "OTHER",
                      label: "Other",
                    },
                  ]
            }
            showErrorText={false}
            label="Dataset"
            searchable
            clearable
            required
            disabled={reconsetFormState.actionOptions.disableAll}
          />
        </Grid.Col>
      </Grid>

      {dataset === "OTHER" ? (
        <Grid
          align="flex-start"
          gutter={"xs"}
          mb="lg"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <Grid.Col span={12}>
            <CTextInput
              name="otherDataset"
              control={control}
              label="Other Dataset Name"
              showErrorText={false}
              disabled={node?.disabled || false}
            />
          </Grid.Col>
        </Grid>
      ) : (
        <></>
      )}

      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          // borderTop: "2px solid #ccc",
          // paddingTop: "20px",
          // width: "90%",
        }}
      >
        <Divider my="xs" label="Filter" labelPosition="center" />
        <Grid align="stretch" gutter={"xs"} mb="xs">
          <Grid.Col span={12}>
            <Where name={`left.where`} control={control} />
          </Grid.Col>
        </Grid>
      </div> */}

      {deriveDataType === "CUSTOM" ? (
        <Custom
          name={""}
          control={control as Control<DeriveDataCustomActionType>}
          disabled={node?.disabled}
        />
      ) : deriveDataType === "ROWS" ? (
        <Rows
          name={""}
          control={control as Control<DeriveDataRowsActionType>}
          disabled={node?.disabled}
        />
      ) : deriveDataType === "COLUMNS" ? (
        <Columns
          name={""}
          control={control as Control<DeriveDataColumnsActionType>}
          disabled={node?.disabled}
        />
      ) : (
        <></>
      )}
      <Grid align="stretch" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            color="teal"
            variant="filled"
            fullWidth
            type="submit"
            onClick={
              node?.disabled
                ? () => {
                    console.log("only cancel");
                    cancel();
                  }
                : async (event) => {
                    event.preventDefault();
                    const values = getValues();
                    // console.log("values", values);
                    clearErrors();
                    try {
                      await DeriveDataActionSchema.validate(values, {
                        abortEarly: false,
                      });

                      save?.(values);
                      //cancel();
                    } catch (error) {
                      console.log("errors", error);
                      console.log("error inner", JSON.stringify(error));
                      (error as any).inner.forEach((err: any) => {
                        setError(err.path, {
                          type: "custom",
                          message: err.message,
                        });
                      });
                    }
                  }
            }
          >
            Ok
          </Button>
        </Grid.Col>
      </Grid>
    </Modal>
  );
}

export default DeriveData;
