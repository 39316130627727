import * as React from "react";
import { Text, ActionIcon, Grid } from "@mantine/core";
import { Control, useFieldArray } from "react-hook-form";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import { ReconsetCVMContext } from "../../ReconsetCVM";
import {
  CTextInput,
  CSelect,
} from "@supportinitiative/react-hook-form-mantine";

const havingOperators = [
  {
    value: "=",
    label: "=",
  },
  {
    value: "<",
    label: "<",
  },
  {
    value: "<=",
    label: "<=",
  },
  {
    value: ">=",
    label: ">=",
  },
  {
    value: ">",
    label: ">",
  },
  {
    value: "<>",
    label: "<>",
  },
];

function Conditions({
  name,
  index,
  conditionsArray,
  control,
}: {
  name:
    | `definition.recons.${number}.definition.actions.${number}.definition.left.having.conditions.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.right.having.conditions.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchFor.left.having.conditions.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchIn.left.having.conditions.${number}`
    | `left.having.conditions.${number}`
    | `right.having.conditions.${number}`;
  index: number;
  conditionsArray: any;
  control: Control<any>;
}) {
  const statementsArray = useFieldArray({
    control,
    name: `${name}.statements`,
  });
  const { state: reconsetCVMState } = React.useContext(ReconsetCVMContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          marginBottom: "10px",
          marginLeft: "20px",
        }}
      >
        <Text fw={500} style={{ fontSize: "14px" }}>
          Statement
        </Text>

        <ActionIcon
          size="sm"
          variant="white"
          color="gray"
          onClick={() => {
            statementsArray.append({ left: "", operator: "=", right: "" });
          }}
          disabled={reconsetCVMState.actionOptions.disableAll}
          style={{
            display: reconsetCVMState.actionOptions.disableAll ? "none" : "",
          }}
        >
          <IconPlus />
        </ActionIcon>

        <ActionIcon
          size="sm"
          variant="white"
          color="gray"
          onClick={() => {
            conditionsArray.remove(index);
          }}
          disabled={reconsetCVMState.actionOptions.disableAll}
          style={{
            display: reconsetCVMState.actionOptions.disableAll ? "none" : "",
          }}
        >
          <IconTrash />
        </ActionIcon>
      </div>

      {statementsArray.fields.map((field, index) => {
        return (
          <Statements
            name={`${name}.statements.${index}`}
            control={control}
            key={field.id}
            index={index}
            statementsArray={statementsArray}
          />
        );
      })}
    </div>
  );
}

function Statements({
  name,
  index,
  statementsArray,
  control,
}: {
  name:
    | `definition.recons.${number}.definition.actions.${number}.definition.left.having.conditions.${number}.statements.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.right.having.conditions.${number}.statements.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchFor.left.having.conditions.${number}.statements.${number}`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchIn.left.having.conditions.${number}.statements.${number}`
    | `left.having.conditions.${number}.statements.${number}`
    | `right.having.conditions.${number}.statements.${number}`;
  index: number;
  statementsArray: any;
  control: Control<any>;
}) {
  const { state: reconsetCVMState } = React.useContext(ReconsetCVMContext);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginLeft: "40px",
      }}
    >
      <Grid align="stretch" gutter={"xs"} style={{ marginBottom: "5px" }}>
        <Grid.Col span={4}>
          <CTextInput
            name={`${name}.left`}
            control={control}
            label={index === 0 ? "Left" : ""}
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <CSelect
            name={`${name}.operator`}
            control={control}
            searchable
            data={havingOperators}
            label={index === 0 ? "Operator" : ""}
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <CTextInput
            name={`${name}.right`}
            control={control}
            label={index === 0 ? "Right" : ""}
            disabled={reconsetCVMState.actionOptions.disableAll}
          />
        </Grid.Col>
        <Grid.Col
          span={1}
          style={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <ActionIcon
            size="sm"
            variant="white"
            color="gray"
            onClick={() => {
              statementsArray.remove(index);
            }}
            disabled={reconsetCVMState.actionOptions.disableAll}
            style={{
              display: reconsetCVMState.actionOptions.disableAll ? "none" : "",
            }}
          >
            <IconTrash />
          </ActionIcon>
        </Grid.Col>
      </Grid>
    </div>
  );
}

function Having({
  name,
  control,
}: {
  name:
    | `definition.recons.${number}.definition.actions.${number}.definition.left.having`
    | `definition.recons.${number}.definition.actions.${number}.definition.right.having`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchFor.left.having`
    | `definition.recons.${number}.definition.actions.${number}.definition.searchIn.left.having`
    | `left.having`
    | `right.having`;
  control: Control<any>;
}) {
  const conditionsArray = useFieldArray({
    control,
    name: `${name}.conditions`,
  });

  const { state: reconsetCVMState } = React.useContext(ReconsetCVMContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
        marginBottom: "20px",
        width: "90%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text fw={500} style={{ fontSize: "14px" }}>
          Having
        </Text>
        <ActionIcon
          size="sm"
          variant="white"
          color="gray"
          onClick={() => {
            conditionsArray.append({});
          }}
          disabled={reconsetCVMState.actionOptions.disableAll}
        >
          <IconPlus />
        </ActionIcon>
      </div>

      {conditionsArray.fields.map((field, index) => {
        return (
          <Conditions
            name={`${name}.conditions.${index}`}
            control={control}
            key={field.id}
            index={index}
            conditionsArray={conditionsArray}
          />
        );
      })}
    </div>
  );
}

export default Having;
