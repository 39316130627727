import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array } from "yup";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";

import { updateMatch } from "../../api";
import {
  CMultiSelect,
  CSelect,
  CTextInput,
} from "@supportinitiative/react-hook-form-mantine";
import { Button, Grid, MultiSelect, Select, TextInput } from "@mantine/core";
import { getConfirmationStatus } from "../ReconsetUtils";
import { showError } from "../../utilities";

export const UpdateMatchSchema = object({
  name: string().required(),
  comment: string().nullable(),
});

export default function UpdateMatch({
  id,
  entityId,
  matchId,
  recon,
  runId,
  match,
  systemStatusLOV,
  userStatusLOV,
  closeDialog,
}: {
  id: string;
  entityId: string;
  matchId: string;
  recon: string;
  runId: string;
  match: any;
  systemStatusLOV: { label: string; value: string }[];
  userStatusLOV: { label: string; value: string }[];
  closeDialog: () => void;
}) {
  const { control, handleSubmit } = useForm<{
    name: string;
    comment: string;
  }>({
    defaultValues: {
      name: match.name,
      comment: match.comment,
    },
    criteriaMode: "all",
    resolver: yupResolver(UpdateMatchSchema),
  });

  const [editable, setEditable] = React.useState(false);
  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    try {
      await updateMatch(id, entityId, recon, matchId, runId, data, "UPDATE");
      queryClient.invalidateQueries([
        "reconsetMatchDetails",
        entityId,
        id,
        runId,
        recon,
        matchId,
      ]);
      closeDialog();
      showNotification({
        id: "success",
        title: "Match detals update",
        message: "Match details updated successfully",
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error: any) {
      showError(
        error,
        "Error in match update",
        "Unable to update match details",
        <IconX />
      );
    }
  };

  return (
    <form noValidate>
      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name="name"
            control={control}
            label="Name"
            required
            showErrorText={false}
            disabled={!editable}
          />
        </Grid.Col>
      </Grid>
      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={4}>
          <TextInput value={match.recon} label="Recon Id" disabled={true} />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput value={match.reconName} label="Recon" disabled={true} />
        </Grid.Col>

        <Grid.Col span={4}>
          <TextInput value={match.action} label="Action Id" disabled={true} />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={4}>
          <TextInput value={match.actionName} label="Action" disabled={true} />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            value={dayjs(match.matchDate).format("YYYY-MM-DD HH:MM")}
            label="Match Date"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput value={match.makerId} label="Maker Id" disabled={true} />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={4}>
          <TextInput
            value={dayjs(match.makerDate).format("YYYY-MM-DD HH:MM")}
            label="Maker Date"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            value={match.checkerId}
            label="Checker Id"
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInput
            value={
              match.checkerDate
                ? dayjs(match.checkerDate).format("YYYY-MM-DD HH:MM")
                : ""
            }
            label="Checker Date"
            disabled={true}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={12}>
          <CTextInput
            name="comment"
            control={control}
            label="Comment"
            showErrorText={false}
            disabled={!editable}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mb="xs">
        <Grid.Col span={4}>
          <Select
            name={"systemStatus"}
            label={"System Status"}
            value={match.systemStatus}
            data={systemStatusLOV}
            required
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <MultiSelect
            name={"userStatus"}
            label={"User Status"}
            value={match.userStatus ? match.userStatus.split(",") : []}
            data={userStatusLOV}
            disabled={true}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            label={"Confirmation Status"}
            value={match.confirmationStatus}
            data={getConfirmationStatus()}
            disabled={true}
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mt="xs">
        {!editable && (
          <Grid.Col span={2}>
            <Button
              fullWidth
              variant="filled"
              color="teal"
              onClick={() => {
                setEditable(true);
              }}
            >
              Edit
            </Button>
          </Grid.Col>
        )}
        {editable && (
          <Grid.Col span={2}>
            <Button
              fullWidth
              variant="filled"
              color="teal"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </Grid.Col>
        )}
        <Grid.Col span={2}>
          <Button variant="default" onClick={closeDialog} fullWidth>
            Cancel
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
