import * as React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Button, Grid, Title, ActionIcon, Group } from "@mantine/core";
import dayjs from "dayjs";

import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { IconPlus, IconTrash } from "@tabler/icons-react";

import { UserSchema, UserType } from "@supportinitiative/common";

import {
  CSelect,
  CTextInput,
  CDatePicker,
} from "@supportinitiative/react-hook-form-mantine";

import { styles } from "../styles";
import { postUser } from "../api";
import { AuditType, IDispatchAction } from "../types";
import { getActionOptions } from "./UserUtils";
import { UserContext } from "../misc/UserContext";
import { useActiveRoles, useUser } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";
import { getId } from "../utilities";

type actionTypes =
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR";

export const UserCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  entityId: "",
  actionOptions: {},
  setValue: () => {},
  submitting: false,
  error: "",
  successMessage: "",
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    default:
      return state;
  }
}

function get30DaysPlus() {
  var date = new Date(); // Now
  date.setDate(date.getDate() + 30);
  return dayjs(date).format("YYYY-MM-DD");
}

export default function UserCVM() {
  // let [user, setUser] = React.useState<UserType>();

  let [audit, setAudit] = React.useState<AuditType | undefined>();

  // let [currentAction, setCurrentAction] = React.useState("new");
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const { data: user } = useUser(entityId, id);
  // let [newUser, setNewUser] = React.useState<UserType | undefined>();
  const [userFormState, userFormDispatch] = React.useReducer(reducer, {
    ...initialState,
    entityId,
    actionOptions: getActionOptions("new", user, user, applicationState),
  });

  const { data: activeRoles } = useActiveRoles(entityId);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UserType>({
    defaultValues: {
      userId: "",
      userName: "",
      email: "",
      type: "USER",
      roles: [],
      apiKeys: [],
    },
    criteriaMode: "all",
    resolver: yupResolver(UserSchema),
  });

  const onOk = () => {
    navigate(`/app/users?entityId=${entityId}`);
  };

  const rolesFieldArray = useFieldArray({
    control,
    name: "roles",
    keyName: "key",
  });

  const apiKeysFieldArray = useFieldArray({
    control,
    name: "apiKeys",
    keyName: "key",
  });

  React.useEffect(() => {
    if (user) {
      userFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      userFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions("view", user, user, applicationState),
      });

      setAudit({
        status: user.status,
        authStatus: user.authStatus,
        usableStatus: user.usableStatus,
        makerId: user.makerId,
        makerDate: user.makerDate,
        modificationNo: user.modificationNo,
        checkerId: user.checkerId,
        checkerDate: user.checkerDate,
      });

      reset(user);
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      userFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      userFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: userFormState.currentAction,
        },
      };
      await postUser(payload);
      // setError("Form", "Unable to Submit");
      userFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["user", entityId, id]);
      queryClient.invalidateQueries(["users", entityId]);
      userFormDispatch({
        type: "SET-SUCCESS",
        value: "User Saved Successfully",
      });
    } catch (error: any) {
      // console.log("post catch", error);
      // console.log("post catch", error?.response?.data?.message);
      userFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      userFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save User",
      });
    }
  };

  return (
    <UserCVMContext.Provider
      value={{ state: userFormState, dispatch: userFormDispatch }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={styles.main} noValidate>
        <div style={styles.header} id="header">
          <Title order={2}>User</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={2}>
              <CTextInput
                name="userId"
                control={control}
                label="User Id"
                aria-label="User Id"
                required
                showErrorText={false}
                disabled={userFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CTextInput
                name="userName"
                control={control}
                label="User Name"
                aria-label="User Name"
                required
                showErrorText={false}
                disabled={userFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CSelect
                name="type"
                control={control}
                label="Type"
                aria-label="Type"
                data={[
                  { label: "User", value: "USER" },
                  { label: "System", value: "SYSTEM" },
                ]}
                required
                showErrorText={false}
                disabled={userFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CTextInput
                name="email"
                control={control}
                label="Email"
                aria-label="Email"
                type="email"
                required
                showErrorText={false}
                disabled={userFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Group mb="xs" mt="xs" style={{ alignItems: "center" }}>
              <Title order={4}>Roles</Title>
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={() => {
                  rolesFieldArray.append({
                    entityId: entityId ? entityId : "",
                    role: "",
                  });
                }}
                style={{
                  display:
                    userFormState.currentAction.toUpperCase() === "VIEW"
                      ? "none"
                      : "",
                }}
                disabled={userFormState.actionOptions.disableAll}
              >
                <IconPlus />
              </ActionIcon>
            </Group>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              {rolesFieldArray.fields.map((field, index) => {
                return (
                  <Grid align="stretch" gutter={"xs"} mb="xs" key={field.key}>
                    <Grid.Col span={4}>
                      <CSelect
                        name={`roles.${index}.role`}
                        control={control}
                        data={
                          activeRoles
                            ? activeRoles.map((role) => {
                                return {
                                  label: `${role.name} - ${role.description}`,
                                  value: role.name,
                                };
                              })
                            : []
                        }
                        showErrorText={false}
                        required
                        disabled={userFormState.actionOptions.disableAll}
                      />
                    </Grid.Col>

                    <Grid.Col
                      span={1}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <ActionIcon
                        variant="subtle"
                        color="gray"
                        onClick={() => {
                          rolesFieldArray.remove(index);
                        }}
                        style={{
                          display:
                            userFormState.currentAction.toUpperCase() === "VIEW"
                              ? "none"
                              : "",
                        }}
                        disabled={userFormState.actionOptions.disableAll}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                );
              })}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Group mb="xs" mt="xs" style={{ alignItems: "center" }}>
              <Title order={4}>API Keys</Title>
              <ActionIcon
                variant="subtle"
                color="gray"
                onClick={() => {
                  apiKeysFieldArray.append({
                    name: "",
                    id: getId(5),
                    token: getId(30),
                    expiryDate: get30DaysPlus() as unknown as Date,
                  });
                }}
                style={{
                  display:
                    userFormState.currentAction.toUpperCase() === "VIEW"
                      ? "none"
                      : "",
                }}
                disabled={userFormState.actionOptions.disableAll}
              >
                <IconPlus />
              </ActionIcon>
            </Group>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "20px",
              }}
            >
              {apiKeysFieldArray.fields.map((field, index) => {
                return (
                  <Grid align="stretch" gutter={"xs"} mb="xs" key={field.key}>
                    <Grid.Col span={1}>
                      <CTextInput
                        name={`apiKeys.${index}.id`}
                        control={control}
                        label={index === 0 ? "Id" : ""}
                        aria-label="Id"
                        showErrorText={false}
                        required
                        disabled={true}
                      />
                    </Grid.Col>
                    <Grid.Col span={2}>
                      <CTextInput
                        name={`apiKeys.${index}.name`}
                        control={control}
                        label={index === 0 ? "Name" : ""}
                        aria-label="Name"
                        showErrorText={false}
                        required
                        disabled={userFormState.actionOptions.disableAll}
                      />
                    </Grid.Col>

                    <Grid.Col span={3}>
                      <CTextInput
                        name={`apiKeys.${index}.token`}
                        control={control}
                        label={index === 0 ? "Token (Hashed on save)" : ""}
                        aria-label="Token"
                        showErrorText={false}
                        required
                        disabled={true}
                      />
                    </Grid.Col>

                    <Grid.Col span={2}>
                      <CDatePicker
                        name={`apiKeys.${index}.expiryDate`}
                        control={control}
                        label={index === 0 ? "Expiry Date" : ""}
                        aria-label="Expiry Date"
                        showErrorText={false}
                        disabled={userFormState.actionOptions.disableAll}
                      />
                    </Grid.Col>

                    <Grid.Col
                      span={1}
                      style={{ display: "flex", alignItems: "flex-end" }}
                    >
                      <ActionIcon
                        variant="subtle"
                        color="gray"
                        onClick={() => {
                          apiKeysFieldArray.remove(index);
                        }}
                        style={{
                          display:
                            userFormState.currentAction.toUpperCase() === "VIEW"
                              ? "none"
                              : "",
                        }}
                        disabled={userFormState.actionOptions.disableAll}
                      >
                        <IconTrash />
                      </ActionIcon>
                    </Grid.Col>
                  </Grid>
                );
              })}
            </div>
          </div>
        </div>

        {(Object.keys(errors).length > 0 || userFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={userFormState.error} />
            </div>
          </div>
        )}

        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(userFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action === "save") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          fullWidth
                          type="submit"
                          color="teal"
                          aria-label="Save"
                          loading={userFormState.submitting}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "cancel") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/users?entityId=${entityId}`}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "back") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/users?entityId=${entityId}`}
                          aria-label="Back"
                        >
                          Back
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "edit") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Edit"
                          fullWidth
                          onClick={() => {
                            userFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });
                            userFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                user,
                                user,
                                applicationState
                              ),
                            });

                            setAudit({
                              status: user.status,
                              authStatus: "UNAUTH",
                              usableStatus: user.usableStatus,
                              makerId: applicationState.user.userId,
                              makerDate: new Date(),
                              modificationNo:
                                user.authStatus === "UNAUTH"
                                  ? user.modificationNo
                                  : (user.modificationNo || 0) + 1,
                              checkerId: "",
                              checkerDate: undefined,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "auth") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Auth"
                          fullWidth
                          onClick={async () => {
                            userFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "AUTH",
                            });

                            try {
                              let payload = {
                                ...user,
                                entityId,
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postUser(payload);
                              // setError("Form", "Unable to Submit");
                              userFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              userFormDispatch({
                                type: "SET-SUCCESS",
                                value: "User Authorized Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              userFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Authorize User",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "delete") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Delete"
                          fullWidth
                          onClick={async () => {
                            userFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "DELETE",
                            });

                            try {
                              let payload = {
                                ...user,
                                entityId,
                                metaData: {
                                  action: "delete",
                                },
                              };
                              await postUser(payload);
                              // setError("Form", "Unable to Submit");
                              userFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              userFormDispatch({
                                type: "SET-SUCCESS",
                                value: "User Deleted Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              userFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Delete User",
                              });
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {userFormState.successMessage && (
          <Success message={userFormState.successMessage} onOk={onOk} />
        )}
      </form>
    </UserCVMContext.Provider>
  );
}
