import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import {
  CCheckbox,
  CRadioGroup,
  CSelect,
} from "@supportinitiative/react-hook-form-mantine";
import { Button, Grid, Select } from "@mantine/core";
import { showError } from "src/utilities";
import { finalizeMatchTemp } from "src/api";
import { getSystemStatus } from "src/Reconset/ReconsetUtils";
import { useNavigate } from "react-router-dom";

export const FinalizeMatchTempSchema = object({
  statusOverride: string().required(),
  copyComments: string().required(),
});

export default function FinalizeMatchTemp({
  id,
  entityId,
  matchId,
  recon,
  runId,
  match,
  systemStatusLOV,
  userStatusLOV,
  closeDialog,
}: {
  id: string;
  entityId: string;
  matchId: string;
  recon: string;
  runId: string;
  match: any;
  systemStatusLOV: { label: string; value: string }[];
  userStatusLOV: { label: string; value: string }[];
  closeDialog: () => void;
}) {
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm<{
    statusOverride: string;
    copyComments: string;
  }>({
    defaultValues: {
      statusOverride: "OVERRIDE",
      copyComments: "NO",
    },
    criteriaMode: "all",
    resolver: yupResolver(FinalizeMatchTempSchema),
  });

  const queryClient = useQueryClient();

  const onSubmit = async (data: any) => {
    try {
      const matchData = {
        ...data,
      };

      await finalizeMatchTemp(id, entityId, recon, runId, matchId, matchData);

      queryClient.invalidateQueries([
        "reconsetMatchDetails",
        entityId,
        id,
        runId,
        recon,
        matchId,
      ]);

      navigate(`/app/reconsets/view?id=${id}&entityId=${entityId}`);

      closeDialog();
      showNotification({
        id: "success",
        title: "Match detals update",
        message: "Match details updated successfully",
        color: "teal",
        icon: <IconCheck />,
      });
    } catch (error: any) {
      showError(
        error,
        "Error in match update",
        "Unable to update match details",
        <IconX />
      );
    }
  };

  return (
    <form noValidate>
      <Grid align="flex-start" gutter={"xs"} mb="lg">
        <Grid.Col span={6}>
          <Select
            name={"systemStatus"}
            label={"System Status"}
            data={getSystemStatus("ALL")}
            value={match.systemStatus}
            disabled
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <Select
            name={"userStatus"}
            label={"User Status"}
            data={userStatusLOV}
            value={match.userStatus}
            disabled
          />
        </Grid.Col>
      </Grid>

      <Grid align="stretch" gutter={"xs"} mb="lg">
        <Grid.Col span={6}>
          <CRadioGroup
            name={`statusOverride`}
            label={"Status Override"}
            control={control}
            data={[
              { label: "Ignore", value: "IGNORE" },
              { label: "Override", value: "OVERRIDE" },
              { label: "Append", value: "APPEND" },
            ]}
          />
        </Grid.Col>

        <Grid.Col span={6}>
          <CCheckbox
            name={`copyComments`}
            control={control}
            label="Copy Comments"
            trueValue="YES"
            falseValue="NO"
          />
        </Grid.Col>
      </Grid>

      <Grid align="flex-start" gutter={"xs"} mt="xs">
        <Grid.Col span={2}>
          <Button
            fullWidth
            variant="filled"
            color="teal"
            onClick={handleSubmit(onSubmit)}
          >
            Finalize
          </Button>
        </Grid.Col>
        <Grid.Col span={2}>
          <Button variant="default" onClick={closeDialog} fullWidth>
            Cancel
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
