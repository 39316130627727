import { Timeline, Text, Badge, Divider } from "@mantine/core";
import {
  IconAnchor,
  IconChartLine,
  IconDatabase,
  IconEdit,
  IconFileUpload,
  IconFilter,
  IconFingerprint,
  IconFlag,
  IconHeartCheck,
  IconNotification,
  IconRun,
  IconTimelineEventX,
} from "@tabler/icons-react";
import dayjs from "dayjs";

function getReconName(recon: string) {
  if (recon.toLowerCase() === "orchestrator") {
    return "Orchestrator";
  } else {
    return `Recon - ${recon}`;
  }
}

function getActionType(type: string) {
  if (type.toLowerCase() === "start") {
    return "Start";
  } else if (type.toLowerCase() === "dataload") {
    return "Dataload";
  } else if (type.toLowerCase() === "filter") {
    return "Filter";
  } else if (type.toLowerCase() === "anchor-match") {
    return "Anchor Match";
  } else if (type.toLowerCase() === "end") {
    return "End";
  } else if (type.toLowerCase() === "derive-data") {
    return "Data Derivation";
  } else if (type.toLowerCase() === "notification") {
    return "Notification";
  } else if (type.toLowerCase() === "status-update") {
    return "Status Update";
  } else if (type.toLowerCase() === "finding") {
    return "Finding";
  } else if (type.toLowerCase() === "summary-stats") {
    return "Summary Stats";
  } else if (type.toLowerCase() === "remediation") {
    return "Remediation";
  } else if (type.toLowerCase() === "multi-match") {
    return "Multi Match";
  } else {
    // TODO: return InitCap of type
    return type;
  }
}

function getTypeIcon(type: string) {
  if (type.toLowerCase() === "start") {
    return <IconRun style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "dataload") {
    return <IconFileUpload style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "derive-data") {
    return <IconDatabase style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "end") {
    return <IconFlag style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "anchor-match") {
    return <IconAnchor style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "filter") {
    return <IconFilter style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "notification") {
    return <IconNotification style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "status-update") {
    return <IconEdit style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "finding") {
    return <IconFingerprint style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "summary-stats") {
    return <IconChartLine style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "remediation") {
    return <IconHeartCheck style={{ width: "70%", height: "70%" }} />;
  } else if (type.toLowerCase() === "multi-match") {
    return <IconTimelineEventX style={{ width: "70%", height: "70%" }} />;
  } else {
    return <IconFileUpload style={{ width: "70%", height: "70%" }} />;
  }
}

function getActive(
  actions: {
    runId: string;
    runSeq: string;
    recon: string;
    action: string;
    status: string;
    type: string;
    startDate: string;
    endDate: string;
    error: string | null;
  }[]
) {
  const activeIndex = actions.findIndex(
    (action) => action.status !== "COMPLETE"
  );
  if (activeIndex === -1) {
    return actions.length - 1;
  }
  return activeIndex + 1;
}

export default function Actions({
  actions,
}: {
  actions: {
    runId: string;
    runSeq: string;
    recon: string;
    action: string;
    actionName: string;
    status: string;
    type: string;
    startDate: string;
    endDate: string;
    error: string | null;
  }[];
}) {
  if (actions && actions.length > 0) {
    return (
      <Timeline active={getActive(actions)} bulletSize={30} lineWidth={2}>
        {actions.map((action, index) => (
          <Timeline.Item
            key={index}
            title={`${getReconName(action.recon)} - ${getActionType(action.type)}`}
            bullet={getTypeIcon(action.type)}
            color={
              action.status === "COMPLETE"
                ? "teal"
                : action.status === "ERROR"
                  ? "pink"
                  : action.status === "RUNNING" || action.status === "WAITING"
                    ? "blue"
                    : "gray"
            }
          >
            <Text c="dimmed" size="xs">
              {action.action} / {action.actionName} - {action.status}
            </Text>
            {action.status === "COMPLETE" ? (
              <>
                <Text c="dimmed" size="xs">
                  {`Start - [ ${dayjs(action.startDate).format("YYYY-MM-DD HH:MM")} ]`}
                </Text>
                <Text c="dimmed" size="xs">
                  {`End - [ ${dayjs(action.endDate).format("YYYY-MM-DD HH:MM")} ]`}
                </Text>
              </>
            ) : action.status === "RUNNING" || action.status === "ERROR" ? (
              <>
                <Text c="dimmed" size="xs">
                  {`Start - [ ${dayjs(action.startDate).format("YYYY-MM-DD HH:MM")} ]`}
                </Text>
              </>
            ) : (
              <></>
            )}
          </Timeline.Item>
        ))}
      </Timeline>
    );
  } else {
    return <Divider label="No Actions" labelPosition="left" />;
  }
}
