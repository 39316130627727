import * as React from "react";
import { useForm, useFieldArray, useWatch, Control } from "react-hook-form";
import { Button, Grid, Title } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { LoaderSchema, LoaderType } from "@supportinitiative/common";

import {
  CSelect,
  CTextInput,
  CJsonInput,
} from "@supportinitiative/react-hook-form-mantine";

import { styles } from "../styles";
import { postLoader } from "../api";
import { AuditType, IDispatchAction } from "../types";
import { getActionOptions } from "./LoaderUtils";
import { UserContext } from "../misc/UserContext";
import { useLoader } from "../hooks";
import FormSubmitError from "../ui/Error/FormSubmitError";
import FormStateError from "../ui/Error/FormStateError";
import Success from "../ui/Success";
import Audit from "../ui/Audit/Audit";
import CSVLoader from "./CSV/CSVLoader";
import DBLoader from "./DB/DBLoader";

type actionTypes =
  | "CURRENT-ACTION"
  | "ACTION-OPTIONS"
  | "SET-VALUE"
  | "SET-SUBMITTING"
  | "SET-SUCCESS"
  | "SET-ERROR";

export const LoaderCVMContext = React.createContext<{
  state: any;
  dispatch: React.Dispatch<IDispatchAction<actionTypes>>;
}>({
  state: {},
  dispatch: () => {},
});

const initialState = {
  currentAction: "NEW",
  entityId: "",
  actionOptions: {},
  setValue: () => {},
  submitting: false,
  error: "",
  successMessage: "",
};

function reducer(state: any, action: IDispatchAction<actionTypes>) {
  switch (action.type) {
    case "CURRENT-ACTION":
      return { ...state, currentAction: action.value };
    case "ACTION-OPTIONS":
      return { ...state, actionOptions: action.value };
    case "SET-VALUE":
      return { ...state, setValue: action.value };
    case "SET-SUBMITTING":
      return { ...state, submitting: action.value };
    case "SET-SUCCESS":
      return { ...state, successMessage: action.value };
    case "SET-ERROR":
      return { ...state, error: action.value };
    default:
      return state;
  }
}

export default function LoaderCVM() {
  // let [loader, setLoader] = React.useState<LoaderType>();

  let [audit, setAudit] = React.useState<AuditType | undefined>();

  // let [currentAction, setCurrentAction] = React.useState("new");
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");
  const { state: applicationState } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const { data: loader } = useLoader(entityId, id);
  // let [newLoader, setNewLoader] = React.useState<LoaderType | undefined>();
  const [loaderFormState, loaderFormDispatch] = React.useReducer(reducer, {
    ...initialState,
    entityId,
    actionOptions: getActionOptions("new", loader, loader, applicationState),
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<LoaderType>({
    defaultValues: {
      name: "",
      displayName: "",
      description: "",
      definition: {},
    },
    criteriaMode: "all",
    resolver: yupResolver(LoaderSchema),
  });

  const onOk = () => {
    navigate(`/app/loaders?entityId=${entityId}`);
  };

  React.useEffect(() => {
    if (loader) {
      loaderFormDispatch({ type: "CURRENT-ACTION", value: "VIEW" });
      loaderFormDispatch({
        type: "ACTION-OPTIONS",
        value: getActionOptions("view", loader, loader, applicationState),
      });

      setAudit({
        status: loader.status,
        authStatus: loader.authStatus,
        usableStatus: loader.usableStatus,
        makerId: loader.makerId,
        makerDate: loader.makerDate,
        modificationNo: loader.modificationNo,
        checkerId: loader.checkerId,
        checkerDate: loader.checkerDate,
      });

      reset(loader);
    }
  }, [loader]);

  const onSubmit = async (data: any) => {
    try {
      loaderFormDispatch({
        type: "SET-SUBMITTING",
        value: true,
      });
      loaderFormDispatch({
        type: "SET-ERROR",
        value: "",
      });
      let payload = {
        ...data,
        entityId,
        metaData: {
          action: loaderFormState.currentAction,
        },
      };
      await postLoader(payload);
      // setError("Form", "Unable to Submit");
      loaderFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      queryClient.invalidateQueries(["loader", entityId, id]);
      queryClient.invalidateQueries(["loaders", entityId]);
      loaderFormDispatch({
        type: "SET-SUCCESS",
        value: "Loader Saved Successfully",
      });
    } catch (error: any) {
      // console.log("post catch", error);
      // console.log("post catch", error?.response?.data?.message);
      loaderFormDispatch({
        type: "SET-SUBMITTING",
        value: false,
      });
      loaderFormDispatch({
        type: "SET-ERROR",
        value: error?.response?.data?.message || "Unable to Save Loader",
      });
    }
  };

  return (
    <LoaderCVMContext.Provider
      value={{ state: loaderFormState, dispatch: loaderFormDispatch }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={styles.main} noValidate>
        <div style={styles.header} id="header">
          <Title order={2}>Loader</Title>
        </div>

        <div style={styles.body} id="body">
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={2}>
              <CTextInput
                name="name"
                control={control}
                label="Name"
                aria-label="Name"
                required
                showErrorText={false}
                disabled={loaderFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <CTextInput
                name="displayName"
                control={control}
                label="Display Name"
                aria-label="Display Name"
                showErrorText={false}
                disabled={loaderFormState.actionOptions.disableAll}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CTextInput
                name="description"
                control={control}
                label="Description"
                aria-label="Description"
                required
                showErrorText={false}
                disabled={loaderFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
          <Grid align="flex-start" gutter={"xs"} mb="lg">
            <Grid.Col span={8}>
              <CJsonInput
                name={`definition.config`}
                control={control}
                label="Config"
                showErrorText={false}
                autosize
                minRows={20}
                disabled={loaderFormState.actionOptions.disableAll}
              />
            </Grid.Col>
          </Grid>
        </div>

        {(Object.keys(errors).length > 0 || loaderFormState.error) && (
          <div style={styles.errors} id="errors">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormStateError errors={errors} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <FormSubmitError error={loaderFormState.error} />
            </div>
          </div>
        )}

        <div style={styles.actions} id="actions">
          <Grid align="stretch" gutter={"xs"} columns={24}>
            <>
              {(loaderFormState.actionOptions.allowedActions || []).map(
                (action: string) => {
                  if (action === "save") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          fullWidth
                          type="submit"
                          color="teal"
                          aria-label="Save"
                          loading={loaderFormState.submitting}
                        >
                          Save
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "cancel") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/loaders?entityId=${entityId}`}
                          aria-label="Cancel"
                        >
                          Cancel
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "back") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          fullWidth
                          component={Link}
                          to={`/app/loaders?entityId=${entityId}`}
                          aria-label="Back"
                        >
                          Back
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "edit") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Edit"
                          fullWidth
                          onClick={() => {
                            loaderFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "EDIT",
                            });
                            loaderFormDispatch({
                              type: "ACTION-OPTIONS",
                              value: getActionOptions(
                                "edit",
                                loader,
                                loader,
                                applicationState
                              ),
                            });

                            setAudit({
                              status: loader.status,
                              authStatus: "UNAUTH",
                              usableStatus: loader.usableStatus,
                              makerId: applicationState.user.userId,
                              makerDate: new Date(),
                              modificationNo:
                                loader.authStatus === "UNAUTH"
                                  ? loader.modificationNo
                                  : (loader.modificationNo || 0) + 1,
                              checkerId: "",
                              checkerDate: undefined,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "auth") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Auth"
                          fullWidth
                          onClick={async () => {
                            loaderFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "AUTH",
                            });

                            try {
                              let payload = {
                                ...loader,
                                entityId,
                                metaData: {
                                  action: "auth",
                                },
                              };
                              await postLoader(payload);
                              // setError("Form", "Unable to Submit");
                              loaderFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              loaderFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Loader Authorized Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              loaderFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Authorize Loader",
                              });
                            }
                          }}
                        >
                          Auth
                        </Button>
                      </Grid.Col>
                    );
                  } else if (action === "delete") {
                    return (
                      <Grid.Col span={3} key={action}>
                        <Button
                          variant="default"
                          aria-label="Delete"
                          fullWidth
                          onClick={async () => {
                            loaderFormDispatch({
                              type: "CURRENT-ACTION",
                              value: "DELETE",
                            });

                            try {
                              let payload = {
                                ...loader,
                                entityId,
                                metaData: {
                                  action: "delete",
                                },
                              };
                              await postLoader(payload);
                              // setError("Form", "Unable to Submit");
                              loaderFormDispatch({
                                type: "CURRENT-ACTION",
                                value: "VIEW",
                              });

                              loaderFormDispatch({
                                type: "SET-SUCCESS",
                                value: "Loader Deleted Successfully",
                              });
                            } catch (error: any) {
                              // console.log("post catch", error);
                              loaderFormDispatch({
                                type: "SET-ERROR",
                                value:
                                  error?.response?.data?.message ||
                                  "Unable to Delete Loader",
                              });
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Grid.Col>
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </>
          </Grid>
        </div>

        <Audit audit={audit} />
        {loaderFormState.successMessage && (
          <Success message={loaderFormState.successMessage} onOk={onOk} />
        )}
      </form>
    </LoaderCVMContext.Provider>
  );
}
