import { FunctionType } from "@supportinitiative/common";

import { ActionOptionsType } from "../types";

export function getActionOptions(
  currentAction: string,
  prevValues: FunctionType | undefined,
  currentValues: FunctionType | undefined,
  applicationState: any
): ActionOptionsType {
  switch (currentAction.toUpperCase()) {
    case "NEW": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    case "VIEW": {
      let actionOptions: { allowedActions: string[]; disableAll: boolean } = {
        allowedActions: [],
        disableAll: true,
      };

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.makerId !== applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("auth");
      } else {
        actionOptions.allowedActions.push("edit");
      }

      if (
        prevValues?.authStatus === "UNAUTH" &&
        prevValues?.modificationNo === 1 &&
        prevValues?.makerId === applicationState.user.userId
      ) {
        actionOptions.allowedActions.push("delete");
      }
      actionOptions.allowedActions.push("cancel");
      return actionOptions;
    }
    case "EDIT": {
      return {
        allowedActions: ["save", "cancel"],
        disableAll: false,
      };
    }
    default:
      return { allowedActions: [] };
  }
}

export const getDisabledStatus = (
  name: string,
  actionOptions: ActionOptionsType,
  prevValues: FunctionType | undefined,
  currentValues: FunctionType | undefined,
  applicationState: any
) => {
  let disabled = false;

  disabled = actionOptions.disableAll || false;

  if (!disabled) {
    disabled = (actionOptions.disabledFields || []).indexOf(name) >= 0;
  }

  if (!disabled) {
    disabled = (actionOptions.regexDisabledFields || []).some(
      (regexDisabledField: string) => {
        let regex = new RegExp(regexDisabledField, "i");
        return regex.test(name);
      }
    );
  }

  return disabled;
};
