import * as React from "react";
import { Link, useNavigate } from "react-router-dom";

import { isAuthorized } from "../utilities";

import landingPageStyles from "./LandingPage.module.css";
import {
  Button,
  Grid,
  Modal,
  PasswordInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconChecklist } from "@tabler/icons-react";
import { auth, getLocalStorage, setLocalStorage } from "../api";
import Logo from "./Logo";
import Mobile from "./Mobile";
import Desktop from "./Desktop";

function LandingPage() {
  const [loginPage, setLoginPage] = React.useState(false);
  const [userId, setUserId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [error, setError] = React.useState("");

  return (
    <>
      <Mobile />
      <Desktop setLoginPage={setLoginPage} />

      <Modal
        centered
        opened={loginPage}
        onClose={() => setLoginPage(false)}
        title={
          <Title order={2} style={{ paddingLeft: "5px" }}>
            Login
          </Title>
        }
      >
        <form
          onSubmit={async (event) => {
            event.preventDefault();
            setError("");
            try {
              let payload = {
                userId: userId,
                password: password,
              };
              let response = await auth(payload);
              if (response.status === 200) {
                console.log(response.data);
                setLocalStorage("token", response.data.token);
                let userRoles = response.data.roles || [];
                setLocalStorage("roles", userRoles);
                setLocalStorage("user", response.data.user);
                if (userRoles && userRoles.length > 0) {
                  const entityId = userRoles[0].entityId;
                  setLocalStorage("currentEntity", entityId);
                  navigate(`/app/dashboard?entityId=${entityId}`);
                } else {
                  setError("Invalid Credentials");
                }
              } else {
                setError("Invalid Credentials");
              }
            } catch (error) {
              setError("Invalid Credentials");
            }
          }}
        >
          <TextInput
            placeholder="User Name"
            style={{ marginBottom: "10px" }}
            value={userId}
            onChange={(event) => setUserId(event.currentTarget.value)}
          />
          <PasswordInput
            type="password"
            placeholder="Password"
            style={{ marginBottom: "10px" }}
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
          <Button
            color="teal"
            fullWidth
            type="submit"
            /*  onClick={async () => {
              console.log("onclick");
              try {
                let payload = {
                  userId: userId,
                  password: password,
                };
                let response = await auth(payload);
                if (response.status === 200) {
                  console.log(response.data);
                  setLocalStorage("token", response.data.token);
                  let userRoles = response.data.roles || [];
                  setLocalStorage("roles", userRoles);
                  setLocalStorage("user", response.data.user);
                  if (userRoles && userRoles.length > 0) {
                    const entityId = userRoles[0].entityId;
                    setLocalStorage("currentEntity", entityId);
                    navigate(`/app/dashboard?entityId=${entityId}`);
                  } else {
                    setError("Invalid Credentials");
                  }
                } else {
                  setError("Invalid Credentials");
                }
              } catch (error) {
                setError("Invalid Credentials");
              }
            }} */
          >
            Login
          </Button>
          <Text color="red" style={{ fontStyle: "italic" }} mt="xs" size={"sm"}>
            {error}
          </Text>
        </form>
      </Modal>
    </>
  );
}

export default LandingPage;
