import * as React from "react";
import {
  TextInput,
  Button,
  Grid,
  Title,
  Card,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";

import { UserType } from "@supportinitiative/common";

import { escapeRegExp } from "../utilities";
import { useUsers } from "../hooks";
import Loading from "../ui/Loading";
import { styles } from "../styles";

function getIsMatch(filter: string) {
  const isMatch = (user: UserType) => {
    const re = new RegExp(escapeRegExp(filter), "i");
    return re.test(user.userId) || re.test(user.userName);
  };
  return isMatch;
}

export default function Users() {
  let [searchParams] = useSearchParams();
  let entityId = searchParams.get("entityId");

  let [filter, setFilter] = React.useState("");
  const { data: users, isLoading } = useUsers(entityId);
  let [filteredUsers, setFilteredUsers] = React.useState<UserType[]>([]);
  const theme = useMantineTheme();

  React.useEffect(() => {
    if (users) {
      let filteredValues = users.filter(getIsMatch(filter));
      setFilteredUsers(filteredValues);
    }
  }, [users, filter]);

  return (
    <div style={{ marginTop: theme.spacing.lg }}>
      <Title order={3}>Users</Title>
      <Grid align="center" gutter={"xs"} mb="xs">
        <Grid.Col span={3}>
          <TextInput
            placeholder="Search"
            onChange={(event) => {
              setFilter(event.target.value);
              let filteredValues = users.filter(getIsMatch(event.target.value));
              setFilteredUsers(filteredValues);
            }}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <Button
            color="teal"
            styles={() => ({
              root: {
                // margin: "5px",
              },
            })}
            aria-label="New"
            fullWidth
            component={Link}
            to={`/app/users/new?entityId=${entityId}`}
          >
            New
          </Button>
        </Grid.Col>
      </Grid>

      {isLoading ? (
        <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
          <Grid.Col span={3}>
            <Loading />
          </Grid.Col>
        </Grid>
      ) : (
        <>
          {filteredUsers.length > 0 ? (
            <Grid align="center" gutter={"xs"} mb="xs">
              {filteredUsers.map((user) => {
                return (
                  <Grid.Col span={3} key={user.id as unknown as string}>
                    <Card
                      component={Link}
                      to={`/app/users/edit?id=${user.id}&entityId=${entityId}`}
                      shadow="sm"
                      p="xs"
                      data-testid="user"
                    >
                      <Text fw={500}>{user.userId}</Text>
                      <Text
                        size="sm"
                        style={{
                          color: theme.colors.gray[7],
                          lineHeight: 1.5,
                        }}
                      >
                        {user.userName}
                      </Text>
                    </Card>
                  </Grid.Col>
                );
              })}
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: theme.spacing.lg,
              }}
            >
              <Text fw={500} style={styles.nodata} data-testid="nodata">
                No Users
              </Text>
            </div>
          )}
        </>
      )}
    </div>
  );
}
