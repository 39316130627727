import * as React from "react";

import { IDispatchAction } from "../types";

interface IUserContextProps {
  state: any;
  dispatch: React.Dispatch<IDispatchAction<any>>;
}

export const UserContext = React.createContext<IUserContextProps>({
  state: {},
  dispatch: () => {},
});
