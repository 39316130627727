import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  IconCircleDashedCheck,
  IconEdit,
  IconHome,
  IconTrash,
} from "@tabler/icons-react";

import {
  Title,
  Text,
  Badge,
  Grid,
  Button,
  Paper,
  Tooltip,
  Modal,
  ActionIcon,
  Divider,
  useMantineTheme,
  Flex,
  Stack,
} from "@mantine/core";
import { useSearchParams } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { IconX, IconCheck } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";

import { deleteFromMatchTemp, updateMatch } from "../../../api";

import {
  useReconsetBasic,
  useReconsetColumnsDefinition,
  useReconsetMatchDetails,
  useReconsetMatchTempDetails,
} from "../../../hooks";
import { styles } from "../../../styles";
import Loading from "../../../ui/Loading";
import { getColor } from "../../ReconsetUtils";
import DataDisplay from "../DataDisplay";
import { getSystemStatus } from "../../ReconsetUtils";
import { showError } from "../../../utilities";
import DeleteFromMatch from "../DeleteFromMatch";
import Comments from "../Comments";
import { set } from "react-hook-form";
import UpdateMatchTemp from "./UpdateMatchTemp";
import FinalizeMatchTemp from "./FinalizeMatchTemp";

export default function MatchTemp() {
  let [searchParams] = useSearchParams();

  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId"),
    matchId = searchParams.get("matchId"),
    runId = searchParams.get("runId"),
    runSeq = searchParams.get("runSeq"),
    recon = searchParams.get("recon");

  const theme = useMantineTheme();

  const queryClient = useQueryClient();

  const { data: reconset } = useReconsetBasic(entityId, id);

  const { data: matchDetails, error } = useReconsetMatchTempDetails(
    entityId,
    id,
    runId,
    recon,
    matchId
  );

  const { data: columnsDefinition } = useReconsetColumnsDefinition(
    entityId,
    id
  );

  const reconDefinition = reconset?.definition.recons.find(
    (reconDef) => reconDef.id === recon
  );

  const reconColumnsDefinition = columnsDefinition?.recons.find(
    (reconColumns) => reconColumns.id === recon
  );

  const [primarySelection, setPrimarySelection] = React.useState<string[]>([]);

  const primaryToggleRow = (__id: string) =>
    setPrimarySelection((current) =>
      current.includes(__id)
        ? current.filter((item) => item !== __id)
        : [...current, __id]
    );

  const primaryToggleAll = () =>
    setPrimarySelection((current) =>
      current.length === matchDetails?.primaryDataset.length
        ? []
        : matchDetails?.primaryDataset.map((item: any) => item.__id)
    );

  const [secondarySelection, setSecondarySelection] = React.useState<string[]>(
    []
  );

  const secondaryToggleRow = (__id: string) =>
    setSecondarySelection((current) =>
      current.includes(__id)
        ? current.filter((item) => item !== __id)
        : [...current, __id]
    );

  const secondaryToggleAll = () =>
    setSecondarySelection((current) =>
      current.length === matchDetails?.secondaryDataset.length
        ? []
        : matchDetails?.secondaryDataset.map((item: any) => item.__id)
    );

  const [display, setDisplay] = React.useState<string>("none");

  const navigate = useNavigate();

  if (reconset && matchDetails) {
    return (
      <>
        <div style={{ marginTop: theme.spacing.lg }}>
          <div id="header">
            <Grid
              align="flex-end"
              gutter={"xs"}
              mt="xs"
              mb="xs"
              // mb="xs"
              style={{
                display: "flex",
                // borderLeft: `${theme.spacing.xxs} solid ${theme.colors.emerald[7]}`,
              }}
              columns={12}
            >
              <Grid.Col span={3}>
                <Flex align={"end"} gap={theme.spacing.xs}>
                  <Stack gap={0}>
                    <Title order={3} c="dark">
                      {reconset.name}
                    </Title>
                    <Text size="sm" c="dark">
                      {reconset.displayName}
                    </Text>
                  </Stack>
                  <Tooltip label="Home">
                    <ActionIcon
                      variant="light"
                      size="lg"
                      ml="lg"
                      mr="xs"
                      onClick={() => {
                        navigate(
                          `/app/reconsets/view?id=${id}&entityId=${entityId}`
                        );
                      }}
                    >
                      <IconHome
                        style={{ width: "70%", height: "70%" }}
                        stroke={2}
                      />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Grid.Col>
            </Grid>
          </div>
          <Divider size="lg" color="dark.2" />

          <Flex mt="lg">
            <Title
              mt="xs"
              order={4}
              // c="white"
              py={0}
              px={theme.spacing.xs}
              style={{
                borderLeft: `${theme.spacing.xxs} solid ${theme.colors.emerald[2]}`,
              }}
            >
              [ Temp ] - {matchDetails.details.id} -{" "}
              {matchDetails.details.actionName}
            </Title>

            <Tooltip label="Details">
              <ActionIcon
                variant="light"
                size="lg"
                ml="xs"
                mr="xs"
                onClick={() => setDisplay("details")}
              >
                <IconEdit style={{ width: "70%", height: "70%" }} stroke={2} />
              </ActionIcon>
            </Tooltip>

            <Tooltip label="Finalize">
              <ActionIcon
                variant="light"
                size="lg"
                mr="xs"
                onClick={() => setDisplay("finalize")}
              >
                <IconCircleDashedCheck
                  style={{ width: "70%", height: "70%" }}
                  stroke={2}
                />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Delete complete match">
              <ActionIcon
                variant="light"
                size="lg"
                mr="xs"
                onClick={async () => {
                  try {
                    await deleteFromMatchTemp(
                      id as string,
                      entityId as string,
                      recon as string,
                      runId as string,
                      matchId as string,
                      "ALL",
                      {}
                    );

                    navigate(
                      `/app/reconsets/view?id=${id}&entityId=${entityId}`
                    );

                    showNotification({
                      id: "success",
                      title: "Match deletion",
                      message: "Draft/Temp match deleted successfully",
                      color: "teal",
                      icon: <IconCheck />,
                    });
                  } catch (error: any) {
                    showError(
                      error,
                      "Error in match deletion",
                      "Unable to delete match",
                      <IconX />
                    );
                  }
                }}
              >
                <IconTrash style={{ width: "70%", height: "70%" }} stroke={2} />
              </ActionIcon>
            </Tooltip>
          </Flex>
          <Flex mt="xs" mb="xxl">
            <Badge ml="xs" radius="xs" variant="light">
              {matchDetails.details.systemStatus}
            </Badge>
            <Badge ml="xs" radius="xs" variant="light">
              {matchDetails.details.userStatus}
            </Badge>
            <Badge ml="xs" radius="xs" variant="light">
              {matchDetails.details.confirmationStatus}
            </Badge>
          </Flex>

          <Stack mb="xxl">
            <Flex align="center">
              <Text
                fw={"bold"}
                c="dark.6"
                style={{
                  borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
                  paddingLeft: theme.spacing.xs,
                }}
              >
                Primary Dataset
              </Text>
              <Tooltip label="Delete from match">
                <ActionIcon
                  variant="light"
                  size="lg"
                  ml="xs"
                  mr="xs"
                  onClick={async () => {
                    try {
                      await deleteFromMatchTemp(
                        id as string,
                        entityId as string,
                        recon as string,
                        runId as string,
                        matchId as string,
                        "ENTRY",
                        {
                          primaryIds: primarySelection,
                        }
                      );

                      queryClient.invalidateQueries([
                        "reconsetMatchTempDetails",
                        entityId,
                        id,
                        runId,
                        recon,
                        matchId,
                      ]);

                      setPrimarySelection([]);

                      showNotification({
                        id: "success",
                        title: "Match deletion",
                        message: "Draft/Temp entries deleted successfully",
                        color: "teal",
                        icon: <IconCheck />,
                      });
                    } catch (error: any) {
                      showError(
                        error,
                        "Error in match entries deletion",
                        "Unable to delete match entries",
                        <IconX />
                      );
                    }
                  }}
                  disabled={primarySelection.length === 0}
                >
                  <IconTrash
                    style={{ width: "70%", height: "70%" }}
                    stroke={2}
                  />
                </ActionIcon>
              </Tooltip>
            </Flex>

            <DataDisplay
              data={matchDetails.primaryDataset} // Pass as array
              table="details"
              definition={
                reconColumnsDefinition && reconColumnsDefinition?.primaryDataSet
                  ? reconColumnsDefinition?.primaryDataSet
                  : []
              }
              links={[
                {
                  name: "__matchids",
                  split: true,
                  splitBy: ",",
                  link: `/app/reconsets/view/match?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&recon=${recon}&matchId=`,
                },
              ]}
              enableSelection
              selection={primarySelection}
              toggleAll={primaryToggleAll}
              toggleRow={primaryToggleRow}
            />
          </Stack>

          {reconDefinition?.definition.noOfDatasets === "TWO" && (
            <Stack mb="xxl">
              <Flex align="center">
                <Text
                  fw={"bold"}
                  c="dark.6"
                  style={{
                    borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
                    paddingLeft: theme.spacing.xs,
                  }}
                >
                  Secondary Dataset
                </Text>
                <Tooltip label="Delete from match">
                  <ActionIcon
                    variant="light"
                    size="lg"
                    ml="xs"
                    mr="xs"
                    onClick={async () => {
                      try {
                        await deleteFromMatchTemp(
                          id as string,
                          entityId as string,
                          recon as string,
                          runId as string,
                          matchId as string,
                          "ENTRY",
                          {
                            secondaryIds: secondarySelection,
                          }
                        );

                        queryClient.invalidateQueries([
                          "reconsetMatchTempDetails",
                          entityId,
                          id,
                          runId,
                          recon,
                          matchId,
                        ]);

                        setSecondarySelection([]);

                        showNotification({
                          id: "success",
                          title: "Match deletion",
                          message: "Draft/Temp entries deleted successfully",
                          color: "teal",
                          icon: <IconCheck />,
                        });
                      } catch (error: any) {
                        showError(
                          error,
                          "Error in match entries deletion",
                          "Unable to delete match entries",
                          <IconX />
                        );
                      }
                    }}
                    disabled={secondarySelection.length === 0}
                  >
                    <IconTrash
                      style={{ width: "70%", height: "70%" }}
                      stroke={2}
                    />
                  </ActionIcon>
                </Tooltip>
              </Flex>

              <DataDisplay
                data={matchDetails.secondaryDataset} // Pass as array
                table="details"
                definition={
                  reconColumnsDefinition &&
                  reconColumnsDefinition?.secondaryDataset
                    ? reconColumnsDefinition?.secondaryDataset
                    : []
                }
                links={[
                  {
                    name: "__matchids",
                    split: true,
                    splitBy: ",",
                    link: `/app/reconsets/view/match?entityId=${entityId}&id=${id}&runId=${runId}&runSeq=${runSeq}&recon=${recon}&matchId=`,
                  },
                ]}
                enableSelection
                selection={secondarySelection}
                toggleAll={secondaryToggleAll}
                toggleRow={secondaryToggleRow}
              />
            </Stack>
          )}

          <Stack mb="xxl">
            <Text
              fw={"bold"}
              c="dark.6"
              style={{
                borderLeft: `${theme.spacing.xxs} solid ${theme.colors.blue[4]}`,
                paddingLeft: theme.spacing.xs,
              }}
            >
              Comments
            </Text>
            <Comments
              id={id as string}
              entityId={entityId as string}
              recon={recon as string}
              runId={runId as string}
              matchId={matchId as string}
              type={"TEMP"}
            />
          </Stack>
        </div>

        <>
          <Modal
            centered
            size={"xl"}
            opened={display === "details"}
            onClose={() => setDisplay("none")}
            title={<Title order={4}>Details</Title>}
          >
            <UpdateMatchTemp
              id={id as string}
              entityId={entityId as string}
              runId={runId as string}
              matchId={matchId as string}
              recon={recon as string}
              match={matchDetails.details}
              closeDialog={() => setDisplay("none")}
              systemStatusLOV={getSystemStatus("ALL")}
              userStatusLOV={(reconset.definition.userStatus || []).map(
                (status) => {
                  return { label: status, value: status };
                }
              )}
            />
          </Modal>

          <Modal
            centered
            size={"xl"}
            opened={display === "finalize"}
            onClose={() => setDisplay("none")}
            title={<Title order={4}>Finalize Match Temp</Title>}
          >
            <FinalizeMatchTemp
              id={id as string}
              entityId={entityId as string}
              runId={runId as string}
              matchId={matchId as string}
              recon={recon as string}
              match={matchDetails.details}
              closeDialog={() => setDisplay("none")}
              systemStatusLOV={getSystemStatus(reconDefinition?.type)}
              userStatusLOV={(reconset.definition.userStatus || []).map(
                (status) => {
                  return { label: status, value: status };
                }
              )}
            />
          </Modal>
        </>
      </>
    );
  } else {
    if (error) {
      return (
        <Grid
          align="flex-start"
          gutter={"xs"}
          m="0"
          mt="lg"
          mb="xs"
          pl="md"
          pr="md"
          style={{
            display: "flex",
            alignItems: "flex-end",
            borderLeft: "5px solid #12B886",
          }}
        >
          <Grid.Col
            span={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Paper
              shadow="xs"
              p="xs"
              style={{ width: "100%", textAlign: "center" }}
            >
              <Text c="gray.7" fw="bold">
                No details for match - {matchId}
              </Text>
            </Paper>
          </Grid.Col>
        </Grid>
      );
    } else {
      return (
        <div style={styles.viewMain}>
          <div style={styles.viewBody} id="body">
            <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
              <Grid.Col span={4}>
                <Loading />
              </Grid.Col>
            </Grid>
          </div>
        </div>
      );
    }
  }
}
