import {
  Divider,
  Blockquote,
  Grid,
  Paper,
  Group,
  Title,
  Text,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

function getColorStyles(color: string | null | undefined) {
  switch ((color || "").toUpperCase()) {
    case "TEAL":
      return { backgroundColor: "rgba(166, 227, 233, 0.5)" };
    case "PINK":
      return { backgroundColor: "rgba(240, 168, 208, .5)" };
    case "RED":
      return { backgroundColor: "rgba(255, 212, 212, 0.5)" };
    case "BLUE":
      return { backgroundColor: "rgba(34, 139, 230, 0.35)" };
    default:
      return { backgroundColor: "rgba(255, 255, 255, 0.5)" };
  }
}

function getColorByType(type: string | null | undefined) {
  switch ((type || "").toUpperCase()) {
    case "WARNING":
      return { backgroundColor: "rgba(250, 82, 82, 0.5)" };
    case "ERROR":
      return { backgroundColor: "rgba(255, 212, 212, 0.5)" };
    case "INFO":
      return { backgroundColor: "rgba(34, 139, 230, 0.2)" };
    default:
      return { backgroundColor: "rgba(255, 255, 255, 0.5)" };
  }
}

export default function SummaryStats({
  summaryStats,
}: {
  summaryStats: {
    type: string;
    header: string;
    value: string;
    valueType?: string;
    additionalContext?: string;
    increaseOrDecrease?: string;
    color?: string;
  }[];
}) {
  if (summaryStats && summaryStats.length > 0) {
    return (
      <Grid align="flex-start" gutter={"xs"} columns={12}>
        {summaryStats.map((summaryStat, index) => {
          return (
            <Grid.Col span={3} mb="xs" key={summaryStat.header}>
              <Paper p="xs" style={{ ...getColorStyles(summaryStat.color) }}>
                <Group justify="apart">
                  <div>
                    <Title order={5} c="gray.9">
                      {summaryStat.header}
                    </Title>

                    <Text fw="bold" size="xl" color="gray.9">
                      {summaryStat.value}
                    </Text>
                    <Text
                      color="gray.7"
                      size="xs"
                      style={{ fontStyle: "italic" }}
                    >
                      {summaryStat.type} / {summaryStat.additionalContext}
                    </Text>
                  </div>
                </Group>
              </Paper>
            </Grid.Col>
          );
        })}
      </Grid>
    );
  } else {
    return <Divider label="No Summary Stats" labelPosition="left" />;
  }
}
