import * as React from "react";
import { Grid, Title, Text, Modal, Button } from "@mantine/core";
import { useSearchParams, useNavigate } from "react-router-dom";
import { IconX, IconCheck } from "@tabler/icons-react";
import { showNotification } from "@mantine/notifications";

import { styles } from "../styles";
import { useLoaderBasic, useLoaderStatus } from "../hooks";
import Loading from "../ui/Loading";
import { UserContext } from "../misc/UserContext";
import UploadCSVFile from "./View/UploadCSVFile";
import DataDisplay from "../Reconset/View/DataDisplay";
import { runLoader } from "../api";
import { showError } from "../utilities";

export default function Loader() {
  let [searchParams] = useSearchParams();
  let id = searchParams.get("id"),
    entityId = searchParams.get("entityId");

  const { data: loader } = useLoaderBasic(entityId, id);
  const [display, setDisplay] = React.useState<string>("none");
  const { data: status } = useLoaderStatus(entityId, id);
  const navigate = useNavigate();
  const [running, setRunning] = React.useState(false);

  const { state: applicationState, dispatch: applicationStateDispatch } =
    React.useContext(UserContext);

  if (loader) {
    return (
      <div style={styles.viewMain}>
        <div style={styles.viewHeader} id="header">
          <Title order={3}>{loader.name}</Title>
          <Text size="sm">{loader.displayName}</Text>
        </div>

        <div style={styles.viewBody}>
          <Grid
            align="flex-start"
            gutter={"xs"}
            mb="xs"
            style={{ display: "flex", alignItems: "flex-end" }}
          >
            <Grid.Col
              span={1}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="default"
                color="teal"
                fullWidth
                onClick={() => setDisplay("log")}
              >
                Log
              </Button>
            </Grid.Col>
            <Grid.Col
              span={1}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                variant="default"
                color="teal"
                fullWidth
                onClick={() =>
                  navigate(`/app/loaders/edit?id=${id}&entityId=${entityId}`)
                }
              >
                Definition
              </Button>
            </Grid.Col>
          </Grid>
        </div>

        <>
          <Modal
            centered
            size={"xl"}
            opened={display === "log"}
            onClose={() => setDisplay("none")}
            title={<Title order={4}>Log</Title>}
          >
            <DataDisplay
              data={status ? status : []} // Pass as array
              links={[]}
              table="log"
              definition={[]}
            />
          </Modal>
        </>
      </div>
    );
  } else {
    return (
      <div style={styles.viewMain}>
        <div style={styles.viewBody} id="body">
          <Grid align="center" gutter={"xs"} mb="xs" mt="lg">
            <Grid.Col span={4}>
              <Loading />
            </Grid.Col>
          </Grid>
        </div>
      </div>
    );
  }
}
